import React from 'react';
import SocialIcons from './SocialIcons';

function Home({ integrations, profile }) {
    return (
        <div id='home-section' className='home-section'>
            <div className='home-section-wrapper'>
                <div className='home-section-image-wrapper'>
                    <img
                        src={profile.pictureurl}
                        sizes='100vw'
                        alt
                        style={{
                            objectFit: 'cover',
                            width: '100vw',
                            maxHeight: '200vh',
                            paddingTop: '50px',
                        }}
                    />
                </div>
                <div className='home-section-menu-wrapper'>
                    <div
                        data-collapse='tiny'
                        data-animation='default'
                        data-duration={400}
                        role='banner'
                        className='navbar w-nav'
                    >
                        <div className='container w-container'></div>
                    </div>
                    <SocialIcons integrations={integrations} />
                </div>
            </div>
        </div>
    );
}

export default Home;
