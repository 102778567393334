import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import Color from 'color';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { openUrl } from '../../utils/action';
import DOMPurify from 'dompurify';
import { decode } from 'html-entities';

const useStyles = makeStyles(() => ({
    root: ({ backgroundColor, roundedCorners }) => ({
        margin: 'auto',
        borderRadius: roundedCorners ? 12 : 0,
        padding: 12,
        backgroundColor,
        '& :hover': {
            cursor: 'pointer',
        },
    }),
    media: ({ roundedCorners }) => ({
        borderRadius: roundedCorners ? 6 : 0,
    }),
    overline: ({ textColor }) => ({
        display: 'block',
        textAlign: 'center',
        color: textColor,
        letterSpacing: '2px',
        fontSize: 14,
        marginTop: 12,
    }),
    title: ({ textColor }) => ({
        textAlign: 'center',
        fontSize: 32,
        lineHeight: 1.5,
        fontWeight: 300,
        // eslint-disable-next-line max-len
        marginBottom: '0.72em',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
        height: '3.3em',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
        '&:after': {
            content: '""',
            width: 24,
            height: 2,
            backgroundColor: textColor,
            display: 'block',
            margin: '8px auto',
            borderRadius: 2,
        },
    }),
    body: {
        width: '88%',
        margin: '0px auto',
        fontSize: '14px',
        textAlign: 'center',
        lineHeight: '1.75',
    },
    useFourThreeMediaStyles: {
        width: '100%',
        height: 0,
        paddingBottom: '75%',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    useOverShadowStyles: {
        boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
        transition: '0.3s',
    },
}));

export const Card3 = ({
    title,
    subtitle,
    note1,
    media,
    url,
    textColor,
    backgroundColor,
    roundedCorners,
}) => {
    const styles = useStyles({ textColor, backgroundColor, roundedCorners });
    let cleanTitle = DOMPurify.sanitize(title, { SAFE_FOR_TEMPLATES: true });
    return (
        <Card
            className={cx(styles.root, styles.useOverShadowStyles)}
            onClick={() => openUrl(url)}
        >
            <CardMedia
                className={cx(styles.media, styles.useFourThreeMediaStyles)}
                image={media}
            />
            <CardContent>
                <Typography
                    variant='overline'
                    component='span'
                    className={styles.overline}
                >
                    {note1}
                </Typography>
                <Typography
                    variant='h4'
                    className={styles.title}
                    sx={{ color: textColor }}
                >
                    {decode(cleanTitle)}
                </Typography>
                <Typography
                    variant='body1'
                    component='p'
                    className={styles.body}
                    sx={{ color: textColor }}
                >
                    {subtitle && subtitle.length > 220
                        ? subtitle.substr(0, 220) + '...'
                        : subtitle}
                </Typography>
            </CardContent>
        </Card>
    );
};

const title = 'Card Option 3';
const detailedEditorDefinition = {
    'card.textColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.textColor',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#000000',
    },
    'card.backgroundColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.backgroundColor',
        label: 'Background Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
    'card.roundedCorners': {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'card.roundedCorners',
        label: 'Rounded Corners',
        description: 'Creates rounded corners on each card',
        isRequired: false,
    },
};

Card3.propTypes = {
    title: PropTypes.string.isRequired,
    media: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    note1: PropTypes.string,
    url: PropTypes.string,
    textcolor: detailedEditorDefinition['card.textColor'].propType,
    backgroundColor: detailedEditorDefinition['card.backgroundColor'].propType,
    roundedCorners: detailedEditorDefinition['card.roundedCorners'].propType,
};

export { Card3 as default, title, detailedEditorDefinition };
