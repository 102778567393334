import React, { useContext } from 'react';
import DetailedEditor from '../../shared/editor/DetailedEditor';
import { detailedEditorDefinition } from './NavigationSection';
import { CompContext } from '../../context/CompContext';

const NavigationDetailedEditor = () => {
    const compContext = useContext(CompContext);
    const componentConfig = compContext.getComponentConfig('navigation');
    const { displaySocialIcons, monoIcons } = componentConfig.userDefinedProps;

    const copy = {...detailedEditorDefinition}

    !displaySocialIcons && delete copy.monoIcons;
    (!displaySocialIcons || !monoIcons) && delete copy.iconColor;

    return (
        <>
        <DetailedEditor detailedEditorDefinition={copy} componentViewKey={'navigation'} />
        </>
    );
};

export default NavigationDetailedEditor;
