import _get from 'lodash.get';
import Card1, { title as Card1Title, detailedEditorDefinition as Card1DetailedEditorDefinition } from './Card1';
import Card2, { title as Card2Title, detailedEditorDefinition as Card2DetailedEditorDefinition } from './Card2';
import Card3, { title as Card3Title, detailedEditorDefinition as Card3DetailedEditorDefinition } from './Card3';
import Card4, { title as Card4Title, detailedEditorDefinition as Card4DetailedEditorDefinition } from './Card4';
import Card5, { title as Card5Title, detailedEditorDefinition as Card5DetailedEditorDefinition } from './Card5';
import Card6, { title as Card6Title, detailedEditorDefinition as Card6DetailedEditorDefinition } from './Card6';
import Card7, { title as Card7Title, detailedEditorDefinition as Card7DetailedEditorDefinition } from './Card7';
import Card8, { title as Card8Title, detailedEditorDefinition as Card8DetailedEditorDefinition } from './Card8';
import Card9, { title as Card9Title, detailedEditorDefinition as Card9DetailedEditorDefinition } from './Card9';
import Card10, { title as Card10Title, detailedEditorDefinition as Card10DetailedEditorDefinition } from './Card10';

const cardList = {
    card1: {
        key: 'card1',
        component: Card1,
        title: Card1Title,
        detailedEditorDefinition: Card1DetailedEditorDefinition,
    },
    card2: {
        key: 'card2',
        component: Card2,
        title: Card2Title,
        detailedEditorDefinition: Card2DetailedEditorDefinition,
    },
    card3: {
        key: 'card3',
        component: Card3,
        title: Card3Title,
        detailedEditorDefinition: Card3DetailedEditorDefinition,
    },
    card4: {
        key: 'card4',
        component: Card4,
        title: Card4Title,
        detailedEditorDefinition: Card4DetailedEditorDefinition,
    },
    card5: {
        key: 'card5',
        component: Card5,
        title: Card5Title,
        detailedEditorDefinition: Card5DetailedEditorDefinition,
    },
    card6: {
        key: 'card6',
        component: Card6,
        title: Card6Title,
        detailedEditorDefinition: Card6DetailedEditorDefinition,
    },
    card7: {
        key: 'card7',
        component: Card7,
        title: Card7Title,
        detailedEditorDefinition: Card7DetailedEditorDefinition,
    },
    card8: {
        key: 'card8',
        component: Card8,
        title: Card8Title,
        detailedEditorDefinition: Card8DetailedEditorDefinition,
    },
    card9: {
        key: 'card9',
        component: Card9,
        title: Card9Title,
        detailedEditorDefinition: Card9DetailedEditorDefinition,
    },
    card10: {
        key: 'card10',
        component: Card10,
        title: Card10Title,
        detailedEditorDefinition: Card10DetailedEditorDefinition,
    },
};

export { cardList };
