import React, { useState, useContext } from 'react';
import PageContainer from '../shared/pages/pageContainer/index';

import TemplateOrchestrator from './TemplateOrchestrator';
import { CreatorContext } from '../context/creatorContext';

import LoadingComponent from '../components/LoadingComponent';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WebBuilderPage = (props) => {
    const creatorContext = useContext(CreatorContext);

    const [openOptions, setOpenOptions] = useState(false);
    const [tabOption, setTabOption] = useState('');

    const setOptionsOpenValue = (value, tab) => {
        const optionValue = tabOption === tab ? !value : value || !value;
        setOpenOptions(optionValue);
        setTabOption(tab);
    };

    const renderLoading = () => {
        return <LoadingComponent title='Loading builder...' />;
    };

    if (!creatorContext || creatorContext.isLoading) {
        return renderLoading();
    }

    return (
        <>
            <ToastContainer />
            <PageContainer
                openOptions={openOptions}
                setOptionsOpenValue={setOptionsOpenValue}
                {...props}
            >
                <TemplateOrchestrator
                    openOptions={openOptions}
                    key={props.id}
                    {...props}
                    isEditor={true}
                />
            </PageContainer>
        </>
    );
};

export default WebBuilderPage;
