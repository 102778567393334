import isEmpty from 'lodash.isempty';
import moment from 'moment';

const getMonthDayYearFormat = (date) => {
    if (isEmpty(date)) {
        return '';
    }
    const dateFormatted = moment(date).utc().format('MMMM D, YYYY');
    return dateFormatted;
};

export { getMonthDayYearFormat };
