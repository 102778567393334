const MusicLayout = (props) => {
    const { layout, music, renderItem, numItems } = props;
    switch (layout) {
        default:
            return music
                .slice(0, numItems ? numItems : 4)
                .map((item) => renderItem(item));
    }
}

export default MusicLayout;
