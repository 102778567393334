import React from "react";
import isEmpty from 'lodash.isempty';

function MusicSection({ music }) {
  return (
    <div id="music-section" className="music-section">
      {!isEmpty(music) && (
        <div className="music-section-wrapper">
          <div className="music-header-wrapper">
            <h1 className="section-header">Music</h1>
          </div>
          <div className="music-collection">
            <div className="music-item-wrapper">
              <div className="music-item-title">
                <h5 className="music-title-header">While You Were Sleeping</h5>
              </div>
              <div className="music-item-image">
                <img
                  src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3837a11efc23_5f10a8acd6076ef471ddd3a1_Screen%20Shot%202020-07-16%20at%202.19.53%20PM.png"
                  sizes="(max-width: 479px) 87vw, (max-width: 767px) 69vw, (max-width: 991px) 37vw, 20vw"
                  srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3837a11efc23_5f10a8acd6076ef471ddd3a1_Screen%2520Shot%25202020-07-16%2520at%25202.19.53%2520PM-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3837a11efc23_5f10a8acd6076ef471ddd3a1_Screen%20Shot%202020-07-16%20at%202.19.53%20PM.png 716w"
                  alt
                  className="music-image"
                />
              </div>
              <div className="music-item-buttons">
                <div className="streaming-button-div">
                  <a href="#" className="streaming-button w-button">
                    Stream Music{" "}
                  </a>
                </div>
                <div className="purchase-button-div">
                  <a href="#" className="purchase-button w-button">
                    Purchase Music
                  </a>
                </div>
              </div>
            </div>
            <div className="music-item-wrapper">
              <div className="music-item-title">
                <h5 className="music-title-header">Unsigned</h5>
              </div>
              <div className="music-item-image">
                <img
                  src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3846441efc39_Cal%20Scruby%20Unsigned%20Album.jpg"
                  sizes="(max-width: 479px) 87vw, (max-width: 767px) 69vw, (max-width: 991px) 37vw, 20vw"
                  srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3846441efc39_Cal%2520Scruby%2520Unsigned%2520Album-p-500.jpeg 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3846441efc39_Cal%2520Scruby%2520Unsigned%2520Album-p-800.jpeg 800w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3846441efc39_Cal%2520Scruby%2520Unsigned%2520Album-p-1080.jpeg 1080w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3846441efc39_Cal%20Scruby%20Unsigned%20Album.jpg 1200w"
                  alt
                  className="music-image"
                />
              </div>
              <div className="music-item-buttons">
                <div className="streaming-button-div">
                  <a href="#" className="streaming-button w-button">
                    Stream Music{" "}
                  </a>
                </div>
                <div className="purchase-button-div">
                  <a href="#" className="purchase-button w-button">
                    Purchase Music
                  </a>
                </div>
              </div>
            </div>
            <div className="music-item-wrapper">
              <div className="music-item-title">
                <h5 className="music-title-header">House in the Hills</h5>
              </div>
              <div className="music-item-image">
                <img
                  src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d387af91efc3d_Cal%20Scruby%20HITH%20Album.jpg"
                  sizes="(max-width: 479px) 87vw, (max-width: 767px) 69vw, (max-width: 991px) 37vw, 20vw"
                  srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d387af91efc3d_Cal%2520Scruby%2520HITH%2520Album-p-500.jpeg 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d387af91efc3d_Cal%2520Scruby%2520HITH%2520Album-p-800.jpeg 800w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d387af91efc3d_Cal%2520Scruby%2520HITH%2520Album-p-1080.jpeg 1080w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d387af91efc3d_Cal%20Scruby%20HITH%20Album.jpg 1200w"
                  alt
                  className="music-image"
                />
              </div>
              <div className="music-item-buttons">
                <div className="streaming-button-div">
                  <a href="#" className="streaming-button w-button">
                    Stream Music{" "}
                  </a>
                </div>
                <div className="purchase-button-div">
                  <a href="#" className="purchase-button w-button">
                    Purchase Music
                  </a>
                </div>
              </div>
            </div>
            <div className="music-item-wrapper">
              <div className="music-item-title">
                <h5 className="music-title-header">While You Were Sleeping</h5>
              </div>
              <div className="music-item-image">
                <img
                  src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38a7891efc38_Cal%20Scruby%20WWWS%20Album.jpg"
                  sizes="(max-width: 479px) 87vw, (max-width: 767px) 69vw, (max-width: 991px) 37vw, 20vw"
                  srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38a7891efc38_Cal%2520Scruby%2520WWWS%2520Album-p-500.jpeg 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38a7891efc38_Cal%2520Scruby%2520WWWS%2520Album-p-800.jpeg 800w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38a7891efc38_Cal%2520Scruby%2520WWWS%2520Album-p-1080.jpeg 1080w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38a7891efc38_Cal%20Scruby%20WWWS%20Album.jpg 1200w"
                  alt
                  className="music-image"
                />
              </div>
              <div className="music-item-buttons">
                <div className="streaming-button-div">
                  <a href="#" className="streaming-button w-button">
                    Stream Music{" "}
                  </a>
                </div>
                <div className="purchase-button-div">
                  <a href="#" className="purchase-button w-button">
                    Purchase Music
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MusicSection;
