import React from 'react'
import Carousel from 'react-multi-carousel';
import CustomArrow from '../../../shared/CustomArrow'
import CustomDot from "../../../shared/CustomDot";
import isEmpty from 'lodash.isempty';

function MusicSection({ music }) {

    return (
        <div id="music-section" className="music-section">
            {!isEmpty(music) && (
                <div className="music-section-wrapper">
                    <div className="music-section-header-wrapper">
                        <h1 className="section-header-2">Music</h1>
                    </div>
                    <div className="music-slider-wrapper">
                        <div>
                            <Carousel
                                containerClass="music-slider w-slider"
                                itemClass="music-slides w-slide"
                                sliderClass="music-slider-mask w-slider-mask"
                                showDots={false}
                                infinite
                                partialVisible
                                showArrows
                                customDot={<CustomDot />}
                                customRightArrow={<CustomArrow isLeft={false} isMusic={true} />}
                                customLeftArrow={<CustomArrow isLeft={true} isMusic={true} />}
                                renderButtonGroupOutside={true}
                                dotListClass="w-slider-nav w-round"
                                responsive={{
                                    desktop: {
                                        breakpoint: { max: 4000, min: 1024 },
                                        items: 4,
                                        slidesToSlide: 3 // optional, default to 1.
                                    },
                                    tablet: {
                                        breakpoint: { max: 1024, min: 464 },
                                        items: 2,
                                        slidesToSlide: 2 // optional, default to 1.
                                    },
                                    mobile: {
                                        breakpoint: { max: 464, min: 0 },
                                        items: 1,
                                        slidesToSlide: 1 // optional, default to 1.
                                    }
                                }}
                            >
                                {
                                    music && music.map((item, idx) => {
                                        return (
                                            <a
                                                href={item.url}
                                                target='blank'
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    textDecoration: 'none'
                                                }}
                                                key={idx}
                                            >
                                                <div className="album-art-wrapper">
                                                    <img
                                                        src={item.thumbnail}
                                                        sizes="(max-width: 479px) 76vw, (max-width: 767px) 71vw, (max-width: 991px) 38vw, (max-width: 4615px) 26vw, 1200px"
                                                        alt
                                                        className="image-8"
                                                    />
                                                </div>
                                                <div className="album-title-wrapper">
                                                    <h3 className="album-title-header">
                                                        {item.title}
                                                    </h3>
                                                </div>
                                            </a>
                                        )
                                    })
                                }
                            </Carousel>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MusicSection
