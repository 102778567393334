import React from 'react';

function AboutSection() {
    return (
        <div id='about-section' className='about-section'>
            <div className='about-section-wrapper'>
                <div className='section-header'>
                    <h1 className='section-header'>About</h1>
                </div>
                <div className='about-text'>
                    <p className='paragraph'>
                        <strong className='bold-text'>
                            instagram / twitter: @calscruby cal scruby is 6’8” 113 lbs. the guy spends most of his time
                            completely overwhelmed by an existential / identity crisis that results in exponential
                            anxiety. good at rapping, not good at anything else. shit his pants on his last birthday
                            (age 41). expect big things from cal scruby in 2004.
                        </strong>
                    </p>
                </div>
                <div className='about-image'>
                    <img
                        src='https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d385c591efc1e_Cal%20Scruby%20About%20page.jpg'
                        width={590}
                        sizes='(max-width: 479px) 92vw, (max-width: 991px) 74vw, 590px'
                        srcSet='https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d385c591efc1e_Cal%20Scruby%20About%20page-p-800.jpeg 800w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d385c591efc1e_Cal%20Scruby%20About%20page-p-1080.jpeg 1080w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d385c591efc1e_Cal%20Scruby%20About%20page.jpg 1280w'
                        alt
                        className='image-2'
                    />
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
