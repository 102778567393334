import React, { useEffect } from 'react';
import HomeSection from './HomeSection/HomeSection';
import MusicSection from './MusicSection/MusicSection';
import VideoSection from './VideoSection/VideoSection';
import NewsSection from './NewsSection/NewsSection';
import MerchSection from './MerchSection/MerchSection';
import AboutSection from './AboutSection/AboutSection';
import ContactSection from './ContactSection/ContactSection';
import TourSection from './TourSection/TourSection';
import './template4.scss';
import NavSection from './NavSection/NavSection';
import NavBar2 from '../../shared/nav/NavBar2';
import isEmpty from 'lodash.isempty';
import { getNetwork } from '../../../utils/network';
function loadFonts() {
    import('webfontloader').then((WebFont) => {
        WebFont.load({
            google: {
                families: [
                    'Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic',
                    'Lato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic',
                ],
            },
        });
    });
}

const Template4 = ({ integrations, profile, videos, music, news }) => {
    useEffect(() => {
        loadFonts();
    }, []);
    const shopifyNetwork = getNetwork(integrations.integrated, 'SHOPIFY');
    const patreonNetwork = getNetwork(integrations.integrated, 'PATREON');

    return (
        <div className='template4'>
            <div className='body'>
                {/* <NavSection profile={profile} integrations={integrations} /> */}
                <NavBar2
                    profile={profile}
                    routes={[
                        { href: '#home-section-new-album', title: 'Home' },
                        !isEmpty(music) &&
                        { href: '#music-section', title: 'Music' },
                        { href: '#tour-section', title: 'Tour' },
                        !isEmpty(videos) &&
                        { href: '#video-section', title: 'Videos' },
                        !isEmpty(news) &&
                        { href: '#news-section', title: 'News' },
                        { href: '#about-section', title: 'About' },
                        !isEmpty(shopifyNetwork) &&
                        shopifyNetwork.data.url && {
                            href: shopifyNetwork.data.url,
                            title: 'Merch',
                            target: '_blank',
                        },
                        !isEmpty(patreonNetwork) &&
                        patreonNetwork.data.url && {
                            href: patreonNetwork.data.url,
                            title: 'Subscribe',
                            target: '_blank',
                        },
                    ]}
                    mobileNavType={'drawer'}
                    backgroundColor={'black'}
                    textColor={'white'}
                />
                <HomeSection
                    integrations={integrations}
                    profile={profile}
                    music={music}
                />
                <MusicSection music={music} />
                <TourSection profile={profile} />
                <VideoSection videos={videos} />
                <NewsSection news={news} />
                {/* <MerchSection appData={appData} /> */}
                <AboutSection profile={profile} />
                <ContactSection profile={profile} />
            </div>
        </div>
    );
};

export default Template4;
