import React, { useContext, useEffect, useState } from 'react';
import { Modal, Typography, Grid, Button } from '@mui/material/';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import { useAuth0 } from '@auth0/auth0-react';
import { config } from '../config';
import jamfeedClient from '../api/jamfeedClient';
import { levenshtein } from '../utils/levenshteinDistance';
import isEmpty from 'lodash.isempty';
import { CreatorContext } from '../context/creatorContext';
import LoadingComponent from '../components/LoadingComponent';
import { makeStyles } from '@mui/styles';

const copy = {
    title: 'Claim Your Artist',
    description:
        // 'To grant you full access, please verify your connection to this artist. Use your Facebook account to get verified instantly or select Manual Claim and we will review within 1-2 business days.',
        'To grant you full access, please verify your connection to this artist. Click below Manual Claim and we will review within 1-2 business days.',
};

const TYPEFORM_VERIFICATION = 'typeform';
// const FACEBOOK_VERIFICATION = 'facebook';

const SERVER_ERROR = "Woops, seems there's an error";

const useStyles = makeStyles((theme) => ({
    searchBar: {
        width: '100%',
        maxWidth: '400px',
        marginBottom: '1rem',
        height: '1.8rem',
    },
    paper: {
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2, 4, 3),
            height: 'auto',
            paddingTop: 0,
            width: '100%',
            maxWidth: '380px',
        },
        minWidth: '220px',
        position: 'absolute',
        width: '100%',
        maxWidth: '600px',
        backgroundColor: '#FFF',
        boxShadow: theme.shadows[5],
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        borderRadius: 10,
        overflowY: 'auto',
        height: '100%',
        paddingTop: 50,
        outline: 'none',
    },
    wrap: {
        paddingBottom: 15,
        maxWidth: '600px',
    },
    header: {
        textAlign: 'center',
        padding: '20px 10px 16px 10px',
        fontWeight: 'bold',
    },
    description: {
        padding: '20px 10px 16px 10px',
    },
    verifyButton: {
        textTransform: 'none',
        width: '100%',
        height: '60px',
        minWidth: '220px',
        color: '#FFF',
        marginBottom: '42px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px 0',
    },
    img: {
        height: '128px',
        width: '128px',
        objectFit: 'cover',
    },
    unLinkButton: {
        ...theme.typography.button,
        textTransform: 'none',
        width: '100%',
        height: '40px', // Adjust the height as needed
        minWidth: '220px',
        color: '#FFF',
        marginBottom: '42px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '16px 0',
        backgroundColor: theme.palette.error.dark, // Use dark red color from theme.palette.error
        '&:hover': {
            backgroundColor: theme.palette.error.dark, // Use the same dark red color for hover effect
        },
    },
}));

// This function takes a provider and returns a boolean indicating whether the user has a connection with that provider.
const getUserIdentity = async (provider) => {
    let identity = null;
    try {
        const response = await jamfeedClient.get('/auth/user/identity', {
            params: {
                provider: provider,
            },
        });

        identity = response.data.identity;
    } catch (error) {
        console.log('get user identity error = ', error);
        return false;
    }
    return identity;
};

const hasConnectionWith = async (provider) => {
    let providers = [];
    try {
        providers = await jamfeedClient
            .get('/auth/user/identities')
            .then(async (response) => {
                // console.log('providers = ', response.data.providers);
                // return [];
                return response.data.providers;
            });
    } catch (error) {
        return false;
    }
    const hasConnection = providers.includes(provider);
    return hasConnection;
};

const callVerify = async (verification) => {
    return await jamfeedClient.post('/creator/verify', {
        verification,
    });
};

const fbPageGetter = async () => {
    return jamfeedClient
        .get('/auth0/facebook/getuserpages')
        .then((response) => {
            return response.data.pages;
        })
        .catch((error) => {
            console.log('error = ', error);
            return error;
        });
};

const verifyFacebookPage = (page, artistName) => {
    let baseResult = {
        result: false,
        message: `Your ${page.name} Facebook page is not verified`,
    };

    if (levenshtein.isSimilar(page.name, artistName, 11)) {
        return {
            ...baseResult,
            message: `${page.name} and ${artistName} do not match. Please make sure you are claiming the correct artist page`,
        };
    }

    if (page.verification_status === 'not_verified') {
        return { ...baseResult };
    }

    if (page.verification_status === 'gray_verified') {
        return {
            ...baseResult,
            message: `Please verify complete ownership of ${page.name}'s page at Facebook before claiming`,
        };
    }

    return {
        result: true,
        message: `Your ${page.name} Facebook page is verified!`,
    };
};

const ModalContainer = ({ children, open, handleClose }) => {
    const flag = 'typeformFinished';
    const classes = useStyles();
    const creatorContext = useContext(CreatorContext);
    const {
        isLoading: isAuthLoading,
        isAuthenticated,
        getAccessTokenSilently,
    } = useAuth0();

    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    //In case modal is open with flag by typeform we will receive it here and start the verification process:
    const handleVerification = async () => {
        // Remove the source parameter from the URL directory
        const newUrl = window.location.href.replace(/&source=[^&]*/g, '');
        window.history.replaceState(null, null, newUrl);
        setIsLoading(true);
        try {
            let { id } = creatorContext.creator;
            const verification = {
                method: TYPEFORM_VERIFICATION,
                aid: id,
            };
            jamfeedClient.updateToken(await getAccessTokenSilently());
            const response = await callVerify(verification);

            if (response.data.success) {
                handleClose();
                // window.location.href = `${window.location.origin}/w/builder?id=${id}`;
                // setIsLoading(false); //not finishing loading state as it will flash the verify modal again prior rendering
            }
        } catch (err) {
            console.log('err = ', err);
            setErrorMessage(SERVER_ERROR);
        }
    };

    useEffect(() => {
        const url = window.location.href;
        if (url.includes(flag) && !isEmpty(creatorContext.creator.id)) {
            handleVerification();
        }
    }, [creatorContext.creator]);

    return !isLoading && (
            <Modal
                open={open}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
            >
                <div className={classes.paper}>
                    <Grid
                        container
                        direction='column'
                        justifyContent='center'
                        alignItems='center'
                        className={classes.wrap}
                    >
                        {isAuthLoading ? (
                            <LoadingComponent />
                        ) : !isAuthenticated ? (
                            <>
                                <Typography variant='h5' className={classes.header}>
                                    Verify Your artist ownership
                                </Typography>
                                <Typography
                                    variant='body1'
                                    className={classes.description}
                                >
                                    Please log in to continue
                                </Typography>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    className={classes.verifyButton}
                                    onClick={() =>
                                        (window.location.href = `${window.location.origin}/welcome/signin`)
                                    }
                                >
                                    Log in
                                </Button>
                            </>
                        ) : errorMessage ? (
                            <>
                                <Grid item>
                                    <Typography
                                        variant='h5'
                                        className={classes.header}
                                    >
                                        {errorMessage}
                                    </Typography>
                                    <Grid item>
                                        <Typography
                                            variant='body1'
                                            className={classes.description}
                                        >
                                            Please reach out the Jamfeed team for
                                        assistance.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        ) : (
                            children
                        )}
                    </Grid>
                </div>
            </Modal>
            );
};

const CustomDropdown = ({ facebookPages, selectedPage, onSelectionChange }) => {
    const classes = useStyles();

    const handleChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const { value } = event.target;
        if (!isEmpty(value)) {
            onSelectionChange(value);
        }
    };

    return (
        <select
            className={classes.searchBar}
            value={selectedPage}
            onChange={handleChange}
        >
            {selectedPage === null && (
                <option value=''>Select a page...</option>
            )}
            {facebookPages.map((page, index) => (
                <option key={index} value={page.id}>
                    {page.name}
                </option>
            ))}
        </select>
    );
};

const PageSelector = ({
    facebookPages,
    artistName,
    handleClose,
    setIsLoading,
    handleVerifyWithManualClaim,
    handleUnlinkFacebook,
}) => {
    const classes = useStyles();
    const [selectedPage, setSelectedPage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const creatorContext = useContext(CreatorContext);

    useEffect(() => {
        if (errorMessage) {
            console.log('errorMessage = ', errorMessage);
        }
    }, [errorMessage]);

    // const handleVerification = async () => {
    //     setErrorMessage(null);
    //     setIsLoading(true);
    //     const page = facebookPages.find((page) => page.id === selectedPage);
    //     const isVerified = verifyFacebookPage(page, artistName);
    //     const result = isVerified.result;
    //     if (result) {
    //         try {
    //             let { id } = creatorContext.creator;
    //             const response = await callVerify({
    //                 method: FACEBOOK_VERIFICATION,
    //                 aid: id,
    //             });
    //             if (response.data.success) {
    //                 setIsLoading(false);
    //                 return handleClose();
    //             }
    //         } catch (err) {
    //             console.log('err = ', err);
    //             setErrorMessage(SERVER_ERROR);
    //             setSelectedPage(null);
    //             setIsLoading(false);
    //         }
    //     } else {
    //         setErrorMessage(isVerified.message);
    //         setIsLoading(false);
    //     }
    // };

    const ManualVerification = ({ message = 'or continue with' }) => (
        <>
            <Grid item>
                <Typography variant='body1' className={classes.description}>
                    {message}
                </Typography>
            </Grid>
            <Grid item>
                <Button
                    variant='contained'
                    className={classes.verifyButton}
                    onClick={handleVerifyWithManualClaim}
                >
                    Manual verification
                </Button>
            </Grid>
        </>
    );

    if (errorMessage !== SERVER_ERROR) {
        return (
            <>
                <Grid item>
                    <Typography variant='body1' className={classes.description}>
                        Please select your artist Facebook Page
                    </Typography>
                </Grid>
                <Grid item>
                    <CustomDropdown
                        facebookPages={facebookPages}
                        selectedPage={selectedPage}
                        onSelectionChange={setSelectedPage}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant='contained'
                        className={classes.verifyButton}
                        onClick={handleVerification}
                        disabled={selectedPage === null}
                    >
                        Continue
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant='contained'
                        className={classes.unLinkButton}
                        onClick={handleUnlinkFacebook}
                    >
                        Unlink facebook
                    </Button>
                </Grid>

                {errorMessage && (
                    <>
                        <Grid item>
                            <Typography
                                variant='body1'
                                style={{ color: 'red' }}
                            >
                                {errorMessage}
                            </Typography>
                        </Grid>
                        <ManualVerification />
                    </>
                )}
            </>
        );
    }

    return (
        <ManualVerification message='Please continue with manual verification' />
    );
};

const VerifyModal = ({
    shouldBeOpen,
    onCloseCallback,
    artistName = 'Your artist',
    artistId = 'xxxx-xxxx-xxxx-xxxx',
    imageSrc = 'https://cataas.com/cat',
}) => {
    // const {
    //     user,
    //     getAccessTokenWithPopup,
    //     getIdTokenClaims,
    //     getAccessTokenSilently,
    // } = useAuth0();

    const classes = useStyles();
    const [method, setMethod] = useState(null);
    // const [facebookPages, setFacebookPages] = useState(null);
    // const [match, setMatch] = useState(null);
    const [isOpen, setIsOpen] = useState(shouldBeOpen);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
        onCloseCallback && onCloseCallback();
    };

    // const getUserIdForProvider = async (provider) => {
    //     const connection = await hasConnectionWith(provider);
    //     console.log('connection = ', connection);
    //     if (!connection) return null;
    //     const identity = await getUserIdentity(provider);
    //     console.log('identity = ', identity);
    //     return identity ? identity.id : null;
    // };

    // const handleUnlinkFacebook = async () => {
    //     const unlinkAccount = async () => {
    //         const accessToken = await getAccessTokenSilently();
    //         const { sub } = user; // Use the sub value from the user object
    //         const provider = 'facebook'; // Replace with the actual provider string
    //         const userId = await getUserIdForProvider(provider); // Replace with the actual user_id of the identity you want to unlink

    //         try {
    //             const response = await fetch(
    //                 `https://${config.auth0.domain}/api/v2/users/${sub}/identities/${provider}/${userId}`,
    //                 {
    //                     method: 'DELETE',
    //                     headers: {
    //                         'Content-Type': 'application/json',
    //                         Authorization: `Bearer ${accessToken}`,
    //                     },
    //                 }
    //             );

    //             if (response.ok) {
    //                 //clear local storage and session storage
    //                 localStorage.clear();
    //                 sessionStorage.clear();
    //                 //reload page
    //                 window.location.reload();
    //                 // Reload the page after successful unlinking
    //                 window.location.reload();
    //             } else {
    //                 // Handle error
    //                 const errorMsg = await response.text();
    //                 console.error(`Error unlinking accounts: ${errorMsg}`);
    //                 throw new Error('Error unlinking accounts');
    //             }
    //         } catch (error) {
    //             console.log('error unlinking accounts = ', error);
    //         }
    //     };

    //     try {
    //         await unlinkAccount();
    //     } catch (error) {
    //         console.log('error unlinking accounts = ', error);
    //     }
    // };

    // const handleVerifyWithFacebook = async () => {
    //     /**
    //      * authenticateUser - authenticate user with a specific connection
    //      * @param {string} connection receives the connection provider to use for login on the account that will be linked on
    //      * @returns
    //      */
    //     const authenticateUser = async (connection) => {
    //         try {
    //             await getAccessTokenWithPopup({
    //                 max_age: 0,
    //                 audience: `https://${config.auth0.domain}/api/v2/`,
    //                 connection,
    //                 clientId: config.auth0.clientId,
    //                 scope: 'openid profile email',
    //             });

    //             return await getIdTokenClaims();
    //         } catch (error) {
    //             console.log('error authenticating second account = ', error);
    //             throw error;
    //         }
    //     };

    //     const linkAccount = async () => {
    //         const accessToken = await getAccessTokenSilently();
    //         const { sub } = user; // Use the sub value from the user object
    //         const { __raw: targetUserIdToken } = await authenticateUser(
    //             FACEBOOK_VERIFICATION
    //         );

    //         await fetch(
    //             `https://${config.auth0.domain}/api/v2/users/${sub}/identities`,
    //             {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     Authorization: `Bearer ${accessToken}`,
    //                 },
    //                 body: JSON.stringify({
    //                     link_with: targetUserIdToken,
    //                 }),
    //             }
    //         ).then(async (response) => {
    //             if (response.ok) {
    //                 // const ignoredCacheAccessToken =
    //                 //     await getAccessTokenSilently({ ignoreCache: true });
    //                 // jamfeedClient.updateToken(ignoredCacheAccessToken);
    //                 // setMethod(FACEBOOK_VERIFICATION);
    //                 //clear local storage and session storage
    //                 localStorage.clear();
    //                 sessionStorage.clear();
    //                 //reload page
    //                 window.location.reload();
    //             } else {
    //                 // Handle error
    //                 const errorMsg = await response.text();
    //                 console.error(`Error linking accounts: ${errorMsg}`);
    //                 throw new Error('Error linking accounts');
    //             }
    //         });
    //     };

    //     try {
    //         await linkAccount();
    //     } catch (error) {
    //         console.log('error linking accounts = ', error);
    //     }
    // };

    /**
     * Determines if the current environment is production.
     * @returns {boolean} True if the current environment is production, false otherwise.
     */
    const isProduction = () => {
        const url = window.location.href;
        // Check if the URL is neither localhost nor test, then it's production
        return !url.includes('localhost') && !url.includes('test.jamfeed.com');
    };

    /**
     * Constructs the URL for the Typeform based on the environment and artist ID.
     * @param {string} artistId - The artist's ID.
     * @returns {string} The constructed Typeform URL.
     */
    const constructTypeformUrl = (artistId) => {
        const prodUrl = 'https://form.typeform.com/to/PToLcKjJ';
        const devUrl = 'https://form.typeform.com/to/lkNxoBa7';
        const baseUrl = isProduction() ? prodUrl : devUrl;
        return `${baseUrl}?id=${encodeURIComponent(artistId)}`;
    };

    /**
     * Handles the verification process with a manual claim by redirecting to a Typeform URL.
     */
    const handleVerifyWithManualClaim = () => {
        setMethod(TYPEFORM_VERIFICATION);
        const typeformUrl = constructTypeformUrl(artistId);
        window.location.href = typeformUrl;
    };

    // useEffect(() => {
    //     setIsLoading(true);
    //     const checkOngoingProcess = async () => {
    //         jamfeedClient.updateToken(await getAccessTokenSilently());
    //         if (await hasConnectionWith(FACEBOOK_VERIFICATION)) {
    //             setMethod(FACEBOOK_VERIFICATION);
    //         }
    //         setIsLoading(false);
    //     };
    //     checkOngoingProcess();
    // }, []);

    // useEffect(() => {
    //     if (facebookPages) {
    //         const matchedPage = facebookPages.find(
    //             (page) => levenshtein.isSimilar(page.name, artistName, 11) // integer value is the threshold for similarity, the higher the number the more different characters are allowed, this is for letting <bandname> + " band", or  <bandname> + " official" pass, example check for "numbers official" facebook page match to a "numb3r$" spotify artist
    //         );
    //         if (matchedPage) {
    //             setMatch(matchedPage);
    //         } else {
    //             setMatch(false);
    //         }
    //     }
    // }, [facebookPages]);

    // useEffect(() => {
    //     const getData = async () => {
    //         setIsLoading(true);
    //         if (method === FACEBOOK_VERIFICATION) {
    //             await jamfeedClient.updateToken(await getAccessTokenSilently());
    //             setFacebookPages(await fbPageGetter());
    //         }
    //         setIsLoading(false);
    //     };
    //     if (method !== null) {
    //         getData();
    //     }
    // }, [method]);

    if (isLoading) {
        return (
            <ModalContainer open={isOpen}>
                <LoadingComponent title='Verification' />
            </ModalContainer>
        );
    }

    return (
        <ModalContainer open={isOpen} handleClose={handleClose}>
            {/**
            {method === FACEBOOK_VERIFICATION && (
                <>
                    <Grid item>
                        <Typography variant='h5' className={classes.header}>
                            Facebook verification
                        </Typography>
                    </Grid>
                    {match ? (
                        <PageSelector
                            facebookPages={facebookPages}
                            artistName={artistName}
                            setIsLoading={setIsLoading}
                            handleVerifyWithManualClaim={
                                handleVerifyWithManualClaim
                            }
                            handleUnlinkFacebook={handleUnlinkFacebook}
                            handleClose={handleClose}
                        />
                    ) : (
                        <>
                            <Grid item>
                                <Typography
                                    variant='body1'
                                    className={classes.description}
                                >
                                    We could not find a Facebook page that
                                    matches your artist name.
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant='contained'
                                    className={classes.verifyButton}
                                    onClick={handleVerifyWithManualClaim}
                                >
                                    Verify with Manual Claim
                                </Button>
                            </Grid>
                        </>
                    )}
                </>
            )}
            */}
            {/* {!method && ( */}
            <>
                <Grid item>
                    <Typography variant='h5' className={classes.header}>
                        {copy.title}
                    </Typography>
                </Grid>
                <Grid item>
                    <img className={classes.img} src={imageSrc} />
                </Grid>
                <Grid item className={classes.header}>
                    {artistName}
                </Grid>
                <Grid item className={classes.description}>
                    {copy.description}
                </Grid>
                {/* <Grid item>
                        <Button
                            variant='contained'
                            style={{
                                background: '#4267B2',
                            }}
                            onClick={handleVerifyWithFacebook}
                            className={classes.verifyButton}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{ marginRight: '8px' }}>
                                    <FacebookOutlinedIcon />
                                </span>
                                Verify with Facebook
                            </div>
                        </Button>
                    </Grid> */}
                <Grid item>
                    <Button
                        variant='contained'
                        className={classes.verifyButton}
                        onClick={handleVerifyWithManualClaim}
                    >
                        Verify with Manual Claim
                    </Button>
                </Grid>
            </>
            {/* )} */}
        </ModalContainer>
    );
};

export default VerifyModal;
