import React, { useState } from 'react';
import { Box, Grid, styled } from '@mui/material';
import PropTypes from 'prop-types';
import { IconGrid } from '../../shared/social icons/HeadersLayout';
import Navbar from '../../shared/headers/components/Navbar';
import {
    InputMethods,
    createField,
    generatePropTypesAndDefaults,
} from '../../utils/detailedEditorDefinition';
import { commonEditorDefinitions } from './commonEditorDefinitions';
import { NavbarConfigProvider } from '../../shared/headers/components/useNavbarConfig';
import { isBuilderURL } from '../../utils/isBuilderUrl';

// Using styled components for styling instead of makeStyles
const HeaderContainer = styled(Box)(
    ({ theme, mediaOverride, backgroundColor }) => ({
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100vh',
        minHeight: '600px',
        maxHeight: isBuilderURL() ? 'calc(100vh - 4.5rem)' : '100vh',
        background: `url(${mediaOverride}) no-repeat center center`,
        backgroundSize: 'cover',
        backgroundPosition: 'center bottom',
        padding: theme.spacing(3),
        boxSizing: 'border-box',
        backgroundColor: backgroundColor,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
        },
    })
);

const LogoContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    maxWidth: '10rem',
    minWidth: '4rem',
    [theme.breakpoints.down('sm')]: {
        maxWidth: '2rem',
        minWidth: '2rem',
        alignSelf: 'flex-start',
    },
}));

const LogoImg = styled('img')({
    width: 'auto',
    maxWidth: '10rem',
    minWidth: '4rem',
    height: 'auto',
    objectFit: 'contain',
});

const NavbarContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '0.8',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
        alignSelf: 'flex-start',
    },
}));

const Header2 = ({
    sections,
    color: textColor,
    backgroundColor,
    profile,
    integrations: { integrated },
    displaySocialIcons,
    fontSizeSelection = 6,
}) => {
    console.log('Header2');
    const styleDetails = {
        backgroundColor,
        textColor,
        fontSizeSelection,
    };

    const { logo, pictureurl: coverPhoto } = profile;
    const [menuState, setMenuState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setMenuState({ ...menuState, [anchor]: open });
    };

    const navbarConfig = {
        sections,
        toggleDrawer,
        menuState,
        integrated,
        styleDetails,
        vertical: true,
        showName: false,
        customStyle: { alignSelf: 'flex-end' },
    };

    const navStyleProps = {
        vertical: true,
        font: profile.font,
        color: textColor,
        fontSize: fontSizeSelection,
    };

    return (
        <NavbarConfigProvider value={navbarConfig}>
            <HeaderContainer
                mediaOverride={coverPhoto}
                backgroundColor={backgroundColor}
            >
                {displaySocialIcons && (
                    <Grid
                        sx={{
                            justifyContent: 'flex-end',
                            width: '100%',
                            order: { xs: 2, sm: 1 },
                        }}
                    >
                        <IconGrid
                            integrated={integrated}
                            textColor={textColor}
                            iconGridFlexEnd
                        />
                    </Grid>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 1,
                        height: '100%',
                        order: { xs: 1, sm: 2 },
                    }}
                >
                    <LogoContainer>
                        {logo && <LogoImg src={logo} alt='Logo' />}
                    </LogoContainer>
                    <NavbarContainer>
                        <Navbar {...navbarConfig} sx={navStyleProps} />
                    </NavbarContainer>
                </Box>
            </HeaderContainer>
        </NavbarConfigProvider>
    );
};

const specificDefinitions = { ...commonEditorDefinitions };
const detailedEditorDefinition = {
    ...commonEditorDefinitions,
};

const { propTypes, defaultProps } = generatePropTypesAndDefaults(
    detailedEditorDefinition
);

Header2.propTypes = propTypes;
Header2.defaultProps = defaultProps;

export default Header2;
export { detailedEditorDefinition, specificDefinitions };
