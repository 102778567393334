import React, { useContext } from 'react';
import _get from 'lodash.get';
import DetailedEditor from '../../shared/editor/DetailedEditor';
import { detailedEditorDefinition } from './VideosSection';
import { CompContext } from '../../context/CompContext';
import { cardList } from '../../shared/cards/cardList';
import { layoutList } from '../../shared/layouts';
import isEmpty from 'lodash.isempty';
import Typography from '@mui/material/Typography';

const VideosDetailedEditor = () => {
    const compContext = useContext(CompContext);
    const componentConfig = compContext.getComponentConfig('video');
    const { layoutType, cardType } = componentConfig.userDefinedProps;

    const cardDetailedEditor = _get(
        Object.values(cardList).find((c) => c.title === cardType),
        'detailedEditorDefinition',
        {},
    );
    const layoutDetailedEditor = _get(
        Object.values(layoutList).find((l) => l.title === layoutType),
        'detailedEditorDefinition',
        {},
    );

    const detailedEditors = [
        <Typography variant='h6' sx={{ p: '5px', pt: '15px', textDecoration: 'underline' }}>
            Section Properties
        </Typography>,
        <DetailedEditor detailedEditorDefinition={detailedEditorDefinition} componentViewKey={'video'} />,
    ];

    if (!isEmpty(layoutDetailedEditor)) {
        detailedEditors.push(
            <Typography variant='h6' sx={{ p: '5px', pt: '15px', textDecoration: 'underline' }}>
                Layout Properties
            </Typography>,
            <DetailedEditor detailedEditorDefinition={layoutDetailedEditor} componentViewKey={'video'} />,
        );
    }

    if (!isEmpty(cardDetailedEditor)) {
        detailedEditors.push(
            <Typography variant='h6' sx={{ p: '5px', pt: '15px', textDecoration: 'underline' }}>
                Card Properties
            </Typography>,
            <DetailedEditor  colorPickerPosition="bottomPosition" detailedEditorDefinition={cardDetailedEditor} componentViewKey={'video'} />,
        );
    }

    return <>{detailedEditors.map((d) => d)}</>;
};

export default VideosDetailedEditor;
