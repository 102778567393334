import React from 'react';
import SocialIcons from '../../../shared/SocialIcons';
import ResponsiveLink from '../../../shared/ResponsiveLink';
import isEmpty from 'lodash.isempty';

function ContactSection({ integrations, profile }) {
    return (
        <footer id='contact-footer-section' className='contact-section'>
            <div className='contact-info-wrapper'>
                <div className='contact-container'>
                    {!isEmpty(profile.logo) && (
                        <div className='contact-logo-wrapper'>
                            <a href='#' className='contact-logo w-inline-block'>
                                <img
                                    src={profile.logo}
                                    alt
                                    className='footer-image'
                                />
                            </a>
                        </div>
                    )}
                    <div className='contact-text-wrapper'>
                        {profile.publicContacts &&
                            profile.publicContacts.map((c) => {
                                return (
                                    <div className='contact-text'>
                                        <h2 className='contact-header'>
                                            {c.title}
                                        </h2>
                                        <ResponsiveLink
                                            linkText={c.email}
                                            className='contact-info-text'
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <SocialIcons integrations={integrations} />
                </div>
                {profile && profile.name && (
                    <div
                        style={{
                            width: '100%',
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        className='contact-copyright-text'
                    >
                        Copyright © {(new Date().getFullYear())} {profile.name}
                    </div>
                )}
            </div>
        </footer>
    );
}

export default ContactSection;
