import React from "react";

function FooterSection() {
  return (
    <footer id="contact-footer-section" className="contact-section">
      <div className="contact-info-wrapper">
        <div className="contact-container">
          <div className="contact-logo-wrapper">
            <a href="#" className="contact-logo w-inline-block">
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f5856f6ea5799175a0_Cal%20Scruby%20Logo%20White.png"
                alt
                className="footer-image"
              />
            </a>
          </div>
          <div className="contact-text-wrapper">
            <div className="contact-text">
              <h2 className="contact-header">Management</h2>
              <div className="contact-info-text">
                C<br />
                ihatecalscruby@gmail.com
                <a href="mailto:ihatecalscruby@gmail.com" />
              </div>
            </div>
            <div className="contact-text">
              <h2 className="contact-header">Press</h2>
              <div className="contact-info-text">
                Cal Scruby
                <br />
                ihatecalscruby@gmail.com
              </div>
            </div>
            <div className="contact-text">
              <h2 className="contact-header">Booking</h2>
              <h5 className="contact-subheader">North America</h5>
              <div className="contact-info-text">
                Cal Scruby
                <br />
                ihatecalscruby@gmail.com
              </div>
              <h5 className="contact-subheader">Rest of World</h5>
              <div className="contact-info-text">
                Cal Scruby
                <br />
                ihatecalscruby@gmail.com
              </div>
            </div>
            <div className="contact-text">
              <h2 className="contact-header">Sync</h2>
              <a
                href="https://docs.google.com/forms/u/0/d/1ln39Wz2AzB4HsT3gEKGtcz-t6xnKSpCmfgRxvdTIKA4/viewform?ts=5a382a0b&edit_requested=true"
                className="button w-button"
              >
                Licensing Request
              </a>
            </div>
          </div>
        </div>
        <div className="social-wrapper">
          <div className="profile-link">
            <a
              href="https://open.spotify.com/artist/25oLRSUjJk4YHNUsQXk7Ut"
              target="_blank"
              className="profile-image w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca72af94dfbe_Spotify%20Sidebar.png"
                width={30}
                height={30}
                alt
                className="profile-icon"
              />
            </a>
          </div>
          <div className="profile-link">
            <a
              href="https://music.apple.com/us/artist/griz/980722716"
              target="_blank"
              className="profile-image w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0acaa94c94dfbc_Apple%20Music%20Sidebar.png"
                width={30}
                height={30}
                alt
                className="profile-icon"
              />
            </a>
          </div>
          <div className="profile-link">
            <a
              href="https://www.youtube.com/user/mynameisGRiZ"
              target="_blank"
              className="youtube-link w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca3a5894dfba_YouTube%20icon.png"
                width={30}
                height={30}
                alt
                className="youtube-icon"
              />
            </a>
          </div>
          <div className="profile-link">
            <a
              href="https://soundcloud.com/griz"
              target="_blank"
              className="soundcloud-link w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0acab1d994dfbb_SoundCloud%20Sidebar.png"
                width={30}
                height={30}
                alt
                className="soundcloud-icon"
              />
            </a>
          </div>
          <div className="profile-link">
            <a
              href="http://instagram.com/griz/"
              target="_blank"
              className="instagram-link w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca309794dfbf_Instagram%20Sidebar.png"
                width={30}
                height={30}
                alt
                className="instagram-icon"
              />
            </a>
          </div>
          <div className="profile-link">
            <a
              href="https://twitter.com/griz"
              target="_blank"
              className="twitter-link w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0acab93b94dfbd_Twitter%20Sidebar.png"
                width={30}
                height={30}
                alt
                className="twitter-icon"
              />
            </a>
          </div>
        </div>
        <div
          data-w-id="7185ddea-82a3-b59d-d5a1-f7ca6305cffc"
          style={{ opacity: 0 }}
          className="contact-copyright-text"
        >
          COPYRIGHT © {(new Date().getFullYear())} CAL SCRUBY MUSIC
        </div>
      </div>
    </footer>
  );
}

export default FooterSection;
