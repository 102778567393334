import React from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { config } from '../config';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(1),
        justifyContent: 'center',
        display: 'flex',
        height: '100vh',
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: '#000000',
        color: '#FFFFFF',
        align: 'center',
        margin: 'auto',
        paddingTop: '3%',
        boxShadow: 'none',
    },
    container: {
        justifyContent: 'center',
    },
    header: {
        fontWeight: 'bold',
    },
    textDescription: {
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem',
        },
        fontSize: '1.2rem',
    },
    loginButton: {
        textTransform: 'none',
        width: '50%',
        color: '#FFF',
        background: '#02AA02',
        '&:hover': {
            backgroundColor: '#017601',
        },
    },
}));

const WebExpiredPage = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
                <Grid container spacing={1} align='center' className={classes.container}>
                    <Grid item xs={12} md={7} align='center'>
                        <Typography variant='h4' className={classes.header}>
                            Website Expired
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7} align='center'>
                        <Typography variant='h5' className={classes.textDescription}>
                            This website account has expired. If you are the owner of this website, please click below
                            to login.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={7} align='center'>
                        <Button
                            variant='contained'
                            size='medium'
                            className={classes.loginButton}
                            href={`${config.WEB_BASE_URL}/create`}
                        >
                            Login to JamFeed
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
};

export default WebExpiredPage;
