import React, { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
    Modal,
    Typography,
    Grid,
    Button,
    Link,
    IconButton,
} from '@mui/material/';
import CloseIcon from '@mui/icons-material/Close';
import templates from '../shared/templates';
import template1Image from '../builder/images/templates/template1.png';
import template2Image from '../builder/images/templates/template2.png';
import template3Image from '../builder/images/templates/template3.png';
import template4Image from '../builder/images/templates/template4.png';
import template5Image from '../builder/images/templates/template5.png';
import template6Image from '../builder/images/templates/template6.png';
import template7Image from '../builder/images/templates/template7.png';
import template8Image from '../builder/images/templates/template8.png';
import { CompContext } from '../context/CompContext';

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2, 4, 3),
            height: 'auto',
            paddingTop: 0,
            width: '60%',
        },
        position: 'absolute',
        width: '100%',
        backgroundColor: '#FFF',
        boxShadow: theme.shadows[5],
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        borderRadius: 10,
        overflowY: 'auto',
        height: '100%',
        paddingTop: 50,
        outline: 'none',
    },
    wrap: {
        paddingBottom: 15,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    header: {
        textAlign: 'center',
        padding: 10,
    },
    screenshot: {
        padding: 10,
        maxWidth: '100%',
        // height: 250,
    },
}));

const templateList = [
    {
        id: 1,
        img: template1Image,
        title: 'Cover Photo & New Music',
        templateConfig: templates.template1,
    },
    {
        id: 2,
        img: template2Image,
        title: 'Cover Photo & New Music Left',
        templateConfig: templates.template2,
    },
    {
        id: 3,
        img: template3Image,
        title: 'Cover Photo & New Release Right',
        templateConfig: templates.template3,
    },
    {
        id: 4,
        img: template4Image,
        title: 'New Video',
        templateConfig: templates.template4,
    },
    {
        id: 5,
        img: template5Image,
        title: 'New Release Dark Theme',
        templateConfig: templates.template5,
    },
    {
        id: 6,
        img: template6Image,
        title: 'New Music Light',
        templateConfig: templates.template6,
    },
    {
        id: 7,
        img: template7Image,
        title: 'Cover Photo Dark Theme',
        templateConfig: templates.template7,
    },
    {
        id: 8,
        img: template8Image,
        title: 'New Music Light Theme Left',
        templateConfig: templates.template8,
    },
];

const TemplateChooserModal = ({ shouldBeOpen, onCloseCallback }) => {
    const classes = useStyles();
    const compConext = useContext(CompContext);
    const [isOpen, setIsOpen] = useState(shouldBeOpen);

    const handleClose = () => {
        setIsOpen(false);
        onCloseCallback();
    };

    const selectTemplate = (templateConfig) => {
        const { componentList, websiteFont } = templateConfig;
        compConext.setSelectedTemplate(componentList, websiteFont);
        handleClose();
    };

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
            >
                <div className={classes.paper}>
                    <IconButton
                        aria-label='close'
                        className={classes.closeButton}
                        onClick={handleClose}
                        size='large'
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography variant='h5' className={classes.header}>
                        Choose a Website Template
                    </Typography>
                    <Grid container justifyContent='center'>
                        {templateList.map((item, idx) => (
                            <Grid item xs={12} md={3} key={idx}>
                                <Grid
                                    container
                                    justifyContent='center'
                                    className={classes.wrap}
                                >
                                    <Grid item xs={12} align='center'>
                                        <img
                                            className={classes.screenshot}
                                            src={item.img}
                                        />
                                    </Grid>
                                    <Grid item xs={12} align='center'>
                                        <Typography
                                            variant='h6'
                                            sx={{ height: '65px' }}
                                        >
                                            <strong>{item.title}</strong>
                                        </Typography>
                                    </Grid>
                                    {/* <Grid item xs={12} align='center' style={{ padding: 10 }}>
                                        <Button
                                            variant='outlined'
                                            size='small'
                                            style={{
                                                textTransform: 'none',
                                                width: '100%',
                                            }}
                                        >
                                            Preview Template
                                        </Button>
                                    </Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        align='center'
                                        style={{ padding: 10 }}
                                    >
                                        <Button
                                            variant='contained'
                                            size='small'
                                            style={{
                                                textTransform: 'none',
                                                width: '100%',
                                                color: '#FFF',
                                                background: '#02AA02',
                                            }}
                                            onClick={() =>
                                                selectTemplate(
                                                    item.templateConfig
                                                )
                                            }
                                        >
                                            Select Template
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        <Grid item xs={12} md={4}>
                            <Typography
                                variant='h6'
                                style={{ textAlign: 'center' }}
                            >
                                <Typography>
                                    or <br />
                                </Typography>
                                <Link
                                    component='button'
                                    variant='h6'
                                    style={{
                                        textDecoration: 'underline',
                                        color: 'black',
                                    }}
                                    onClick={handleClose}
                                >
                                    build your own website
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
        </div>
    );
};
export default TemplateChooserModal;
