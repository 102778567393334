import React from "react";
import isEmpty from 'lodash.isempty';
import { getNetwork } from '../../../../utils/network';

function NavBarSection({ news, music, videos, integrations }) {
  const patreonNetwork = getNetwork(integrations.integrated, 'PATREON');

  return (
    <div
      data-collapse="medium"
      data-animation="over-right"
      data-duration={400}
      role="banner"
      className="navbar-3 w-nav"
    >
      <div className="nav-bar-wrapper w-container">
        <a href="#" className="band-logo w-nav-brand">
          <img
            src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38987c1efc2b_cal%20scruby%20logo.png"
            sizes="76.875px"
            srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38987c1efc2b_cal%2520scruby%2520logo-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38987c1efc2b_cal%20scruby%20logo.png 640w"
            alt
            className="image-7"
          />
        </a>
        <nav role="navigation" className="nav-menu-items w-nav-menu">
          <a href="#home-section-new-album" className="nav-link-3 w-nav-link">
            Home
          </a>
          {!isEmpty(music) && (
            <a href="#music-section" className="nav-link-3 w-nav-link">
              Music
            </a>
          )}
          {!isEmpty(videos) && (
            <a href="#video-section" className="nav-link-3 w-nav-link">
              Videos
            </a>
          )}
          <a href="#tour-section" className="nav-link-3 w-nav-link">
            Tour
          </a>
          {!isEmpty(news) && (
            <a href="#news-section" className="nav-link-3 w-nav-link">
              News
            </a>
          )}
          <a href="#merch-section" className="nav-link-3 w-nav-link">
            Merch
          </a>
          <a href="#about-section" className="nav-link-3 w-nav-link">
            About
          </a>
          <a href="#contact-footer-section" className="nav-link-3 w-nav-link">
            Contact
          </a>
          {!isEmpty(patreonNetwork) && patreonNetwork.data.url && (
            <a
              href={patreonNetwork.data.url}
              target='_blank'
              className='nav-link-3 w-nav-link'
            >
              Subscribe
            </a>
          )}
        </nav>
        <div className="w-nav-button">
          <div className="w-icon-nav-menu" />
        </div>
      </div>
    </div>
  );
}

export default NavBarSection;
