import React from 'react';
import isEmpty from 'lodash.isempty';
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 10,
    },
    link: {
        margin: 10,
    },
};

function SocialIcons({ integrations }) {
    const setupIntegrations = integrations.integrated || [];
    return (
        <div style={styles.container}>
            {setupIntegrations.map(({ network, data: { url } }) => {
                if (isEmpty(url)) {
                    return;
                }
                switch (network) {
                    case 'SPOTIFY':
                        return (
                            <div style={styles.link}>
                                <a href={url} target='_blank'>
                                    <img
                                        src='https://assets.website-files.com/5f2c19129fc88015b9209854/5f2c19129fc88036f4209868_Spotify%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        alt
                                        style={{
                                            objectFit: 'contain',
                                            borderRadius: 500,
                                        }}
                                    />
                                </a>
                            </div>
                        );
                    case 'APPLE_MUSIC':
                        return (
                            <div style={styles.link}>
                                <a href={url} target='_blank'>
                                    <img
                                        src='https://assets.website-files.com/5f2c19129fc88015b9209854/5f2c19129fc880479120986a_Apple%20Music%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        style={{
                                            objectFit: 'contain',
                                            borderRadius: 500,
                                        }}
                                        alt
                                    />
                                </a>
                            </div>
                        );
                    case 'YOUTUBE':
                        return (
                            <div style={styles.link}>
                                <a href={url} target='_blank'>
                                    <img
                                        src='https://assets.website-files.com/5f2c19129fc88015b9209854/5f2c19129fc8806276209869_YouTube%20icon.png'
                                        width={30}
                                        height={30}
                                        style={{
                                            objectFit: 'contain',
                                            borderRadius: 500,
                                        }}
                                        alt
                                    />
                                </a>
                            </div>
                        );
                    case 'SOUNDCLOUD':
                        return (
                            <div style={styles.link}>
                                <a href={url} target='_blank'>
                                    <img
                                        src='https://assets.website-files.com/5f2c19129fc88015b9209854/5f2c19129fc880b25120986e_SoundCloud%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        style={{
                                            objectFit: 'contain',
                                            borderRadius: 500,
                                        }}
                                        alt
                                    />
                                </a>
                            </div>
                        );
                    case 'INSTAGRAM':
                        return (
                            <div style={styles.link}>
                                <a href={url} target='_blank'>
                                    <img
                                        src='https://assets.website-files.com/5f2c19129fc88015b9209854/5f2c19129fc88026bb20986c_Instagram%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        style={{
                                            objectFit: 'contain',
                                            borderRadius: 500,
                                        }}
                                        alt
                                    />
                                </a>
                            </div>
                        );
                    case 'TWITTER':
                        return (
                            <div style={styles.link}>
                                <a href={url} target='_blank'>
                                    <img
                                        src='https://assets.website-files.com/5f2c19129fc88015b9209854/5f2c19129fc880b2ff20986b_Twitter%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        alt
                                    />
                                </a>
                            </div>
                        );
                    case 'BANDSINTOWN':
                        return (
                            <div style={styles.link}>
                                <a href={url} target='_blank'>
                                    <img
                                        src='https://musicrow.com/wp-content/uploads/2017/11/bandsintownlogo.png'
                                        width={30}
                                        height={30}
                                        style={{
                                            objectFit: 'contain',
                                            borderRadius: 500,
                                        }}
                                        alt
                                    />
                                </a>
                            </div>
                        );
                    case 'FACEBOOK':
                        return (
                            <div style={styles.link}>
                                <a href={url} target='_blank'>
                                    <img
                                        src='https://jamfeed-storage.s3.amazonaws.com/icons/facebook.svg'
                                        width={30}
                                        height={30}
                                        style={{
                                            objectFit: 'contain',
                                            borderRadius: 500,
                                        }}
                                        alt
                                    />
                                </a>
                            </div>
                        );
                    case 'SONGKICK':
                        return (
                            <div style={styles.link}>
                                <a href={url} target='_blank'>
                                    <img
                                        src='https://jamfeed-storage.s3.amazonaws.com/icons/songkick.svg'
                                        width={30}
                                        height={30}
                                        style={{
                                            objectFit: 'contain',
                                            borderRadius: 500,
                                        }}
                                        alt
                                    />
                                </a>
                            </div>
                        );
                }
            })}
        </div>
    );
}

export default SocialIcons;
