import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Template1 from './Template1';
import Template2 from './Template2';
import Template3 from './Template3';
import Template4 from './Template4';
import Template5 from './Template5';

const AboutSection = (props) => {
    const { color, backgroundColor, variant, sectionTitle, profile } = props;
    // ensure variant is number incase it switch
    // to a string
    let variantInt = variant;
    if (typeof variantInt !== 'number') {
        variantInt = parseInt(variantInt, 10);
    }

    switch (variantInt) {
        case 1:
            return (
                <Template1
                    color={color}
                    backgroundColor={backgroundColor}
                    headerText={sectionTitle}
                    bodyText={profile.bio}
                />
            );
        case 2:
            return (
                <Template2
                    color={color}
                    backgroundColor={backgroundColor}
                    headerText={sectionTitle}
                    bodyText={profile.bio}
                    imageUrl={profile.pictureurl}
                />
            );
        case 3:
            return (
                <Template3
                    color={color}
                    backgroundColor={backgroundColor}
                    headerText={sectionTitle}
                    bodyText={profile.bio}
                    imageUrl={profile.pictureurl}
                />
            );
        case 4:
            return (
                <Template4
                    color={color}
                    backgroundColor={backgroundColor}
                    headerText={sectionTitle}
                    bodyText={profile.bio}
                    imageUrl={profile.pictureurl}
                />
            );
        case 5:
            return (
                <Template5
                    color={color}
                    headerText={sectionTitle}
                    bodyText={profile.bio}
                    imageUrl={profile.pictureurl}
                />
            );
        default:
            return (
                <Template1
                    color={color}
                    backgroundColor={backgroundColor}
                    headerText={sectionTitle}
                    bodyText={profile.bio}
                />
            );
    }
};

const title = 'About Us';
const isDisplayedOnNav = true;
const detailedEditorDefinition = {
    sectionTitle: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'text',
        name: 'sectionTitle',
        label: 'Section Title',
        isRequired: false,
        defaultValue: 'ABOUT US',
    },
    variant: {
        propType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        valueType: 'number',
        inputMethod: 'select',
        inputOptions: ['1', '2', '3', '4', '5'],
        name: 'variant',
        label: 'Select Design',
        isRequired: true,
        defaultValue: '1',
    },
    displayOnNavigation: {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'displayOnNavigation',
        label: 'Display On Navigation',
        isRequired: true,
        defaultValue: true,
    },
    color: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'color',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#000000',
    },
    backgroundColor: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'backgroundColor',
        label: 'Background Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
};

AboutSection.propTypes = {
    variant: detailedEditorDefinition.variant.propType,
    displayOnNavigation: detailedEditorDefinition.displayOnNavigation.propType,
    color: detailedEditorDefinition.color.propType,
    backgroundColor: detailedEditorDefinition.backgroundColor.propType,
    sectionTitle: detailedEditorDefinition.sectionTitle.propType,
    profile: PropTypes.shape({
        bio: PropTypes.string,
        pictureurl: PropTypes.string,
    }).isRequired,
};

AboutSection.defaultProps = {
    variant: detailedEditorDefinition.variant.defaultValue,
    displayOnNavigation: detailedEditorDefinition.displayOnNavigation.defaultValue,
    sectionTitle: detailedEditorDefinition.sectionTitle.defaultValue,
    color: detailedEditorDefinition.color.defaultValue,
    backgroundColor: detailedEditorDefinition.backgroundColor.defaultValue,
    profile: {
        bio: '',
        pictureurl: '',
    },
};

export { AboutSection as default, title, isDisplayedOnNav, detailedEditorDefinition };
