import React from 'react';
import NewsList from '../../../shared/news/NewsList';
import isEmpty from 'lodash.isempty';

function NewsSection({ news, theme }) {
    return (
        <div id='news-section' className='news-section'>
            {!isEmpty(news) && (
                <div className='news-section-wrapper'>
                    <div className='section-header-wrapper'>
                        <h1
                            className='section-header'
                            style={{ color: theme.textColor }}
                        >
                            News
                        </h1>
                        <NewsList
                            news={news}
                            outerClass={'news-cell-grid'}
                            theme={theme}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default NewsSection;
