import React from "react";
import isEmpty from 'lodash.isempty';

function VideoSection({ videos }) {
  return (
    <div id="video-section" className="videos-section">
      {!isEmpty(videos) && (
        <div className="videos-section-wrapper">
          <div className="videos-section-header">
            <h2 className="section-header">Videos</h2>
          </div>
          <div className="videos-collection">
            <div className="videos-item-wrapper">
              <div
                style={{ paddingTop: "56.17021276595745%" }}
                className="video w-video w-embed"
              >
                <iframe
                  className="embedly-embed"
                  src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FRXtqpKo87d0%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DRXtqpKo87d0&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FRXtqpKo87d0%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
                  scrolling="no"
                  title="YouTube embed"
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen="true"
                />
              </div>
            </div>
            <div className="videos-item-wrapper">
              <div
                style={{ paddingTop: "56.17021276595745%" }}
                className="video w-video w-embed"
              >
                <iframe
                  className="embedly-embed"
                  src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2F-w9Z_ICSmtk%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3D-w9Z_ICSmtk&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2F-w9Z_ICSmtk%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
                  scrolling="no"
                  title="YouTube embed"
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen="true"
                />
              </div>
            </div>
            <div className="videos-item-wrapper">
              <div
                style={{ paddingTop: "56.17021276595745%" }}
                className="video w-video w-embed"
              >
                <iframe
                  className="embedly-embed"
                  src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2Fqv5kEDlZM-g%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3Dqv5kEDlZM-g&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2Fqv5kEDlZM-g%2Fhqdefault.jpg&key=c4e54deccf4d4ec997a64902e9a30300&type=text%2Fhtml&schema=youtube"
                  scrolling="no"
                  title="YouTube embed"
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen="true"
                />
              </div>
            </div>
            <div className="videos-item-wrapper">
              <div
                style={{ paddingTop: "56.17021276595745%" }}
                className="video w-video w-embed"
              >
                <iframe
                  className="embedly-embed"
                  src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FvZcyHPTZtYs%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DvZcyHPTZtYs&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FvZcyHPTZtYs%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
                  scrolling="no"
                  title="YouTube embed"
                  frameBorder={0}
                  allow="autoplay; fullscreen"
                  allowFullScreen="true"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoSection;
