import React from 'react';
import NewsLayout from '../../../shared/news/NewsLayout';
import isEmpty from 'lodash.isempty';

function NewsSection({ news }) {
    return (
        <div id='news-section' className='news-section'>
            {!isEmpty(news) && (
                <div className='news-wrapper'>
                    <div className='news-section-header'>
                        <h1 className='section-header'>News</h1>
                    </div>
                    <NewsLayout
                        news={news}
                        layout='grid'
                        numItems={6}
                        outerClass={'news-collection'}
                        renderItem={({ media, title, source, url }) => {
                            return (
                                <a
                                    href={url}
                                    target='_blank'
                                    className='news-link-block w-inline-block'
                                >
                                    <div className='news-article-wrapper'>
                                        <div className='news-article-image-wrapper'>
                                            <img
                                                src={media}
                                                sizes='(max-width: 479px) 100vw, (max-width: 767px) 74vw, (max-width: 991px) 39vw, 33vw'
                                                alt
                                                className='news-article-image'
                                            />
                                        </div>
                                        <div className='news-article-title'>
                                            <h4 className='news-article-header'>
                                                {title}
                                            </h4>
                                        </div>
                                        <div className='news-article-source-wrapper'>
                                            <div className='news-article-source'>
                                                {source}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            );
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default NewsSection;
