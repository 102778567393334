import React, { useEffect } from 'react';
import './template2.scss';

import AboutSection1 from '../../shared/about/AboutSection1';
import Footer from './FooterSection/Footer';
import Home from './HomeSection/Home';
import Music from './MusicSection/Music';
import News from './NewsSection/News';
import Tour from './TourSection/Tour';
import Videos from './VideosSection/Videos';
import Navbar from './NavbarSection/Navbar';

function loadBandsinTown() {
    const wf = document.createElement('script');
    wf.src = 'https://widget.bandsintown.com/main.min.js';
    wf.charset = 'utf-8';
    wf.async = 'true';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(wf, s);
}

function loadFonts() {
    import('webfontloader').then((WebFont) => {
        WebFont.load({
            google: {
                families: [
                    'Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic',
                    'Lato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic',
                ],
            },
        });
    });
}

const Template2 = ({ profile, videos, music, news, integrations }) => {
    const theme = {
        textColor: 'white',
        backgroundColor: 'black',
    };
    useEffect(() => {
        loadBandsinTown();
        loadFonts();
    }, []);
    return (
        <div className='template2'>
            <div className='body'>
                <Navbar profile={profile} integrations={integrations} news={news} music={music} videos={videos} />
                <Home integrations={integrations} profile={profile} />
                <Tour profile={profile} />
                <Music music={music} />
                <Videos videos={videos} />
                <News news={news} />
                {/* <Subscribe/> */}
                <AboutSection1
                    profile={profile}
                    theme={theme}
                    textStyle={{ fontFamily: 'Varela, sans-serif' }}
                />
                <Footer profile={profile} />
            </div>
        </div>
    );
};

export default Template2;
