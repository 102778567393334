import React from 'react';

function NoContent(props) {
    return (
        <>
            {(props.hasContent == false ||
                (props.data && props.data.length === 0)) && (
                <h3
                    style={
                        props.style || {
                            color: props.theme.textColor || 'white',
                            fontSize: 16,
                        }
                    }
                >
                    {props.text || 'No content available yet, check back soon!'}
                </h3>
            )}
        </>
    );
}

export default NoContent;
