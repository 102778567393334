import * as React from 'react';

const SvgBandcamp = (props) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 45 45'
            width='25px'
            height='25px'
            {...props}
        >
            {' '}
            <path d='M 8.5957031 17 L 0 33 L 18.404297 33 L 27 17 L 8.5957031 17 z M 27 17 L 27 32.693359 L 29.373047 32.693359 L 29.373047 31.132812 L 29.416016 31.132812 C 30.164016 32.627813 31.813562 33 33.351562 33 C 36.715563 33 38.253906 30.120406 38.253906 27.066406 C 38.253906 23.747406 36.515594 21.021484 32.933594 21.021484 C 31.658594 21.021484 30.229828 21.703734 29.548828 22.802734 L 29.505859 22.802734 L 29.505859 17 L 27 17 z M 44.878906 21.021484 C 41.141906 21.021484 39.273438 23.704812 39.273438 27.132812 C 39.273437 30.473813 41.230016 33 44.791016 33 C 47.714016 33 49.538 31.372734 50 28.427734 L 47.494141 28.427734 C 47.251141 30.054734 46.241016 31.023438 44.791016 31.023438 C 42.615016 31.023437 41.779297 29.022813 41.779297 27.132812 C 41.779297 23.682813 43.603391 23 44.900391 23 C 46.285391 23 47.296141 23.748812 47.494141 25.132812 L 47.498047 25.132812 L 50 25.132812 C 49.759 22.341813 47.515906 21.021484 44.878906 21.021484 z M 32.605469 23 C 34.669469 23 35.747047 24.955484 35.748047 27.021484 C 35.748047 28.999484 34.891469 31.021484 32.605469 31.021484 C 30.583469 31.021484 29.417969 29.306484 29.417969 27.021484 C 29.417969 24.604484 30.496469 23 32.605469 23 z' />
        </svg>
    );
};

export default SvgBandcamp;
