import React from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import isEmpty from 'lodash.isempty';
import { getNetworkIcon } from '../../utils/network';
import { getNetworkMonoIcon } from '../../utils/monoIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            background: 'transparent !important',
            height: '100%',
            textAlign: 'center',
            '@media(max-width:600px)': {
                padding: 15,
            },
            justifyContent: ({ position }) => position,
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
        },
        display: ({ setSocialIcon }) => setSocialIcon,
    },
    container: {
        display: 'flex',
        paddingLeft: '0px',
        alignItems: 'center',
        flexFlow: 'row nowrap',
        justifyContent: ({ position }) => position,
        '@media(max-width:600px)': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            textAlign: 'center',
            justifyContent: ({ position }) => position,
        },
    },
    profileLink: {
        listStyleType: 'none',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        '@media(max-width:600px)': {
            width: 20,
        },
        width: 30,
        borderRadius: 500,
        padding: 6,
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const SocialIcon = ({ network, url, monoIcons, iconColor }) => {
    const classes = useStyles();

    if (isEmpty(url)) return null;

    const excludedNetworks = [
        'MUSICBRAINZ',
        'BOOKING',
        'SHOPIFY',
        'MAILCHIMP',
        'EPK',
        'GOOGLE_ANALYTICS',
        'FACEBOOK_PIXEL',
        'PATREON',
    ];

    if (excludedNetworks.includes(network)) return null;

    const icon = monoIcons
        ? getNetworkMonoIcon(network, iconColor)
        : getNetworkIcon(network);

    return (
        <div className={classes.profileLink}>
            <a href={url} target='_blank' rel='noopener noreferrer'>
                {monoIcons ? (
                    <div style={{ width: '30px', padding: '5px' }}>{icon}</div>
                ) : (
                    icon && (
                        <img
                            src={icon}
                            width={30}
                            height={30}
                            alt={`${network} icon`}
                            className={classes.icon}
                        />
                    )
                )}
            </a>
        </div>
    );
};

const Horizontal = ({
    integrated,
    displaySocialIcons,
    position,
    setSocialIcon,
    monoIcons,
    iconColor,
}) => {
    const classes = useStyles({ position, setSocialIcon });

    return (
        <div className={classes.root}>
            {displaySocialIcons && (
                <div className={classes.container}>
                    {integrated.map(({ network, data }) => {
                        if (!data || !data.url) return null;
                        return (
                            <SocialIcon
                                key={network}
                                network={network}
                                url={data.url}
                                monoIcons={monoIcons}
                                iconColor={iconColor}
                            />
                        );
                    })}
                </div>  
            )}
        </div>
    );
};

export default Horizontal;
