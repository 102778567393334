import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { hexToCSSFilter } from 'hex-to-css-filter';
import { isEmpty } from 'lodash';
import React from 'react';
import BandsinTown from '../icons/mono/bandsintown';
import SongkickIcon from '../icons/mono/songkick';
import SoundCloudIcon from '../icons/mono/soundcloud';
import SpotifyIcon from '../icons/mono/spotify';
import AmazonMusicIcon from '../icons/mono/amazon_music';
import SvgBandcamp from '../icons/mono/bandcamp';
import SvgTidal from '../icons/mono/tidal';
import SvgTikTok from '../icons/mono/tiktok';

const networkDataMap = {
    FACEBOOK: {
        icon: FacebookIcon,
        humanReadableName: 'Facebook',
    },
    TWITTER: {
        icon: TwitterIcon,
        humanReadableName: 'Twitter',
    },
    INSTAGRAM: {
        icon: InstagramIcon,
        humanReadableName: 'Instagram',
    },
    SPOTIFY: {
        icon: SpotifyIcon,
        humanReadableName: 'Spotify',
    },
    APPLE_MUSIC: {
        icon: AppleIcon,
        humanReadableName: 'Apple Music',
    },
    YOUTUBE: {
        icon: YouTubeIcon,
        humanReadableName: 'Youtube',
    },
    SOUNDCLOUD: {
        icon: SoundCloudIcon,
        humanReadableName: 'Soundcloud',
    },
    BANDSINTOWN: {
        icon: BandsinTown,
        humanReadableName: 'Bandsintown',
    },
    SONGKICK: {
        icon: SongkickIcon,
        humanReadableName: 'Songkick',
    },
    AMAZON_MUSIC: {
        icon: AmazonMusicIcon,
        humanReadableName: 'Amazon Music',
    },
    BANDCAMP: {
        icon: SvgBandcamp,
        humanReadableName: 'Bandcamp',
    },
    TIDAL: {
        icon: SvgTidal,
        humanReadableName: 'Tidal',
    },
    TIKTOK: {
        icon: SvgTikTok,
        humanReadableName: 'TikTok',
    },
};

const getIconWithColor = (IconComponent, color, network) => {
    let cssFilterValue = '';
    if (color !== undefined) {
        const cssFilter = hexToCSSFilter(color, {
            acceptanceLossPercentage: 1,
            maxChecks: 10,
        });
        cssFilterValue = cssFilter.filter.replace(';', '');
    }

    const getIconWithSxColor = () => <IconComponent sx={{ color: color }} />;
    const getIconWithFilter = () => (
        <IconComponent style={{ filter: `${cssFilterValue}` }} />
    );
    const getIconWithFill = () => (
        <IconComponent
            style={{ filter: `${cssFilterValue}` }}
            fillColor={color || '#FFFFFF'}
        />
    );

    switch (network) {
        case 'FACEBOOK':
        case 'TWITTER':
        case 'INSTAGRAM':
        case 'APPLE_MUSIC':
        case 'YOUTUBE':
            return getIconWithSxColor();
        case 'SPOTIFY':
        case 'SOUNDCLOUD':
            return getIconWithFilter();
        case 'BANDSINTOWN':
        case 'SONGKICK':
        case 'AMAZON_MUSIC':
        case 'BANDCAMP':
        case 'TIDAL':
        case 'TIKTOK':
            return getIconWithFill();
        default:
            return <IconComponent />;
    }
};

export const getNetworkMonoIcon = (network, color = '#FFFFFF') => {
    const networkData = networkDataMap[network];
    return networkData ? (
        getIconWithColor(networkData.icon, color, network)
    ) : (
        <></>
    );
};

export const getHumanReadableName = (network) => {
    const n = networkDataMap[network];
    return n ? n.humanReadableName : network;
};

export const getNetwork = (integrations, network) => {
    if (isEmpty(integrations)) {
        return undefined;
    }

    const networkIntegration = integrations.filter((i) => i.network == network);
    return !isEmpty(networkIntegration) ? networkIntegration[0] : undefined;
};
