import jamfeedClient from './jamfeedClient';

const websiteApi = {
    getTemplates: async (entityId, entityType, configType) =>
        jamfeedClient
            .get('/website/config', {
                params: {
                    id: entityId,
                    type: entityType,
                    config: configType,
                },
                transformRequest: (data, headers) => {
                    delete headers.common['Authorization'];
                    delete headers.Authorization;
                    return data;
                },
            })
            .then((response) => {
                return response.data.results;
            })
            .catch((error) => {
                console.log(`error retrieving ${configType} config`, error);
                return [
                    {
                        componentList: {},
                        entity: {
                            id: entityId,
                            type: entityType,
                        },
                    },
                ];
            }),
    savePreviewConfig: async (templateConfig) => {
        if (Object.keys(templateConfig).length === 0) {
            return Promise.resolve({});
        }

        try {
            const response = await jamfeedClient.post(
                '/website/config/preview',
                {
                    templateConfig,
                }
            );
            return response.data.templateConfig;
        } catch (error) {
            console.log('error saving preview config', error);
            return Promise.resolve({});
        }
    },
    savePublishedConfig: async (templateConfig) =>
        jamfeedClient
            .post('/website/config/published', {
                templateConfig,
            })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log(
                    'error saving published config',
                    error.response.data.msg
                );
                throw error;
            }),
    getDomain: async (artistId) =>
        jamfeedClient
            .get('/domain/config', {
                params: {
                    id: artistId,
                    type: 'ARTIST',
                },
            })
            .then(({ data }) => {
                return data;
            })
            .catch((error) => {
                console.log(
                    'error getting Domain',
                    error //error.response.data.msg
                );
                throw error;
            }),
};

export default websiteApi;
