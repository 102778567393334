import React from 'react';
import PropTypes from 'prop-types';
import { Grid, styled, useMediaQuery } from '@mui/material';
import IconGrid from '../../../shared/social icons/IconGrid';

const RightContentContainer = styled(Grid)(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down('md')]: {
        order: 2,
        alignSelf: 'center',
    },
}));

const IconContent = styled(Grid)(({ theme }) => ({
    display: 'flex',
    color: 'white', 
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
        alignSelf: 'flex-end',
    },
}));

const RightContent = ({ integrated, textColor, displaySocialIcons }) => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <RightContentContainer>
            {displaySocialIcons && integrated && (
                <IconContent style={{ color: textColor }}> {/* Dynamic textColor */}
                    <IconGrid 
                        integrated={integrated} 
                        textColor={textColor} 
                        vertical={!isMobile} 
                        iconGridFlexEnd={true} 
                    />
                </IconContent>
            )}
        </RightContentContainer>
    );
};

RightContent.propTypes = {
    integrated: PropTypes.array.isRequired,
    textColor: PropTypes.string,
    displaySocialIcons: PropTypes.bool.isRequired,
};

export default RightContent;
