import React, { useEffect } from 'react';
import allSettled from 'promise.allsettled';
import isEmpty from 'lodash.isempty';
import _get from 'lodash.get';
import DynamicRouter from './DynamicRouter';
import WebPublished from './WebPublished';
import creatorApi from './api/creatorApi';
import jamfeedClient from './api/jamfeedClient';
import WebExpiredPage from './builder/WebExpiredPage';

allSettled.shim();

const getDataResults = (data, idx) => {
    return data[idx] && data[idx].status === 'fulfilled' ? data[idx].value : {};
};

const BuilderRouter = (props) => {
    // If the subscription is not active, display page
    if (!props.isWebsiteAccessible) {
        console.log('Website Expired', {
            isWebsiteAccessible: props.isWebsiteAccessible,
        });
        return <WebExpiredPage />;
    }

    console.log('BuilderRouter', {
        builderVersion: props.builderVersion,
    });
    let Builder = WebPublished;
    switch (props.builderVersion) {
        case 'v2':
            Builder = WebPublished;
            break;
        case 'v1':
            Builder = DynamicRouter;
            break;
        default:
            Builder = () => <>under construction, will be back soon!</>;
            break;
    }

    return <Builder {...props} />;
};

BuilderRouter.getInitialProps = async ({
    req,
    res,
    match,
    history,
    location,
    ...ctx
}) => {
    if (!req) {
        console.log('BuilderRouter: not server side request');
        return { builderVersion: undefined, isWebsiteAccessible: true };
    }
    const builderVersion = _get(req, "headers['jf-web-builder-version']", 'v2');
    const entityId = _get(req, "headers['jf-entity-id']", req.query.id);

    if (isEmpty(entityId)) {
        return { builderVersion: undefined };
    }
    if (builderVersion === 'v2') {
        const dataResults = await Promise.allSettled([
            creatorApi.getProfile(entityId),
            creatorApi.getVideos(entityId, 'ARTIST'),
            creatorApi.getMusic(entityId, 'ARTIST'),
            creatorApi.getNews(entityId, 'ARTIST'),
            creatorApi.getIntegrations(entityId),
            creatorApi.getImages(entityId),
            creatorApi.getEvents(entityId, 'ARTIST'),
        ]);

        let isWebsiteAccessible = false;
        try {
            const subscriptionStatus = await creatorApi.getSubscriptionStatus(
                entityId,
                'ARTIST'
            );
            isWebsiteAccessible = subscriptionStatus.isActive;
        } catch (e) {
            console.log('Error getting payment plan', e);
        }

        const props = {
            builderVersion,
            entityId,
            profile: getDataResults(dataResults, 0),
            videos: getDataResults(dataResults, 1),
            music: getDataResults(dataResults, 2),
            news: getDataResults(dataResults, 3),
            integrations: getDataResults(dataResults, 4),
            images: getDataResults(dataResults, 5),
            events: getDataResults(dataResults, 6),
            isWebsiteAccessible,
        };

        return props;
    } else {
        // TODO remove once fully migrated
        const templateId = req && req.query.tid;

        const siteConfig = await jamfeedClient
            .get(`/site/config`, {
                params: {
                    id: entityId,
                    type: 'ARTIST',
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error(error + ' error');
            });

        const profile = await jamfeedClient
            .get('/artist', {
                params: {
                    aid: entityId,
                },
            })
            .then((response) => {
                let { artist } = response.data;
                const publicContacts = isEmpty(artist.contacts)
                    ? []
                    : artist.contacts.filter((c) => c.publiclyVisible);
                artist.publicContacts = publicContacts;
                delete artist.contacts;
                return artist;
            })
            .catch((error) => {
                console.log('error getting artist profile', error);
                return {
                    artist: {},
                };
            });

        const { results: videos } = await jamfeedClient
            .get('/content/videos', {
                params: {
                    id: entityId,
                    type: 'ARTIST',
                },
            })
            .then((response) => response.data)
            .catch((error) => {
                console.log('error getting artist videos', error);
                return {
                    results: [],
                };
            });

        const { results: music } = await jamfeedClient
            .get('/content/music', {
                params: {
                    id: entityId,
                    type: 'ARTIST',
                },
            })
            .then((response) => response.data)
            .catch((error) => {
                console.log('error getting artist music', error);
                return {
                    results: [],
                };
            });

        const { results: news } = await jamfeedClient
            .get('/news/creator', {
                params: {
                    id: entityId,
                    type: 'ARTIST',
                },
            })
            .then((response) => response.data)
            .catch((error) => console.log('error getting artist news', error));

        const integrations = await jamfeedClient
            .get('/artist/integrations', {
                params: {
                    artistId: entityId,
                },
            })
            .then((response) => response.data)
            .catch((error) => {
                console.log('error getting artist integrations', error);
                return {
                    integrated: [],
                };
            });

        let isWebsiteAccessible = false;
        try {
            const subscriptionStatus = await creatorApi.getSubscriptionStatus(
                entityId,
                'ARTIST'
            );
            isWebsiteAccessible = subscriptionStatus.isActive;
        } catch (e) {
            console.log('Error getting payment plan', e);
        }

        return {
            builderVersion,
            entityId,
            templateId: templateId || siteConfig.templateId || 'at1',
            profile,
            videos,
            music,
            news,
            integrations,
            isWebsiteAccessible,
        };
    }
};

export default BuilderRouter;
