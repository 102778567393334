function componentListToSections(componentsList) {
    const blacklist = ['header', 'footer', 'navigation'];

    return componentsList
        .filter(
            (component) =>
                component.isVisible &&
                !blacklist.includes(component.componentName)
        )
        .map((component) => {
            // Extracting the tagId based on the componentName
            const tagId = component.componentName;

            // Extracting the label based on the componentName
            let label = tagId.charAt(0).toUpperCase() + tagId.slice(1);

            // Special handling for components with a user-defined sectionTitle
            if (
                component.userDefinedProps &&
                component.userDefinedProps.sectionTitle
            ) {
                label = component.userDefinedProps.sectionTitle;
            }

            return { label, tagId };
        });
}

export default componentListToSections;
