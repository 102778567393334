import React, { useState, useContext, useEffect } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import makeStyles from '@mui/styles/makeStyles';
import { CreatorContext } from '../../context/creatorContext';
import jamfeedClient from '../../api/jamfeedClient';
import axios from 'axios';
// import { useAuth0 } from '@auth0/auth0-react'

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            width: '40%',
        },
        display: 'none',
    },
    multiSelect: {
        textAlign: 'left',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '250px',
        },
        margin: '5px 0px 5px 0px',
    },
}));

const CreatorSearch = () => {
    // const { getAccessTokenSilently } = useAuth0()
    const classes = useStyles();
    const creatorContext = useContext(CreatorContext);
    const [creatorSearchResults, setCreatorSearchResults] = useState([]);
    const [creatorSearchToken, setCreatorSearchToken] = useState(undefined);

    const searchCreator = async (searchTerm) => {
        if (creatorSearchToken) {
            creatorSearchToken.cancel();
        }

        const creatorRequestCancelToken = axios.CancelToken.source();
        setCreatorSearchToken(creatorRequestCancelToken);
        // jamfeedClient.updateToken(await getAccessTokenSilently())
        jamfeedClient
            .get('/creator/search', {
                params: {
                    searchTerm,
                },
                cancelToken: creatorRequestCancelToken.token,
            })
            .then((response) => {
                setCreatorSearchResults(response.data.results);
            })
            .catch((e) => {
                if (axios.isCancel(e)) {
                    console.log('request cancelled');
                    return;
                }
                console.log('user search error', e);
            })
            .finally(() => setCreatorSearchToken(undefined));
    };

    return (
        <div className={classes.container}>
            <Autocomplete
                id='creator-search-autocomplete'
                value={creatorContext.creator}
                onChange={(event, newValue) => {
                    if (newValue) {
                        creatorContext.setCreator(newValue);
                    }
                }}
                options={creatorSearchResults}
                getOptionLabel={(option) => {
                    return option.name;
                }}
                renderOption={(props, option) => <Box {...props}>{option.name}</Box>}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label='Search Artist or Band'
                        name='creator-search-input'
                        onChange={(e) => {
                            searchCreator(e.target.value);
                        }}
                        onFocus={(e) => {
                            searchCreator(e.target.value);
                        }}
                    />
                )}
                className={classes.multiSelect}
            />
        </div>
    );
};

export default CreatorSearch;
