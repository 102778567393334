import he from 'he';

export default function decodeHtml(html) {
    if (html == null || typeof html !== 'string') {
        console.warn('Invalid input provided to decodeHtml:', html);
        return html;
    }

    try {
        // Attempt to decode the HTML
        const decodedHtml = he.decode(html);
        return decodedHtml;
    } catch (error) {
        // Log any errors that occur during decoding
        console.error('Error decoding HTML:', error);
        // Return the original input if an error occurs
        return html;
    }
}
