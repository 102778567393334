import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@mui/material';
import SocialIcons from '../../shared/social icons/SocialIcons';

const useStyles = makeStyles(() => ({
    footer: {
        backgroundColor: ({ backgroundColor }) => backgroundColor,
        color: 'white',
        width: '100%',
        paddingTop: '40px',
        paddingBottom: '40px',
        textAlign: 'right',
        float: 'right',
        width: '100%',
    },
    container: {
        display: 'inline',
        textAlign: 'right',
        float: 'right',
        paddingRight: 20,
        '@media(max-width:600px)': {
            textAlign: 'center',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    footerLogo: {
        display: 'flex',
        backgroundColor: 'transparent',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    image: {
        padding: '5px',
        width: '150px',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: '10px',
        '@media(max-width:600px)': {
            paddingLeft: '10px',
            textAlign: 'center',
            float: 'center',
        },
    },
    footerCopyright: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        display: 'flex',
        color: ({ color }) => color,
        padding: '5px',
        // paddingRight: '20px',
        marginBottom: '5px',
        '@media(max-width:600px)': {
            paddingRight: '10px',
            textAlign: 'center',
            float: 'center',
        },
    },
}));

const V3 = ({ logoUrl, artistName, color, backgroundColor, integrations, displaySocialIcons,monoIcons,iconColor }) => {
    const classes = useStyles({ color, backgroundColor });
    return (
        <footer className={classes.footer} id='footer'>
            <div className={classes.container}>
                <div className={classes.footerLogo}>
                    <a href='#'>
                        <img className={classes.image} src={logoUrl} alt='' />
                    </a>
                </div>
                <Grid container justifyContent='center'>
                    <Grid item xs={12}>
                        <SocialIcons
                            variant={1}
                            integrations={integrations}
                            displaySocialIcons={displaySocialIcons}
                            monoIcons={monoIcons} 
                            iconColor={iconColor}
                            position='flex-end'
                        ></SocialIcons>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.footerCopyright}>
                            © Copyright {artistName} {new Date().getFullYear()}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </footer>
    );
};

export default V3;

V3.propTypes = {
    logoUrl: PropTypes.string.isRequired,
    artistName: PropTypes.string.isRequired,
};
