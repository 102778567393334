import React from 'react';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
function VideosGrid({ videos, setVideoToPreview, handleOpen, numItems }) {
    return videos.slice(0, numItems || 9).map((item) => {
        return (
            <div className={'video w-video w-embed'}>
                <img
                    style={{ paddingTop: '56.17021276595745%' }}
                    src={item.thumbnail}
                    alt={item.title}
                    style={{ width: '100%' }}
                />
                <PlayCircleOutlineIcon
                    style={{
                        fontSize: 50,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: '#ffffff',
                    }}
                    onClick={() => {
                        setVideoToPreview(item.url);
                        handleOpen();
                    }}
                />
            </div>
        );
    });
}

export default VideosGrid;
