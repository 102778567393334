import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ShowMoreLess from './ShowMoreLess';

const useStyles = makeStyles(() => ({
    template1: {
        backgroundColor: 'black',
        paddingTop: '80px',
        paddingBottom: '40px',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        backgroundSize: '100% auto',
        backgroundRepeat: 'no-repeat',
        width: '100%',
    },
    sectionHeader: {
        color: '#FFF',
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        fontSize: '35px',
        fontWeight: 'bold',
        // textTransform: 'uppercase',
    },
    aboutText: {
        width: '70vw',
        margin: '20px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        maxWidth: '550px',
        textOverflow: 'ellipsis',
    },
    paragraph: {
        color: 'white',
        marginTop: '0',
        marginBottom: '10px',
        textAlign: 'left',
        lineHeight: '28px',
        flex: '1',
        textOverflow: 'ellipsis',
        letterSpacing: '0.8px',
        fontSize: '16px',
        fontWeight: 'lighter',
    },
}));

const Template5 = ({ color, headerText, bodyText, imageUrl }) => {
    const classes = useStyles();
    return (
        <div
            id='about'
            style={{
                background: `linear-gradient(rgba(30, 39, 46, 0.7), rgba(52, 73, 94, 0.7)), url(${imageUrl})`,
            }}
            className={classes.template1}
        >
            <Typography variant='h1' className={classes.sectionHeader} style={{ color: `${color}` }}>
                {headerText}
            </Typography>
            <div className={classes.aboutText}>
                <Typography className={classes.paragraph} style={{ color: `${color}` }}>
                    <ShowMoreLess bodyText={bodyText} />
                </Typography>
            </div>
        </div>
    );
};

export default Template5;

Template5.propTypes = {
    headerText: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
};
