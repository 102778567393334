import React from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { openUrl } from '../../utils/action';
import DOMPurify from 'dompurify';
import { decode } from 'html-entities';

const useStyles = makeStyles(() => ({
    card: ({ roundedCorners }) => ({
        position: 'relative',
        boxShadow: '0 8px 24px 0 rgba(0,0,0,0.12)',
        overflow: 'visible',
        borderRadius: roundedCorners ? '1.5rem' : 0,
        transition: '0.4s',
        '&:hover': {
            transform: 'translateY(-2px)',
            cursor: 'pointer',
            '& $shadow': {
                bottom: '-1.5rem',
            },
            '& $shadow2': {
                bottom: '-2.5rem',
            },
        },
        '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: 0,
            display: 'block',
            width: '100%',
            bottom: -1,
            height: '100%',
            borderRadius: '1.5rem',
            backgroundColor: 'rgba(0,0,0,0.08)',
        },
    }),
    main: ({ roundedCorners }) => ({
        overflow: 'hidden',
        borderTopLeftRadius: roundedCorners ? '1.5rem' : 0,
        borderTopRightRadius: roundedCorners ? '1.5rem' : 0,
        zIndex: 1,
        '&:after': {
            content: '""',
            position: 'absolute',
            bottom: 0,
            display: 'block',
            width: '100%',
            height: '100%',
            background: 'linear-gradient(to top, #014a7d, rgba(0,0,0,0))',
        },
    }),
    content: {
        position: 'absolute',
        bottom: 0,
        width: 'inherit',
        zIndex: 1,
        padding: '1.5rem 1.5rem 1rem',
    },
    avatar: {
        width: 48,
        height: 48,
        margin: '8px',
    },
    tag: {
        display: 'inline-block',
        backgroundColor: '#ff5dac',
        borderRadius: '0.5rem',
        padding: '2px 0.5rem',
        color: '#fff',
        marginBottom: '0.5rem',
    },
    title: {
        fontSize: '2rem',
        fontWeight: 800,
        color: '#fff',
    },
    author: ({ roundedCorners, backgroundColor }) => ({
        zIndex: 1,
        position: 'relative',
        backgroundColor: backgroundColor,
        borderBottomLeftRadius: roundedCorners ? '1.5rem' : 0,
        borderBottomRightRadius: roundedCorners ? '1.5rem' : 0,
    }),
    shadow: ({ roundedCorners }) => ({
        transition: '0.2s',
        position: 'absolute',
        zIndex: 0,
        width: '88%',
        height: '100%',
        bottom: 0,
        borderRadius: roundedCorners ? '1.5rem' : 0,
        backgroundColor: 'rgba(0,0,0,0.06)',
        left: '50%',
        transform: 'translateX(-50%)',
    }),
    shadow2: {
        bottom: 0,
        width: '72%',
        backgroundColor: 'rgba(0,0,0,0.04)',
    },
    mediaStyles: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.08)', //bgColor,
        backgroundPosition: 'center', //bgPosition
    },
}));

export const Card5 = ({
    title,
    subtitle,
    note1,
    media,
    avatar,
    url,
    textColor,
    backgroundColor,
    roundedCorners,
}) => {
    const styles = useStyles({ roundedCorners, backgroundColor });
    let cleanTitle = DOMPurify.sanitize(title, { SAFE_FOR_TEMPLATES: true });
    return (
        <>
            {/* <NoSsr>
        <GoogleFontLoader fonts={[{ font: 'Sen', weights: [400, 800] }]} />
      </NoSsr> */}
            <Card className={styles.card} onClick={() => openUrl(url)}>
                <Box
                    className={styles.main}
                    minHeight={300}
                    position={'relative'}
                >
                    <CardMedia className={styles.mediaStyles} image={media} />
                    <div className={styles.content}>
                        {/* <div className={styles.tag}>Fashion</div> */}
                        <Typography
                            variant={'h2'}
                            className={styles.title}
                            style={{ fontSize: '2em' }}
                        >
                            {decode(cleanTitle)}
                        </Typography>
                    </div>
                </Box>
                <Box
                    className={styles.author}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '0px',
                        display: 'flex',
                        padding: '16px 24px 24px',
                    }}
                >
                    <Avatar
                        className={styles.avatar}
                        src={avatar}
                        variant='circular'
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            pt: '8px',
                        }}
                    >
                        <Typography
                            variant='h6'
                            sx={{
                                color: textColor,
                                fontSize: '1rem',
                                lineHeight: '1.2',
                                overflowWrap: 'anywhere',
                            }}
                        >
                            {subtitle}
                        </Typography>
                        <Typography
                            variant='body1'
                            component='p'
                            sx={{
                                color: textColor,
                                fontSize: '0.875rem',
                                lineHeight: '1.75',
                            }}
                        >
                            {note1}
                        </Typography>
                    </Box>
                </Box>
                <div className={styles.shadow} />
                <div className={`${styles.shadow} ${styles.shadow2}`} />
            </Card>
        </>
    );
};

const title = 'Card Option 5';
const detailedEditorDefinition = {
    'card.textColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.textColor',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
    'card.backgroundColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.backgroundColor',
        label: 'Background Color',
        isRequired: false,
        defaultValue: '#ffffff',
    },
    'card.roundedCorners': {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'card.roundedCorners',
        label: 'Rounded Corners',
        description: 'Creates rounded corners on each card',
        isRequired: false,
    },
};

Card5.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    note1: PropTypes.string.isRequired,
    media: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    textColor: detailedEditorDefinition['card.textColor'].propType,
    backgroundColor: detailedEditorDefinition['card.backgroundColor'].propType,
    roundedCorners: detailedEditorDefinition['card.roundedCorners'].propType,
};

export { Card5 as default, title, detailedEditorDefinition };
