import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ensureReady, After } from '@jaredpalmer/after';
import routes from './routes';
import { config } from './config';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Auth0Provider } from '@auth0/auth0-react';
import CreatorProvider from './context/creatorContext';
import AuthProvider from './context/authContext';

// MUI 5 theme setup
const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#02AA02',
        },
    },
});

ensureReady(routes).then((data) =>
    hydrate(
        <ThemeProvider theme={lightTheme}>
            <Auth0Provider
                domain={config.auth0.domain}
                clientId={config.auth0.clientId}
                redirectUri={config.auth0.redirectUri}
                cacheLocation='localstorage'
                useRefreshTokens={true}
                audience={config.auth0.audience}
                scope='openid read:current_user read:current_user_metadata update:current_user_metadata  update:users update:current_user_identities'
            >
                <BrowserRouter>
                    <AuthProvider>
                        <CreatorProvider>
                            <After data={data} routes={routes} />
                        </CreatorProvider>
                    </AuthProvider>
                </BrowserRouter>
            </Auth0Provider>
        </ThemeProvider>,
        document.getElementById('root')
    )
);

if (module.hot) {
    module.hot.accept();
}
