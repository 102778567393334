import React, { useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import NavBar1 from '../../shared/nav/NavBar1';
import FooterSection from './FooterSection/FooterSection';
import HomeSection from './HomeSection/HomeSection';
import MusicSection from './MusicSection/MusicSection';
import VideosSection from './VideosSection/VideosSection';
import NewsSection from './NewsSection/NewsSection';
import './template3.scss';
import TourSection from './TourSection/TourSection';
import { getNetwork } from '../../../utils/network';

function loadFonts() {
    import('webfontloader').then((WebFont) => {
        WebFont.load({
            google: {
                families: [
                    'Lato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic',
                    'Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic',
                ],
            },
        });
    });
}

const Template3 = ({ integrations, profile, videos, music, news }) => {
    useEffect(() => {
        document.body.style.backgroundColor = 'white';
        loadFonts();
    }, []);

    const shopifyNetwork = getNetwork(integrations.integrated, 'SHOPIFY');
    const patreonNetwork = getNetwork(integrations.integrated, 'PATREON');

    return (
        <div className='template3'>
            <div className='body'>
                <NavBar1
                    profile={profile}
                    routes={[
                        { href: '#home-section', title: 'Home' },
                        !isEmpty(videos) &&
                        { href: '#videos-section', title: 'Videos' },
                        !isEmpty(music) &&
                        { href: '#music-section', title: 'Music' },
                        !isEmpty(news) &&
                        { href: '#news-section', title: 'News' },
                        { href: '#tour-section', title: 'Tour' },
                        { href: '#contact-section', title: 'Contact' },
                        !isEmpty(shopifyNetwork) &&
                        shopifyNetwork.data.url && {
                            href: shopifyNetwork.data.url,
                            title: 'Merch',
                            target: '_blank',
                        },
                        !isEmpty(patreonNetwork) &&
                        patreonNetwork.data.url && {
                            href: patreonNetwork.data.url,
                            title: 'Patreon',
                            target: '_blank',
                        },
                    ]}
                    mobileNavType={'drawer'}
                    backgroundColor={'white'}
                    textColor={'black'}
                />
                <HomeSection integrations={integrations} profile={profile} />
                {/* <SubscribeForm appData={appData} /> */}
                <VideosSection videos={videos} />
                <MusicSection music={music} />
                <NewsSection news={news} />
                {/* <PhotosSection appData={appData} /> */}
                <TourSection profile={profile} />
                {/* <ContactSection appData={appData} /> */}
                <FooterSection profile={profile} />
            </div>
        </div>
    );
};

export default Template3;
