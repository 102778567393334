import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ShowMoreLess = ({ bodyText }) => {
	const [isFullView, setIsFullView] = useState(false);
	const charLimit = 500;
	return (
		<>
			{bodyText && (
				<strong>
					{isFullView
						? bodyText
						: bodyText.substring(0, charLimit) +
						(bodyText.length > charLimit ? '...' : '')}
				</strong>
			)}
			{bodyText && bodyText.length > charLimit && (
				<a
					onClick={() => setIsFullView(!isFullView)}
					style={{
						color: 'white',
						fontWeight: 'bolder !important',
						cursor: 'pointer',
					}}
				>
					{isFullView ? ' Show Less' : ' Show More'}
				</a>
			)}
		</>
	);
};

export default ShowMoreLess;

ShowMoreLess.propTypes = {
	bodyText: PropTypes.string.isRequired,
};
