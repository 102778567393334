import React, { useEffect, useState } from 'react';
import { Paper, Typography, Fab } from '@mui/material';
import { Helmet } from 'react-helmet';
import _get from 'lodash.get';
import TemplateOrchestrator from './builder/TemplateOrchestrator';
import CompProvider from './context/CompContext';
import creatorApi from './api/creatorApi';
import allSettled from 'promise.allsettled';
import JFLogo from './builder/images/JFLogo.png';
import { useTheme } from '@mui/material/styles';

allSettled.shim();

const getDataResults = (data, idx) => {
    return data[idx] && data[idx].status === 'fulfilled' ? data[idx].value : {};
};

function extractIdFromSearch(props) {
    const searchParams = new URLSearchParams(props.location.search);
    return searchParams.get('id');
}

const WebPreview = (props) => {
    // State to store fetched data
    const [data, setData] = useState({
        entityId: null,
        profile: {},
        videos: [],
        music: [],
        news: [],
        integrations: {},
        images: [],
        events: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            const id = extractIdFromSearch(props);
            const entityId = id;
            const dataResults = await Promise.allSettled([
                creatorApi.getProfile(entityId),
                creatorApi.getVideos(entityId, 'ARTIST'),
                creatorApi.getMusic(entityId, 'ARTIST'),
                creatorApi.getNews(entityId, 'ARTIST'),
                creatorApi.getIntegrations(entityId),
                creatorApi.getImages(entityId),
                creatorApi.getEvents(entityId, 'ARTIST'),
            ]);

            const newData = {
                entityId,
                profile: getDataResults(dataResults, 0),
                videos: getDataResults(dataResults, 1),
                music: getDataResults(dataResults, 2),
                news: getDataResults(dataResults, 3),
                integrations: getDataResults(dataResults, 4),
                images: getDataResults(dataResults, 5),
                events: getDataResults(dataResults, 6),
            };

            setData(newData);
        };
        fetchData();
    }, []);

    const theme = useTheme();

    const fabButton = (
        <Fab
            variant='extended'
            size='small'
            aria-label='add'
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexFlow: 'row nowrap',
                position: 'fixed',
                bottom: { xs: theme.spacing(1), sm: theme.spacing(2) },
                right: { xs: theme.spacing(1), sm: theme.spacing(2) },
                zIndex: 100,
                borderRadius: 5,
                backgroundColor: '#e0e0e0',
                padding: '0 10px',
            }}
            href='https://www.jamfeed.com'
        >
            <img src={JFLogo} style={{ width: '20px' }} />
            <Typography
                sx={{
                    textDecoration: 'none',
                    color: '#000',
                    fontSize: '10px',
                    textTransform: 'none',
                    fontWeight: 600,
                }}
            >
                Made in JamFeed
            </Typography>
        </Fab>
    );
    return (
        <>
            <CompProvider
                entityId={data.entityId}
                defaultConfigType='PREVIEW'
                isBuilder={false}
            >
                <Helmet>
                    <title>{data.profile && data.profile.name}</title>
                    <meta
                        content='width=device-width, initial-scale=1'
                        name='viewport'
                    />
                    <meta
                        content={data.profile && data.profile.bio}
                        name='description'
                    />
                    <meta
                        property='og:image'
                        content={data.profile && data.profile.pictureurl}
                    />
                    <meta property='og:type' content='artist-website' />
                    <meta
                        property='og:title'
                        content={data.profile && data.profile.name}
                    />
                    <meta
                        property='og:description'
                        content={data.profile && data.profile.bio}
                    />
                    <meta name='twitter:card' content='summary_large_image' />
                    <meta
                        name='twitter:title'
                        content={data.profile && data.profile.name}
                    />
                    <meta
                        name='twitter:description'
                        content={data.profile && data.profile.bio}
                    />
                    <meta
                        name='twitter:image'
                        content={data.profile && data.profile.pictureurl}
                    />
                    <link
                        href={
                            data.profile &&
                            (data.profile.pictureurl || data.profile.logo)
                        }
                        rel='icon'
                        type='image/*'
                    />
                    <link
                        href={
                            data.profile &&
                            (data.profile.pictureurl || data.profile.logo)
                        }
                        rel='shortcut icon'
                        type='image/*'
                    />
                    <link
                        href={
                            data.profile &&
                            (data.profile.pictureurl || data.profile.logo)
                        }
                        rel='apple-touch-icon'
                    />
                </Helmet>
                <Paper
                    sx={{
                        maxWidth: '100vw',
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        margin: 0,
                        padding: 0,
                    }}
                >
                    <TemplateOrchestrator
                        openOptions={false}
                        key={data && data.id}
                        {...data}
                        isEditor={false}
                    />
                </Paper>
            </CompProvider>
            {fabButton}
        </>
    );
};

export default WebPreview;
