import makeStyles from '@mui/styles/makeStyles';
import Color from 'color';
import PropTypes from 'prop-types';
import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// go here for v6 docs https://web.archive.org/web/20210825020143/https://swiperjs.com/react
// also https://swiper6.vercel.app/
SwiperCore.use([Navigation, Pagination]);

const useStyles = makeStyles({
    sliderWrapper: {
        '& .swiper-button-next': {
            color: '#FFFFFF',
        },
        '& .swiper-button-prev': {
            color: '#FFFFFF',
        },
        '& .swiper-pagination-bullet': {
            backgroundColor: Color('#FFFFFF').darken(0.5).toString(),
        },
        '& .swiper-pagination-bullet.swiper-pagination-bullet-active': {
            backgroundColor: '#FFFFFF',
        },
    },
});

const SliderLayoutRow = ({
    items,
    renderItem,
    columns = 1,
    spacing = 0,
    rows = 1,
}) => {
    const styles = useStyles();
    return (
        <div style={{ width: '100%' }} className={styles.sliderWrapper}>
            <Swiper
                spaceBetween={spacing}
                slidesPerView={columns}
                onSlideChange={() => console.log('slide change')}
                onSwiper={() => {}}
                onUpdate={(swiper) => console.log('onUpdated', swiper)}
                navigation={true}
                pagination={{
                    clickable: true,
                }}
                breakpoints={{
                    // when window width is >= 0px (maps to xs for MUI5)
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                    },
                    // when window width is >= 600px (maps to sm for MUI5)
                    600: {
                        slidesPerView: 3,
                    },
                    // when window width is >= 900px (maps to md for MUI5)
                    900: {
                        slidesPerView: columns,
                    },
                }}
            >
                {items.map((i, idx) => {
                    return <SwiperSlide key={idx}>{renderItem(i)}</SwiperSlide>;
                })}
            </Swiper>
        </div>
    );
};

SliderLayoutRow.propTypes = {
    items: PropTypes.arrayOf(Object).isRequired,
    renderItem: PropTypes.func.isRequired,
    columns: PropTypes.number,
    spacing: PropTypes.number,
    rows: PropTypes.number,
};

SliderLayoutRow.defaultProps = {
    items: [1, 2, 3, 4, 5],
    columns: 1,
    spacing: 0,
    rows: 1,
};

export default SliderLayoutRow;
