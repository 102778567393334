import React from 'react';
import ShowMore from '@tedconf/react-show-more';
import { useMediaQuery } from 'react-responsive';
import NoContent from '../../shared/NoContent';
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
};
const NotMobile = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 });
    return isNotMobile ? children : null;
};
function NewsList({ news, renderItem, outerClass, numPerRequest, theme }) {
    const defaultRenderItem = ({ media, title, source, url }) => (
        <a
            href={url}
            target='_blank'
            style={{
                textDecoration: 'inherit',
                color: 'inherit',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
            }}
        >
            <NotMobile>
                <div
                    className='news-cell-wrapper'
                    style={{
                        msGridColumns: '1fr',
                        gridTemplateColumns: '1fr 1fr',
                        display: 'grid',
                        marginRight: '10vh',
                        marginLeft: '10vh',
                        gridAutoColums: '1fr',
                        gridTemplateRows: 'auto',
                    }}
                >
                    <div className='news-image-div'>
                        <img
                            src={media}
                            alt
                            style={{
                                width: '40vw',
                                height: '50vh',
                                padding: 20,
                                objectFit: ' cover',
                            }}
                        />
                    </div>
                    <div
                        className='news-info-wrapper'
                        style={{
                            marginLeft: 20,
                            marginRight: 20,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <h3
                            className='news-article-header'
                            style={{
                                color: theme.textColor,
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '1.5em',
                                marginTop: 10,
                            }}
                        >
                            {title}
                        </h3>
                        <h6
                            className='news-article-source'
                            style={{
                                color: theme.textColor,
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '1em',
                            }}
                        >
                            {source}
                        </h6>
                    </div>
                </div>
            </NotMobile>
            <Mobile>
                <div className='news-cell-wrapper' style={{ marginBottom: 20 }}>
                    <div className='news-image-div'>
                        <img
                            src={media}
                            width={450}
                            sizes='(max-width: 479px) 100vw, (max-width: 991px) 80vw, 40vw'
                            alt
                            style={{
                                width: '100%',
                                height: '50vh',
                                padding: 20,
                                objectFit: ' cover',
                            }}
                        />
                    </div>
                    <div
                        className='news-info-wrapper'
                        style={{
                            marginLeft: 20,
                            marginRight: 20,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <h3
                            className='news-article-header'
                            style={{
                                color: theme.textColor,
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '1.5em',
                                marginTop: 10,
                            }}
                        >
                            {title}
                        </h3>
                        <h6
                            className='news-article-source'
                            style={{
                                color: theme.textColor,
                                fontFamily: 'Lato, sans-serif',
                                fontSize: '1em',
                            }}
                        >
                            {source}
                        </h6>
                    </div>
                </div>
            </Mobile>
        </a>
    );

    return (
        <>
            <ShowMore items={news} by={numPerRequest ? numPerRequest : 4}>
                {({ current, onMore }) => (
                    <>
                        <div className={outerClass}>
                            {current.map(
                                (item) =>
                                    (renderItem && renderItem(item)) ||
                                    defaultRenderItem(item)
                            )}
                        </div>
                        {news.length !== current.length && (
                            <div
                                style={{
                                    display: 'flex',
                                    flex: 1,
                                    alignSelf: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <button
                                    outline
                                    style={{
                                        borderRadius: '5px',
                                        backgroundColor: '#0baa1f',
                                    }}
                                    className='button w-button'
                                    disabled={!onMore}
                                    onClick={() => {
                                        if (!!onMore) onMore();
                                    }}
                                >
                                    Load more
                                </button>
                            </div>
                        )}
                    </>
                )}
            </ShowMore>
            <NoContent
                data={news}
                style={{
                    color: theme.textColor,
                    opacity: 0.8,
                    textAlign: 'center',
                    margin: 20,
                }}
            />
        </>
    );
}

export default NewsList;
