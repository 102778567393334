import React, { useState } from 'react';
import Carousel from 'react-multi-carousel';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CardMedia from '@mui/material/CardMedia';
import ReactPlayer from 'react-player';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CustomArrow from '../../../shared/CustomArrow';
import isEmpty from 'lodash.isempty';

function VideosSection({ videos }) {
    const [videoToPreview, setVideoToPreview] = useState([]);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (

        <>
            {!isEmpty(videos) && (
                <div id="video-section" className="videos-section">
                    <div className="videos-section-wrapper">
                        <div className="videos-section-header">
                            <h2 className="section-header">Videos</h2>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center'
                            }}
                            className="slider-wrap"
                        >
                            {videos && videos.length > 0
                                &&
                                <Carousel
                                    ssr
                                    containerClass="slider w-slider slider-relative"
                                    itemClass="w-slide"
                                    sliderClass="mask w-slider-mask"
                                    showDots={false}
                                    showArrows={false}
                                    infinite
                                    customRightArrow={<CustomArrow isLeft={false} isMusic={true} />}
                                    customLeftArrow={<CustomArrow isLeft={true} isMusic={true} />}
                                    responsive={{
                                        desktop: {
                                            breakpoint: { max: 4000, min: 1024 },
                                            items: 2,
                                            slidesToSlide: 1 // optional, default to 1.
                                        },
                                        tablet: {
                                            breakpoint: { max: 1024, min: 464 },
                                            items: 1,
                                            slidesToSlide: 1 // optional, default to 1.
                                        },
                                        mobile: {
                                            breakpoint: { max: 464, min: 0 },
                                            items: 1,
                                            slidesToSlide: 1 // optional, default to 1.
                                        }
                                    }}
                                >
                                    {
                                        videos.map((item, idx) => {
                                            return (
                                                <div className="videos-item-wrapper" key={idx}>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#141414'
                                                        }}
                                                        className="video w-video w-embed"
                                                    >
                                                        <img src={item.thumbnail} alt={item.title} style={{ width: '100%' }} />
                                                        <PlayCircleOutlineIcon style={{
                                                            fontSize: 120,
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)'
                                                        }}
                                                            onClick={() => {
                                                                setVideoToPreview(item.url);
                                                                handleOpen();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Carousel>
                            }
                        </div>
                    </div>
                </div>
            )}
            {open && <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{
                    backgroundColor: '#000000',
                }}
            >
                <>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        style={{
                            float: 'right'
                        }}
                    >
                        <CancelPresentationIcon />
                    </Button>
                    <ReactPlayer
                        url={videoToPreview}
                        controls={false}
                        muted={false}
                        loop
                        playsinline
                        width={'100%'}
                        height={'100%'}
                    />
                </>
            </Modal>}
        </>

    )
}

export default VideosSection
