import template1 from './template1';
import template2 from './template2';
import template3 from './template3';
import template4 from './template4';
import template5 from './template5';
import template6 from './template6';
import template7 from './template7';
import template8 from './template8';

const templates = {
    template1,
    template2,
    template3,
    template4,
    template5,
    template6,
    template7,
    template8,
};

export default templates;
