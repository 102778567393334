import React from "react";
import isEmpty from 'lodash.isempty';

function NewsSection({ news }) {
  return (
    <div id="news-section" className="news-section">
      {!isEmpty(news) && (
        <div className="news-wrapper">
          <div className="news-section-header">
            <h1 className="section-header">News</h1>
          </div>
          <div className="news-collection">
            <a
              href="#"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829551efc3e_Cal%20Scruby%20News%201%20WWWS.png"
                    sizes="(max-width: 479px) 96vw, (max-width: 767px) 90vw, (max-width: 991px) 44vw, 49vw"
                    srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829551efc3e_Cal%2520Scruby%2520News%25201%2520WWWS-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829551efc3e_Cal%2520Scruby%2520News%25201%2520WWWS-p-800.png 800w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829551efc3e_Cal%2520Scruby%2520News%25201%2520WWWS-p-1080.png 1080w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829551efc3e_Cal%2520Scruby%2520News%25201%2520WWWS-p-1600.png 1600w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829551efc3e_Cal%20Scruby%20News%201%20WWWS.png 1790w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    Cal Scruby Shines On "While You Were Sleeping"
                  </h4>
                </div>
                <div className="news-article-source-wrapper">
                  <div className="news-article-source">Hot New Hip Hop</div>
                </div>
              </div>
            </a>
            <a
              href="#"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d383d3e1efc3b_Cal%20Scruby%20News%204%20Unsigned.png"
                    sizes="(max-width: 479px) 96vw, (max-width: 767px) 90vw, (max-width: 991px) 44vw, 49vw"
                    srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d383d3e1efc3b_Cal%2520Scruby%2520News%25204%2520Unsigned-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d383d3e1efc3b_Cal%2520Scruby%2520News%25204%2520Unsigned-p-800.png 800w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d383d3e1efc3b_Cal%2520Scruby%2520News%25204%2520Unsigned-p-1080.png 1080w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d383d3e1efc3b_Cal%20Scruby%20News%204%20Unsigned.png 1478w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    Cal Scruby's Unsigned is a Captivating Introduction
                  </h4>
                </div>
                <div className="news-article-source-wrapper">
                  <div className="news-article-source">Hot New Hip Hop</div>
                </div>
              </div>
            </a>
            <a
              href="#"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3863c91efc3c_Cal%20Scruby%20News%205%20White%20Privledge.png"
                    sizes="(max-width: 479px) 96vw, (max-width: 767px) 90vw, (max-width: 991px) 44vw, 49vw"
                    srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3863c91efc3c_Cal%2520Scruby%2520News%25205%2520White%2520Privledge-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3863c91efc3c_Cal%2520Scruby%2520News%25205%2520White%2520Privledge-p-800.png 800w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3863c91efc3c_Cal%2520Scruby%2520News%25205%2520White%2520Privledge-p-1080.png 1080w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3863c91efc3c_Cal%20Scruby%20News%205%20White%20Privledge.png 1268w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    Cal Scruby Is Honest About White Privilege In Hip Hop
                  </h4>
                </div>
                <div className="news-article-source-wrapper">
                  <div className="news-article-source">HipHopDX</div>
                </div>
              </div>
            </a>
            <a
              href="#"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3866871efc35_Cal%20Scruby%20News%203%20Redman.jpg"
                    sizes="(max-width: 479px) 96vw, (max-width: 767px) 90vw, (max-width: 991px) 44vw, 49vw"
                    srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3866871efc35_Cal%2520Scruby%2520News%25203%2520Redman-p-800.jpeg 800w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3866871efc35_Cal%20Scruby%20News%203%20Redman.jpg 1024w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    Cal Scruby &amp; Redman Come Back to Life for 'Do Or Die'
                    Video: Premiere
                  </h4>
                </div>
                <div className="news-article-source-wrapper">
                  <div className="news-article-source">Billboard</div>
                </div>
              </div>
            </a>
            <a
              href="#"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d386f031efc3a_Cal%20Scruby%20News%207%20Confidence.png"
                    sizes="(max-width: 479px) 96vw, (max-width: 767px) 90vw, (max-width: 991px) 44vw, 49vw"
                    srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d386f031efc3a_Cal%2520Scruby%2520News%25207%2520Confidence-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d386f031efc3a_Cal%2520Scruby%2520News%25207%2520Confidence-p-800.png 800w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d386f031efc3a_Cal%20Scruby%20News%207%20Confidence.png 1166w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    L.A. rapper Remembers His HJumble Lebanon Roots
                  </h4>
                </div>
                <div className="news-article-source-wrapper">
                  <div className="news-article-source">Cincinnati.com</div>
                </div>
              </div>
            </a>
            <a
              href="#"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3825961efc3f_Cal%20Scruby%20News%202%20Big%20Year.png"
                    sizes="(max-width: 479px) 96vw, (max-width: 767px) 90vw, (max-width: 991px) 44vw, 49vw"
                    srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3825961efc3f_Cal%2520Scruby%2520News%25202%2520Big%2520Year-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3825961efc3f_Cal%2520Scruby%2520News%25202%2520Big%2520Year-p-800.png 800w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3825961efc3f_Cal%20Scruby%20News%202%20Big%20Year.png 1024w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    2015: Cal Scruby's Big Year
                  </h4>
                </div>
                <div className="news-article-source-wrapper">
                  <div className="news-article-source">Cincinnati.com</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewsSection;
