import React from 'react';
import PropTypes from 'prop-types';
import Template1 from './Template1';
import Template2 from './Template2';
import jamfeedClient from '../../api/jamfeedClient';
import _get from 'lodash.get';

const isPreviewPage = () => {
    const url = window.location.href;
    return url.includes('/w/preview');
};

const getPreviewIdFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('id');
};

// const FLOST8_MAILCHIMP_LIST = '315f21c4ec'; // for testing

const SubscribeSection = ({
    profile,
    variant,
    color,
    backgroundColor,
    buttonColor,
    sectionTitle,
    subtitle,
    buttonBackgroundColor,
    // mailchimpList = FLOST8_MAILCHIMP_LIST, // for testing
    mailchimpList,
    entityId: entityProvidedByTemplate,
    id: entityId,
}) => {
    const subscribeToList = async (email, firstName, lastName) => {
        // if (mailchimpList === FLOST8_MAILCHIMP_LIST) { // for testing
        //     console.log('mailchimp using FLOST8_MAILCHIMP_LIST');
        // }

        if (!mailchimpList) {
            throw new Error('No MailChimp list selected');
        }

        const id = isPreviewPage()
            ? getPreviewIdFromUrl()
            : entityProvidedByTemplate || entityId;

        try {
            if (!id) {
                throw new Error(
                    'Seems we are running into issues, please stay tuned!'
                );
            }

            const { data } = await jamfeedClient.post('/mailchimp/subscribe', {
                subscriber: {
                    email,
                    firstName,
                    lastName,
                },
                entity: {
                    id,
                },
                listId: mailchimpList,
            });
            return data;
        } catch (e) {
            console.log('Error subscribing to list', email, entityId);
            throw new Error(
                `Uh oh, something went wrong while trying to subscribe. ${_get(
                    e,
                    'response.data.msg',
                    ''
                )}`
            );
        }
    };
    // ensure variant is number incase it switch
    // to a string
    let variantInt = variant;
    if (typeof variantInt !== 'number') {
        variantInt = parseInt(variantInt, 10);
    }
    switch (variantInt) {
        case 1:
            return (
                <Template1
                    color={color}
                    backgroundColor={backgroundColor}
                    sectionTitle={sectionTitle}
                    subtitle={subtitle}
                    buttonColor={buttonColor}
                    buttonBackgroundColor={buttonBackgroundColor}
                    onSubmit={subscribeToList}
                />
            );
        case 2:
            return (
                <Template2
                    color={color}
                    backgroundColor={backgroundColor}
                    sectionTitle={sectionTitle}
                    subtitle={subtitle}
                    buttonColor={buttonColor}
                    buttonBackgroundColor={buttonBackgroundColor}
                    onSubmit={subscribeToList}
                />
            );
        default:
            return (
                <Template1
                    color={color}
                    backgroundColor={backgroundColor}
                    sectionTitle={sectionTitle}
                    subtitle={subtitle}
                    buttonColor={buttonColor}
                    buttonBackgroundColor={buttonBackgroundColor}
                    onSubmit={subscribeToList}
                />
            );
    }
};

const title = 'Email Capture';
const isDisplayedOnNav = true;
const detailedEditorDefinition = {
    sectionTitle: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'text',
        name: 'sectionTitle',
        label: 'Section Title',
        isRequired: false,
        defaultValue: 'SUBSCRIBE',
    },
    variant: {
        propType: PropTypes.number.isRequired,
        valueType: 'number',
        inputMethod: 'select',
        inputOptions: ['1', '2'],
        name: 'variant',
        label: 'Select Design',
        isRequired: true,
        defaultValue: '1',
    },
    displayOnNavigation: {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'displayOnNavigation',
        label: 'Display On Navigation',
        isRequired: true,
        defaultValue: true,
    },
    color: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'color',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#000000',
    },
    backgroundColor: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'backgroundColor',
        label: 'Background Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
    buttonBackgroundColor: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'buttonBackgroundColor',
        label: 'Button Background Color',
        isRequired: false,
        defaultValue: '#6B5353',
    },
    buttonColor: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'buttonColor',
        label: 'Button Text Color',
        isRequired: false,
        defaultValue: '#000000',
    },
    subtitle: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'text',
        name: 'subtitle',
        label: 'Subtitle Text',
        isRequired: false,
        defaultValue: 'Please sign up for new updates.',
    },
    mailchimpList: {
        propType: PropTypes.shape({
            id: PropTypes.string,
            webId: PropTypes.number,
            name: PropTypes.string,
        }),
        valueType: 'element',
        inputMethod: 'dropdown',
        name: 'mailchimpList',
        label: 'MailChimp List',
        isRequired: true,
        defaultValue: '',
        getMenuOptions: async (id) => {
            try {
                const response = await jamfeedClient.get(
                    '/mailchimp/audiencelist',
                    {
                        params: {
                            id,
                            type: 'ARTIST',
                        },
                    }
                );
                return _get(response, 'data.results', []);
            } catch (e) {
                return [];
            }
        },
    },
};

SubscribeSection.propTypes = {
    variant: detailedEditorDefinition.variant.propType,
    displayOnNavigation: detailedEditorDefinition.displayOnNavigation.propType,
    color: detailedEditorDefinition.color.propType,
    backgroundColor: detailedEditorDefinition.backgroundColor.propType,
    buttonBackgroundColor:
        detailedEditorDefinition.buttonBackgroundColor.propType,
    sectionTitle: detailedEditorDefinition.sectionTitle.propType,
    subtitle: detailedEditorDefinition.subtitle.propType,
};

SubscribeSection.defaultProps = {
    buttonBackgroundColor:
        detailedEditorDefinition.buttonBackgroundColor.defaultValue,
    variant: detailedEditorDefinition.variant.defaultValue,
    displayOnNavigation:
        detailedEditorDefinition.displayOnNavigation.defaultValue,
    sectionTitle: detailedEditorDefinition.sectionTitle.defaultValue,
    color: detailedEditorDefinition.color.defaultValue,
    backgroundColor: detailedEditorDefinition.backgroundColor.defaultValue,
    subtitle: detailedEditorDefinition.subtitle.defaultValue,
};

export {
    SubscribeSection as default,
    title,
    isDisplayedOnNav,
    detailedEditorDefinition,
};
