import React, { useEffect } from 'react';
import './template5.scss';
import HomeSection from './HomeSection/HomeSection';
import MusicSection from './MusicSection/MusicSection';
import VideoSection from './VideoSection/VideoSection';
import TourSection from './TourSection/TourSection';
import NewsSection from './NewsSection/NewsSection';
import MerchSection from './MerchSection/MerchSection';
import AboutSection from './AboutSection/AboutSection';
import SubscribeFormSection from './SubscribeFormSection/SubscribeFormSection';
import FooterSection from './FooterSection/FooterSection';
import NavBarSection from './NavBarSection/NavBarSection';

function loadFonts() {
    import('webfontloader').then((WebFont) => {
        WebFont.load({
            google: {
                families: [
                    'Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic',
                ],
            },
        });
    });
}

const Template5 = ({ profile, videos, news, music, integrations }) => {
    useEffect(() => {
        loadFonts();
    }, []);

    return (
        <div className='template5'>
            <div className='body'>
                <NavBarSection news={news} music={music} videos={videos} integrations={integrations} />
                <HomeSection />
                <MusicSection music={music} />
                <VideoSection videos={videos} />
                <TourSection profile={profile} />
                <NewsSection news={news} />
                <MerchSection />
                <AboutSection />
                <SubscribeFormSection />
                <FooterSection />
            </div>
        </div>
    );
};

export default Template5;
