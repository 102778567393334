export const generatePropTypesAndDefaults = (definition) => {
    const propTypes = {};
    const defaultProps = {};

    Object.keys(definition).forEach((key) => {
        const field = definition[key];
        propTypes[key] = field.isRequired
            ? field.propType.isRequired
            : field.propType;
        if (field.defaultValue !== undefined) {
            defaultProps[key] = field.defaultValue;
        }
    });

    return { propTypes, defaultProps };
};

export const createField = (propType, valueType, inputMethod, options = {}) => {
    return {
        propType,
        valueType,
        inputMethod,
        ...options,
    };
};

export const InputMethods = {
    SELECT: 'select',
    TEXT: 'text',
    LINK: 'link',
    COLOR: 'color',
    SWITCH: 'switch',
    STRING: 'string',
    BOOLEAN: 'boolean',
};
