import React from 'react';
import SocialIcons from '../../../shared/SocialIcons';
function HomeSection({ integrations, profile }) {
    return (
        <div id='home-section' className='home-section'>
            <div>
                <img
                    src={profile.pictureurl}
                    sizes='100vw'
                    alt
                    className='image'
                    style={{
                        objectFit: 'cover',
                        width: '100vw',
                        maxHeight: '200vh',
                        paddingTop: '50px',
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <SocialIcons integrations={integrations} />
                </div>
            </div>
        </div>
    );
}

export default HomeSection;
