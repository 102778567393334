import GridLayout, { detailedEditorDefinition as gridDED } from './grid/GridLayout';
import SliderLayout, { detailedEditorDefinition as sliderDED } from './slider/SliderLayout';
import MasonryLayout, { detailedEditorDefinition as masonryDED } from './masonry/MasonryLayout';

const layoutList = {
    grid: {
        key: 'grid',
        component: GridLayout,
        title: 'Grid',
        detailedEditorDefinition: gridDED,
    },
    slider: {
        key: 'slider',
        component: SliderLayout,
        title: 'Slider',
        detailedEditorDefinition: sliderDED,
    },
    masonry: {
        key: 'masonry',
        component: MasonryLayout,
        title: 'Masonry',
        detailedEditorDefinition: masonryDED,
    },
};

export { layoutList };
