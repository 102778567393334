// config.js
let buildConfig

// environment variables for auth0
const auth0 = {
    domain: process.env.AUTH0_DOMAIN,
    redirectUri: process.env.AUTH0_REDIRECT_URI,
    clientId: process.env.AUTH0_CLIENT_ID,
    audience: process.env.AUTH0_AUDIENCE
}

if (typeof window !== 'undefined') {
    // running client side (in browser)
    buildConfig = {
        JAMFEED_API: window.env.JAMFEED_API,
        auth0: window.env.auth0,
        WEB_BASE_URL: window.env.WEB_BASE_URL,
        basePath: '/w',
        intercomAppId: window.env.intercomAppId,
        rewardfulCampaignId: window.env.rewardfulCampaignId,
    };
} else {
    // running server side (Node)
    switch (process.env.RAZZLE_NODE_ENV) {
        case 'production':
            buildConfig = {
                JAMFEED_API: 'https://api.jamfeed.com/v1',
                WEB_BASE_URL: 'https://www.jamfeed.com',
                basePath: '/w',
                google: {
                    tagmanager: {
                        key: 'GTM-WR4VV8M',
                    },
                },
                intercomAppId: 'pc9loayx',
                rewardfulCampaignId: '',
            };
            break;
        case 'test':
            buildConfig = {
                JAMFEED_API: 'https://api-test.jamfeed.com/v1',
                WEB_BASE_URL: 'https://test.jamfeed.com',
                basePath: '/w',
                google: {
                    tagmanager: {
                        key: 'GTM-TTJNZRX',
                    },
                },
                intercomAppId: 'm8sxmmgv',
                rewardfulCampaignId: '266def',
            };
            break;
        default:
            buildConfig = {
                JAMFEED_API: 'http://localhost:3002/v1',
                // JAMFEED_API: 'https://api-test.jamfeed.com/v1',
                WEB_BASE_URL: 'http://localhost:8080',
                // WEB_BASE_URL: 'https://test.jamfeed.com',
                basePath: '/w',
                google: {
                    tagmanager: {
                        key: 'GTM-TTJNZRX',
                    },
                },
                intercomAppId: 'm8sxmmgv',
                rewardfulCampaignId: '266def',
            };
    }
}

const config = {
    auth0,
    ...buildConfig
}
const serverSideConfig = {
    auth0,
    ...buildConfig
}

export { config, serverSideConfig }
