import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import isEmpty from 'lodash.isempty';
import { getNetworkIcon } from '../../utils/network';

const useStyles = makeStyles({
    root: {
        background: 'transparent !important',
        height: '100%',
        padding: '50px',
        textAlign: 'center',
    },
    container: {
        display: 'inline',
        paddingLeft: '0px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    profileLink: {
        listStyleType: 'none',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        width: '30px',
        height: '30px',
        borderRadius: '500px !important',
        paddingBottom: '10px',
        objectFit: 'contain',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const Vertical = ({ integrated, displaySocialIcons }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {displaySocialIcons ? (
                <div className={classes.container}>
                    {integrated &&
                        integrated.map(({ network, data: { url } }) => {
                            if (isEmpty(url)) {
                                return;
                            }
                            return (
                                <div className={classes.profileLink}>
                                    <a href={url} target='_blank'>
                                        <img
                                            src={getNetworkIcon(network)}
                                            width={30}
                                            height={30}
                                            alt
                                            className={classes.icon}
                                        />
                                    </a>
                                </div>
                            );
                        })}
                </div>
            ) : null}
        </div>
    );
};

export default Vertical;
