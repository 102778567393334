import React from 'react';
import { Box, Button, Drawer, styled } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

/**
 * Styles for the StyledBox component.
 *
 * @param {Object} theme - The theme object from Material-UI.
 * @param {Object} sx - Additional custom styles.
 * @returns {Object} A styles object.
 */
const boxStyles = ({ theme, sx }) => ({
    display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' },
    width: '100%',
    ...sx,
});

const StyledBox = styled(Box)(boxStyles);

/**
 * MenuIconComponent displays a menu icon that triggers a drawer component.
 *
 * @param {Object} props - Props for the component.
 * @param {Function} props.toggleDrawer - Function to toggle the drawer.
 * @param {Object} props.state - State object indicating if the drawer is open.
 * @param {React.Component} props.ListComponent - Component to render inside the drawer.
 * @param {Object} props.sx - Custom styles for the component.
 * @returns {React.Element} The rendered component.
 */
const MenuIconComponent = ({ toggleDrawer, state, ListComponent, sx }) => {
    return (
        <StyledBox
            sx={sx}
            style={{
                color: 'white',
                backgroundColor: 'transparent',
            }}
        >
            <Button
                style={{
                    color: 'white',
                    backgroundColor: 'transparent',
                }}
                sx={{
                    color: 'white',
                    background: 'transparent', // Set the background to transparent
                    '& .MuiButton-label': { display: 'flex' },
                    '&:hover': {
                        background: 'transparent', // Ensure hover background is also transparent
                    },
                }}
                onClick={toggleDrawer('left', true)}
            >
                <MenuIcon />
            </Button>
            <Drawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
                PaperProps={{
                    sx: {
                        width: '100vw',
                        backgroundColor: 'transparent',
                        ...sx,
                    },
                }}
            >
                <Box sx={{ width: '100vw', ...sx }}>
                    {ListComponent('left')}
                </Box>
            </Drawer>
        </StyledBox>
    );
};

export default MenuIconComponent;
