import DynamicRouter from './DynamicRouter';
import WebBuilder from './WebBuilder';
import { serverSideConfig } from './config';
import WebPreview from './WebPreview';
import WebPublished from './WebPublished';
import BuilderRouter from './BuilderRouter';

export default [
    {
        path: `/`,
        exact: true,
        component: BuilderRouter,
    },
    {
        path: `/website`,
        exact: true,
        component: DynamicRouter,
    },
    {
        path: `${serverSideConfig.basePath}/`,
        exact: true,
        component: DynamicRouter,
    },
    {
        path: `${serverSideConfig.basePath}/website`,
        exact: true,
        component: DynamicRouter,
    },
    {
        path: `${serverSideConfig.basePath}/builder`,
        exact: true,
        component: WebBuilder,
    },
    {
        path: `${serverSideConfig.basePath}/preview`,
        exact: true,
        component: WebPreview,
    },
    {
        path: `${serverSideConfig.basePath}/published`,
        exact: true,
        component: WebPublished,
    },
];

export const PUBLIC_ROUTES = { PREVIEW: 'preview', PUBLISHED: 'published' };

export const publicRoutes = [
    {
        path: `${serverSideConfig.basePath}/${PUBLIC_ROUTES.PREVIEW}`,
        component: WebPreview,
    },
    {
        path: `${serverSideConfig.basePath}/${PUBLIC_ROUTES.PUBLISHED}`,
        component: WebPublished,
    },
];

export const privateRoutes = [
    {
        path: `/`,
        exact: true,
        component: BuilderRouter,
    },
    {
        path: `/website`,
        exact: true,
        component: DynamicRouter,
    },
    {
        path: `${serverSideConfig.basePath}/`,
        exact: true,
        component: DynamicRouter,
    },
    {
        path: `${serverSideConfig.basePath}/website`,
        exact: true,
        component: DynamicRouter,
    },
    {
        path: `${serverSideConfig.basePath}/builder`,
        exact: true,
        component: WebBuilder,
    },
];
