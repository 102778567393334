import React from 'react';

function AboutSection({ theme, profile }) {
    return (
        <div id='about-section' className='about-section'>
            <div className='about-section-wrapper'>
                <div className='about-section-photo-wrapper'>
                    <img
                        src={profile.pictureurl}
                        style={{ height: '100vh', objectFit: 'contain' }}
                        alt
                        className='about-section-image'
                    />
                </div>
                <div className='section-header-wrapper'>
                    <h1
                        className='section-header'
                        style={{ color: theme.textColor }}
                    >
                        About The Band
                    </h1>
                    <p style={{ color: theme.textColor, textAlign: 'center' }}>
                        {profile.bio}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
