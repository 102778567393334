import React from 'react'
import isEmpty from 'lodash.isempty';

function MusicSection({ music }) {
  return (
    <div id="music-section" className="music-section">
      {!isEmpty(music) && (
        <div className="music-section-wrapper">
          <div className="music-header-wrapper">
            <h1 className="section-header">Music</h1>
          </div>
          <div className="music-collection">
            <div className="music-item-wrapper">
              <div className="music-item-image">
                <img
                  src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296acaffda231a_Cal%20Scruby%20WWWS%20Album.jpg"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 89vw, (max-width: 991px) 39vw, 49vw"
                  srcSet="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296acaffda231a_Cal%2520Scruby%2520WWWS%2520Album-p-500.jpeg 500w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296acaffda231a_Cal%2520Scruby%2520WWWS%2520Album-p-800.jpeg 800w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296acaffda231a_Cal%2520Scruby%2520WWWS%2520Album-p-1080.jpeg 1080w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296acaffda231a_Cal%20Scruby%20WWWS%20Album.jpg 1200w"
                  alt
                  className="music-image"
                />
              </div>
              <div className="music-item-title">
                <h5 className="music-title-header">While You Were Sleeping</h5>
              </div>
              <div className="music-item-buttons">
                <div className="streaming-button-div">
                  <a href="#" className="streaming-button w-button">
                    Stream Music{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="music-item-wrapper">
              <div className="music-item-image">
                <img
                  src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296a7c58da2324_Cal%20Scruby%20Unsigned%20Album.jpg"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 89vw, (max-width: 991px) 39vw, 49vw"
                  srcSet="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296a7c58da2324_Cal%2520Scruby%2520Unsigned%2520Album-p-500.jpeg 500w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296a7c58da2324_Cal%2520Scruby%2520Unsigned%2520Album-p-800.jpeg 800w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296a7c58da2324_Cal%2520Scruby%2520Unsigned%2520Album-p-1080.jpeg 1080w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296a7c58da2324_Cal%20Scruby%20Unsigned%20Album.jpg 1200w"
                  alt
                  className="music-image"
                />
              </div>
              <div className="music-item-title">
                <h5 className="music-title-header">Unsigned</h5>
              </div>
              <div className="music-item-buttons">
                <div className="streaming-button-div">
                  <a href="#" className="streaming-button w-button">
                    Stream Music{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="music-item-wrapper">
              <div className="music-item-image">
                <img
                  src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296ac553da231f_Cal%20Scruby%20HITH%20Album.jpg"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 89vw, (max-width: 991px) 39vw, 49vw"
                  srcSet="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296ac553da231f_Cal%2520Scruby%2520HITH%2520Album-p-500.jpeg 500w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296ac553da231f_Cal%2520Scruby%2520HITH%2520Album-p-800.jpeg 800w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296ac553da231f_Cal%2520Scruby%2520HITH%2520Album-p-1080.jpeg 1080w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296ac553da231f_Cal%20Scruby%20HITH%20Album.jpg 1200w"
                  alt
                  className="music-image"
                />
              </div>
              <div className="music-item-title">
                <h5 className="music-title-header">House in the Hills</h5>
              </div>
              <div className="music-item-buttons">
                <div className="streaming-button-div">
                  <a href="#" className="streaming-button w-button">
                    Stream Music{" "}
                  </a>
                </div>
              </div>
            </div>
            <div className="music-item-wrapper">
              <div className="music-item-image">
                <img
                  src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296ac553da231f_Cal%20Scruby%20HITH%20Album.jpg"
                  sizes="(max-width: 479px) 100vw, (max-width: 767px) 89vw, (max-width: 991px) 39vw, 49vw"
                  srcSet="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296ac553da231f_Cal%2520Scruby%2520HITH%2520Album-p-500.jpeg 500w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296ac553da231f_Cal%2520Scruby%2520HITH%2520Album-p-800.jpeg 800w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296ac553da231f_Cal%2520Scruby%2520HITH%2520Album-p-1080.jpeg 1080w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0bc501c296ac553da231f_Cal%20Scruby%20HITH%20Album.jpg 1200w"
                  alt
                  className="music-image"
                />
              </div>
              <div className="music-item-title">
                <h5 className="music-title-header">House in the Hills</h5>
              </div>
              <div className="music-item-buttons">
                <div className="streaming-button-div">
                  <a href="#" className="streaming-button w-button">
                    Stream Music{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default MusicSection
