import React from 'react';

function AboutSection({ profile }) {
    return (
        <div id='about-section' className='about-section'>
            <div className='about-section-wrapper'>
                <div className='section-header'>
                    <h1 className='section-header'>About</h1>
                </div>
                <div className='about-image'>
                    {profile && profile.pictureurl && (
                        <img
                            className='image-2'
                            src={profile.pictureurl}
                            style={{
                                objectFit: 'contain',
                                maxWidth:
                                    '(max-width: 479px) 92vw, (max-width: 767px) 90vw, (max-width: 991px) 80vw, 43vw',
                            }}
                        />
                    )}
                </div>
                <div className='about-text'>
                    <p className='paragraph'>
                        <strong className='bold-text'>{profile.bio}</strong>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
