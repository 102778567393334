import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import Typography from '@mui/material/Typography';
import GridLayout from '../../shared/layouts/grid/GridLayout';
import MasonryLayout from '../../shared/layouts/masonry/MasonryLayout';
import SliderLayout from '../../shared/layouts/slider/SliderLayout';
import { cardList } from '../../shared/cards/cardList';
import { layoutList } from '../../shared/layouts';
import { getMonthDayYearFormat } from '../../utils/dates';
import MasonryImg from '../../builder/images/img_masonry.png';
import GridImg from '../../builder/images/img_grid.png';
import sliderImg from '../../builder/images/img_slide.png';
import cardOption1 from '../../builder/images/card_option/img_option1.png';
import cardOption2 from '../../builder/images/card_option/img_option2.png';
import cardOption3 from '../../builder/images/card_option/img_option3.png';
import cardOption4 from '../../builder/images/card_option/img_option4.png';
import cardOption5 from '../../builder/images/card_option/img_option5.png';
import cardOption6 from '../../builder/images/card_option/img_option6.png';
import cardOption7 from '../../builder/images/card_option/img_option7.png';
import cardOption8 from '../../builder/images/card_option/img_option8.png';
import cardOption9 from '../../builder/images/card_option/img_option9.png';
import cardOption10 from '../../builder/images/card_option/img_option10.png';

const MusicSection = (props) => {
    const {
        sectionTextColor,
        sectionBackgroundColor,
        layoutType,
        layout: layoutProps,
        cardType,
        card: cardProps,
        music,
        sectionTitle,
        profile,
    } = props;

    const getLayoutComponet = (layoutKey) => {
        if (isEmpty(layoutKey)) {
            return undefined;
        }

        switch (layoutKey.toLowerCase()) {
            case 'grid':
                return GridLayout;
            case 'masonry':
                return MasonryLayout;
            case 'slider':
                return SliderLayout;
        }
    };

    const getRenderItem = () => {
        if (isEmpty(cardType)) {
            return undefined;
        }

        const card = Object.values(cardList).find((c) => c.title === cardType);
        if (card === undefined || card.component === undefined) {
            return () => <></>;
        }

        const CardComponent = card.component;
        const renderItem = (item, idx) => {
            const { title, thumbnail, releaseDate, url } = item;
            const { name, logo } = profile;
            const dateFormatted = getMonthDayYearFormat(releaseDate);
            return (
                <CardComponent
                    key={idx || item.id}
                    title={title}
                    subtitle={name}
                    note1={dateFormatted}
                    media={thumbnail}
                    avatar={logo}
                    url={url}
                    {...cardProps}
                />
            );
        };
        return renderItem;
    };

    const Layout = getLayoutComponet(layoutType);
    if (Layout === undefined) {
        return <></>;
    }

    const renderItem = getRenderItem(cardType, cardProps);
    if (renderItem === undefined) {
        return <></>;
    }

    return (
        <div
            id='music'
            style={{ width: '100%', backgroundColor: sectionBackgroundColor }}
        >
            {sectionTitle && (
                <div
                    style={{
                        width: '100%',
                        textAlign: 'center',
                        paddingTop: '20px',
                        paddingBottom: '20px',
                    }}
                >
                    <Typography
                        variant='button'
                        sx={{
                            fontSize: '35px',
                            fontWeight: 'bold',
                            color: sectionTextColor,
                            textTransform: 'initial',
                        }}
                    >
                        {sectionTitle}
                    </Typography>
                </div>
            )}
            <Layout items={music} renderItem={renderItem} {...layoutProps} />
        </div>
    );
};

const detailedEditorDefinition = {
    sectionTitle: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'text',
        name: 'sectionTitle',
        label: 'Section Title',
        isRequired: false,
        defaultValue: undefined,
    },
    sectionTextColor: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'sectionTextColor',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#000000',
    },
    sectionBackgroundColor: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'sectionBackgroundColor',
        label: 'Background Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
    layoutType: {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'visualDropdown',
        inputOptions: Object.values(layoutList).map((l) => l.title),
        name: 'layoutType',
        label: 'Select Layout',
        description:
            'The Select Layout determines how your informational cards are presented to the user on your website.',
        isRequired: true,
        defaultValue: 'Select One',
        inputImages: { Masonry: MasonryImg, Grid: GridImg, Slider: sliderImg },
    },
    cardType: {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'visualDropdown',
        inputOptions: Object.values(cardList).map((c) => c.title),
        name: 'cardType',
        label: 'Select Design',
        description:
            'A Select Design determines how information will be displayed to the user.  Each card has a slightly different variation that you might enjoy.',
        isRequired: true,
        defaultValue: 'Select One',
        inputImages: {
            CardOption1: cardOption1,
            CardOption2: cardOption2,
            CardOption3: cardOption3,
            CardOption4: cardOption4,
            CardOption5: cardOption5,
            CardOption6: cardOption6,
            CardOption7: cardOption7,
            CardOption8: cardOption8,
            CardOption9: cardOption9,
            CardOption10: cardOption10,
        },
    },
    displayOnNavigation: {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'displayOnNavigation',
        label: 'Display On Navigation',
        isRequired: true,
        defaultValue: true,
    },
};

MusicSection.propTypes = {
    sectionTitle: detailedEditorDefinition.sectionTitle.propType,
    sectionTextColor: detailedEditorDefinition.sectionTextColor.propType,
    sectionBackgroundColor: detailedEditorDefinition.sectionBackgroundColor.propType,
    layoutType: detailedEditorDefinition.layoutType.propType,
    cardType: detailedEditorDefinition.cardType.propType,
    displayOnNavigation: detailedEditorDefinition.displayOnNavigation.propType,
};

MusicSection.defaultProps = {
    sectionTextColor: detailedEditorDefinition.sectionTextColor.defaultValue,
    sectionBackgroundColor: detailedEditorDefinition.sectionBackgroundColor.defaultValue,
};

export { MusicSection as default, detailedEditorDefinition };
