import React from 'react';
import VideosGrid from './VideosGrid';
function StaticVideos(props) {
    const renderVideos = () => {
        switch (props.layout) {
            case 'grid':
                return <VideosGrid {...props} />;
            default:
                return <VideosGrid {...props} />;
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: 10,
            }}
        >
            <div className={props.outerClass && props.outerClass}>
                {renderVideos()}
            </div>
        </div>
    );
}

export default StaticVideos;
