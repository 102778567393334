import React from 'react';
import { Paper } from '@mui/material';
import { Helmet } from 'react-helmet';
import _get from 'lodash.get';
import _find from 'lodash.find';
import TemplateOrchestrator from './builder/TemplateOrchestrator';
import CompProvider from './context/CompContext';
import creatorApi from './api/creatorApi';
import allSettled from 'promise.allsettled';
import { getAnalyticsUrl } from './utils/network';

allSettled.shim();

const getDataResults = (data, idx) => {
    return data[idx] && data[idx].status === 'fulfilled' ? data[idx].value : {};
};

const WebPublished = (props) => {
    console.log('WebPublished');
    const googleAnalyticsCode = _get(
        _find(
            _get(props, "integrations.integrated", null)
            , { 'network': 'GOOGLE_ANALYTICS' }
        ),
        "data.url", null
    );
    const facebookPixelCode = _get(
        _find(
            _get(props, "integrations.integrated", null)
            , { 'network': 'FACEBOOK_PIXEL' }
        ),
        "data.url", null
    );
    return (
        <CompProvider entityId={props.entityId} defaultConfigType='PUBLISHED' isBuilder={false}>
            <Helmet>
                <title>{props.profile && props.profile.name}</title>
                <meta content='width=device-width, initial-scale=1' name='viewport' />
                <meta content={props.profile && props.profile.bio} name='description' />
                <meta property='og:image' content={props.profile && props.profile.pictureurl} />
                <meta property='og:type' content='artist-website' />
                <meta property='og:title' content={props.profile && props.profile.name} />
                <meta property='og:description' content={props.profile && props.profile.bio} />
                <meta name='twitter:card' content='summary_large_image' />
                <meta name='twitter:title' content={props.profile && props.profile.name} />
                <meta name='twitter:description' content={props.profile && props.profile.bio} />
                <meta name='twitter:image' content={props.profile && props.profile.pictureurl} />
                <link
                    href={props.profile && (props.profile.pictureurl || props.profile.logo)}
                    rel="icon"
                    type="image/*"
                />
                <link
                    href={props.profile && (props.profile.pictureurl || props.profile.logo)}
                    rel="shortcut icon"
                    type="image/*"
                />
                <link
                    href={props.profile && (props.profile.pictureurl || props.profile.logo)}
                    rel="apple-touch-icon"
                />
            </Helmet>
            {/*  Facebook Pixel */}
            {facebookPixelCode && (
                <Helmet>
                    <script id="Facebook Pixel">
                    {`!function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    '${getAnalyticsUrl('FACEBOOK_PIXEL')}');
                    fbq('init', '${facebookPixelCode}');
                    fbq('track', 'PageView');`}
                    </script>
                    {`<noscript>
                        <img height="1" width="1" style="display:none"
                    src='https://www.facebook.com/tr?id=${facebookPixelCode}&ev=PageView&noscript=1'
                    /></noscript>`}
                </Helmet>
            )}
            {/*  Google Analytics */}
            {googleAnalyticsCode && (
                <Helmet>
                    <script async src={`${getAnalyticsUrl('GOOGLE_ANALYTICS')}${googleAnalyticsCode}`} />
                    <script id="google analytics">
                        {`window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-${googleAnalyticsCode}');`}
                    </script>
                </Helmet>
            )}
            <Paper>
                <TemplateOrchestrator {...props} />
            </Paper>
        </CompProvider>
    );
};

WebPublished.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {
    const entityId = (req && req.headers['jf-entity-id']) || req.query.id;
    console.log('WebPublished: getInitialProps');
    console.log('WebPublished:req.headers', req.headers);

    const dataResults = await Promise.allSettled([
        creatorApi.getProfile(entityId),
        creatorApi.getVideos(entityId, 'ARTIST'),
        creatorApi.getMusic(entityId, 'ARTIST'),
        creatorApi.getNews(entityId, 'ARTIST'),
        creatorApi.getIntegrations(entityId),
        creatorApi.getImages(entityId),
        creatorApi.getEvents(entityId, 'ARTIST'),
    ]);

    const props = {
        entityId,
        profile: getDataResults(dataResults, 0),
        videos: getDataResults(dataResults, 1),
        music: getDataResults(dataResults, 2),
        news: getDataResults(dataResults, 3),
        integrations: getDataResults(dataResults, 4),
        images: getDataResults(dataResults, 5),
        events: getDataResults(dataResults, 6),
    };

    return props;
};

export default WebPublished;
