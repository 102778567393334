import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import isEmpty from 'lodash.isempty';
import MenuIcon from '@mui/icons-material/Menu';
import { getNetwork } from '../../../../utils/network';

function Navbar({ profile, integrations, news, videos, music }) {
    const [isOpen, setOpen] = useState(false);
    const shopifyNetwork = getNetwork(integrations.integrated, 'SHOPIFY');
    const patreonNetwork = getNetwork(integrations.integrated, 'PATREON');

    return (
        <div
            data-collapse='medium'
            data-animation='over-right'
            data-duration={400}
            data-doc-height={1}
            role='banner'
            className='navbar-3 w-nav'
        >
            <div className='navbar-3'>
                <div className='container w-container nav-bar-container'>
                    <div className='nav-bar-wrapper'>
                        {profile.logo && (
                            <a href='#'>
                                <img src={profile.logo} alt className='logo-image' />
                            </a>
                        )}
                        <nav role='navigation' className='nav-menu w-nav-menu'>
                            <a
                                onClick={() => {
                                    window.document.getElementById('home-section').scrollIntoView();
                                }}
                                className='menu-nav-link w-nav-link'
                            >
                                Home
                            </a>
                            <a
                                onClick={() => {
                                    window.document.getElementById('tour-section').scrollIntoView();
                                }}
                                className='menu-nav-link w-nav-link'
                            >
                                Tour
                            </a>
                            {!isEmpty(music) && (
                                <a
                                    onClick={() => {
                                        window.document.getElementById('music-section').scrollIntoView();
                                    }}
                                    className='menu-nav-link w-nav-link'
                                >
                                    Music
                                </a>
                            )}
                            {profile.logo && (
                                <a onClick={() => {}} className='menu-logo-link w-nav-brand'>
                                    <img src={profile.logo} width={64} alt />
                                </a>
                            )}
                            {!isEmpty(videos) && (
                                <a
                                    onClick={() => {
                                        window.document.getElementById('videos-section').scrollIntoView();
                                    }}
                                    className='menu-nav-link w-nav-link'
                                >
                                    Videos
                                </a>
                            )}
                            {!isEmpty(news) && (
                                <a
                                    onClick={() => {
                                        window.document.getElementById('news-section').scrollIntoView();
                                    }}
                                    className='menu-nav-link w-nav-link'
                                >
                                    News
                                </a>
                            )}
                            <a
                                onClick={() => {
                                    window.document.getElementById('about-section').scrollIntoView();
                                }}
                                className='menu-nav-link w-nav-link'
                            >
                                About
                            </a>
                            {!isEmpty(profile.publicContacts) && (
                                <a
                                    onClick={() => {
                                        window.document.getElementById('footer').scrollIntoView();
                                    }}
                                    className='menu-nav-link w-nav-link'
                                >
                                    Contact
                                </a>
                            )}
                            {!isEmpty(shopifyNetwork) && shopifyNetwork.data.url && (
                                <a href={shopifyNetwork.data.url} target='_blank' className='menu-nav-link w-nav-link'>
                                    Merch
                                </a>
                            )}
                            {!isEmpty(patreonNetwork) && patreonNetwork.data.url && (
                                <a href={patreonNetwork.data.url} target='_blank' className='nav-link-3 w-nav-link'>
                                    Subscribe
                                </a>
                            )}
                        </nav>
                    </div>
                </div>
                <div onClick={() => setOpen(!isOpen)} className='w-nav-button'>
                    <MenuIcon style={{ fontSize: 30, color: '#ffffff' }} />
                </div>
            </div>
            <Menu
                right
                disableAutoFocus
                customBurgerIcon={false}
                customCrossIcon={false}
                styles={{
                    bmMenuWrap: {
                        position: 'fixed',
                        height: '50%',
                    },
                    bmMenu: {
                        background: '#141414',
                        fontSize: '16',
                    },
                    bmItemList: {
                        color: '#141414',
                        padding: '0.8em',
                    },
                    bmOverlay: {
                        background: 'rgba(0, 0, 0, 0.3)',
                    },
                    bmItem: {
                        padding: 0,
                        margin: 0,
                    },
                }}
                width={200}
                isOpen={isOpen}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <a
                        onClick={() => {
                            window.document.getElementById('home-section-bgvideo').scrollIntoView();
                            setOpen(false);
                        }}
                        className='mobile-nav-link'
                    >
                        Home
                    </a>
                    <a
                        onClick={() => {
                            window.document.getElementById('tour-section').scrollIntoView();
                            setOpen(false);
                        }}
                        className='mobile-nav-link'
                    >
                        Tour
                    </a>
                    {!isEmpty(music) && (
                        <a
                            onClick={() => {
                                window.document.getElementById('music-section').scrollIntoView();
                                setOpen(false);
                            }}
                            className='mobile-nav-link'
                        >
                            Music
                        </a>
                    )}
                    {!isEmpty(videos) && (
                        <a
                            onClick={() => {
                                window.document.getElementById('videos-section').scrollIntoView();
                                setOpen(false);
                            }}
                            className='mobile-nav-link'
                        >
                            Videos
                        </a>
                    )}
                    {!isEmpty(news) && (
                        <a
                            onClick={() => {
                                window.document.getElementById('news-section').scrollIntoView();
                                setOpen(false);
                            }}
                            className='mobile-nav-link'
                        >
                            News
                        </a>
                    )}
                    <a
                        onClick={() => {
                            window.document.getElementById('about-section').scrollIntoView();
                            setOpen(false);
                        }}
                        className='mobile-nav-link'
                    >
                        About
                    </a>
                    {!isEmpty(profile.publicContacts) && (
                        <a
                            onClick={() => {
                                window.document.getElementById('footer').scrollIntoView();
                                setOpen(false);
                            }}
                            className='mobile-nav-link'
                        >
                            Contact
                        </a>
                    )}
                    {!isEmpty(shopifyNetwork) && shopifyNetwork.data.url && (
                        <a
                            onClick={() => setOpen(false)}
                            href={shopifyNetwork.data.url}
                            target='_blank'
                            className='mobile-nav-link'
                        >
                            Merch
                        </a>
                    )}
                    {!isEmpty(patreonNetwork) && patreonNetwork.data.url && (
                        <a href={patreonNetwork.data.url} target='_blank' className='nav-link-3 w-nav-link'>
                            Subscribe
                        </a>
                    )}
                </div>
            </Menu>
        </div>
    );
}

export default Navbar;
