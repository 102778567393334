import isEmpty from 'lodash.isempty';
import React from 'react';
import { Helmet } from 'react-helmet';
import jamfeedClient from './api/jamfeedClient';
import Template1 from './templates/artist/template1';
import Template2 from './templates/artist/template2';
import Template3 from './templates/artist/template3';
import Template4 from './templates/artist/template4';
import Template5 from './templates/artist/template5';
import Template6 from './templates/artist/template6';
import Template7 from './templates/artist/template7';
import Template9 from './templates/artist/template9';

const DynamicRouter = (props) => {
    console.log('DynamicRouter');
    let DisplayTemplate = Template1;
    switch (props.templateId) {
        case 'at1':
            DisplayTemplate = Template1;
            break;
        case 'at2':
            DisplayTemplate = Template2;
            break;
        case 'at3':
            DisplayTemplate = Template3;
            break;
        case 'at4':
            DisplayTemplate = Template4;
            break;
        case 'at5':
            DisplayTemplate = Template5;
            break;
        case 'at6':
            DisplayTemplate = Template6;
            break;
        case 'at7':
            DisplayTemplate = Template7;
            break;
        case 'at9':
            DisplayTemplate = Template9;
            break;
        default:
    }

    const { profile } = props;
    return (
        <>
            <Helmet>
                <title>{profile.name}</title>
                <meta content='width=device-width, initial-scale=1' name='viewport' />
                <meta content={profile.bio} name='description' />
                <meta property='og:image' content={profile.pictureurl} />
                <meta property='og:type' content='artist-website' />
                <meta property='og:title' content={profile.name} />
                <meta property='og:description' content={profile.bio} />
                <meta name='twitter:card' content='summary_large_image' />
                <meta name='twitter:title' content={profile.name} />
                <meta name='twitter:description' content={profile.bio} />
                <meta name='twitter:image' content={profile.pictureurl} />
                {/*[if lt IE 9]><![endif]*/}
                <link href={profile.logo || profile.pictureurl} rel='icon' type='image/*' />
                <link href={profile.logo || profile.pictureurl} rel='shortcut icon' type='image/*' />
                <link href={profile.logo || profile.pictureurl} rel='apple-touch-icon' />
            </Helmet>
            <DisplayTemplate {...props} />
        </>
    );
};

DynamicRouter.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {
    const templateId = req && req.query.tid;
    const entityId = (req && req.headers['jf-entity-id']) || req.query.entityId;

    const siteConfig = await jamfeedClient
        .get(`/site/config`, {
            params: {
                id: entityId,
                type: 'ARTIST',
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            console.error(error + ' error');
        });

    const profile = await jamfeedClient
        .get('/artist', {
            params: {
                aid: entityId,
            },
        })
        .then((response) => {
            let { artist } = response.data;
            const publicContacts = isEmpty(artist.contacts) ? [] : artist.contacts.filter((c) => c.publiclyVisible);
            artist.publicContacts = publicContacts;
            delete artist.contacts;
            return artist;
        })
        .catch((error) => {
            console.log('error getting artist profile', error);
            return {
                artist: {},
            };
        });

    const { results: videos } = await jamfeedClient
        .get('/content/videos', {
            params: {
                id: entityId,
                type: 'ARTIST',
            },
        })
        .then((response) => response.data)
        .catch((error) => {
            console.log('error getting artist videos', error);
            return {
                results: [],
            };
        });

    const { results: music } = await jamfeedClient
        .get('/content/music', {
            params: {
                id: entityId,
                type: 'ARTIST',
            },
        })
        .then((response) => response.data)
        .catch((error) => {
            console.log('error getting artist music', error);
            return {
                results: [],
            };
        });

    const { results: news } = await jamfeedClient
        .get('/news/creator', {
            params: {
                id: entityId,
                type: 'ARTIST',
            },
        })
        .then((response) => response.data)
        .catch((error) => console.log('error getting artist news', error));

    const integrations = await jamfeedClient
        .get('/artist/integrations', {
            params: {
                artistId: entityId,
            },
        })
        .then((response) => response.data)
        .catch((error) => {
            console.log('error getting artist integrations', error);
            return {
                integrated: [],
            };
        });

    return {
        templateId: templateId || siteConfig.templateId || 'at1',
        profile,
        videos,
        music,
        news,
        integrations,
    };
};

export default DynamicRouter;
