import React from 'react';

function AboutSection() {
    return (
        <div id='about-section' className='about-section'>
            <div className='section-header'>
                <h1 className='section-header'>About</h1>
            </div>
            <div className='about-section-wrapper'>
                <div className='about-text'>
                    <p className='paragraph'>
                        <strong className='bold-text'>
                            instagram / twitter: @calscruby cal scruby is 6’8” 113 lbs. the guy spends most of his time
                            completely overwhelmed by an existential / identity crisis that results in exponential
                            anxiety. good at rapping, not good at anything else. shit his pants on his last birthday
                            (age 41). expect big things from cal scruby in 2004.
                        </strong>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
