import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ShowMoreLess from './ShowMoreLess';

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: 'black',
        paddingTop: '40px',
        paddingBottom: '40px',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        width: '100%',
    },
    sectionHeader: {
        color: '#FFF',
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        fontSize: '35px',
        fontWeight: 'bold',
        // textTransform: 'uppercase',
    },
    sectionImage: {
        padding: '15px',
    },
    image: {
        width: '550px',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '500px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        alignItems: 'center',
        objectPosition: 'center',
        border: 'none',
    },
    aboutText: {
        width: '70vw',
        margin: '25px',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        maxWidth: '550px',
        textOverflow: 'ellipsis',
    },
    paragraph: {
        color: '#e0e0e0',
        marginTop: '0',
        marginBottom: '10px',
        textAlign: 'left',
        lineHeight: '28px',
        flex: '1',
        textOverflow: 'ellipsis',
        letterSpacing: '0.8px',
        fontSize: '16px',
        fontWeight: 'lighter',
    },
}));

const Template2 = ({ color, backgroundColor, headerText, bodyText, imageUrl }) => {
    const classes = useStyles();
    return (
        <div id='about' className={classes.container} style={{ backgroundColor: `${backgroundColor}` }}>
            <Typography variant='h1' className={classes.sectionHeader} style={{ color: `${color}` }}>
                {headerText}
            </Typography>
            <div className={classes.sectionImage}>
                <img className={classes.image} src={imageUrl} alt='' />
            </div>
            <div className={classes.aboutText}>
                <Typography className={classes.paragraph} style={{ color: `${color}` }}>
                    <ShowMoreLess bodyText={bodyText} />
                </Typography>
            </div>
        </div>
    );
};

export default Template2;

Template2.propTypes = {
    headerText: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
};
