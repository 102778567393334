import React, { useContext, useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import makeStyles from '@mui/styles/makeStyles';
import { Container, Box } from '@mui/material';
import SideBar from '../../../builder/sideBar/SideBar';
import Header from '../header/index';
import { darkModeContext } from '../../../context/themeHandler';
import { CreatorContext } from '../../../context/creatorContext';

export const HEADER_HEIGHT = '4.5rem';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#000000',
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
        maxWidth: '100vw',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    flexContainer: {
        display: 'flex',
        height: `calc(100vh - ${HEADER_HEIGHT})`,
        marginTop: HEADER_HEIGHT,
    },
    content: {
        flexGrow: 1,
        overflow: 'hidden',
        overflowY: 'auto',
        zIndex: 1,
    },
}));

const useSubscriptionState = () => {
    const creatorContext = useContext(CreatorContext);
    const [isSubscriptionBannerDisplayed, setIsSubscriptionBannerDisplayed] =
        useState(false);

    useEffect(() => {
        if (!creatorContext.isLoading) {
            const checkSubscription =
                !creatorContext.hasSubscription() ||
                creatorContext.hasDelinquentSubscription() ||
                creatorContext.hasCanceledSubscription();
            setIsSubscriptionBannerDisplayed(checkSubscription);
        }
    }, [creatorContext.isLoading]);

    return isSubscriptionBannerDisplayed;
};

const PageContainer = ({
    openOptions,
    setOptionsOpenValue,
    children,
    ...props
}) => {
    const { darkMode, setDarkMode } = useContext(darkModeContext);
    const isSubscriptionBannerDisplayed = useSubscriptionState();
    const classes = useStyles({ isSubscriptionBannerDisplayed });
    const creatorContext = useContext(CreatorContext);

    useEffect(() => {
        const themePreference =
            localStorage.getItem('preferred-theme') || 'light';
        setDarkMode(themePreference === 'dark');
    }, []);

    if (creatorContext.isLoading) return <div>Loading...</div>;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Header headerHeight={HEADER_HEIGHT} />
            <div className={classes.flexContainer}>
                <SideBar
                    openOptions={openOptions}
                    setOptionsOpenValue={setOptionsOpenValue}
                    {...props}
                />
                <Box
                    id='mainContainer'
                    sx={{
                        marginLeft: {
                            xs: '0px',
                            sm: '0px',
                            md: openOptions ? '0px' : '133px',
                            lg: openOptions ? '0px' : '133px',
                        },
                    }}
                    className={classes.content}
                >
                    <Container
                        maxWidth={false}
                        style={{ padding: 0, margin: 0 }}
                    >
                        {children}
                    </Container>
                </Box>
            </div>
        </div>
    );
};

export default PageContainer;
