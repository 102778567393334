/**
 * Levenshtein distance algorithm to calculate the difference between two strings
 * @param {*} str1 string 1
 * @param {*} str2 string 2
 * @returns Number difference in characters
 */
const levenshteinDistance = (str1, str2) => {
  if ((!str1 || !str2, str1.length === 0 || str2.length === 0)) {
    return undefined
  }

  const track = Array(str2.length + 1)
    .fill(null)
    .map(() => Array(str1.length + 1).fill(null))
  for (let i = 0; i <= str1.length; i += 1) {
    track[0][i] = i
  }
  for (let j = 0; j <= str2.length; j += 1) {
    track[j][0] = j
  }
  for (let j = 1; j <= str2.length; j += 1) {
    for (let i = 1; i <= str1.length; i += 1) {
      const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1
      track[j][i] = Math.min(
        track[j][i - 1] + 1, // deletion
        track[j - 1][i] + 1, // insertion
        track[j - 1][i - 1] + indicator // substitution
      )
    }
  }
  return track[str2.length][str1.length]
}

/**
 * Returns true or false if it is a valid difference by the approved distance/difference
 * @param {*} str1 string 1
 * @param {*} str2 string 2
 * @param {*} approvedDistance default 3
 * @returns Boolean
 */
const isSimilar = (str1, str2, approvedDistance = 3) => {
  const distance = levenshteinDistance(str1, str2)
  if (distance === undefined) {
    return false
  }

  if (distance <= approvedDistance) {
    return true
  }
  return false
}

const levenshtein = {
  distance:levenshteinDistance,
  isSimilar
}

module.exports = {
  levenshtein
}
