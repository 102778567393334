import React from 'react';
import ResponsiveLink from '../../../shared/ResponsiveLink';

function Footer({ profile }) {
    return (
        <footer id='footer' className='footer'>
            <div className='w-container'>
                <div className='footer-flex-container'>
                    <a href='#' className='footer-logo-link w-inline-block'>
                        <img
                            src={profile.logo}
                            width={94}
                            alt
                            className='footer-image'
                        />
                    </a>
                    {profile.publicContacts.map((c) => {
                        return (
                            <div className='footer-contact-wrapper'>
                                <h2 className='footer-heading'>{c.title}</h2>
                                <ul
                                    role='list'
                                    className='list w-list-unstyled'
                                >
                                    <li className='list-item'>
                                        <ResponsiveLink
                                            className='footer-link'
                                            linkText={c.email}
                                        />
                                    </li>
                                </ul>
                            </div>
                        );
                    })}
                </div>
                {profile && profile.name && (
                    <div
                        style={{
                            width: '100%',
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        className='text-block'
                    >
                        Copyright © {(new Date().getFullYear())} {profile.name}
                        
                    </div>
                )}
            </div>
        </footer>
    );
}

export default Footer;
