import _get from 'lodash.get';
import { musicDefaultSubtitle } from './constants';
// Constants for keys
const KEYS_SOURCE = {
    TITLE: 'title',
    NETWORK: 'network',
    THUMBNAIL: 'thumbnail',
    MEDIA: 'media',
    URL: 'url',
    DESCRIPTION: 'description',
    SOURCE: 'source',
    OFFERS: 'offers',
    VENUE_NAME: 'venue.name',
    VENUE_LOCATION: 'venue.location',
    PICTUREURL: 'pictureurl',
    COVERVIDEOURL: 'coverVideoUrl',
    NAME: 'name',
};

/**
 * Generate default title based on source type.
 * @param {string} sourceType - The type of the source
 * @returns {string} The default title.
 */
const generateDefaultTitle = (sourceType) => {
    return `No automated ${sourceType} content, please use manual overrides.`;
};

/**
 * Get source information based on the type of source.
 * @returns {Object} An object containing source information.
 */
const getSourceInformation = ({
    music,
    videos,
    images,
    news,
    events,
    profile,
    useSource,
}) => {
    const sourceMap = {
        music: {
            source: music,
            defaultTitle: generateDefaultTitle('music'),
            buttonText: 'Listen Now',
            keys: [
                KEYS_SOURCE.TITLE,
                KEYS_SOURCE.NETWORK,
                KEYS_SOURCE.THUMBNAIL,
                KEYS_SOURCE.URL,
            ],
        },
        'New Music': {
            source: music,
            defaultTitle: generateDefaultTitle('music'),
            buttonText: 'Listen Now',
            keys: [
                KEYS_SOURCE.TITLE,
                KEYS_SOURCE.NETWORK,
                KEYS_SOURCE.THUMBNAIL,
                KEYS_SOURCE.URL,
            ],
        },
        videos: {
            source: videos,
            defaultTitle: generateDefaultTitle('video'),
            buttonText: 'Watch Now',
            keys: [
                KEYS_SOURCE.TITLE,
                KEYS_SOURCE.DESCRIPTION,
                KEYS_SOURCE.THUMBNAIL,
                KEYS_SOURCE.URL,
            ],
        },
        'New Video': {
            source: videos,
            defaultTitle: generateDefaultTitle('video'),
            buttonText: 'Watch Now',
            keys: [
                KEYS_SOURCE.TITLE,
                KEYS_SOURCE.DESCRIPTION,
                KEYS_SOURCE.THUMBNAIL,
                KEYS_SOURCE.URL,
            ],
        },
        photos: {
            source: images,
            defaultTitle: generateDefaultTitle('photo'),
            buttonText: undefined,
            keys: [KEYS_SOURCE.TITLE, undefined, KEYS_SOURCE.URL],
        },
        news: {
            source: news,
            defaultTitle: generateDefaultTitle('news'),
            buttonText: 'Read Now',
            keys: [
                KEYS_SOURCE.TITLE,
                KEYS_SOURCE.SOURCE,
                KEYS_SOURCE.MEDIA,
                KEYS_SOURCE.URL,
            ],
        },
        'New Press': {
            source: news,
            defaultTitle: generateDefaultTitle('news'),
            buttonText: 'Read Now',
            keys: [
                KEYS_SOURCE.TITLE,
                KEYS_SOURCE.SOURCE,
                KEYS_SOURCE.MEDIA,
                KEYS_SOURCE.URL,
            ],
        },
        tour: {
            source: events,
            defaultTitle: generateDefaultTitle('tour'),
            buttonText: 'Get Tickets',
            keys: [
                KEYS_SOURCE.VENUE_NAME,
                KEYS_SOURCE.VENUE_LOCATION,
                undefined,
                KEYS_SOURCE.OFFERS,
            ],
        },
        'Cover Photo': {
            source: profile,
            defaultTitle: profile.name,
            buttonText: undefined,
            keys: [KEYS_SOURCE.NAME, undefined, KEYS_SOURCE.PICTUREURL],
        },
        'Cover Video': {
            source: profile,
            defaultTitle: profile.name,
            buttonText: undefined,
            keys: [KEYS_SOURCE.NAME, undefined, KEYS_SOURCE.COVERVIDEOURL],
        },
        'profile and photo': {
            source: profile,
            defaultTitle: profile.name,
            buttonText: undefined,
            keys: [KEYS_SOURCE.NAME, undefined, KEYS_SOURCE.PICTUREURL],
        },
        'profile and cover video': {
            source: profile,
            defaultTitle: profile.name,
            buttonText: undefined,
            keys: [KEYS_SOURCE.NAME, undefined, KEYS_SOURCE.COVERVIDEOURL],
        },
    };

    const defaultReturn = {
        title: '',
        subtitle: undefined,
        media: undefined,
        buttonText: undefined,
        buttonLocation: undefined,
    };

    const config = sourceMap[useSource];
    if (!config) return defaultReturn;

    let item;
    if (
        useSource === 'Cover Photo' ||
        useSource === 'Cover Video' ||
        useSource === 'profile and photo' ||
        useSource === 'profile and cover video'
    ) {
        // Directly use the profile for 'Cover Photo' and 'Cover Video' cases
        item = profile;
    } else {
        // Use the first item of the source array for other cases
        item = _get(config.source, '[0]', { title: config.defaultTitle });
    }

    const [titleKey, subtitleKey, mediaKey, urlKey] = config.keys || [];

    const title =
        item && item[titleKey] != null ? item[titleKey] : config.defaultTitle;

    const isMusic = useSource === 'music' || useSource === 'New Music';

    const subtitle = isMusic
        ? musicDefaultSubtitle
        : item && item[subtitleKey] != null
        ? item[subtitleKey]
        : undefined;
    const media = item && item[mediaKey] != null ? item[mediaKey] : undefined;
    const buttonLocation =
        item && item[urlKey] != null ? item[urlKey] : undefined;

    const props = {
        title,
        subtitle,
        media,
        buttonText: config.buttonText,
        buttonLocation,
    };

    return props;
};

export default getSourceInformation;
