import React, { useContext, useEffect, useState } from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import {
    AppBar,
    Button,
    Box,
    Menu,
    Toolbar,
    IconButton,
    MenuItem,
    Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { serverSideConfig } from '../../../config';
import { AuthContext } from '../../../context/authContext';
import { CompContext } from '../../../context/CompContext';
import { CreatorContext } from '../../../context/creatorContext';
import { darkModeContext } from '../../../context/themeHandler';
import CreatorSearch from '../CreatorSearch';
import MenuItemLink from '../MenuItemLink';
import BasicModal, { buttonsVariants } from '../BasicModal';
import TemplateChooserModal from '../../../builder/TemplateChooserModal';
import isEmpty from 'lodash.isempty';
import _find from 'lodash.find';
import JamfeedLogo from './JamFeedLogo-light.png';
import VerifyModal from '../../../builder/VerifyModal';

const useStyles = makeStyles((theme) => ({
    root: ({ headerHeight }) => {
        return {
            height: headerHeight,
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
        };
    },
    appBar: ({ headerHeight }) => ({
        height: headerHeight,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    }),
    toolbar: {
        padding: '0 1rem',
        margin: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            padding: '0 0.5rem',
        },
    },
    leftGroup: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    logo: {
        maxWidth: '10%',
        padding: 15,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    button: {
        height: '100%',
        marginLeft: '8px',
    },
    previewButton: {
        border: '1px solid #22aa02',
        '&:hover': {
            color: 'white',
            backgroundColor: '#22aa02',
            border: '1px solid #22aa02',
            // color: '#22aa02',
            // backgroundColor: 'transparent',
            // border: '1px solid #22aa02',
        },
    },
    publishButton: {
        color: 'white',
        backgroundColor: '#22aa02',
        border: '1px solid #22aa02',
        '&:hover': {
            color: '#22aa02',
            backgroundColor: 'transparent',
            border: '1px solid #22aa02',
        },
    },
    templatesButton: {
        color: 'white',
        backgroundColor: 'black',
        border: '1px solid black',
        '&:hover': {
            color: 'black',
            backgroundColor: 'white',
            border: '1px solid black',
        },
    },
    closeButton: {
        color: 'white',
        backgroundColor: 'black',
        marginLeft: '10px',
    },
    menuItem: {
        fontSize: '12px',
    },
    triggerButton: {
        backgroundColor: '#02AA02',
        fontSize: '12px',
    },
    errorPub: {
        color: 'red',
    },
    successPub: {
        color: 'green',
    },
    userButton: {
        display: 'block',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    userIconButton: {
        display: 'none',
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },
}));

const HeaderNav = ({
    isDrawerOpen,
    setIsDrawerOpen,
    headerHeight,
    ...props
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const authContext = useContext(AuthContext);
    const dmContext = useContext(darkModeContext);
    const creatorContext = useContext(CreatorContext);
    const componentContext = useContext(CompContext);
    const [templateChooserProps, setTemplateChooserProps] = useState({
        shouldBeOpen: false,
    });
    const [modalProps, setModalProps] = useState({ isOpen: false });

    const [verifyModalProps, setVerifyModalProps] = useState({
        artistName: null,
        artistId: null,
        imageSrc: null,
    });

    const handleVerifyModalProps = (imageSrc) => {
        setVerifyModalProps({
            artistName: creatorContext.creator.name,
            artistId: creatorContext.creator.id,
            imageSrc,
            shouldBeOpen: true,
            onCloseCallback: () =>
                setVerifyModalProps({
                    shouldBeOpen: false,
                }),
        });
    };

    const handleTemplateChooserOpen = () => {
        setTemplateChooserProps({
            shouldBeOpen: true,
            onCloseCallback: () =>
                setTemplateChooserProps({
                    shouldBeOpen: false,
                }),
        });
    };

    const setDarkMode = (event, isDarkMode) => {
        localStorage.setItem('preferred-theme', isDarkMode ? 'dark' : 'light');
        dmContext.setDarkMode(isDarkMode);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        handleClose();
        window.location = '/welcome/logout';
    };

    const preview = () => {
        componentContext.savePreviewConfig();
        const url = `${serverSideConfig.basePath}/preview?id=${creatorContext.creator.id}`;
        window.open(url, '_blank');
    };

    // const classes = useStyles({ openOptions });

    const getDisplayName = () => {
        if (authContext.user.first_name) {
            return `${authContext.user.first_name} ${authContext.user.last_name}`;
        }
        return authContext.user.email;
    };

    useEffect(() => {
        if (
            !isEmpty(creatorContext.creator.id) &&
            !creatorContext.creator.hasOwnProperty('jf_verification')
        ) {
            handleVerifyModalProps(creatorContext.creator.pictureurl);
        }
    }, [creatorContext.creator]);

    useEffect(() => {
        // determine whether to display template chooser modal
        if (
            !isEmpty(componentContext.templateConfiguration) &&
            !isEmpty(componentContext.templateConfiguration) &&
            isEmpty(
                _find(componentContext.getComponentList(), { isVisible: true })
            )
        ) {
            handleTemplateChooserOpen();
        }
    }, [componentContext.templateConfiguration]);

    const publishPage = async (event) => {
        if (!creatorContext.hasActiveSubscription()) {
            setModalProps({
                isOpen: true,
                title: 'Select a Website Plan',
                paragraph:
                    'In order to publish your website to your domain, you first need to selecte a website plan.',
                actionLabel: 'Select A Plan',
                actionCallback: () =>
                    (window.location = `/create/pricing/plans?id=${creatorContext.creator.id}`),
            });
            return;
        }

        if (!creatorContext.hasConfiguredDNS()) {
            setModalProps({
                isOpen: true,
                title: 'Connect Your Custom Domain',
                paragraph:
                    'Purchase a brand new domain, or connect your own domain that you already purchased.',
                actionLabel: 'Action label',
                actionCallback: () => console.log('action callback'),
                customButtons: [
                    {
                        actionLabel: 'Buy a New Domain',
                        actionCallback: () =>
                            (window.location = `/create/manage-domain?id=${creatorContext.creator.id}`),
                        variant: buttonsVariants.contained,
                    },
                    {
                        actionLabel: 'Connect Domain',
                        actionCallback: () =>
                            console.log('connect owned domain WIP'),
                        variant: buttonsVariants.outlined,
                    },
                ],
            });
            return;
        }

        try {
            setModalProps({
                isOpen: true,
                title: 'Publish Your Website',
                paragraph:
                    'Click the publish button below to push your website live on your domain.',
                actionLabel: 'Publish Website',
                actionCallback: async () =>
                    await componentContext.savePublishedConfig(),
            });
        } catch (e) {
            if (e.message === 'Subscription Inactive') {
                setModalProps({
                    isOpen: true,
                    title: 'Select a Website Plan',
                    paragraph:
                        'In order to publish your website to your domain, you first need to selecte a website plan.',
                    actionLabel: 'Select A Plan',
                    actionCallback: () =>
                        (window.location = `/create/pricing/plans?id=${creatorContext.creator.id}`),
                });
                return;
            }
            if (e.message === 'DNS Not Configured') {
                setModalProps({
                    isOpen: true,
                    title: 'Connect Your Domain',
                    paragraph:
                        "In order to publish your website to your domain, you must first connect your domain's DNS Settings.",
                    actionLabel: 'Connect Domain',
                    actionCallback: () =>
                        (window.location = `/create/manage-domain?id=${creatorContext.creator.id}`),
                });
                return;
            }
        }
    };
    const classes = useStyles({ headerHeight });

    return (
        <AppBar
            position={props.position || 'absolute'}
            className={props.headerStyling || classes.appBar}
        >
            <Toolbar className={classes.toolbar}>
                <img
                    src={JamfeedLogo}
                    className={classes.logo}
                    alt='Jamfeed Logo'
                />
                <div className={classes.leftGroup}>
                    <CreatorSearch />
                </div>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: { xs: 'space-between', md: 'flex-end' },
                        alignItems: 'flex-end',
                        width: '100%',
                    }}
                >
                    <Button
                        onClick={handleTemplateChooserOpen}
                        className={`${classes.button} ${classes.templatesButton}`}
                    >
                        Templates
                    </Button>
                    <Button
                        disabled={componentContext.isAutoSaving === true}
                        onClick={preview}
                        variant='outlined'
                        className={`${classes.button} ${classes.previewButton}`}
                    >
                        {componentContext.isAutoSaving === true
                            ? 'Saving...'
                            : 'Preview'}
                    </Button>
                    <Button
                        onClick={(event) => {
                            publishPage(event);
                        }}
                        variant='outlined'
                        className={`${classes.button} ${classes.publishButton}`}
                    >
                        Publish
                    </Button>
                    <Button
                        aria-controls='account-menu'
                        aria-haspopup='true'
                        onClick={handleClick}
                        className={classes.userButton}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                        {getDisplayName()}
                    </Button>
                    <IconButton
                        aria-controls='account-menu'
                        aria-haspopup='true'
                        onClick={handleClick}
                        size='small'
                        className={classes.userIconButton}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                        color='primary'
                    >
                        <PersonIcon color='primary' />
                    </IconButton>
                </Box>
                <Menu
                    id='account-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <MenuItemLink
                        primary='Logout'
                        icon={<ExitToAppIcon />}
                        onClick={logout}
                    />
                </Menu>
            </Toolbar>
            {modalProps.isOpen && (
                <BasicModal {...modalProps} setModalProps={setModalProps} />
            )}
            {templateChooserProps.shouldBeOpen &&
                !verifyModalProps.shouldBeOpen && (
                    <TemplateChooserModal {...templateChooserProps} />
                )}
            {verifyModalProps.shouldBeOpen && (
                <VerifyModal {...verifyModalProps} />
            )}
        </AppBar>
    );
};

export default HeaderNav;
