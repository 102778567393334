import React, { useEffect } from 'react';

import HomeSection from './HomeSection';
import NewsSection from './NewsSection';
import TourSection from './TourSection';
import MusicSection from './MusicSection';
import VideosSection from './VideosSection';
import AboutSection1 from '../../shared/about/AboutSection1';
import NavBar from './NavBar';
import ContactSection from './ContactSection';

import './template1.scss';

function loadFonts() {
    import('webfontloader').then((WebFont) => {
        WebFont.load({
            google: {
                families: [
                    'Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic',
                    'Lato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic',
                ],
            },
        });
    });
}

const Template1 = ({ profile, videos, music, news, integrations }) => {
    const theme = {
        textColor: 'white',
        backgroundColor: 'black',
    };
    useEffect(() => {
        loadFonts();
    }, []);
    return (
        <div className='template1'>
            <div className='body'>
                <NavBar profile={profile} integrations={integrations} news={news} music={music} videos={videos} />
                <HomeSection profile={profile} />
                <NewsSection news={news} />
                <TourSection profile={profile} />
                <MusicSection music={music} />
                <VideosSection videos={videos} />
                <AboutSection1 profile={profile} theme={theme} />
                {/* <SubscribeForm/> */}
                <ContactSection integrations={integrations} profile={profile} />
            </div>
        </div>
    );
};

export default Template1;
