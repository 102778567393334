import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { openUrl } from '../../utils/action';
import DOMPurify from 'dompurify';
import { decode } from 'html-entities';

const useStyles = makeStyles(() => ({
    root: {
        margin: 'auto',
        borderRadius: 0,
        position: 'relative',
        transition: 'all .7s ease-in-out',
        '&:hover': {
            cursor: 'pointer',
            '& $backgroundImage': {
                transform: 'scale(1.2)',
            },
            '& $title': {
                transform: 'scale(1)',
                opacity: 1,
            },
            '& $textBox': {
                background: 'rgba(0, 0, 0, 0.7)',
            },
        },
    },
    backgroundImage: {
        transition: 'all .7s ease-in-out',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.08)', //bgColor,
        backgroundPosition: 'center', //bgPosition
    },
    cta: {
        display: 'block',
        textAlign: 'center',
        letterSpacing: '3px',
        fontWeight: 200,
        fontSize: 12,
    },
    title: {
        letterSpacing: '2px',
        display: 'block',
        transition: 'all .7s ease-in-out',
        transform: 'scale(0.1)',
        flexShrink: 3,
        opacity: 0.0,
        fontSize: '18px',
    },
    textBox: {
        transition: 'all .7s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 360,
        color: ({ textColor }) => textColor,
        textAlign: 'center',
    },
    useLightTopShadowStyles: {
        backgroundColor: 'transparent',
        boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
        transition: '0.3s',
        '&:hover': {
            boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.5)',
            zIndex: 100,
        },
    },
}));

export const Card7 = ({
    title,
    media,
    url,
    note1,
    textColor,
    roundedCorners,
}) => {
    const styles = useStyles({ textColor });
    let cleanTitle = DOMPurify.sanitize(title, { SAFE_FOR_TEMPLATES: true });
    return (
        <Card
            className={cx(styles.root, styles.useLightTopShadowStyles)}
            onClick={() => openUrl(url)}
            style={{ borderRadius: roundedCorners ? '1.5rem' : 0 }}
        >
            <CardMedia
                image={media}
                href={url}
                className={styles.backgroundImage}
            />
            <CardActionArea sx={{ padding: 0 }}>
                <CardContent sx={{ padding: 0 }}>
                    <Box className={styles.textBox}>
                        <h1 className={styles.title}>{decode(cleanTitle)}</h1>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

const title = 'Card Option 7';
const detailedEditorDefinition = {
    'card.textColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.textColor',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
    'card.roundedCorners': {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'card.roundedCorners',
        label: 'Rounded Corners',
        description: 'Creates rounded corners on each card',
        isRequired: false,
    },
};

Card7.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    note1: PropTypes.string.isRequired,
    media: PropTypes.string.isRequired,
    textColor: detailedEditorDefinition['card.textColor'].propType,
    roundedCorners: detailedEditorDefinition['card.roundedCorners'].propType,
};

export { Card7 as default, title, detailedEditorDefinition };
