import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Box, IconButton, Card, CardActionArea, CardMedia, CardContent } from '@mui/material/';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const useStyles = makeStyles(() => ({
    root: {
        margin: 'auto',
        borderRadius: 0,
        position: 'relative',
    },
    title: {
        letterSpacing: '2px',
    },
    mediaStyles: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.08)', //bgColor,
        backgroundPosition: 'center', //bgPosition
    },
    playButton: {
        fontSize: 60,
        position: 'absolute',
        color: 'white',
        opacity: 1,
    },
    useLightTopShadowStyles: {
        backgroundColor: 'transparent',
        boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
        transition: '0.3s',
        '&:hover': {
            boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
        },
    },
}));

export const Card10 = ({ media, textColor, url , roundedCorners }) => {
    const styles = useStyles();
    return (
        <Card className={cx(styles.root, styles.useLightTopShadowStyles)}
            style={{ borderRadius: roundedCorners ? '1.5rem' : 0 }}
        >
            <CardMedia className={styles.mediaStyles} image={media} />
            <CardActionArea>
                <CardContent className={styles.content}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        minHeight={360}
                        color={textColor}
                        textAlign={'center'}
                    >
                        <IconButton href={url} target='blank' className={styles.playButton} size='large'>
                            <PlayCircleOutlineIcon
                                sx={{
                                    fontSize: 60,
                                }}
                            />
                        </IconButton>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

const title = 'Card Option 10';
const detailedEditorDefinition = {
    'card.textColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.textColor',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
    'card.roundedCorners': {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'card.roundedCorners',
        label: 'Rounded Corners',
        description: 'Creates rounded corners on each card',
        isRequired: false,
    },
};

Card10.propTypes = {
    media: PropTypes.string.isRequired,
    textColor: detailedEditorDefinition['card.textColor'].propType,
    roundedCorners: detailedEditorDefinition['card.roundedCorners'].propType,
};

export { Card10 as default, title, detailedEditorDefinition };
