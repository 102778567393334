import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Drawer, Grid, Slide } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import Navbar from '../../shared/headers/components/Navbar';
import { IconGrid } from '../../shared/social icons/HeadersLayout';
import decodeHtml from '../../utils/decodeHtml';
import {
    AVAILABLE_MENU_LAYOUTS,
    AVAILABLE_SOURCE,
    musicDefaultSubtitle,
} from './constants';

import {
    InputMethods,
    createField,
    generatePropTypesAndDefaults,
} from '../../utils/detailedEditorDefinition';
import { commonEditorDefinitions } from './commonEditorDefinitions';
import PropTypes from 'prop-types';
import { NavbarConfigProvider } from '../../shared/headers/components/useNavbarConfig';
import { CardiBMenuStyle } from '../../shared/headers/components/CardiBMenu';
import useDynamicFontSize from '../../shared/headers/hooks/useDynamicFontSize';
import { isBuilderURL } from '../../utils/isBuilderUrl';

const useNavStyles = makeStyles((theme) => ({
    appBar: {
        background: 'transparent',
        boxShadow: 'none',
        height: 'auto',
        width: 'auto',
    },
    custom: {
        // background: 'transparent',
        background: 'green',
        boxShadow: 'none',
        height: 'auto',
        width: 'auto',
    },
    navContent: {
        display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
        flexFlow: 'row nowrap',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        width: '100%',
        fontSize: '0.8rem',
    },
    navlinks: {
        marginLeft: theme.spacing(2),
        display: 'flex',
        overflowX: 'hidden',
        background: 'transparent',
    },
    link: {
        fontSize: '0.5rem',
        textDecoration: 'none',
        color: (props) => props.textColor || '#FFF',
        fontSize: '1rem',
        marginLeft: theme.spacing(2),
        fontWeight: 'bold', // Make links bold
        '&:hover': {
            textDecoration: 'none',
            color: (props) => props.textColor || '#FFFF00',
            borderBottom: (props) =>
                `1px solid ${props.textColor || '#FFFF00'}`,
        },
        '&:visited': {
            color: (props) => props.textColor || '#FFF',
        },
    },
    menuIcon: {
        display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' },
    },
}));

const useStyles = makeStyles((theme) => {
    return {
        headerContainer: (props) => ({
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            height: '100vh',
            minHeight: '600px',
            maxHeight: isBuilderURL() ? 'calc(100vh - 4.5rem)' : '100vh',
            background: `url(${props.mediaOverride}) no-repeat center center`,
            backgroundSize: 'cover',
            backgroundPosition: 'center bottom',
            padding: theme.spacing(3),
            boxSizing: 'border-box',
            padding: theme.spacing(3),
            backgroundColor: props.backgroundColor || '#000000',
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1),
                margin: '0 auto',
            },
        }),
        mainContent: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            // padding: theme.spacing(4),
            margin: theme.spacing(2),
            justifyContent: 'space-evenly',
            alignItems: 'center',
            fontFamily: (props) => props.font || 'initial',
            [theme.breakpoints.up('md')]: {
                width: '50%',
                marginLeft: '50%',
            },
            [theme.breakpoints.down('md')]: {
                justifyContent: 'flex-end',
            },
            [theme.breakpoints.down('sm')]: {
                margin: '0 auto',
                width: '100%',
            },
            paddingBottom: '9rem',
        },
        name: {
            fontSize: '1.6rem',
            fontWeight: 'light',
            color: (props) => props.textColor || '#FFF',
            textAlign: 'center',
            marginBottom: theme.spacing(1),
        },
        title: {
            fontSize: '2rem',
            color: (props) => props.textColor || '#FFF',
            textAlign: 'center',
            marginBottom: theme.spacing(1),
            lineHeight: '1',
        },
        subtitle: {
            fontSize: '1rem',
            color: (props) => props.textColor || '#FFF',
            textAlign: 'center',
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.8rem',
            },
        },
        menuContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            // [theme.breakpoints.down('md')]: {
            //     display: 'none',
            // },
        },
    };
});

/**
 * The Inner Content of the Header5 component
 * @param {Object} props - The props passed to the component
 * @returns {React.Element} The InnerContent component
 */
const InnerContent = ({
    title,
    subtitle,
    buttonText,
    buttonLocation,
    textColor,
    buttonBackgroundColor = '#FFF',
    buttonTextColor = '#FFF',
    logo,
    showButton,
}) => {
    const updatedClasses = makeStyles((theme) => ({
        content: {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: `${textColor}`,
            padding: theme.spacing(2),
            justifyContent: 'space-between',
            height: '33%',
            [theme.breakpoints.down('md')]: {
                height: 'auto',
            },
        },
        titleSubtitleBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        buttonText: {
            fontSize: '1.4rem',
            fontWeight: 'light',
            margin: 0,
            padding: 0,
        },
        subtitleText: {
            fontSize: '2.6rem',
            fontWeight: 'bold',
            marginTop: '-0.6rem',
            margin: 0,
            padding: 0,
            maxWidth: '100%', // to make the useDynamicFontSizeHook Set max-width
            maxHeight: '20vh', // Set max-height
            overflow: 'hidden', // Hide overflow content
        },
        button: {
            border: `2px solid ${buttonBackgroundColor}`,
            backgroundColor: 'transparent',
            color: `${buttonTextColor}`,
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            fontSize: 'small',
            [theme.breakpoints.down('md')]: {
                padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
            },
            '&:hover': {
                border: `2px solid #fff`,
            },
        },
        title: {
            fontWeight: 'bold',
            margin: 0,
            padding: 0,
            minHeight: '2rem',
            maxWidth: '100%', // to make the useDynamicFontSizeHook Set max-width
            maxHeight: '2rem', // Set max-height
            overflow: 'hidden', // Hide overflow content
        },
    }))();

    const titleRef = useDynamicFontSize();
    const subtitleRef = useDynamicFontSize();

    return (
        <Grid className={updatedClasses.content}>
            {logo ? (
                <img src={logo} alt='Logo' />
            ) : (
                <Box className={updatedClasses.titleSubtitleBox}>
                    <h1 className={updatedClasses.title} ref={subtitleRef}>
                        {decodeHtml(subtitle)}
                    </h1>
                    <p className={updatedClasses.subtitleText} ref={titleRef}>
                        {decodeHtml(title)}
                    </p>
                </Box>
            )}
            {showButton && (
                <Button
                    variant='outlined'
                    className={updatedClasses.button}
                    onClick={() => {
                        window.open(
                            buttonLocation || 'https://www.jamfeed.com',
                            '_blank'
                        );
                    }}
                >
                    {decodeHtml(buttonText)}
                </Button>
            )}
        </Grid>
    );
};

InnerContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonLocation: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    buttonBackgroundColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
};

const Header5 = ({
    color: textColor,
    backgroundColor,
    btnColor: buttonTextColor,
    btnBg: buttonBackgroundColor,
    title,
    subtitle,
    profile,
    integrations: { integrated },
    displaySocialIcons,
    fontSizeSelection = 6,
    sections,
    button: buttonTextOverride,
    buttonLocation,
    font,
    displayActionButton,
}) => {
    console.log('header5');
    const styleDetails = {
        textColor,
        backgroundColor,
        buttonTextColor,
        buttonBackgroundColor,
        fontSizeSelection,
        font,
    };
    const navbarConfig = {
        title: name,
        sections: sections,
        classes: navStyles,
        toggleDrawer: toggleDrawer,
        menuState: menuState,
        styleDetails,
        specialFontSize: true,
        fontWeight: 'lighter',
        fontSize: '0.8rem',
    };

    const { pictureurl: coverPhoto, name } = profile;
    const [menuState, setMenuState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        // console.log('toggle drawer pressed');
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setMenuState({ ...menuState, [anchor]: open });
    };

    const navStyles = useNavStyles(styleDetails);

    const classes = useStyles({
        mediaOverride: coverPhoto,
        textColor,
        ...styleDetails,
    });

    return (
        <NavbarConfigProvider value={navbarConfig}>
            <Grid className={classes.headerContainer}>
                <Grid>
                    <Navbar
                        title={name}
                        sections={sections}
                        classes={navStyles}
                        toggleDrawer={toggleDrawer}
                        menuState={menuState}
                        styleDetails={styleDetails}
                        showName={false}
                        integrated={integrated}
                        custom={{
                            component: AVAILABLE_MENU_LAYOUTS.CARDI_B,
                            props: {
                                title: name.toUpperCase(),
                                textColor,
                                toggleDrawer,
                                menuState,
                            },
                            listComponentStyle: CardiBMenuStyle(),
                        }}
                        showMenuIcon
                    />
                </Grid>
                <Grid className={classes.mainContent}>
                    <InnerContent
                        title={decodeHtml(title || '').toUpperCase()}
                        subtitle={decodeHtml(
                            subtitle === musicDefaultSubtitle
                                ? name.toUpperCase()
                                : subtitle
                        )}
                        showButton={displayActionButton}
                        buttonText={buttonTextOverride}
                        buttonLocation={buttonLocation}
                        textColor={textColor}
                        buttonBackgroundColor={buttonBackgroundColor}
                        buttonTextColor={buttonTextColor}
                    />
                    {integrated && (
                        <Slide
                            in={true}
                            direction='left'
                            style={{ transform: '0 0 0' }}
                            {...(true ? { timeout: 1000 } : {})}
                        >
                            <Grid className={classes.menuContainer}>
                                {displaySocialIcons && (
                                    <IconGrid
                                        integrated={integrated}
                                        textColor={textColor}
                                        classes={classes}
                                    />
                                )}
                            </Grid>
                        </Slide>
                    )}
                </Grid>
            </Grid>
        </NavbarConfigProvider>
    );
};

// specificDefinitions and propsTypes and defaultProps section for this header layout type
const specificDefinitions = {
    source: createField(
        PropTypes.string.isRequired,
        InputMethods.STRING,
        InputMethods.SELECT,
        {
            inputOptions: AVAILABLE_SOURCE,
            name: 'source',
            label: 'Select Header',
            isRequired: true,
            defaultValue: 'music',
        }
    ),
    color: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.COLOR,
        {
            name: 'color',
            label: 'Text Color',
            isRequired: false,
            defaultValue: '#FFFFFF',
        }
    ),
    btnBg: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.COLOR,
        {
            name: 'btnBg',
            label: 'Button Color',
            isRequired: false,
            defaultValue: '#FFFFFF',
        }
    ),
    btnColor: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.COLOR,
        {
            name: 'btnColor',
            label: 'Button Text Color',
            isRequired: false,
            defaultValue: '#000000',
        }
    ),
    subtitle: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'subtitle',
            label: 'Subtitle Override',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    title: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'title',
            label: 'Title Override',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    displayActionButton: createField(
        PropTypes.bool.isRequired,
        InputMethods.BOOLEAN,
        InputMethods.SWITCH,
        {
            name: 'displayActionButton',
            label: 'Display Action Button',
            isRequired: false,
            defaultValue: true,
        }
    ),
    button: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'button',
            label: 'Button Text Override',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    buttonLocation: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'buttonLocation',
            label: 'Button Click Override',
            description:
                'URL where will the viewer go when clicking on the button?',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    displaySocialIcons: createField(
        PropTypes.bool.isRequired,
        InputMethods.BOOLEAN,
        InputMethods.SWITCH,
        {
            name: 'displaySocialIcons',
            label: 'Display Social Icons',
            isRequired: false,
            defaultValue: false,
        }
    ),
};
const detailedEditorDefinition = {
    ...commonEditorDefinitions, // shared definitions by all headers
    ...specificDefinitions,
};

const { propTypes, defaultProps } = generatePropTypesAndDefaults(
    detailedEditorDefinition
);

Header5.propTypes = propTypes;
Header5.defaultProps = defaultProps;

export { Header5 as default, detailedEditorDefinition, specificDefinitions };
