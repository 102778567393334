import { AVAILABLE_LAYOUTS_NAMES } from '../../sections/header';

const SectionData = {
    /**
     * 0:taylor 1:young 2:malone 3:youngw 4:kid 5:red
     * music- videos-about-footer-news
     */
    header: [
        //0
        {
            layout: AVAILABLE_LAYOUTS_NAMES[0], // Jake Blount // Doja Cat file header3.js
            button: 'Listen Now',
            source: 'New Music',
            displaySocialIcons: true,
            displayActionButton: true,
            color: '#FFFFFF',
            backgroundColor: '#000000',
            btnColor: '#FFFFFF',
            btnBg: '#FFFFFF',
            sectionBackgroundColor: '#000000',
        },
        //1
        {
            layout: AVAILABLE_LAYOUTS_NAMES[1], // anderson .paak //Taylor Swift file header2.js
            button: 'Listen Now',
            source: 'New Music',
            displaySocialIcons: true,
            color: '#FFFFFF',
            backgroundColor: '#000000',
            sectionBackgroundColor: '#000000',
        },
        //2
        {
            layout: AVAILABLE_LAYOUTS_NAMES[2], // Cardi B file//Drake header5.js
            button: 'Listen Now',
            source: 'New Music',
            btnColor: '#FFFFFF',
            btnBg: '#FFFFFF',
            displaySocialIcons: true,
            displayActionButton: true,
            color: '#FFFFFF',
            backgroundColor: '#000000',
            sectionBackgroundColor: '#000000',
        },
        //3
        {
            layout: AVAILABLE_LAYOUTS_NAMES[3], // Leeland file header4.js
            button: 'Listen Now',
            source: 'New Music',
            displaySocialIcons: true,
            color: '#FFFFFF',
            backgroundColor: '#000000',
            sectionBackgroundColor: '#000000',
        },
        //4
        {
            layout: AVAILABLE_LAYOUTS_NAMES[4], // file header section if option 'right' is selected
            button: 'Listen Now',
            subtitle: 'New Release',
            source: 'New Music',
            btnColor: '#FFFFFF',
            btnBg: '#22AA02',
            displaySocialIcons: true,
            color: '#FFFFFF',
            backgroundColor: '#000000',
            sectionBackgroundColor: '#000000',
        },
        // 5
        {
            layout: AVAILABLE_LAYOUTS_NAMES[5], // file header section if default case is returned
            button: 'Listen Now',
            subtitle: 'New Release',
            source: 'New Music',
            btnColor: '#FFFFFF',
            btnBg: '#22AA02',
            displaySocialIcons: true,
            color: '#FFFFFF',
            backgroundColor: '#000000',
            sectionBackgroundColor: '#000000',
        },
        //6
        {
            layout: AVAILABLE_LAYOUTS_NAMES[6], // file header section if option 'full' is selected
            button: 'Click Here',
            source: 'Cover Photo',
            enableParallax: false,
            displaySocialIcons: false,
            color: '#FFFFFF',
            btnColor: '#000000',
            btnBg: '#FFFFFF',
            backgroundColor: '#000000',
            sectionBackgroundColor: '#000000',
            subtitle: '',
        },
        //7
        {
            layout: AVAILABLE_LAYOUTS_NAMES[7], // file header section if option 'right' is selected
            button: 'Listen Now',
            subtitle: 'New Release',
            source: 'New Music',
            backgroundColor: '#ffffff',
            btnBg: '#22aa02',
            btnColor: '#ffffff',
            color: '#000000',
            displaySocialIcons: true,
            layout: 'right',
            source: 'New Music',
            sectionBackgroundColor: '#000000',
        },
    ],
    music: [
        {
            layout: {
                showMore: true,
                spacing: 1,
                rows: 2,
                columns: 4,
            },
            sectionWidth: 'Narrow',
            sectionTitle: 'Music',
            sectionBackgroundImage: null,
            cardType: 'Card Option 3',
            layoutType: 'Grid',
            sectionBackgroundColor: '#ffffff',
            sectionTextColor: '#000000',
            card: {
                textColor: '#000000',
                backgroundColor: '#fafafa',
            },
            displayOnNavigation: true,
        },
        {
            layout: {
                showMore: true,
                spacing: 2,
                rows: 3,
                columns: 3,
            },
            backgroundColor: 'black',
            color: '#FFF',
            sectionTitle: 'Music',
            cardType: 'Card Option 9',
            layoutType: 'Grid',
            sectionBackgroundColor: '#000000',
            sectionTextColor: '#ffffff',
            cols: '3',
            card: {
                textColor: '#ffffff',
                roundedCorners: true,
            },
            displayOnNavigation: true,
        },
        {
            layout: {
                columns: 3,
                spacing: 10,
            },
            sectionTitle: 'Music',
            cardType: 'Card Option 6',
            layoutType: 'Slider',
            sectionBackgroundColor: '#000000',
            sectionTextColor: '#ffffff',
            card: {
                textColor: '#ffffff',
                roundedCorners: false,
            },
        },
        {
            layout: {
                columns: 4,
            },
            sectionTitle: 'Music',
            cardType: 'Card Option 7',
            layoutType: 'Grid',
            sectionBackgroundColor: '#ffffff',
            sectionTextColor: '#000000',
            card: {
                textColor: '#ffffff',
                roundedCorners: false,
            },
        },
        {
            backgroundColor: 'black',
            color: '#FFF',
            displayLayout: 'Slider',
            cardType: 'Card Option 4',
            itemView: 'Album Art + Song Name',
            sectionTextColor: '#030303',
            undefined: 2,
            layout: {
                numItemsToRender: 10,
                showMore: true,
                spacing: 1,
                rows: 1,
                columns: 3,
            },
            sectionWidth: 'Narrow',
            sectionTitle: 'MUSIC',
            layoutType: 'Grid',
            sectionBackgroundColor: '#ffffff',
            cols: '2',
            card: {
                textColor: '#ffffff',
                roundedCorners: false,
            },
        },
        {
            layout: {
                showMore: false,
                spacing: 10,
                rows: 3,
                columns: 4,
            },
            sectionWidth: 'Narrow',
            sectionTitle: 'Music',
            sectionBackgroundImage: null,
            cardType: 'Card Option 8',
            layoutType: 'Slider',
            sectionBackgroundColor: '#ffffff',
            sectionTextColor: '#000000',
            card: {
                textColor: '#ffffff',
                backgroundColor: '#fafafa',
                roundedCorners: true,
            },
            displayOnNavigation: true,
        },
    ],
    news: [
        {
            //1
            layout: {
                numItemsToRender: 3,
                spacing: 2,
                rows: 3,
                columns: 3,
            },
            sectionWidth: 'Narrow',
            sectionTitle: 'NEWS',
            cardType: 'Card Option 2',
            layoutType: 'Grid',
            sectionBackgroundColor: '#ffffff',
            sectionTextColor: '#000000',
            card: {
                textColor: '#000000',
                backgroundColor: '#ffffff',
                roundedCorners: true,
            },
        },
        {
            //2
            layout: {
                showMore: true,
                spacing: 2,
            },
            sectionTitle: 'News',
            cardType: 'Card Option 4',
            layoutType: 'Grid',
            sectionBackgroundColor: '#0e0e0e',
            sectionTextColor: '#f5f4f4',
            card: {
                textColor: '#fbf9f9',
                backgroundColor: '#0a0a0a',
            },
        },
        {
            //3
            layout: {
                columns: 2,
            },
            sectionTitle: 'News',
            cardType: 'Card Option 4',
            layoutType: 'Grid',
            sectionBackgroundColor: '#000000',
            sectionTextColor: '#ffffff',
            card: {
                textColor: '#ffffff',
            },
        },
        {
            //4
            cardType: 'Card Option 2',
            layout: {
                columns: 4,
                spacing: 6,
            },
            layoutType: 'Slider',
            sectionBackgroundColor: '#ffffff',
            sectionTextColor: '#000000',
            sectionTitle: 'NEWS',
        },
        {
            //5
            backgroundColor: '#050404',
            color: 'red',
            cardType: 'Card Option 6',
            itemView: 'Design 1',
            sectionTextColor: '#4d4343',
            displayOnNavigation: true,
            layout: {
                showMore: true,
                spacing: 4,
                rows: 2,
                columns: 3,
            },
            sectionWidth: 'Full',
            buttonBackground: '',
            sectionTitle: 'NEWS ',
            layoutType: 'Slider',
            sectionBackgroundColor: '#ffffff',
            cols: '3',
            card: {
                textColor: '#f9f4f4',
                backgroundColor: '#000000',
                roundedCorners: false,
            },
        },
        {
            //6
            layout: {
                numItemsToRender: 3,
                spacing: 3,
                rows: 4,
                columns: 3,
            },
            sectionWidth: 'Narrow',
            sectionTitle: 'NEWS',
            cardType: 'Card Option 2',
            layoutType: 'Grid',
            sectionBackgroundColor: '#ffffff',
            sectionTextColor: '#000000',
            card: {
                textColor: '#000000',
                backgroundColor: '#ffffff',
                roundedCorners: true,
            },
        },
    ],
    video: [
        {
            //1
            layout: {
                showMore: true,
                spacing: 10,
                rows: 2,
                columns: 2,
            },
            sectionWidth: 'Narrow',
            sectionTitle: 'VIDEOS',
            cardType: 'Card Option 10',
            layoutType: 'Slider',
            sectionBackgroundColor: '#ffffff',
            sectionTextColor: '#000000',
            card: {
                textColor: '#000000',
                backgroundColor: '#ffffff',
                roundedCorners: false,
            },
            displayOnNavigation: false,
        },
        {
            //2
            layout: {
                showMore: true,
                columns: 3,
                spacing: 3,
            },
            color: '',
            sectionTitle: 'Videos',
            cardType: 'Card Option 10',
            layoutType: 'Grid',
            sectionBackgroundColor: '#020202',
            sectionTextColor: '#f7f4f4',
            card: {
                textColor: '#f7f2f2',
                backgroundColor: '#0a0a0a',
                roundedCorners: true,
            },
        },
        {
            //3
            layout: {
                columns: 2,
            },
            sectionTitle: 'Videos',
            cardType: 'Card Option 4',
            itemView: 'Design 3',
            layoutType: 'Slider',
            sectionBackgroundColor: '#000000',
            sectionTextColor: '#ffffff',
        },
        {
            //4
            cardType: 'Card Option 10',
            layout: {
                columns: 2,
                spacing: 10,
            },
            layoutType: 'Slider',
            sectionBackgroundColor: '#ffffff',
            sectionTextColor: '#000000',
            sectionTitle: 'Videos',
        },
        {
            //5
            backgroundColor: 'black',
            color: '#FFF',
            displayLayout: 'Slider',
            cardType: 'Card Option 10',
            itemView: 'Design 1',
            sectionTextColor: '#000000',
            layout: {
                columns: 4,
                spacing: 2,
            },
            sectionWidth: 'Full',
            sectionTitle: 'VIDEOS',
            layoutType: 'Grid',
            sectionBackgroundColor: '#ffffff',
            cols: '3',
            card: {
                textColor: '#ebe2e2',
                roundedCorners: false,
            },
        },
        {
            //6
            layout: {
                showMore: true,
                spacing: 2,
                rows: 2,
                columns: 3,
            },
            sectionWidth: 'Narrow',
            sectionTitle: 'VIDEOS',
            cardType: 'Card Option 4',
            layoutType: 'Grid',
            sectionBackgroundColor: '#ffffff',
            sectionTextColor: '#000000',
            card: {
                textColor: '#ffffff',
                backgroundColor: '#ffffff',
                roundedCorners: false,
            },
            displayOnNavigation: false,
        },
    ],
    about: [
        {
            //0
            variant: '2',
            backgroundColor: '#ffffff',
            color: '#000000',
            sectionTitle: 'ABOUT',
        },
        {
            //1
            variant: '5',
            backgroundColor: '#000000',
            color: '#ffffff',
            sectionTitle: 'About',
        },
        {
            //2
            variant: '2',
            backgroundColor: '#000000',
            color: '#ffffff',
            sectionTitle: 'ABOUT ME',
        },
        {
            //3
            variant: '3',
            backgroundColor: '#000000',
            color: '#ffffff',
            sectionTitle: 'ABOUT',
        },
        {
            //4
            variant: '4',
            backgroundColor: '#ffffff',
            color: '#000000',
            sectionTitle: 'ABOUT US',
            displayOnNavigation: true,
        },
        {
            //5
            variant: '4',
            backgroundColor: '#ffffff',
            color: '#000000',
            sectionTitle: 'ABOUT US',
            displayOnNavigation: true,
        },
        {
            //6
            variant: '4',
            backgroundColor: '#ffffff',
            color: '#000000',
            sectionTitle: 'ABOUT',
        },
    ],
    tour: [
        {
            //0
            buttonBackgroundColor: '#000000',
            sectionWidth: 'Narrow',
            backgroundColor: '#ffffff',
            color: '#000000',
            titleColor: '#000000',
            buttonColor: '#ffffff',
        },
        {
            //1
            buttonBackgroundColor: '#6b6b6b',
            backgroundColor: '#000000',
            color: '#ffffff',
            titleColor: '#FFF',
            buttonColor: '#ffffff',
            sectionTitle: 'TOUR',
        },
        {
            //2
            buttonBackgroundColor: '#8a8a8a',
            backgroundColor: '#000000',
            color: '#ffffff',
            titleColor: '#ffffff',
            buttonColor: '#ffffff',
        },
        {
            //3
            buttonBackgroundColor: '#dedede',
            sectionWidth: 'Narrow',
            backgroundColor: '#ffffff',
            color: '#000000',
            titleColor: '#000000',
            buttonColor: '#000000',
        },
        {
            //4
            sectionWidth: 'Narrow',
            backgroundColor: '#ffffff',
            color: '#000000',
            titleColor: '#ffffff',
            buttonColor: '#000000',
        },
        {
            //5
            color: '#000000',
            titleColor: '#000000',
            backgroundColor: '#ffffff',
        },
        {
            //6
            buttonBackgroundColor: '#dedede',
            sectionWidth: 'Narrow',
            backgroundColor: '#ffffff',
            color: '#000000',
            titleColor: '#000000',
            buttonColor: '#000000',
        },
    ],
    footer: [
        {
            //0
            variant: '1',
            backgroundColor: '#ffffff',
            color: '#000000',
            displaySocialIcons: true,
        },
        {
            //1
            variant: '1',
            backgroundColor: '#000000',
            color: '#ffffff',
            displaySocialIcons: true,
            monoIcons: true,
        },
        {
            //2
            iconColor: '#000000',
            variant: '2',
            backgroundColor: '#ffffff',
            color: '#000000',
            displaySocialIcons: true,
            monoIcons: true,
        },
        {
            //3
            backgroundColor: '#ffffff',
            color: '#000000',
            monoIcons: true,
            cardType: 'Card Option 5',
            variant: '3',
            layoutType: 'Grid',
            displaySocialIcons: true,
        },
        {
            //4
            color: '#ffffff',
            backgroundColor: '#000000',
        },
        {
            //5
            variant: '1',
            iconColor: '#000000',
            backgroundColor: '#ffffff',
            color: '#000000',
            displaySocialIcons: true,
            monoIcons: true,
        },
    ],
    /**
     * contact-photos
     * 0:malone 1:red 2:kid 3:young
     */
    photos: [
        {
            //0
            cardType: 'Card Option 6',
            layout: {
                columns: 3,
            },
            layoutType: 'Grid',
            sectionBackgroundColor: '#000000',
            sectionTextColor: '#ffffff',
            sectionTitle: 'Photos',
        },
        {
            //1
            layout: {
                columns: 2,
            },
            sectionWidth: 'Full',
            sectionTitle: 'Photos',
            cardType: 'Card Option 4',
            layoutType: 'Grid',
            sectionBackgroundColor: '#ffffff',
            sectionTextColor: '#000000',
            card: {
                textColor: '#000000',
                backgroundColor: '#ffffff',
            },
        },
        {
            //2
            cardType: 'Card Option 7',
            layout: {
                rows: 1,
                columns: 3,
                spacing: 0,
            },
            layoutType: 'Slider',
            cols: '2',
            sectionTitle: 'Photos',
        },
        {
            //3
            cardType: 'Card Option 7',
            layout: {
                columns: 4,
            },
            layoutType: 'Slider',
            sectionTitle: 'PHOTOS',
        },
    ],
    contact: [
        {
            //0
            layout: 'One Col',
            color: '#ffffff',
            textColor: '#ffffff',
            backgroundColor: '#000000',
        },
        {
            //1
            layout: 'Three Cols',
            backgroundColor: '#ffffff',
            color: '#000000',
            mediaOverride: '',
            textColor: '#000000',
        },
        {
            //2
            layout: 'Two Cols',
            backgroundColor: '#ffffff',
            color: '#000000',
            textColor: '#000000',
        },
        {
            //3
            layout: 'One Col',
            backgroundColor: '#ffffff',
            color: '#000000',
            textColor: '#000000',
            displayOnNavigation: false,
        },
    ],
};

export default SectionData;
