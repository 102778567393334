import React from 'react'
import ShowMore from "@tedconf/react-show-more";
import isEmpty from 'lodash.isempty';

function NewsSection({ news }) {
  return (
    <div id="news-section" className="news-section-2" >
      {!isEmpty(news) && (
        <div className="news-section-wrapper">
          <div className="section-header-wrapper">
            <h1 className="section-header-2">News</h1>

            <ShowMore items={news} by={3}>
              {({ current, onMore }) => (
                <>
                  {current.map((item, idx) => {
                    return (
                      <a
                        key={idx}
                        href={item.url}
                        target='blank'
                        className="news-cell-wrapper"
                        style={{
                          textDecoration: 'none',
                          color: '#fff'
                        }}
                      >
                        <div className="news-image-div">
                          <img
                            src={item.media}
                            width={535}
                            height={331}
                            sizes="(max-width: 479px) 100vw, (max-width: 991px) 80vw, 40vw"
                            alt
                            className="news-image"
                          />
                        </div>
                        <div className="news-info-wrapper">
                          <h3 className="news-article-header-2"
                            style={{
                              textDecoration: 'none',
                              color: '#fff'
                            }}
                          >
                            {item.title}
                          </h3>
                          <h6 className="news-article-source">{item.source}</h6>
                        </div>
                      </a>
                    )
                  })}
                  {news.length !== current.length && (
                    <div
                      style={{
                        display: 'flex',
                        alignSelf: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <button
                        outline
                        className="button w-button"
                        disabled={!onMore}
                        onClick={() => {
                          if (!!onMore) onMore();
                        }}
                      >
                        Load more
                      </button>
                    </div>
                  )}
                </>
              )}
            </ShowMore>
          </div>
        </div>
      )}
    </div>
  )
}

export default NewsSection;