import React from 'react';
import isEmpty from 'lodash.isempty';
import { getNetwork } from '../../../../utils/network';

function NavBarSection({ music, videos, news, integrations }) {
    const patreonNetwork = getNetwork(integrations.integrated, 'PATREON');

    return (
        <div
            data-collapse='medium'
            data-animation='over-right'
            data-duration={400}
            data-doc-height={1}
            role='banner'
            className='navbar-3 w-nav'
        >
            <div className='div-block-7'>
                <div className='div-block-6'>
                    <div className='div-block-9'>
                        <a onClick={() => {}} className='band-logo w-nav-brand'>
                            <img
                                src='https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f5856f6ea5799175a0_Cal%20Scruby%20Logo%20White.png'
                                alt
                                className='image-7'
                            />
                        </a>
                        <nav role='navigation' className='nav-menu-items w-nav-menu'>
                            <a onClick={() => {}} className='nav-link-3 w-nav-link'>
                                Home
                            </a>
                            {!isEmpty(music) && (
                                <a
                                    onClick={() => {
                                        window.document.getElementById('music-section').scrollIntoView();
                                    }}
                                    className='nav-link-3 w-nav-link'
                                >
                                    Music
                                </a>
                            )}
                            {!isEmpty(videos) && (
                                <a
                                    onClick={() => {
                                        window.document.getElementById('video-section').scrollIntoView();
                                    }}
                                    className='nav-link-3 w-nav-link'
                                >
                                    Videos
                                </a>
                            )}
                            <a
                                onClick={() => {
                                    window.document.getElementById('tour-section').scrollIntoView();
                                }}
                                className='nav-link-3 w-nav-link'
                            >
                                Tour
                            </a>
                            {!isEmpty(news) && (
                                <a
                                    onClick={() => {
                                        window.document.getElementById('news-section').scrollIntoView();
                                    }}
                                    className='nav-link-3 w-nav-link'
                                >
                                    News
                                </a>
                            )}
                            <a
                                onClick={() => {
                                    window.document.getElementById('merch-section').scrollIntoView();
                                }}
                                className='nav-link-3 w-nav-link'
                            >
                                Merch
                            </a>
                            <a
                                onClick={() => {
                                    window.document.getElementById('about-section').scrollIntoView();
                                }}
                                className='nav-link-3 w-nav-link'
                            >
                                About
                            </a>
                            <a
                                onClick={() => {
                                    window.document.getElementById('contact-footer-section').scrollIntoView();
                                }}
                                className='nav-link-3 w-nav-link'
                            >
                                Contact
                            </a>
                            {!isEmpty(patreonNetwork) && patreonNetwork.data.url && (
                                <a href={patreonNetwork.data.url} target='_blank' className='nav-link-3 w-nav-link'>
                                    Subscribe
                                </a>
                            )}
                        </nav>
                    </div>
                    <div className='div-block-8'>
                        <div className='social-wrapper'>
                            <div className='profile-link'>
                                <a
                                    href='https://open.spotify.com/artist/2wcrc3fjebDRLVdtRUa3pu'
                                    className='profile-image w-inline-block'
                                >
                                    <img
                                        src='https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca72af94dfbe_Spotify%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='profile-icon'
                                    />
                                </a>
                            </div>
                            <div className='profile-link'>
                                <a
                                    href='https://itunes.apple.com/us/artist/cal-scruby/670791732'
                                    className='profile-image w-inline-block'
                                >
                                    <img
                                        src='https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0acaa94c94dfbc_Apple%20Music%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='profile-icon'
                                    />
                                </a>
                            </div>
                            <div className='profile-link'>
                                <a href='http://youtube.com/calvinscruby' className='youtube-link w-inline-block'>
                                    <img
                                        src='https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca3a5894dfba_YouTube%20icon.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='youtube-icon'
                                    />
                                </a>
                            </div>
                            <div className='profile-link'>
                                <a href='http://soundcloud.com/calscruby' className='soundcloud-link w-inline-block'>
                                    <img
                                        src='https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0acab1d994dfbb_SoundCloud%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='soundcloud-icon'
                                    />
                                </a>
                            </div>
                            <div className='profile-link'>
                                <a href='http://instagram.com/calscruby' className='instagram-link w-inline-block'>
                                    <img
                                        src='https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca309794dfbf_Instagram%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='instagram-icon'
                                    />
                                </a>
                            </div>
                            <div className='profile-link'>
                                <a href='https://twitter.com/calscruby' className='twitter-link w-inline-block'>
                                    <img
                                        src='https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0acab93b94dfbd_Twitter%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='twitter-icon'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='w-nav-button'>
                        <div className='w-icon-nav-menu' />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavBarSection;
