import React from 'react';
import VideosLayout from '../../../shared/videos/VideosLayout';
import isEmpty from 'lodash.isempty';

function VideosSection({ videos }) {
    return (
        <div
            id='videos-section'
            style={{ maxWidth: '100vw', overflow: 'hidden' }}
        >
            {!isEmpty(videos) && (
                <div>
                    <div className='section-header-wrapper'>
                        <h1 className='section-header'>Videos</h1>
                    </div>

                    <VideosLayout
                        swipable={true}
                        classes={{
                            rootClass: 'videos-section, slider-div',
                            containerClass: 'slider w-slider slider-relative',
                            sliderClass: 'mask-2 w-slider-mask',
                            itemClass: 'w-slide',
                            videoClass: 'video w-video w-embed',
                        }}
                        videos={videos}
                    />
                </div>
            )}
        </div>
    );
}

export default VideosSection;
