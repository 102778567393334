import React, { useContext, useEffect, useState } from 'react';
import WebBuilderPage from './builder/WebBuilderPage';
import { Helmet } from 'react-helmet';
import CompProvider from './context/CompContext';
import { DarkModeState } from './context/themeHandler';
import creatorApi from './api/creatorApi';
import { CreatorContext } from './context/creatorContext';
import _get from 'lodash.get';
import { useAuth0 } from '@auth0/auth0-react';
import jamfeedClient from './api/jamfeedClient';

const WebBuilder = ({ entityId: propsEntityId }) => {
    const creatorContext = useContext(CreatorContext);
    const [newProps, setNewProps] = useState({});

    const fetchData = async (entityId) => {
        const endpoints = [
            creatorApi.getProfile(entityId),
            creatorApi.getVideos(entityId, 'ARTIST'),
            creatorApi.getMusic(entityId, 'ARTIST'),
            creatorApi.getNews(entityId, 'ARTIST'),
            creatorApi.getIntegrations(entityId),
            creatorApi.getImages(entityId),
            creatorApi.getEvents(entityId, 'ARTIST'),
        ];

        const [profile, videos, music, news, integrations, images, events] =
            await Promise.all(endpoints);

        setNewProps({
            entityId,
            profile,
            videos,
            music,
            news,
            integrations,
            images,
            id: entityId,
            events,
        });
    };

    useEffect(() => {
        const id =
            propsEntityId || _get(creatorContext, 'creator.id', undefined);
        if (!creatorContext.isLoading && id) {
            fetchData(id);
        }
    }, [creatorContext.isLoading, creatorContext.creator, propsEntityId]);

    return (
        <DarkModeState>
            <CompProvider entityId={newProps.entityId} isBuilder={true}>
                <Helmet>
                    <title>Web Builder</title>
                </Helmet>
                <WebBuilderPage {...newProps} />
            </CompProvider>
        </DarkModeState>
    );
};

WebBuilder.getInitialProps = async ({ req }) => {
    const entityId = (req && req.headers['jf-entity-id']) || req.query.id;

    return {
        entityId,
    };
};

export default WebBuilder;
