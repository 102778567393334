import React from "react";
import "./template7.scss";
import HomeSection from "./HomeSection/HomeSection";
import MusicSection from "./MusicSection/MusicSection";
import VideoSection from "./VideoSection/VideoSection";
import TourSection from "./TourSection/TourSection";
import NewsSection from "./NewsSection/NewsSection";
import MerchSection from "./MerchSection/MerchSection";
import AboutSection from "./AboutSection/AboutSection";
import ContactSection from "./ContactSection/ContactSection";
import NavBarSection from "./NavBarSection/NavBarSection";

const Template7 = ({ profile, videos, music, news, integrations }) => {
  return (
    <div className="template7">
      <div className="body">
        <NavBarSection news={news} music={music} videos={videos} integrations={integrations} />
        <HomeSection />
        <MusicSection music={music} />
        <VideoSection videos={videos} />
        <TourSection profile={profile} />
        <NewsSection news={news} />
        <MerchSection />
        <AboutSection />
        <ContactSection />
      </div>
      {/*[if lte IE 9]><![endif]*/}
    </div>
  );
}

export default Template7;
