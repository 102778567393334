import React, { useState, useEffect } from 'react';
import {
    List,
    ListItem,
    Typography,
    Drawer,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    TextField,
    RadioGroup,
    Radio,
    Select,
    Switch,
    Link,
} from '@mui/material';

const DetailedEditorDropdown = ({ defaultValue, value, onFieldChange, creator }) => {
    const [menuOptions, setMenuOptions] = useState([]);
    const [menuValue, setMenuValue] = useState(defaultValue);

    useEffect(() => {
        value.getMenuOptions(creator.id).then((results) => setMenuOptions(results));
    }, []);

    useEffect(() => {
        setMenuValue(defaultValue);
    }, [menuOptions]);

    const onMenuItemChange = (e) => {
        setMenuValue(e.target.value);
        onFieldChange(value.name, e.target.value);
    };

    return (
        <FormControl variant='outlined' style={{ width: '200px' }}>
            <Select
                labelId='demo-simple-select-outlined-label'
                id='demo-simple-select-outlined'
                value={menuValue}
                onChange={onMenuItemChange}
                label={value.desc}
            >
                <MenuItem value=''>
                    <em>None</em>
                </MenuItem>
                {menuOptions.map((mo) => {
                    return <MenuItem value={mo.id}>{mo.name}</MenuItem>;
                })}
            </Select>
        </FormControl>
    );
};

export default DetailedEditorDropdown;
