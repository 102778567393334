import React, { createContext, useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import jamfeedClient from '../api/jamfeedClient';
import { config } from '../config';
import { useAuth0 } from '@auth0/auth0-react';

export const AuthContext = createContext({
    isLoggedIn: false,
    user: null,
    getUser: () => {},
    logout: () => {},
});

const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const {
        getAccessTokenSilently,
        isLoading: isAuthLoading,
        isAuthenticated,
    } = useAuth0();

    const getUser = async () => {
        await jamfeedClient
            .get('/auth/user')
            .then(({ data }) => {
                setUser(data.user);
            })
            .catch((error) => {
                console.log('get user error', {
                    error,
                });
                setUser({});
            });
    };

    const loadIntercom = () => {
        if (window) {
            window.Intercom('boot', {
                app_id: config.intercomAppId,
                user_id: user.id,
                email: user.email,
                phone: user.phone,
                name: user.first_name
                    ? `${user.first_name} ${user.last_name}`
                    : null,
                hide_default_launcher:
                    !window.location.pathname.includes('/w/builder'),
            });
        }
    };

    const gtmIdentifyUser = () => {
        if (window) {
            window.dataLayer.push({
                user_id: user.id,
            });
        }
    };

    const logout = async () => {
        window.location = `/welcome/logout`;
    };

    useEffect(() => {
        if (isEmpty(user)) {
            setIsLoggedIn(false);
        } else {
            setIsLoggedIn(true);
            loadIntercom();
            gtmIdentifyUser();
        }
    }, [user]);

    useEffect(() => {
        if (!isAuthLoading && isAuthenticated) {
            jamfeedClient.setRefreshToken(getAccessTokenSilently);
            jamfeedClient.setupLogoutCallback(logout);
            jamfeedClient.setAuthenticationStatus(isAuthenticated);
            getUser();
        }
    }, [isAuthLoading, isAuthenticated]);

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                user,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
