import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@mui/material';
import SocialIcons from '../../shared/social icons/SocialIcons';

const useStyles = makeStyles(() => ({
    footer: {
        backgroundColor: ({ backgroundColor }) => backgroundColor,
        color: 'white',
        width: '100%',
        paddingTop: '40px',
        paddingBottom: '40px',
        textAlign: 'left',
        float: 'left',
        '@media(max-width:600px)': {
            justifyContent: 'center',
            alignItems: 'center',
        },
        width: '100%',
    },
    container: {
        display: 'inline',
        textAlign: 'left',
        float: 'left',
        paddingLeft: 20,
        '@media(max-width:600px)': {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    footerLogo: {
        backgroundColor: 'transparent',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    image: {
        padding: '5px',
        width: '150px',
        justifyContent: 'center',
        alignItems: 'center',
        '@media(max-width:600px)': {
            paddingLeft: '10px',
            float: 'center',
        },
    },
    footerCopyright: {
        justifyContent: 'flext-start',
        alignItems: 'center',
        color: ({ color }) => color,
        padding: '5px',
        marginBottom: '5px',
        '@media(max-width:600px)': {
            paddingRight: '10px',
            float: 'center',
        },
    },
}));

const V2 = ({ logoUrl, artistName, color, backgroundColor, displaySocialIcons, integrations,monoIcons,iconColor }) => {
    const classes = useStyles({ color, backgroundColor });
    return (
        <footer className={classes.footer} id='footer'>
            <div className={classes.container}>
                <div className={classes.footerLogo}>
                    <a href='#'>
                        <img className={classes.image} src={logoUrl} alt='' />
                    </a>
                </div>
                <Grid container justifyContent='center'>
                    <Grid item xs={12}>
                        <SocialIcons
                            variant={1}
                            integrations={integrations}
                            displaySocialIcons={displaySocialIcons}
                            monoIcons={monoIcons} 
                            iconColor={iconColor}
                            position='flex-start'
                        ></SocialIcons>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.footerCopyright}>
                            © Copyright {artistName} {new Date().getFullYear()}
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </footer>
    );
};

export default V2;

V2.propTypes = {
    logoUrl: PropTypes.string.isRequired,
    artistName: PropTypes.string.isRequired,
};
