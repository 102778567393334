import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography, Box, useTheme } from '@mui/material';
import ShowMore from '@tedconf/react-show-more';
import _get from 'lodash.get';

const TourSection = (props) => {
    const {
        sectionTitle,
        titleColor,
        color,
        backgroundColor,
        buttonColor,
        buttonBackgroundColor,
        events,
    } = props;

    const theme = useTheme();

    return (
        <Box
            sx={{
                padding: '50px',
                maxWidth: '100%',
                backgroundColor: backgroundColor,
                [theme.breakpoints.down('md')]: {
                    padding: '30px 24px',
                },
                [theme.breakpoints.down('sm')]: {
                    padding: '24px 16px',
                },
            }}
        >
            <Grid
                container
                sx={{ maxWidth: '1050px', margin: '0 auto' }}
                id='tour'
            >
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <Typography
                        variant='h1'
                        sx={{
                            color: titleColor,
                            marginBottom: '50px',
                            fontSize: '35px',
                            fontWeight: 'bold',
                            letterSpacing: '1px',
                            [theme.breakpoints.down('sm')]: {
                                marginBottom: '40px',
                            },
                        }}
                    >
                        {sectionTitle}
                    </Typography>
                </Grid>
                {events && events.length === 0 && (
                    <Grid
                        item
                        xs={12}
                        sx={{ justifyContent: 'center', display: 'flex' }}
                    >
                        <Typography sx={{ color: color }}>
                            NO UPCOMING TOUR DATES
                        </Typography>
                    </Grid>
                )}
                {events && (
                    <ShowMore items={events} by={5}>
                        {({ current, onMore }) => (
                            <>
                                {current.map((evt) => {
                                    const eventDate = _get(
                                        evt,
                                        'datetime',
                                        undefined
                                    );
                                    const venueName = _get(
                                        evt,
                                        'venue.name',
                                        ''
                                    );
                                    const venueLocation = _get(
                                        evt,
                                        'venue.location',
                                        ''
                                    );
                                    const tickets = _get(
                                        evt,
                                        'offers',
                                        []
                                    ).find((offer) => offer.type === 'Tickets');
                                    const rsvp = _get(evt, 'url', '');

                                    return (
                                        <Grid
                                            container
                                            spacing={0}
                                            sx={{
                                                paddingBottom: 2,
                                                marginBottom: 2,
                                                borderBottom: `solid 1px ${color}`,
                                            }}
                                        >
                                            <Grid
                                                item
                                                xs={6}
                                                md={8}
                                                sx={{ color: color }}
                                            >
                                                {eventDate && (
                                                    <Typography
                                                        variant='h6'
                                                        sx={{
                                                            fontSize: {
                                                                xs: '0.8rem',
                                                                sm: '1rem',
                                                            },
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        {new Date(
                                                            eventDate
                                                        ).toDateString()}
                                                    </Typography>
                                                )}
                                                {venueName && (
                                                    <Typography
                                                        sx={{
                                                            fontSize: {
                                                                xs: '0.7rem',
                                                                sm: '0.9rem',
                                                            },
                                                        }}
                                                    >
                                                        {venueName}
                                                    </Typography>
                                                )}
                                                {venueLocation && (
                                                    <Typography
                                                        sx={{
                                                            fontSize: {
                                                                xs: '0.7rem',
                                                                sm: '0.9rem',
                                                            },
                                                        }}
                                                    >
                                                        {venueLocation}
                                                    </Typography>
                                                )}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                md={4}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'flex-start',
                                                }}
                                            >
                                                {tickets && (
                                                    <Button
                                                        variant='outlined'
                                                        sx={{
                                                            m: 1,
                                                            color: buttonColor,
                                                            borderRadius: 0,
                                                            border: 0,
                                                            backgroundColor:
                                                                buttonBackgroundColor,
                                                            minWidth: {
                                                                xs: 60,
                                                                md: 85,
                                                            },
                                                            padding: {
                                                                xs: '1px 6px',
                                                                md: 'normal',
                                                            },
                                                            fontSize: {
                                                                xs: 13,
                                                                md: 'normal',
                                                            },
                                                        }}
                                                        href={tickets.url}
                                                        target='_blank'
                                                    >
                                                        Tickets
                                                    </Button>
                                                )}
                                                {rsvp && (
                                                    <Button
                                                        variant='outlined'
                                                        sx={{
                                                            m: 1,
                                                            color: buttonColor,
                                                            borderRadius: 0,
                                                            border: 0,
                                                            backgroundColor:
                                                                buttonBackgroundColor,
                                                            minWidth: {
                                                                xs: 60,
                                                                md: 85,
                                                            },
                                                            padding: {
                                                                xs: '1px 6px',
                                                                md: 'normal',
                                                            },
                                                            fontSize: {
                                                                xs: 13,
                                                                md: 'normal',
                                                            },
                                                        }}
                                                        href={rsvp}
                                                        target='_blank'
                                                    >
                                                        RSVP
                                                    </Button>
                                                )}
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                                {events.length !== current.length && (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ textAlign: 'center' }}
                                    >
                                        <Button
                                            variant='outlined'
                                            sx={{
                                                minWidth: '120px',
                                                m: 1,
                                                color: buttonColor,
                                                borderRadius: 0,
                                                border: 0,
                                                backgroundColor:
                                                    buttonBackgroundColor,
                                            }}
                                            disabled={!onMore}
                                            onClick={() => onMore && onMore()}
                                        >
                                            See More
                                        </Button>
                                    </Grid>
                                )}
                            </>
                        )}
                    </ShowMore>
                )}
            </Grid>
        </Box>
    );
};
const title = 'Tour';
const isDisplayedOnNav = true;

const detailedEditorDefinition = {
    sectionTitle: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'text',
        name: 'sectionTitle',
        label: 'Section Title',
        isRequired: false,
        defaultValue: 'TOUR',
    },
    displayOnNavigation: {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'displayOnNavigation',
        label: 'Display On Navigation',
        isRequired: true,
        defaultValue: true,
    },
    titleColor: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'titleColor',
        label: 'Title Text Color',
        isRequired: false,
        defaultValue: '#000000',
    },
    color: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'color',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#000000',
    },
    backgroundColor: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'backgroundColor',
        label: 'Background Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
    buttonColor: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'buttonColor',
        label: 'Button Text Color',
        isRequired: false,
        defaultValue: '#000000',
    },
    buttonBackgroundColor: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'buttonBackgroundColor',
        label: 'Button Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
};

TourSection.propTypes = {
    events: PropTypes.array.isRequired,
    sectionTitle: detailedEditorDefinition.sectionTitle.propType,
    displayOnNavigation: detailedEditorDefinition.displayOnNavigation.propType,
    titleColor: detailedEditorDefinition.titleColor.propType,
    color: detailedEditorDefinition.color.propType,
    backgroundColor: detailedEditorDefinition.backgroundColor.propType,
    buttonColor: detailedEditorDefinition.buttonColor.propType,
    buttonBackgroundColor:
        detailedEditorDefinition.buttonBackgroundColor.propType,
};

TourSection.defaultProps = {
    sectionTitle: detailedEditorDefinition.sectionTitle.defaultValue,
    displayOnNavigation:
        detailedEditorDefinition.displayOnNavigation.defaultValue,
    titleColor: detailedEditorDefinition.titleColor.defaultValue,
    color: detailedEditorDefinition.color.defaultValue,
    backgroundColor: detailedEditorDefinition.backgroundColor.defaultValue,
    buttonColor: detailedEditorDefinition.buttonColor.defaultValue,
    buttonBackgroundColor:
        detailedEditorDefinition.buttonBackgroundColor.defaultValue,
};

export {
    TourSection as default,
    title,
    isDisplayedOnNav,
    detailedEditorDefinition,
};
