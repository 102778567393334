// import React, { useState } from 'react';
// import makeStyles from '@mui/styles/makeStyles';
// import { ChromePicker } from 'react-color';
// import { Typography } from '@mui/material';
// import clsx from 'clsx';

// const useStyles = makeStyles((theme) => ({
//     container: {
//         display: 'flex',
//     },
//     color: {
//         width: '20px',
//         height: '20px',
//         borderRadius: '2px',
//     },
//     swatch: {
//         padding: '5px',
//         background: '#fff',
//         borderRadius: '1px',
//         boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
//         display: 'inline-block',
//         cursor: 'pointer',
//         height: 30,
//     },
//     popover: {
//         position: 'absolute',
//         zIndex: '2',
//     },
//     bottomPosition:{
//         bottom: '100%',
//     },
//     cover: {
//         position: 'fixed',
//         top: '0px',
//         right: '0px',
//         bottom: '0px',
//         left: '0px',
//     },
//     fieldName: {
//         paddingLeft: 10,
//     },
// }));

// const ColorPicker = ({ colorPicker, value, handleClick, onFieldChange, handleClose, defaultValue, colorPickerPosition }) => {
//     const classes = useStyles();
//     const [color, setColor] = useState(defaultValue);

//     return (
//         <div>
//             <div className={classes.container}>
//                 <div className={classes.swatch}>
//                     <div
//                         className={classes.color}
//                         onClick={(e) => handleClick(value.name)}
//                         style={{ background: `${color}` }}
//                     />
//                 </div>
//                 <Typography onClick={(e) => handleClick(value.name)} className={classes.fieldName}>
//                     {value.label}
//                 </Typography>
//             </div>
//             {colorPicker === value.name ? (
//                 <div className={clsx(classes.popover, classes[colorPickerPosition] )}>
//                     <div className={classes.cover} onClick={handleClose} />
//                     <ChromePicker
//                         color={defaultValue}
//                         onChange={(newColorValue) => {
//                             const newValue = typeof newColorValue === 'object' ? `${newColorValue.hex}` : newColorValue;
//                             onFieldChange(value.name, newValue);
//                             setColor(newValue);
//                         }}
//                     />
//                 </div>
//             ) : null}
//         </div>
//     );
// };

// export default ColorPicker;
import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { ChromePicker } from 'react-color';
import { Typography } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
    },
    color: {
        width: '20px',
        height: '20px',
        borderRadius: '2px',
    },
    swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
        height: 30,
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    bottomPosition: {
        bottom: '100%',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
    fieldName: {
        paddingLeft: 10,
    },
}));

const ColorPicker = ({
    colorPicker,
    value,
    handleClick,
    onFieldChange,
    handleClose,
    defaultValue,
    colorPickerPosition,
}) => {
    const classes = useStyles();
    const [color, setColor] = useState(defaultValue);
    const popoverRef = useRef(null); // Create a ref for the popover

    const handleDocumentClick = (e) => {
        // Close the color picker if the click is outside the popover
        if (popoverRef.current && !popoverRef.current.contains(e.target)) {
            handleClose();
        }
    };

    useEffect(() => {
        // Add listener when the component mounts
        document.addEventListener('mousedown', handleDocumentClick);

        // Remove listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);

    return (
        <div>
            <div className={classes.container}>
                <div className={classes.swatch}>
                    <div
                        className={classes.color}
                        onClick={(e) => handleClick(value.name)}
                        style={{ background: `${color}` }}
                    />
                </div>
                <Typography
                    onClick={(e) => handleClick(value.name)}
                    className={classes.fieldName}
                >
                    {value.label}
                </Typography>
            </div>
            {colorPicker === value.name && (
                <div
                    className={clsx(
                        classes.popover,
                        classes[colorPickerPosition]
                    )}
                    ref={popoverRef}
                >
                    <ChromePicker
                        color={defaultValue}
                        onChange={(newColorValue) => {
                            const newValue =
                                typeof newColorValue === 'object'
                                    ? `${newColorValue.hex}`
                                    : newColorValue;
                            onFieldChange(value.name, newValue);
                            setColor(newValue);
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default ColorPicker;
