import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { Alert } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: ({ backgroundColor }) => backgroundColor,
        display: 'inline-block',
        textAlign: 'center',
        width: '100%',
        paddingTop: 20,
        paddingBottom: 20,
    },
    block: {
        textAlign: 'center',
    },
    sectionHeader: {
        color: ({ color }) => color,
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        fontSize: '35px',
        fontWeight: 'bold',
    },
    subtitle: {
        color: ({ color }) => color,
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        fontSize: '15px',
        whiteSpace: 'pre-wrap',
        letterSpacing: '0.5px',
    },
    form: {
        padding: '10px 0.8rem 20px 0.8rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: '#FFF',
        textAlign: 'center',
        alignItems: 'center',
        width: '100%',
        maxWidth: '300px',
        margin: '0 auto',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            maxWidth: '600px',
        },
    },
    input: {
        display: 'block',
        boxSizing: 'border-box',
        borderRadius: '5px',
        height: '45px',
        color: '#000',
        backgroundColor: '#eef3f8',
        padding: '12px 1rem',
        margin: '10px 0',
        width: '100%',
        border: '1px solid #ccc',
        outline: 'none',
        [theme.breakpoints.up('md')]: {
            width: 'calc(75% - 20px)',
            margin: '10px 10px',
        },
    },
    button: {
        display: 'block',
        boxSizing: 'border-box',
        borderRadius: '5px',
        height: '45px',
        padding: '12px 1rem',
        margin: '10px 0',
        width: '100%',
        border: 'none',
        outline: 'none',
        fontSize: '1rem',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        cursor: 'pointer',
        color: ({ buttonColor }) => buttonColor || '#FFF',
        backgroundColor: ({ buttonBackgroundColor }) =>
            buttonBackgroundColor || '#696969',
        '&:hover': {
            backgroundColor: ({ hoverBackgroundColor }) =>
                hoverBackgroundColor || '#FFFFFF',
            color: ({ hoverTextColor }) => hoverTextColor || '#000000',
            borderBottom: ({ hoverTextColor }) =>
                `1px solid ${hoverTextColor || '#000000'}`,
        },
        [theme.breakpoints.up('md')]: {
            width: 'calc(25% - 20px)',
            margin: '10px 10px',
        },
    },
}));

const Template2 = ({
    color,
    backgroundColor,
    buttonBackgroundColor,
    buttonColor,
    sectionTitle,
    subtitle,
    onSubmit,
}) => {
    const classes = useStyles({
        color,
        backgroundColor,
        buttonColor,
        buttonBackgroundColor,
    });
    const [subscriber, setSubscriber] = useState({
        email: undefined,
    });
    const [error, setError] = useState(undefined);
    const [success, setSuccess] = useState(undefined);

    const onChange = (e) => {
        const subscriberCopy = {
            ...subscriber,
            [e.target.id]: e.target.value,
        };
        setSubscriber(subscriberCopy);
    };

    const subscribeUser = async () => {
        setSuccess(undefined);
        setError(undefined);
        try {
            const { email, firstName, lastName } = subscriber;
            const response = await onSubmit(email, firstName, lastName);
            setSuccess(
                `Congrats ${response.email}, you've successfully subscribed!`
            );
            setSubscriber({
                email: '',
            });
        } catch (e) {
            setError(e.message);
        }
    };

    return (
        <div className={classes.root} id='email'>
            <div className={classes.block}>
                {error && <Alert severity='error'>{error}</Alert>}
                {success && <Alert severity='success'>{success}</Alert>}
                <Typography variant='h1' className={classes.sectionHeader}>
                    {sectionTitle}
                </Typography>
                <Typography className={classes.subtitle}>{subtitle}</Typography>
            </div>
            <form
                className={classes.form}
                onSubmit={async (e) => {
                    e.preventDefault();
                    await subscribeUser();
                }}
            >
                <input
                    className={classes.input}
                    placeholder='Email Address'
                    type='email'
                    id='email'
                    value={subscriber.email}
                    onChange={onChange}
                />
                <button className={classes.button} type='submit'>
                    <Typography>Subscribe</Typography>
                </button>
            </form>
        </div>
    );
};

export default Template2;
