import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import {
    AppBar as MuiAppBar,
    CssBaseline,
    Toolbar,
    Typography,
    Box,
    Avatar,
    useTheme,
    useMediaQuery,
    styled,
} from '@mui/material';
import SectionsLinkMapping from './SectionsLinkMapping';
import MenuIconComponent from './MenuIconComponent';
import ListComponent from './ListComponent';
import { useNavbarConfig } from './useNavbarConfig';
import { getNetwork } from '../../../utils/network';
import isEmpty from 'lodash.isempty';
import UrlLinkMapping from './UrlLinkMapping';
import { CompContext } from '../../../context/CompContext';
import MenuFactory from './MenuFactory';
import useDynamicFontSize from '../hooks/useDynamicFontSize';
import { HEADERS_SECTION_KEY } from '../../../sections/header';
import _get from 'lodash.get';

/**
 * Processes static navigation items to determine their rendering and URLs.
 * @param {Array} integrated - Integrated networks array.
 * @param {string} name - Name of the network.
 * @param {string} readableName - Readable name for the navigation item.
 * @returns {Object} Object with rendering info and URL.
 */
const processStaticNavItem = (integrated, name, readableName) => {
    const networkObj = getNetwork(integrated, name);

    const shouldRender = !isEmpty(networkObj) && !isEmpty(networkObj.data.url);
    const url = shouldRender ? networkObj.data.url : null;

    return {
        shouldRender,
        url,
        readableName,
    };
};

/**
 * Generates dynamic navigation options based on integrated networks.
 * @param {Object} integrated - Integrated networks object.
 * @returns {Array} An array of navigation links.
 */
const generateDynamicOptions = ({ integrated }) => {
    const navItems = [
        { network: 'SHOPIFY', readableName: 'Merch' },
        { network: 'PATREON', readableName: 'Subscribe' },
        { network: 'EPK', readableName: 'EPK' },
        { network: 'BOOKING', readableName: 'Booking' },
    ];

    const navLinks = navItems
        .map(({ network, readableName }) => {
            const { shouldRender, url } = processStaticNavItem(
                integrated,
                network,
                readableName
            );
            return shouldRender ? { name: network, url, readableName } : null;
        })
        .filter(Boolean);

    return navLinks;
};

const AppBar = styled(MuiAppBar)(({ theme, sx }) => ({
    backgroundColor: 'transparent',
    ...sx,
}));

// Constants
const DEFAULT_TEXT_TRANSFORM = 'uppercase';
const DEFAULT_FONT_WEIGHT = 'bold';
const DEFAULT_FONT_SIZE = '1.6rem';

/**
 * Function to extract color property from the component context.
 * @param {Object} compContext - Component context object.
 * @param {string} [sectionKey=HEADERS_SECTION_KEY] - Section key for color extraction.
 * @returns {string|null} Extracted color value or null.
 */
export function extractColor(compContext, sectionKey = HEADERS_SECTION_KEY) {
    try {
        const path = `templateConfiguration.componentList[${sectionKey}].userDefinedProps.color`;
        const color = _get(compContext, path, null); // Defaults to null if the path doesn't exist
        return typeof color === 'string' ? color : null;
    } catch (error) {
        console.error('Error extracting color:', error);
        return null;
    }
}

const Navbar = ({
    sections,
    toggleDrawer,
    menuState,
    title,
    logo,
    vertical = false,
    showName = true,
    textTransform,
    fontWeight,
    fontSize,
    color,
    textColor,
    specialFontSize,
    styleDetails = {},
    showMenuIcon = false,
    integrated,
    custom,
    customStyle,
    sx,
    ...props
}) => {
    const theme = useTheme();
    const compContext = useContext(CompContext);
    const dynamicOptionsRef = useRef(null);
    const [navLinks, setNavLinks] = useState([]);
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        dynamicOptionsRef.current = generateDynamicOptions({ integrated });
        setNavLinks(dynamicOptionsRef.current);
    }, [integrated]);

    const baseTextStyle = {
        textAlign: 'center',
        color: color || (styleDetails && styleDetails.textColor) || '#FFFF',
        fontFamily: compContext.font || 'initial',
        fontWeight: fontWeight || 'normal',
        fontSize: fontSize || 'initial',
        '&:hover': {
            color: color || (styleDetails && styleDetails.textColor) || '#FFFF',
        },
        '&:visited': {
            color: color || (styleDetails && styleDetails.textColor) || '#FFFF',
        },
        margin: theme.spacing(2),
    };

    const linkStyles = {
        ...baseTextStyle,
        textTransform: textTransform || 'uppercase', // Uppercase transform for links
        textDecoration: 'none', // No underline
        '&:hover': {
            ...baseTextStyle['&:hover'],
            textDecoration: 'none', // No underline on hover
        },
    };

    const navStyles = {
        navContent: {
            display: 'flex',
            flexDirection: vertical ? 'column' : 'row nowrap',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: compContext.font || 'initial',
            ...customStyle,
        },
        navText: {
            ...baseTextStyle,
            textTransform: textTransform || 'none', // No text transform for nav text
            '&:hover': {
                ...baseTextStyle['&:hover'],
                textDecoration: 'underline', // Underline on hover for nav text
            },
            textAlign: 'center',
        },
    };

    const hasMenu = isMobile && showMenuIcon;

    const handleScrollToSection = (targetId) => {
        // Close the drawer first (if it's open)
        if (menuState.left) {
            toggleDrawer('left', false);
        }

        setTimeout(() => {
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300); // Adjust delay as needed
    };

    return (
        <AppBar position='static' elevation={0} sx={sx}>
            <CssBaseline />
            <Toolbar
                style={vertical ? { height: '100%' } : {}}
                sx={{
                    ...navStyles.navContent
                }}
            >
                {logo ? (
                    <Avatar src={logo} alt={title} />
                ) : showName ? (
                    <Typography variant='h6' sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                ) : (
                    <div />
                )}
                <Box sx={navStyles.navContent}>
                    {hasMenu ? (
                        <MenuFactory
                            custom={custom && custom.component && custom}
                            toggleDrawer={toggleDrawer}
                            state={menuState}
                            styleDetails={styleDetails}
                            ListComponent={(anchor) => {
                                return (
                                    <ListComponent
                                        handleScrollToSection={
                                            handleScrollToSection
                                        }
                                        anchor={anchor}
                                        sections={sections}
                                        navLinks={navLinks}
                                        toggleDrawer={toggleDrawer}
                                        styleDetails={
                                            custom &&
                                            custom.listComponentStyle &&
                                            custom.listComponentStyle
                                                ? custom.listComponentStyle ||
                                                  {}
                                                : styleDetails
                                        }
                                    />
                                );
                            }}
                        />
                    ) : (
                        <>
                            <SectionsLinkMapping
                                sections={sections}
                                sx={linkStyles}
                                onLinkClick={handleScrollToSection}
                            />
                            <UrlLinkMapping
                                navLinks={navLinks}
                                sx={linkStyles}
                            />
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
