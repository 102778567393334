import React from "react";

function SubscribeFormSection() {
  return (
    <section id="subscribe-form" className="subscribe-form">
      <div className="centered-container w-container">
        <div className="subscribe-wrapper">
          <div className="div-block-5">
            <h2 className="heading">JOIN MY SQUAD</h2>
            <p className="paragraph-3">
              Enter your email and be the first to know about new music.
            </p>
          </div>
          <div>
            <div className="w-form">
              <form
                id="email-form"
                name="email-form"
                data-name="Email Form"
                className="subscribe-form-flex"
              >
                <div className="subscribe-form-input-wrapper">
                  <label
                    htmlFor="Subscriber-Email"
                    id="subscribe-email"
                    className="field-label"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    className="subscribe-form-input w-input"
                    maxLength={256}
                    name="Subscriber-Email"
                    data-name="Subscriber Email"
                    id="Subscriber-Email"
                    required
                  />
                </div>
                <input
                  type="submit"
                  defaultValue="Subscribe"
                  data-wait="Please wait..."
                  className="submit-button w-button"
                />
              </form>
              <div className="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div className="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SubscribeFormSection;
