import React from 'react';
import PropTypes from 'prop-types';
import Horizontal from './Horizontal';
import Vertical from './Vertical';
import integrated from './Data';

const SocialIcons = (props) => {
    const { variant, integrations, displaySocialIcons, position, setSocialIcon, monoIcons, iconColor } = props;
    switch (variant) {
        case 1:
            return (
                <Horizontal
                    integrated={integrations.integrated}
                    displaySocialIcons={displaySocialIcons}
                    position={position}
                    setSocialIcon={setSocialIcon}
                    monoIcons={monoIcons}
                    iconColor={iconColor}
                />
            );
        case 2:
            return (
                <Vertical
                    integrated={integrations.integrated}
                    displaySocialIcons={displaySocialIcons}
                    monoIcons={monoIcons}
                    iconColor={iconColor}
                />
            );
        default:
            return (
                <Horizontal
                    integrated={integrations.integrated}
                    displaySocialIcons={displaySocialIcons}
                    monoIcons={monoIcons}
                    iconColor={iconColor}
                />
            );
    }
};

SocialIcons.propTypes = {
    variant: PropTypes.number.isRequired,
    integrations: PropTypes.shape({
        integrated: PropTypes.array,
    }).isRequired,
};

SocialIcons.defaultProps = {
    variant: 1,
    integrations: {
        integrated: [],
    },
};

export default SocialIcons;
