import React, { useContext } from 'react';
import { Box, Button, Drawer, Grid, styled } from '@mui/material';
import { CompContext } from '../../../context/CompContext';
import useDynamicFontSize from '../hooks/useDynamicFontSize';

export const CardiBMenuStyle = (props = {}) => ({
    listStyle: {
        padding: 0,
        margin: 0,
        justifyContent: 'center',
    },
});

const StyledGrid = styled(Grid)(({ theme, sx }) => ({
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    color: 'white',
    textTransform: 'uppercase',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: 0,
    margin: 0,
    top: 0,
    ...sx,
    lineHeight: '1',
}));

const StyledButtonBox = styled(Box)(({ theme, sx }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 5,
    color: sx.color,
    ...sx,
}));

const CardiBMenu = ({
    title,
    textColor,
    toggleDrawer,
    state,
    ListComponent,
    sx,
}) => {
    const compContext = useContext(CompContext);
    const font = compContext.font || 'initial';

    return (
        <StyledGrid sx={sx}>
            <StyledButtonBox sx={{ color: textColor }}>
                <h1
                    style={{
                        padding: 0,
                        margin: 0,
                        fontWeight: 'bold',
                        fontFamily: font,
                        maxWidth: '100%',
                        overflow: 'hidden',
                    }}
                >
                    {title}
                </h1>
                <Button
                    variant='text'
                    sx={{
                        color: textColor,
                        backgroundColor: 'transparent', // Ensure transparent background
                        // marginTop: '-0.6rem',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                        fontFamily: font,
                    }}
                    // ref={menuRef}
                    onClick={toggleDrawer('left', true)}
                >
                    menu
                </Button>
            </StyledButtonBox>
            <Drawer
                anchor={'left'}
                open={state.left}
                onClose={toggleDrawer('left', false)}
                sx={{
                    backgroundColor: 'transparent',
                    width: '100vw',
                    height: '100vh',
                    ...sx,
                }}
            >
                {ListComponent('left')}
            </Drawer>
        </StyledGrid>
    );
};

export default CardiBMenu;
