import * as React from 'react';

const SvgBandsintown = (props) => {
    return (
        <svg
            width='24px'
            height='20px'
            viewBox='0 0 20 24'
            role='img'
            xmlns='http://www.w3.org/2000/svg'
            {...props}
        >
            <title>Bandsintown icon</title>
            <g>
                <path d='M18.783 0H24v12h-5.217V0zm-6.261 5h5.217v7h-5.217V5zM6.26 5h5.217v7H6.261V5zM24 24H0V0h5.217v19h13.566v-1H6.26v-5H24v11Z' />
            </g>
        </svg>
    );
};

export default SvgBandsintown;
