import React from 'react';

function HomeSection({ appData, profile }) {
    return (
        <div id='landing-section' className='landing-section'>
            <div className='hero-wrapper'>
                <img
                    src={profile.pictureurl}
                    className='image'
                    style={{
                        width: '100vw',
                        maxHeight: '200vh',
                        objectFit: 'contain',
                        paddingTop: '80px',
                    }}
                    loading='lazy'
                />
            </div>
        </div>
    );
}

export default HomeSection;
