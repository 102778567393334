import React, { useContext, useEffect, useState } from 'react';
import { CompContext } from '../context/CompContext';
import { sectionList } from '../sections';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import _get from 'lodash.get';
import isEmpty from 'lodash.isempty';

/**
 * Create a theme based on the given font.
 * @param {string} font - Font to set in the theme
 * @returns {object} A theme object
 */
const createFontTheme = (font) => {
    return createTheme({
        typography: {
            fontFamily: font,
        },
    });
    return createTheme({
        typography: {
            fontFamily: font,
        },
    });
};

/**
 * Get the component-specific props.
 * @param {object} compDetails - Details about the component from the section list
 * @param {object} compConfig - Configuration of the component
 * @param {object} additionalProps - Additional props to pass to the component
 * @param {boolean} isNavigation - Whether the component is a navigation component
 * @returns {object} Props to pass to the component
 */
const getComponentProps = (
    compDetails,
    compConfig,
    additionalProps,
    isNavigation
) => {
    const defaultProps = _get(compDetails, 'component.defaultProps', {});
    const userDefinedProps = _get(compConfig, 'userDefinedProps', {});
    const newProps = Object.assign(
        defaultProps,
        userDefinedProps,
        additionalProps
    );

    if (isNavigation) {
        return { ...newProps, ...additionalProps };
    }

    return newProps;
};

/**
 * Render a single component based on its configuration.
 * @param {object} compConfig - Component configuration
 * @param {boolean} openOptions - Whether options are open or not
 * @param {object} props - Additional props
 * @returns {React.Element|null} The rendered component or null
 */
const renderComponent = (compConfig, openOptions, props) => {
    if (isEmpty(compConfig)) {
        return null;
    }

    const compDetails = sectionList[compConfig.componentName];
    if (isEmpty(compDetails) || !compConfig.isVisible) {
        return null;
    }

    const isNavigation = compDetails.key === 'navigation';
    const Component = compDetails.component;
    const componentProps = getComponentProps(
        compDetails,
        compConfig,
        props,
        isNavigation
    );

    return (
        <Component
            {...componentProps}
            key={compConfig.componentName}
            openOptions={isNavigation ? openOptions : undefined}
            id={props.entityId}
        />
    );
};

/**
 * Main orchestrator for rendering templates.
 * @param {object} props - Props for the component
 * @param {boolean} props.openOptions - Whether options are open or not
 * @returns {React.Element} The rendered template orchestrator component
 */
const TemplateOrchestrator = ({ openOptions, ...props }) => {
    const compContext = useContext(CompContext);
    const [fontTheme, setFontTheme] = useState(
        createFontTheme(compContext.font)
    );
    const [selectedComponents, setSelectedComponents] = useState([]);

    /**
     * Update selected components when template configuration changes.
     */
    useEffect(() => {
        setSelectedComponents(compContext.getComponentList());
    }, [compContext.templateConfiguration]);

    /**
     * Update font theme when font changes.
     */
    useEffect(() => {
        setFontTheme(createFontTheme(compContext.font));
    }, [compContext.font]);

    return (
        <ThemeProvider theme={fontTheme}>
            <>
                {selectedComponents.map((compConfig, idx) =>
                    renderComponent(compConfig, openOptions, {
                        ...props,
                    })
                )}
            </>
        </ThemeProvider>
    );
};

export default TemplateOrchestrator;
