import React from 'react'
import isEmpty from 'lodash.isempty';

function NewsSection({ news }) {
  return (
    <div id="news-section" className="news-section">
      {!isEmpty(news) && (
        <div className="news-wrapper">
          <div className="news-section-header">
            <h1 className="section-header">News</h1>
          </div>
          <div className="news-collection">
            <a
              href="https://edm.com/music-releases/griz-solo-feat-probcause"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%20Scruby%20News%204%20Unsigned.png"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 80vw, (max-width: 991px) 39vw, 40vw"
                    srcSet="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%2520Scruby%2520News%25204%2520Unsigned-p-500.png 500w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%2520Scruby%2520News%25204%2520Unsigned-p-800.png 800w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%2520Scruby%2520News%25204%2520Unsigned-p-1080.png 1080w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%20Scruby%20News%204%20Unsigned.png 1478w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    Cal Scruby's "Unsigned" Is A Captivating Introduction
                  </h4>
                </div>
                <div className="news-article-paragraph">
                  <p className="paragraph-2">
                    Cal Scruby name is beginning to buzz, a flashback to the days in
                    which “Unsigned Hype” carried special significance. Since
                    departing his...
                  </p>
                </div>
                <div className="news-date-wrapper">
                  <div className="news-article-date">HotNewHipHop</div>
                  <div className="news-article-date">May 31, 2020</div>
                </div>
              </div>
            </a>
            <a
              href="https://edm.com/music-releases/griz-solo-feat-probcause"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%20Scruby%20News%204%20Unsigned.png"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 80vw, (max-width: 991px) 39vw, 40vw"
                    srcSet="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%2520Scruby%2520News%25204%2520Unsigned-p-500.png 500w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%2520Scruby%2520News%25204%2520Unsigned-p-800.png 800w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%2520Scruby%2520News%25204%2520Unsigned-p-1080.png 1080w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%20Scruby%20News%204%20Unsigned.png 1478w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    Cal Scruby's "Unsigned" Is A Captivating Introduction
                  </h4>
                </div>
                <div className="news-article-paragraph">
                  <p className="paragraph-2">
                    Cal Scruby name is beginning to buzz, a flashback to the days in
                    which “Unsigned Hype” carried special significance. Since
                    departing his...
                  </p>
                </div>
                <div className="news-date-wrapper">
                  <div className="news-article-date">HotNewHipHop</div>
                  <div className="news-article-date">May 31, 2020</div>
                </div>
              </div>
            </a>
            <a
              href="https://edm.com/music-releases/griz-solo-feat-probcause"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%20Scruby%20News%204%20Unsigned.png"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 80vw, (max-width: 991px) 39vw, 40vw"
                    srcSet="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%2520Scruby%2520News%25204%2520Unsigned-p-500.png 500w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%2520Scruby%2520News%25204%2520Unsigned-p-800.png 800w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%2520Scruby%2520News%25204%2520Unsigned-p-1080.png 1080w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%20Scruby%20News%204%20Unsigned.png 1478w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    Cal Scruby's "Unsigned" Is A Captivating Introduction
                  </h4>
                </div>
                <div className="news-article-paragraph">
                  <p className="paragraph-2">
                    Cal Scruby name is beginning to buzz, a flashback to the days in
                    which “Unsigned Hype” carried special significance. Since
                    departing his...
                  </p>
                </div>
                <div className="news-date-wrapper">
                  <div className="news-article-date">HotNewHipHop</div>
                  <div className="news-article-date">May 31, 2020</div>
                </div>
              </div>
            </a>
            <a
              href="https://edm.com/music-releases/griz-solo-feat-probcause"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%20Scruby%20News%204%20Unsigned.png"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 80vw, (max-width: 991px) 39vw, 40vw"
                    srcSet="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%2520Scruby%2520News%25204%2520Unsigned-p-500.png 500w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%2520Scruby%2520News%25204%2520Unsigned-p-800.png 800w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%2520Scruby%2520News%25204%2520Unsigned-p-1080.png 1080w, https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0be46252b64e109caa7b0_Cal%20Scruby%20News%204%20Unsigned.png 1478w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    Cal Scruby's "Unsigned" Is A Captivating Introduction
                  </h4>
                </div>
                <div className="news-article-paragraph">
                  <p className="paragraph-2">
                    Cal Scruby name is beginning to buzz, a flashback to the days in
                    which “Unsigned Hype” carried special significance. Since
                    departing his...
                  </p>
                </div>
                <div className="news-date-wrapper">
                  <div className="news-article-date">HotNewHipHop</div>
                  <div className="news-article-date">May 31, 2020</div>
                </div>
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default NewsSection
