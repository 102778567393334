import React from 'react';
import PropTypes from 'prop-types';
import ContactGrid from './ContactGrid';

const Contact = (props) => {
    const { layout, color, backgroundColor, textColor, sectionTitle, profile, link } = props;

    switch (layout) {
        case 'One Col':
            return (
                <ContactGrid
                    cols={1}
                    color={color}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    headerText={sectionTitle}
                    contacts={profile.publicContacts}
                />
            );
        case 'Two Cols':
            return (
                <ContactGrid
                    cols={2}
                    color={color}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    headerText={sectionTitle}
                    contacts={profile.publicContacts}
                />
            );
        case 'Three Cols':
            return (
                <ContactGrid
                    cols={3}
                    color={color}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    headerText={sectionTitle}
                    contacts={profile.publicContacts}
                />
            );
        default:
            return (
                <ContactGrid
                    cols={1}
                    color={color}
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    headerText={sectionTitle}
                    contacts={profile.publicContacts}
                />
            );
    }
};

const title = 'Contact';
const isDisplayedOnNav = true;
const detailedEditorDefinition = {
    sectionTitle: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'text',
        name: 'sectionTitle',
        label: 'Section Title',
        isRequired: false,
        defaultValue: 'Contact',
    },
    layout: {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'select',
        inputOptions: ['One Col', 'Two Cols', 'Three Cols'],
        name: 'layout',
        label: 'Select Design',
        isRequired: true,
        defaultValue: 'One Col',
    },
    displayOnNavigation: {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'displayOnNavigation',
        label: 'Display On Navigation',
        isRequired: true,
        defaultValue: true,
    },
    color: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'color',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#000000',
    },
    backgroundColor: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'backgroundColor',
        label: 'Background Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
    textColor: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'textColor',
        label: 'Content Color',
        isRequired: false,
        defaultValue: '#000000',
    },
    link: {
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'link',
        name: 'link',
        label: 'Update Contact Info',
        urlPage: 'profile',
        conditionParam: '',
        isRequired: false,
    },
};

Contact.propTypes = {
    layout: detailedEditorDefinition.layout.propType,
    displayOnNavigation: detailedEditorDefinition.displayOnNavigation.propType,
    sectionTitle: detailedEditorDefinition.sectionTitle.propType,
    color: detailedEditorDefinition.color.propType,
    backgroundColor: detailedEditorDefinition.backgroundColor.propType,
    textColor: detailedEditorDefinition.textColor.propType,
    profile: PropTypes.shape({
        publicContacts: PropTypes.array,
    }).isRequired,
};

Contact.defaultProps = {
    layout: detailedEditorDefinition.layout.defaultValue,
    displayOnNavigation: detailedEditorDefinition.displayOnNavigation.defaultValue,
    sectionTitle: detailedEditorDefinition.sectionTitle.defaultValue,
    color: detailedEditorDefinition.color.defaultValue,
    backgroundColor: detailedEditorDefinition.backgroundColor.defaultValue,
    textColor: detailedEditorDefinition.textColor.defaultValue,
    profile: {
        publicContacts: [],
    },
};

export { Contact as default, title, isDisplayedOnNav, detailedEditorDefinition };
