import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import * as Vibrant from 'node-vibrant';
import { openUrl } from '../../utils/action';
import DOMPurify from 'dompurify';
import { decode } from 'html-entities';

const useStyles = makeStyles(() => ({
    actionArea: ({ roundedCorners }) => ({
        ...(roundedCorners ? { borderRadius: 16 } : { borderRadius: 0 }),
        transition: '0.2s',
        '&:hover': {
            cursor: 'pointer',
            transform: 'scale(1.1)',
            zIndex: 100,
        },
    }),
    card: ({ color, roundedCorners, backgroundColor }) => ({
        minWidth: 256,
        background: backgroundColor,
        ...(roundedCorners ? { borderRadius: 16 } : { borderRadius: 0 }),
        boxShadow: 'none',
        '&:hover': {
            boxShadow: `0 6px 12px 0 ${Color(color)
                .rotate(-12)
                .darken(0.2)
                .fade(0.5)}`,
        },
    }),
    content: ({ color }) => ({
        backgroundColor: color,
        padding: '1rem 1.5rem 1.5rem',
    }),
    title: ({ textColor }) => ({
        fontSize: '2rem',
        color: textColor,
        textTransform: 'uppercase',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        overflow: 'hidden',
        height: '4.3rem',
        display: '-webkit-box',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
    }),
    subtitle: ({ textColor }) => ({
        color: textColor,
        opacity: 0.87,
        marginTop: '2rem',
        fontWeight: 500,
        fontSize: 14,
    }),
    useFourThreeMediaStyles: {
        width: '100%',
        height: 0,
        paddingBottom: '75%',
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
}));

const Card2 = ({
    title,
    subtitle,
    media,
    url,
    textColor,
    backgroundColor,
    roundedCorners,
}) => {
    const classes = useStyles({
        color: overlayColor,
        textColor,
        backgroundColor,
        roundedCorners,
    });
    // const mediaStyles = useFourThreeCardMediaStyles();
    const [overlayColor, setOverlayColor] = useState(undefined);
    const [lightVibrant, setLightVibrant] = useState(undefined);
    const [darkVibrant, setDarkVibrant] = useState(undefined);
    // (async () => {
    //     Vibrant.from(media)
    //         .getPalette()
    //         .then((palette) => {
    //             const vibrantHex = palette.Vibrant.getHex();
    //             const darkVibrant = palette.DarkVibrant.getHex();
    //             const lightVibrant = palette.LightVibrant.getHex();
    //             setOverlayColor(vibrantHex);
    //             setLightVibrant(lightVibrant);
    //             setDarkVibrant(darkVibrant);
    //         })
    //         .catch((e) => {
    //             console.log(`Error getting color palette for ${media}`, e.message);
    //         });
    // })();
    let cleanTitle = DOMPurify.sanitize(title, { SAFE_FOR_TEMPLATES: true });
    return (
        <CardActionArea
            className={classes.actionArea}
            onClick={() => openUrl(url)}
        >
            <Card className={classes.card}>
                {/* <CardMedia classes={mediaStyles} image={media} /> */}
                <CardMedia
                    className={classes.useFourThreeMediaStyles}
                    image={media}
                />
                <CardContent
                    className={classes.content}
                    style={{
                        background: `linear-gradient(240deg, ${lightVibrant} 0%, ${darkVibrant} 100%)`,
                    }}
                >
                    <Typography className={classes.title} variant={'h2'}>
                        {decode(cleanTitle)}
                    </Typography>
                    <Typography className={classes.subtitle}>
                        {subtitle}
                    </Typography>
                </CardContent>
            </Card>
        </CardActionArea>
    );
};

const title = 'Card Option 2';
const detailedEditorDefinition = {
    'card.textColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.textColor',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
    'card.backgroundColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.backgroundColor',
        label: 'Background Color',
        isRequired: false,
        defaultValue: '#00000',
    },
};

Card2.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    media: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    textColor: detailedEditorDefinition['card.textColor'].propType,
    backgroundColor: detailedEditorDefinition['card.backgroundColor'].propType,
};

export { Card2 as default, title, detailedEditorDefinition };
