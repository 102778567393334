import React from "react";
import isEmpty from 'lodash.isempty';

function MusicSection({ music }) {
  return (
    <div id="music-section" className="music-section">
      {!isEmpty(music) && (
        <div>
          <div className="music-section-wrapper">
            <div className="music-header-wrapper">
              <h1 className="section-header">Music</h1>
            </div>
            <div className="music-collection">
              <div className="music-item-wrapper">
                <div className="music-item-image">
                  <img
                    src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f60c8f20b8a8e62a19_Cal%20Scruby%20WWWS%20Album.jpg"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 84vw, (max-width: 991px) 39vw, 28vw"
                    srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f60c8f20b8a8e62a19_Cal%20Scruby%20WWWS%20Album-p-500.jpeg 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f60c8f20b8a8e62a19_Cal%20Scruby%20WWWS%20Album-p-800.jpeg 800w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f60c8f20b8a8e62a19_Cal%20Scruby%20WWWS%20Album-p-1080.jpeg 1080w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f60c8f20b8a8e62a19_Cal%20Scruby%20WWWS%20Album.jpg 1200w"
                    alt
                    className="music-image"
                  />
                </div>
                <div className="music-item-title">
                  <h5 className="music-title-header">While You Were Sleeping</h5>
                </div>
                <div className="music-item-buttons">
                  <div className="streaming-button-div">
                    <a href="#" className="streaming-button w-button">
                      Stream Music{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="music-item-wrapper">
                <div className="music-item-image">
                  <img
                    src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f6a16348a1c221516c_Cal%20Scruby%20Unsigned%20Album.jpg"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 84vw, (max-width: 991px) 39vw, 28vw"
                    srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f6a16348a1c221516c_Cal%20Scruby%20Unsigned%20Album-p-500.jpeg 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f6a16348a1c221516c_Cal%20Scruby%20Unsigned%20Album-p-800.jpeg 800w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f6a16348a1c221516c_Cal%20Scruby%20Unsigned%20Album-p-1080.jpeg 1080w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f6a16348a1c221516c_Cal%20Scruby%20Unsigned%20Album.jpg 1200w"
                    alt
                    className="music-image"
                  />
                </div>
                <div className="music-item-title">
                  <h5 className="music-title-header">Unsigned</h5>
                </div>
                <div className="music-item-buttons">
                  <div className="streaming-button-div">
                    <a href="#" className="streaming-button w-button">
                      Stream Music{" "}
                    </a>
                  </div>
                </div>
              </div>
              <div className="music-item-wrapper">
                <div className="music-item-image">
                  <img
                    src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f68212946dbe7f99f8_Cal%20Scruby%20HITH%20Album.jpg"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 84vw, (max-width: 991px) 39vw, 28vw"
                    srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f68212946dbe7f99f8_Cal%20Scruby%20HITH%20Album-p-500.jpeg 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f68212946dbe7f99f8_Cal%20Scruby%20HITH%20Album-p-800.jpeg 800w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f68212946dbe7f99f8_Cal%20Scruby%20HITH%20Album-p-1080.jpeg 1080w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f68212946dbe7f99f8_Cal%20Scruby%20HITH%20Album.jpg 1200w"
                    alt
                    className="music-image"
                  />
                </div>
                <div className="music-item-title">
                  <h5 className="music-title-header">House in the Hills</h5>
                </div>
                <div className="music-item-buttons">
                  <div className="streaming-button-div">
                    <a href="#" className="streaming-button w-button">
                      Stream Music{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="soundcloud-section-wrapper">
            <div className="soundcloud-header-wrapper-copy">
              <h1 className="section-header">DJ Mixes</h1>
            </div>
            <div className="w-layout-grid soundcloud-grid">
              <div className="soundcloud-item-wrapper">
                <div className="w-embed w-iframe">
                  <iframe
                    width="100%"
                    height={300}
                    scrolling="no"
                    frameBorder="no"
                    allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/281150257&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                  />
                  <div
                    style={{
                      fontSize: 10,
                      color: "#cccccc",
                      lineBreak: "anywhere",
                      wordBreak: "normal",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontFamily:
                        "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                      fontWeight: 100,
                    }}
                  >
                    <a
                      href="https://soundcloud.com/calscruby"
                      title="cal scruby"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      cal scruby
                    </a>{" "}
                    ·{" "}
                    <a
                      href="https://soundcloud.com/calscruby/geturight"
                      title="Get U Right"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      Get U Right
                    </a>
                  </div>
                </div>
              </div>
              <div className="soundcloud-item-wrapper">
                <div className="w-embed w-iframe">
                  <iframe
                    width="100%"
                    height={300}
                    scrolling="no"
                    frameBorder="no"
                    allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/174299821&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                  />
                  <div
                    style={{
                      fontSize: 10,
                      color: "#cccccc",
                      lineBreak: "anywhere",
                      wordBreak: "normal",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontFamily:
                        "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                      fontWeight: 100,
                    }}
                  >
                    <a
                      href="https://soundcloud.com/calscruby"
                      title="cal scruby"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      cal scruby
                    </a>{" "}
                    ·{" "}
                    <a
                      href="https://soundcloud.com/calscruby/fakeworld"
                      title="Fake World"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      Fake World
                    </a>
                  </div>
                </div>
              </div>
              <div className="soundcloud-item-wrapper">
                <div className="w-embed w-iframe">
                  <iframe
                    width="100%"
                    height={300}
                    scrolling="no"
                    frameBorder="no"
                    allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/245165333&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                  />
                  <div
                    style={{
                      fontSize: 10,
                      color: "#cccccc",
                      lineBreak: "anywhere",
                      wordBreak: "normal",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontFamily:
                        "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                      fontWeight: 100,
                    }}
                  >
                    <a
                      href="https://soundcloud.com/calscruby"
                      title="cal scruby"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      cal scruby
                    </a>{" "}
                    ·{" "}
                    <a
                      href="https://soundcloud.com/calscruby/rightnow"
                      title="Right Now"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      Right Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="soundcloud-item-wrapper">
                <div className="w-embed w-iframe">
                  <iframe
                    width="100%"
                    height={300}
                    scrolling="no"
                    frameBorder="no"
                    allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/132115579&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                  />
                  <div
                    style={{
                      fontSize: 10,
                      color: "#cccccc",
                      lineBreak: "anywhere",
                      wordBreak: "normal",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontFamily:
                        "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                      fontWeight: 100,
                    }}
                  >
                    <a
                      href="https://soundcloud.com/calscruby"
                      title="cal scruby"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      cal scruby
                    </a>{" "}
                    ·{" "}
                    <a
                      href="https://soundcloud.com/calscruby/goldcoins"
                      title="Gold Coins"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      Gold Coins
                    </a>
                  </div>
                </div>
              </div>
              <div className="soundcloud-item-wrapper">
                <div className="w-embed w-iframe">
                  <iframe
                    width="100%"
                    height={300}
                    scrolling="no"
                    frameBorder="no"
                    allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/346787540&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                  />
                  <div
                    style={{
                      fontSize: 10,
                      color: "#cccccc",
                      lineBreak: "anywhere",
                      wordBreak: "normal",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontFamily:
                        "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                      fontWeight: 100,
                    }}
                  >
                    <a
                      href="https://soundcloud.com/calscruby"
                      title="cal scruby"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      cal scruby
                    </a>{" "}
                    ·{" "}
                    <a
                      href="https://soundcloud.com/calscruby/madatme"
                      title="mad @ me"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      mad @ me
                    </a>
                  </div>
                </div>
              </div>
              <div className="soundcloud-item-wrapper">
                <div className="w-embed w-iframe">
                  <iframe
                    width="100%"
                    height={300}
                    scrolling="no"
                    frameBorder="no"
                    allow="autoplay"
                    src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/343452800&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                  />
                  <div
                    style={{
                      fontSize: 10,
                      color: "#cccccc",
                      lineBreak: "anywhere",
                      wordBreak: "normal",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      fontFamily:
                        "Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif",
                      fontWeight: 100,
                    }}
                  >
                    <a
                      href="https://soundcloud.com/calscruby"
                      title="cal scruby"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      cal scruby
                    </a>{" "}
                    ·{" "}
                    <a
                      href="https://soundcloud.com/calscruby/future"
                      title="future"
                      target="_blank"
                      style={{ color: "#cccccc", textDecoration: "none" }}
                    >
                      future
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MusicSection;
