import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import Color from 'color';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import * as Vibrant from 'node-vibrant';
import { openUrl } from '../../utils/action';
import DOMPurify from 'dompurify';
import { decode } from 'html-entities';

const useStyles = makeStyles(({ palette, breakpoints }) => ({
    color: ({ color }) => ({
        '&:before': {
            backgroundColor: Color(color)
                .darken(0.3)
                .desaturate(0.2)
                .toString(),
        },
    }),
    root: ({ textColor, roundedCorners }) => ({
        position: 'relative',
        ...(roundedCorners ? { borderRadius: '1rem' } : {}),
        // minWidth: 320,
        '&:before': {
            transition: '0.2s',
            position: 'absolute',
            width: '100%',
            height: '100%',
            content: '""',
            display: 'block',
            ...(roundedCorners ? { borderRadius: '1rem' } : {}),
            zIndex: 0,
            bottom: 0,
        },
        '&:hover': {
            cursor: 'pointer',
            '&:before': {
                bottom: -6,
            },
            '& $logo': {
                transform: 'scale(1.1)',
                boxShadow: '0 6px 20px 0 rgba(0,0,0,0.38)',
            },
        },
        '& > div': {
            color: textColor,
        },
    }),
    cover: ({ roundedCorners }) => ({
        ...(roundedCorners ? { borderRadius: '1rem' } : {}),
    }),
    content: ({ color, roundedCorners, backgroundColor }) => ({
        position: 'relative',
        zIndex: 1,
        ...(roundedCorners ? { borderRadius: '1rem' } : {}),
        boxShadow: `0 6px 16px 0 ${Color(color).fade(0.5)}`,
        '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 0,
            width: 'calc(100% + 1px)',
            height: 'calc(100% + 1px)',
            clipPath:
                'polygon(0% 100%, 0% 35%, 0.3% 33%, 1% 31%, 1.5% 30%, 2% 29%, 2.5% 28.4%, 3% 27.9%, 3.3% 27.6%, 5% 27%,95% 0%,100% 0%, 100% 100%)',
            ...(roundedCorners ? { borderRadius: '1rem' } : {}),
            background: `linear-gradient(to top, ${backgroundColor}, ${Color(
                backgroundColor
            )
                .rotate(24)
                .lighten(0.12)})`,
        },
    }),
    title: {
        fontSize: '1.1rem',
        margin: 0,
        padding: '5px',
        [breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    logo: ({ roundedCorners }) => ({
        transition: '0.3s',
        width: 100,
        height: 100,
        boxShadow: '0 4px 12px 0 rgba(0,0,0,0.24)',
        ...(roundedCorners
            ? { borderRadius: '1rem' }
            : { borderRadius: '0rem' }),
        padding: '8px',
    }),
    subtitle: ({ textColor }) => ({
        fontSize: '0.75rem',
        color: Color(textColor).lighten(0.3).toString(),
    }),
    note1: ({ color }) => ({
        backgroundColor: Color(color).darken(0.3).toString(),
        opacity: 0.72,
        fontSize: '0.75rem',
        padding: '0 0.5rem',
        borderRadius: 12,
    }),
    mediaStyles: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.08)', //bgColor,
        backgroundPosition: 'center', //bgPosition
    },
}));

/**
 * Primary UI component for user interaction
 */
const Card1 = ({
    title,
    subtitle,
    note1,
    media,
    url,
    textColor,
    backgroundColor,
    roundedCorners = false,
}) => {
    const [overlayColor, setOverlayColor] = useState('#000000');
    const styles = useStyles({
        textColor,
        backgroundColor,
        roundedCorners,
        ...(overlayColor ? { color: overlayColor } : {}),
    });
    // (async () => {
    //     Vibrant.from(media)
    //         .getPalette()
    //         .then((palette) => {
    //             const vibrantHex = palette.Vibrant.getHex();
    //             setOverlayColor(vibrantHex);
    //         })
    //         .catch((e) => {
    //             console.log(`Error getting color palette for ${media}`, e.message);
    //         });
    // })();
    let cleanTitle = DOMPurify.sanitize(title, { SAFE_FOR_TEMPLATES: true });
    return (
        <Box
            className={cx(styles.root, styles.color)}
            pt={20}
            onClick={() => openUrl(url)}
        >
            <CardMedia
                image={media}
                className={cx(styles.cover, styles.mediaStyles)}
            />
            <Box className={styles.content} p={1}>
                <Box position={'relative'} zIndex={1}>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'end',
                            }}
                        >
                            <Avatar className={styles.logo} src={media} />
                            <h2 className={styles.title}>
                                {decode(cleanTitle)}
                            </h2>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 4 }}>
                        <Grid item xs={6}>
                            <div className={styles.subtitle}>{subtitle}</div>
                        </Grid>
                        <Grid item xs={6} sx={{ textAlign: 'right' }}>
                            <div className={styles.note1}>{note1}</div>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

const title = 'Card Option 1';
const detailedEditorDefinition = {
    'card.textColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.textColor',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
    'card.backgroundColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.backgroundColor',
        label: 'Background Color',
        isRequired: false,
        defaultValue: '#00000',
    },
    'card.roundedCorners': {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'card.roundedCorners',
        label: 'Rounded Corners',
        description: 'Creates rounded corners on each card',
        isRequired: false,
    },
};

Card1.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    note1: PropTypes.string.isRequired,
    media: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    textColor: detailedEditorDefinition['card.textColor'].propType,
    backgroundColor: detailedEditorDefinition['card.backgroundColor'].propType,
    roundedCorners: detailedEditorDefinition['card.roundedCorners'].propType,
};

export { Card1 as default, title, detailedEditorDefinition };
