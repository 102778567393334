import React from 'react';
import VideosLayout from '../../../shared/videos/VideosLayout';
import isEmpty from 'lodash.isempty';

function VideoSection({ videos }) {
    return (
        <div id='video-section' className='videos-section'>
            {!isEmpty(videos) && (
                <div className='videos-section-wrapper'>
                    <div className='videos-section-header'>
                        <h2 className='section-header'>Videos</h2>
                    </div>
                    <VideosLayout
                        swipable={false}
                        layout='grid'
                        outerClass='videos-collection'
                        videoClass='videos-item-wrapper'
                        videos={videos}
                    />
                </div>
            )}
        </div>
    );
}

export default VideoSection;
