import React from 'react';

function TourSection({ theme, profile }) {
    return (
        <div id='tour-section' className='tour-section'>
            <div className='tour-section-wrapper'>
                <div className='section-header-wrapper'>
                    <h1
                        className='section-header'
                        style={{ color: theme.textColor }}
                    >
                        Tour
                    </h1>
                </div>
                <div className='bandsintown-widget-embed w-embed w-script'>
                    <a
                        className='bit-widget-initializer'
                        data-artist-name={profile.name}
                        data-display-local-dates='false'
                        data-display-past-dates='false'
                        data-auto-style='false'
                        data-text-color={theme.textColor}
                        data-link-color='#00b4b3'
                        data-background-color='rgba(0,0,0,0)'
                        data-display-limit={15}
                        data-display-start-time='false'
                        data-link-text-color='#FFFFFF'
                        data-display-lineup='false'
                        data-display-play-my-city='true'
                        data-separator-color='rgba(124,124,124,0.25)'
                    />
                </div>
            </div>
        </div>
    );
}

export default TourSection;
