import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ShowMoreLess from './ShowMoreLess';

const useStyles = makeStyles(() => ({
    container: {
        backgroundColor: 'black',
        paddingTop: '30px',
        paddingBottom: '30px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        '@media(max-width:600px)': {
            display: 'block',
        },
        width: '100%',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
        paddingRight: '40px',
        '@media(max-width:600px)': {
            width: '100%',
        },
    },
    header: {
        color: 'white',
        float: 'left',
        width: '100%',
        marginTop: '37px',
        marginBottom: '0px',
        marginLeft: '0',
        margin: '25px',
        // textTransform: 'uppercase',
        fontSize: '35px',
        fontWeight: 'bold',
        '@media(max-width:600px)': {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            fontSize: '35px',
        },
    },
    paragraph: {
        width: '100%',
        marginRight: '50px',
        display: 'flex',
        flexDirection: 'column',
        '@media(max-width:600px)': {
            flexDirection: 'initial',
            margin: 'auto',
            paddingLeft: '30px',
        },
    },
    text: {
        width: '100%',
        color: '#e0e0e0',
        marginBottom: '10px',
        marginRight: '60px',
        lineHeight: '28px',
        textOverflow: 'ellipsis',
        letterSpacing: '0.8px',
        fontSize: '16px',
        fontWeight: 'lighter',
        paddingRight: '20px',
    },
    image: {
        width: '50%',
        padding: '15px',
        height: '50%',
        top: '20px',
        display: 'flex',
        borderRadius: '5px',
        marginTop: '15px',
        border: 'none',
        marginLeft: '25px',
        marginRight: '50px',
        paddingTop: '30px',
        transition: 'all 200ms',
        '@media(max-width:600px)': {
            display: 'block',
            flexDirection: 'column',
            top: '100%',
            width: '70vw',
            height: '100%',
            margin: 'auto',
            transform: 'scale(1)',
        },
    },
}));

const Template4 = ({ color, backgroundColor, headerText, bodyText, imageUrl }) => {
    const classes = useStyles();
    return (
        <div id='about' className={classes.container} style={{ backgroundColor: `${backgroundColor}` }}>
            <img className={classes.image} src={imageUrl} alt='' />
            <div className={classes.section}>
                <Typography variant='h1' className={classes.header} style={{ color: `${color}` }}>
                    {headerText}
                </Typography>
                <div className={classes.paragraph}>
                    <Typography className={classes.text} style={{ color: `${color}` }}>
                        <ShowMoreLess bodyText={bodyText} />
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default Template4;

Template4.propTypes = {
    headerText: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
};
