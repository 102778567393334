import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Box, CircularProgress, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
    div:{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    header: {
        textAlign: 'center',
        padding: '20px 10px 16px 10px',
        fontWeight: 'bold',
    },
    spinnerCenter: {
        padding: '20px 10px 16px 10px',
    },
}));
const LoadingComponent = ({ title = 'Loading...', hideTitle = false }) => {
    const classes = useStyles();

    return (
        <Box className={classes.div}>
            {!hideTitle && (
                <>
                    <Typography variant='h5' className={classes.header}>
                        {title}
                    </Typography>
                </>
            )}
            <CircularProgress color='primary' />
        </Box>
    );
};

export default LoadingComponent;
