import React from 'react';
import NoContent from '../../../shared/NoContent';
import VideosLayout from '../../../shared/videos/VideosLayout';
import isEmpty from 'lodash.isempty';

function Videos({ videos }) {
    return (
        <>
            <div id='videos-section' className='videos-section'>
                {!isEmpty(videos) && (
                    <div>
                        <div className='music-section-header-wrapper'>
                            <h1 className='section-header'>Videos</h1>
                        </div>
                        <NoContent
                            data={videos}
                            style={{
                                color: 'white',
                                opacity: 0.8,
                                textAlign: 'center',
                                margin: 20,
                            }}
                        />
                        <VideosLayout
                            swipable={true}
                            classes={{
                                rootClass: 'videos-section, slider-div',
                                containerClass: 'slider w-slider slider-relative',
                                sliderClass: 'mask-2 w-slider-mask',
                                itemClass: 'w-slide',
                                videoClass: 'video w-video w-embed',
                            }}
                            videos={videos}
                        />
                    </div>
                )}
            </div>
        </>
    );
}

export default Videos;
