import MenuIcon from '@mui/icons-material/Menu';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import MobileNav1 from './MobileNav1';
import MobileNav2 from './MobileNav2';
import isEmpty from 'lodash.isempty';

function NavBar(props) {
    const [isOpen, setOpen] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 935px)' });

    const styles = {
        link: {
            color: props.textColor || 'black',
            textDecoration: 'none',
            margin: 10,
            fontSize: 18,
        },
    };
    const renderMobileNavBar = () => {
        switch (props.mobileNavType) {
            case 'minimal':
                return <MobileNav1 isOpen={isOpen} {...props} />;
            case 'drawer':
                return <MobileNav2 isOpen={isOpen} {...props} />;
            default:
                return <MobileNav1 isOpen={isOpen} {...props} />;
        }
    };
    return (
        <div
            style={{
                position: 'fixed',
                left: '0%',
                top: '0%',
                right: '0%',
                bottom: 'auto',
                zIndex: 2000,
            }}
        >
            <div
                style={{
                    left: '0%',
                    top: '0%',
                    right: '0%',
                    bottom: 'auto',
                    display: 'flex',
                    background: 'white',
                }}
            >
                {isMobile && (
                    <div>
                        {props.profile.logo && (
                            <a href='#'>
                                <img src={props.profile.logo} alt width={40} style={{ margin: '10px' }} />
                            </a>
                        )}
                    </div>
                )}
                <div
                    style={{
                        flex: 1,
                        alignItems: 'center',
                        paddingTop: 10,
                        paddingBottom: 10,
                        backgroundColor: props.backgroundColor || 'white',
                    }}
                >
                    {!isMobile ? (
                        <nav
                            role='navigation'
                            style={{
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                        >
                            {props.routes.map((item) => {
                                if (!isEmpty(item)) {
                                    if (item.href.startsWith('#')) {
                                        return (
                                            <a
                                                style={styles.link}
                                                onClick={() => {
                                                    window.document
                                                        .getElementById(item.href.replace('#', ''))
                                                        .scrollIntoView();
                                                }}
                                            >
                                                {item.title}
                                            </a>
                                        );
                                    }
                                    return (
                                        <a href={item.href} style={styles.link} target={item.target || ''}>
                                            {item.title}
                                        </a>
                                    );
                                }
                            })}
                        </nav>
                    ) : (
                        <div>
                            <div onClick={() => setOpen(!isOpen)} style={{ float: 'right' }}>
                                <MenuIcon
                                    style={{
                                        fontSize: 30,
                                        marginRight: 10,
                                        color: props.textColor || 'black',
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {renderMobileNavBar()}
        </div>
    );
}

export default NavBar;
