import React from 'react';
import NewsList from '../../../shared/news/NewsList';
import isEmpty from 'lodash.isempty';

function NewsSection({ news }) {
    return (
        <div id='news-section' className='news-section'>
            {!isEmpty(news) && (
                <div className='news-section-wrapper'>
                    <div className='section-header-wrapper'>
                        <h1 className='section-header'>News</h1>
                        <NewsList
                            news={news}
                            outerClass={'news-cell-grid'}
                            renderItem={({ media, title, source, url }) => {
                                return (
                                    <a href={url} target='_blank' style={{ textDecoration: 'inherit', color: 'inherit' }}>
                                        <div className='news-cell-wrapper'>
                                            <div className='news-image-div'>
                                                <img
                                                    src={media}
                                                    width={450}
                                                    sizes='(max-width: 479px) 100vw, (max-width: 991px) 80vw, 40vw'
                                                    alt
                                                    className='news-image'
                                                />
                                            </div>
                                            <div className='news-info-wrapper'>
                                                <h3 className='news-article-header'>{title}</h3>
                                                <h6 className='news-article-source'>{source}</h6>
                                            </div>
                                        </div>
                                    </a>
                                );
                            }}
                            theme={{
                                textColor: 'black',
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default NewsSection;
