import { useEffect, useRef } from 'react';

const useDynamicFontSize = (minFontSize = 12, maxFontSize = 36) => {
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    if (!element) return;

    const resizeFont = () => {
      let style = window.getComputedStyle(element, null).getPropertyValue('font-size');
      let currentSize = parseFloat(style);
      currentSize = Math.max(minFontSize, currentSize); // Ensure starting size isn't below min

      while (
        (element.scrollHeight > element.offsetHeight ||
        element.scrollWidth > element.offsetWidth) &&
        currentSize > minFontSize
      ) {
        currentSize -= 0.5;
        element.style.fontSize = `${currentSize}px`;
      }

      element.style.fontSize = `${Math.min(currentSize, maxFontSize)}px`; // Ensure final size isn't above max
    };

    resizeFont();
  }, [minFontSize, maxFontSize]);

  return ref;
};

export default useDynamicFontSize;
