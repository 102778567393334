import React from 'react'
import isEmpty from 'lodash.isempty';
import { getNetwork } from '../../../../utils/network';

function NavBarSection({ news, music, videos, integrations }) {
  const patreonNetwork = getNetwork(integrations.integrated, 'PATREON');

  return (
    <div
      data-collapse="medium"
      data-animation="over-right"
      data-duration={400}
      data-doc-height={1}
      role="banner"
      className="navbar-3 w-nav"
    >
      <div className="div-block-7">
        <div className="div-block-6">
          <div className="div-block-9">
            <a href="#" className="band-logo w-nav-brand">
              <img
                src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0ba6b1270e873d934e816_Cal%20Scruby%20Logo%20White.png"
                alt
                className="image-7"
              />
            </a>
            <nav role="navigation" className="nav-menu-items w-nav-menu">
              <a href="#" className="nav-link-3 w-nav-link">
                Home
              </a>
              {!isEmpty(music) && (
                <a href="#" className="nav-link-3 w-nav-link">
                  Music
                </a>
              )}
              {!isEmpty(videos) && (

                <a href="#" className="nav-link-3 w-nav-link">
                  Videos
                </a>
              )}
              <a href="#" className="nav-link-3 w-nav-link">
                Tour
              </a>
              {!isEmpty(news) && (
                <a href="#" className="nav-link-3 w-nav-link">
                  News
                </a>
              )}
              <a href="#" className="nav-link-3 w-nav-link">
                Merch
              </a>
              <a href="#" className="nav-link-3 w-nav-link">
                About
              </a>
              <a href="#" className="nav-link-3 w-nav-link">
                Contact
              </a>
              {!isEmpty(patreonNetwork) && patreonNetwork.data.url && (
                <a
                  href={patreonNetwork.data.url}
                  target='_blank'
                  className='nav-link-3 w-nav-link'
                >
                  Subscribe
                </a>
              )}
            </nav>
          </div>
          <div className="div-block-8">
            <div className="social-wrapper">
              <div className="profile-link">
                <a
                  href="https://open.spotify.com/artist/2wcrc3fjebDRLVdtRUa3pu"
                  className="profile-image w-inline-block"
                >
                  <img
                    src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0c15155ea6c2cc8997d9a_Spotify%20White.png"
                    width={30}
                    height={30}
                    alt
                    className="profile-icon"
                  />
                </a>
              </div>
              <div className="profile-link">
                <a
                  href="https://itunes.apple.com/us/artist/cal-scruby/670791732"
                  className="profile-image w-inline-block"
                >
                  <img
                    src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0c15180440a04ba9e03b0_Apple%20White.png"
                    width={30}
                    height={30}
                    alt
                    className="profile-icon"
                  />
                </a>
              </div>
              <div className="profile-link">
                <a
                  href="http://youtube.com/calvinscruby"
                  className="profile-image w-inline-block"
                >
                  <img
                    src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0c151b7492323f6f4baa9_FB%20White.png"
                    width={30}
                    height={30}
                    alt
                    className="profile-icon"
                  />
                </a>
              </div>
              <div className="profile-link">
                <a
                  href="http://soundcloud.com/calscruby"
                  className="profile-image w-inline-block"
                >
                  <img
                    src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0c151a5b8fd30ca392c5e_Soundcloud%20White.png"
                    width={30}
                    height={30}
                    alt
                    className="profile-icon"
                  />
                </a>
              </div>
              <div className="profile-link">
                <a
                  href="http://instagram.com/calscruby"
                  className="profile-image w-inline-block"
                >
                  <img
                    src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0c1515fda979420535407_Instagram%20White.png"
                    width={30}
                    height={30}
                    alt
                    className="profile-icon"
                  />
                </a>
              </div>
              <div className="profile-link">
                <a
                  href="https://twitter.com/calscruby"
                  className="profile-image w-inline-block"
                >
                  <img
                    src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0c15155ea6c47df997d99_Twitter%20White.png"
                    width={30}
                    height={30}
                    alt
                    className="profile-icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="w-nav-button">
            <div className="w-icon-nav-menu" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavBarSection
