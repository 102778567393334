import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Masonry from '@mui/lab/Masonry';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles(() => ({
    gridWrapper: {
        position: 'relative',
    },
    footer: {
        width: '100%',
        position: 'absolute',
        bottom: 0,
        zIndex: 100,
        borderRadius: 0,
        color: '#000000',
        '&:hover': {
            backgroundColor: 'transparent',
            '& $footerIcon': {
                transform: 'scale(1.5)',
            },
        },
    },
    footerIcon: {
        transition: '0.2s',
        fontSize: '64px',
    },
}));

const MasonryLayout = ({ items, renderItem, columns, numItemsToRender, spacing = 0, showMore = false }) => {
    const classes = useStyles();
    // set the breakpoints for each cell
    const [colSpans, setColSpans] = useState({
        xs: 12,
    });
    // total available page size
    const [pageSize, setPageSize] = useState(numItemsToRender);
    // holds all rendered grid items
    const [gridItems, setGridItems] = useState([]);
    // keep track of the current loaded page
    const [currentPage, setCurrentPage] = useState(1);
    // should show more button be available
    const [shouldShowMore, setShouldShowMore] = useState(showMore);

    useEffect(() => {
        setColSpans({
            xs: 1,
            sm: 1,
            md: columns,
            lg: columns,
        });
        setPageSize(numItemsToRender);
        setCurrentPage(1);
    }, [columns, numItemsToRender, renderItem]);

    useEffect(() => {
        buildGrid(currentPage);
    }, [currentPage, colSpans, pageSize]);

    useEffect(() => {
        if (showMore && currentPage * pageSize < items.length) {
            setShouldShowMore(true);
        }
        if (showMore === false) {
            setShouldShowMore(false);
        }
    }, [showMore]);

    const buildGridItem = (index) => {
        if (index >= items.length) {
            return <></>;
        }

        const item = items[index];
        if (typeof renderItem === 'function') {
            return <div key={index}>{renderItem(item)}</div>;
        }
        return (
            <div key={index}>
                <renderItem {...item} />
            </div>
        );
    };

    const buildGrid = (page) => {
        const pageAddition = (page - 1) * pageSize;
        const builtGrid = [];
        for (let r = 0; r < pageSize; r++) {
            builtGrid.push(buildGridItem(r + pageAddition));
        }
        const prepend = page === 1 ? [] : gridItems;
        setGridItems([...prepend, ...builtGrid]);
    };

    return (
        <div className={classes.gridWrapper}>
            <Masonry spacing={spacing} columns={colSpans} sx={{ margin: '0px' }}>
                {gridItems.map((gi) => gi)}
            </Masonry>
            {shouldShowMore && currentPage * pageSize < items.length && (
                <IconButton className={classes.footer} onClick={() => setCurrentPage(currentPage + 1)} size='large'>
                    <ExpandMoreIcon className={classes.footerIcon} />
                </IconButton>
            )}
        </div>
    );
};

const detailedEditorDefinition = {
    'layout.columns': {
        propType: PropTypes.number.isRequired,
        valueType: 'number',
        inputMethod: 'slider',
        step: 1,
        min: 1,
        max: 4,
        name: 'layout.columns',
        label: 'Columns',
        description: 'The number of columns to display in the grid.',
        isRequired: true,
        defaultValue: 2,
    },
    'layout.numItemsToRender': {
        propType: PropTypes.number.isRequired,
        valueType: 'number',
        inputMethod: 'slider',
        step: 1,
        min: 1,
        max: 10,
        name: 'layout.numItemsToRender',
        label: 'Items To Display',
        description: 'The number of items to dipaly on initial load.',
        isRequired: true,
        defaultValue: 2,
    },
    'layout.spacing': {
        propType: PropTypes.number.isRequired,
        valueType: 'number',
        inputMethod: 'slider',
        step: 1,
        min: 0,
        max: 10,
        name: 'layout.spacing',
        label: 'Spacing',
        description: 'The amount of spacing between the grid items.',
        isRequired: true,
        defaultValue: 0,
    },
    'layout.showMore': {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'layout.showMore',
        label: 'Can Viewer Load More?',
        description: 'Do you want the viewer to be able to load more items?',
        isRequired: true,
        defaultValue: false,
    },
};

MasonryLayout.propTypes = {
    items: PropTypes.arrayOf(Object).isRequired,
    renderItem: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]).isRequired,
    columns: PropTypes.oneOf([1, 2, 3, 4]),
    numItemsToRender: PropTypes.number,
    spacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]),
    showMore: PropTypes.bool,
};

MasonryLayout.defaultProps = {
    items: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    renderItem: (item) => {
        return <div>{item}</div>;
    },
    columns: 3,
    numItemsToRender: 6,
    spacing: 0,
    showMore: false,
};

export { MasonryLayout as default, detailedEditorDefinition };
