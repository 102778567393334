import React from 'react';
import PropTypes from 'prop-types';
import DetailedEditor from '../../shared/editor/DetailedEditor';
import { getAdvanceSettings } from './getDetailedEditorDefinition';
import { HEADERS_SECTION_KEY } from './constants';

function HeaderDetailedEditor(layoutKey) {
    const detailedEditorDefinition = getAdvanceSettings(layoutKey);

    return (
        <DetailedEditor
            detailedEditorDefinition={detailedEditorDefinition}
            componentViewKey={HEADERS_SECTION_KEY}
        />
    );
}

HeaderDetailedEditor.propTypes = {
    layoutKey: !PropTypes.string.isRequired,
};

export default HeaderDetailedEditor;
