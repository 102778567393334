import header1 from './header1_DojaCat.png';
import header2 from './header2_TaylorSwift.png';
import header3 from './header3_Drake.png';
import header4 from './header4_EdSheeran.png';
import header5 from './header5_JustinBieber.png';
import header6 from './header6_MilleyCyrus.png';
import header7 from './header7_Eminem.png';
import header8 from './header8_SelenaGomez.png';

export default {
    header1,
    header2,
    header3,
    header4,
    header5,
    header6,
    header7,
    header8,
};
