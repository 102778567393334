import React from 'react';
import ResponsiveLink from '../../../shared/ResponsiveLink';

function ContactSection({ profile }) {
    return (
        <footer id='contact-footer-section' className='contact-section'>
            <div className='contact-section-wrapper w-container'>
                <div>
                    {/* <a href='#' className='contact-logo w-inline-block'>
                        <img
                            src={profile.logo}
                            sizes='(max-width: 479px) 48vw, (max-width: 767px) 100vw, 115.3125px'
                            alt
                            className='footer-image'
                        />
                    </a> */}
                    <div
                        className='contact-text-wrapper'
                        style={{
                            paddingLeft: 50,
                            paddingRight: 50,
                            paddingBottom: 50,
                        }}
                    >
                        {profile.publicContacts.map((c) => {
                            return (
                                <div className='management-text'>
                                    <h2 className='contact-header'>
                                        {c.title}
                                    </h2>
                                    <ResponsiveLink
                                        className='contact-info-text'
                                        textStyle={{ color: 'white' }}
                                        linkText={c.email}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div
                    style={{
                        width: '100%',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    className='contact-copyright-text'
                >
                    Copyright © {(new Date().getFullYear())} {profile.name}
                </div>
            </div>
        </footer>
    );
}

export default ContactSection;
