import {
    InputMethods,
    createField,
} from '../../utils/detailedEditorDefinition';
import { AVAILABLE_LAYOUTS_NAMES } from './constants';
import { PropTypes } from 'prop-types';

export const commonEditorDefinitions = {
    layout: createField(
        PropTypes.string.isRequired,
        InputMethods.STRING,
        InputMethods.SELECT,
        {
            inputOptions: AVAILABLE_LAYOUTS_NAMES,
            name: 'layout',
            label: 'Select Design',
            isRequired: true,
            defaultValue: 'left',
        }
    ),
    color: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.COLOR,
        {
            name: 'color',
            label: 'Text Color',
            isRequired: false,
            defaultValue: '#FFFFFF',
        }
    ),
        displaySocialIcons: createField(
        PropTypes.bool.isRequired,
        InputMethods.BOOLEAN,
        InputMethods.SWITCH,
        {
            name: 'displaySocialIcons',
            label: 'Display Social Icons',
            isRequired: false,
            defaultValue: false,
        }
    ),
};
