import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import _get from 'lodash.get';
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { CompContext } from '../../context/CompContext';
import Horizontal from '../../shared/social icons/Horizontal';
import decodeHtml from '../../utils/decodeHtml';
import { getHeaderComponent, headersList } from './headerList';
import { componentListToSections } from '../../shared/headers/util';
import { generatePropTypesAndDefaults } from '../../utils/detailedEditorDefinition';
import getSourceInformation from './getSourceInformation';
import { getAdvanceSettings } from './getDetailedEditorDefinition';
import { isBuilderURL } from '../../utils/isBuilderUrl';
import useDynamicFontSize from '../../shared/headers/hooks/useDynamicFontSize';
import { getLayoutFromName } from './constants';

const useStyles = makeStyles((theme) => ({
    headerContainer: (props) => {
        return {
            display: 'flex',
            fontFamily: `${props.font}`,
            backgroundColor: props.backgroundColor || '#000000',
            '& > *': {
                color: props.textColor,
                fontFamily: `${props.font}`,
            },
            maxHeight: '100vh',
            height: '100vh',
            maxHeight: isBuilderURL() ? 'calc(100vh - 63px - 4.5rem)' : '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
        };
    },
    callToActionButton: {
        color: ({ buttonTextColor }) => buttonTextColor,
        backgroundColor: ({ buttonBackgroundColor }) => buttonBackgroundColor,
    },
    wrapperContainer: {
        maxWidth: 1220,
        margin: '0',
        padding: '0 2rem',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            padding: '0 0.8rem',
        },
    },
}));

/*
 * 1. Layout = left | right | center | full
 * 2. Text Color
 * 3. Background Color
 * 4. Button Text Color
 * 5. Button BG Color
 * 6. Title
 * 7. Subtitle
 * 8. Button Text
 * 9. Button Link
 * 10. Media
 *
 */
const HeaderSection = ({
    layout,
    source,
    link = 'https://www.jamfeed.com',
    color: textColor,
    backgroundColor,
    btnColor,
    btnBg: buttonBackgroundColor,
    title,
    subtitle,
    button: buttonText,
    buttonLocation,
    mediaOverride,
    music,
    news,
    images,
    videos,
    events,
    profile,
    integrations: { integrated = false },
    displaySocialIcons,
    displayActionButton
}) => {
    const [useSource, setUseSource] = useState(source);
    const [displayImage, setDisplayImage] = useState(false);
    const matches = useMediaQuery('(min-width:600px)');
    const compContext = useContext(CompContext);
    const classes = useStyles({
        textColor,
        backgroundColor,
        buttonTextColor: btnColor,
        buttonBackgroundColor,
        font: compContext.font,
    });

    const titleRef = useDynamicFontSize();
    const subtitleRef = useDynamicFontSize();
    const buttonRef = useDynamicFontSize();

    useEffect(() => {
        setDisplayImage(false);
        setUseSource(source);
    }, [source]);

    // props for layouts, this has to be updated to any expected new prop on the headers if added through proptypes and create field!
    let propsForTemplates = {
        source,
        link,
        color: textColor,
        backgroundColor,
        btnColor: btnColor,
        btnBg: buttonBackgroundColor,
        button: buttonText,
        buttonLocation,
        mediaOverride,
        // enableParallax,
        music,
        news,
        images,
        videos,
        events,
        profile,
        integrations: { integrated },
        displaySocialIcons,
        displayActionButton
    };

    const getLayout = () => {
        const data = getSourceInformation({
            music,
            videos,
            images,
            news,
            events,
            profile,
            useSource,
        });

        propsForTemplates = {
            ...propsForTemplates,
            ...data,
            mediaOverride: mediaOverride || data.media,
            title: decodeHtml(title || data.title),
            subtitle: decodeHtml(subtitle || data.subtitle),
            button: decodeHtml(buttonText || data.buttonText),
            buttonLocation: buttonLocation || data.buttonLocation,
            coverPhoto: profile.pictureurl || null,
            logo: profile.logo || null,
            sections: componentListToSections(compContext.getComponentList()),
            backgroundColor,
            font: compContext.font,
        };

        layout = getLayoutFromName(layout);

        // Get layout component
        const LayoutComponent = headersList[layout]
            ? getHeaderComponent(layout)
            : false;

        if (LayoutComponent) {
            return (
                <Suspense fallback={<div>Loading...</div>}>
                    {LayoutComponent && (
                        <LayoutComponent {...propsForTemplates} />
                    )}
                </Suspense>
            );
        }

        if (layout === 'right') {
            return (
                <Box className={classes.headerContainer}>
                    <Grid
                        container
                        spacing={0}
                        className={classes.wrapperContainer}
                        sx={{ flexWrap: { xs: 'wrap-reverse', md: 'none' } }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: { xs: 'center' },
                                alignItems: 'center',
                                padding: { xs: '0', md: '40px 40px 40px 0px' },
                                height: 'auto',
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    textAlign: { xs: 'center', md: 'left' },
                                }}
                            >
                                <Typography
                                    variant='h5'
                                    sx={{
                                        fontSize: { xs: '38px', md: '55px' },
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        overflow: 'hidden',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    ref={titleRef}
                                >
                                    {title || data.title}
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    sx={{
                                        pb: '15px',
                                        fontSize: '20px',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                        overflow: 'hidden',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    ref={subtitleRef}
                                >
                                    {subtitle || data.subtitle}
                                </Typography>
                                {(data.buttonText || buttonText) &&
                                    (buttonLocation || data.buttonLocation) && (
                                        <Button
                                            ref={buttonRef}
                                            sx={{
                                                color: btnColor,
                                                backgroundColor:
                                                    buttonBackgroundColor,
                                                width: '100%',
                                                mb: { xs: 0, md: '5px' },
                                                '&:hover': {
                                                    backgroundColor:
                                                        buttonBackgroundColor,
                                                },
                                            }}
                                            onClick={() => {
                                                window.open(
                                                    buttonLocation ||
                                                        data.buttonLocation,
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            {buttonText || data.buttonText}
                                        </Button>
                                    )}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        margin: '10px',
                                        justifyContent: {
                                            xs: 'center',
                                            md: 'inherit',
                                        },
                                    }}
                                >
                                    <Horizontal
                                        integrated={integrated}
                                        displaySocialIcons={displaySocialIcons}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: { xs: 'center', md: 'start' },
                                alignItems: { xs: 'flex-end', md: 'center' },
                                maxWidth: { xs: '100%', md: '50%' },
                                width: '100%',
                                padding: {
                                    xs: '10px 0',
                                    md: '40px 0 40px 40px',
                                },
                            }}
                        >
                            <Box
                                sx={
                                    displayImage
                                        ? {
                                              maxWidth: { xs: 600, md: 520 },
                                              width: '100%',
                                              margin: '0',
                                              paddingTop: { xs: '15px', md: 0 },
                                          }
                                        : {
                                              display: 'flex',
                                              justifyContent: 'center',
                                              width: '100%',
                                              margin: {
                                                  xs: '5px',
                                                  md: '20px 0px 20px 0px',
                                              },
                                          }
                                }
                            >
                                {displayImage && (
                                    <img
                                        style={{
                                            width: '100%',
                                            borderRadius: '10px',
                                        }}
                                        src={mediaOverride || data.media}
                                    />
                                )}
                                {!displayImage && (
                                    <ReactPlayer
                                        url={mediaOverride || data.media}
                                        onError={(err) => setDisplayImage(true)}
                                        width={matches ? '85%' : '100%'}
                                        height={matches ? '400px' : '260px'}
                                        style={{ width: '100%' }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            );
        }

        if (layout === 'center') {
            return (
                <Box className={classes.headerContainer}>
                    <Grid
                        container
                        spacing={0}
                        className={classes.wrapperContainer}
                    >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: { xs: 'center' },
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            <Box
                                sx={
                                    displayImage
                                        ? {
                                              maxWidth: {
                                                  xs: '330px',
                                                  md: '360px',
                                              },
                                              width: '100%',
                                              margin: {
                                                  xs: '5px 0px 0px 0px',
                                                  md: '20px 0px 0px 0px',
                                              },
                                          }
                                        : {
                                              display: 'flex',
                                              justifyContent: 'center',
                                              width: '100%',
                                              margin: {
                                                  xs: '20px 10px 20px 10px',
                                                  md: '20px 0px 20px 0px',
                                              },
                                          }
                                }
                            >
                                {displayImage && (
                                    <img
                                        style={{
                                            width: '100%',
                                            borderRadius: '10px',
                                        }}
                                        src={mediaOverride || data.media}
                                    />
                                )}
                                {!displayImage && (
                                    <ReactPlayer
                                        url={mediaOverride || data.media}
                                        onError={(err) => setDisplayImage(true)}
                                        width={matches ? '45%' : '100%'}
                                        height={matches ? '400px' : '260px'}
                                        style={{ width: '100%' }}
                                    />
                                )}
                            </Box>
                            <Box
                                sx={{
                                    width: { xs: '100%' },
                                    textAlign: 'center',
                                }}
                            >
                                <Typography
                                    variant='h5'
                                    sx={{
                                        fontSize: { xs: '38px', md: '55px' },
                                    }}
                                >
                                    {title || data.title}
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    sx={{ pb: '15px', fontSize: '20px' }}
                                >
                                    {subtitle || data.subtitle}
                                </Typography>
                                {(data.buttonText || buttonText) &&
                                    (buttonLocation || data.buttonLocation) && (
                                        <Button
                                            sx={{
                                                color: btnColor,
                                                backgroundColor:
                                                    buttonBackgroundColor,
                                                width: {
                                                    xs: '100%',
                                                    sm: '90%',
                                                    md: '55%',
                                                    lg: '50%',
                                                },
                                                '&:hover': {
                                                    backgroundColor:
                                                        buttonBackgroundColor,
                                                },
                                            }}
                                            onClick={() => {
                                                window.open(
                                                    buttonLocation ||
                                                        data.buttonLocation,
                                                    '_blank'
                                                );
                                            }}
                                        >
                                            {buttonText || data.buttonText}
                                        </Button>
                                    )}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        margin: '10px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Horizontal
                                        integrated={integrated}
                                        displaySocialIcons={displaySocialIcons}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            );
        }

        if (layout === 'full') {
            return (
                <Grid container spacing={0} className={classes.headerContainer}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: { xs: 'center' },
                            alignItems: 'center',
                            width: '100vh',
                            height: '100vh',
                        }}
                        style={{
                            backgroundImage: `url(${
                                mediaOverride || data.media
                            })`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            maxHeight: '100%',
                            // ...(enableParallax
                            //     ? { backgroundAttachment: 'fixed' }
                            //     : {}),
                        }}
                    >
                        <Box
                            sx={{
                                width: 'fit-content',
                                textAlign: 'center',
                                p: { xs: '0 16px', md: '0 24px' },
                            }}
                        >
                            <Typography
                                variant='h5'
                                sx={{ fontSize: { xs: '38px', md: '55px' } }}
                            >
                                {title || data.title}
                            </Typography>
                            <Typography
                                variant='subtitle1'
                                sx={{ fontSize: '20px', mb: '15px' }}
                            >
                                {subtitle || data.subtitle}
                            </Typography>
                            {(data.buttonText || buttonText) &&
                                (buttonLocation || data.buttonLocation) && (
                                    <Button
                                        sx={{
                                            color: btnColor,
                                            backgroundColor:
                                                buttonBackgroundColor,
                                            '&:hover': {
                                                backgroundColor:
                                                    buttonBackgroundColor,
                                            },
                                        }}
                                        onClick={() => {
                                            window.open(
                                                buttonLocation ||
                                                    data.buttonLocation,
                                                '_blank'
                                            );
                                        }}
                                    >
                                        {buttonText || data.buttonText}
                                    </Button>
                                )}
                            <Box sx={{ display: 'flex', margin: '10px' }}>
                                <Horizontal
                                    integrated={integrated}
                                    displaySocialIcons={displaySocialIcons}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            );
        }

        return (
            <Box className={classes.headerContainer}>
                <Grid
                    container
                    spacing={0}
                    className={classes.wrapperContainer}
                >
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: { xs: 'center', md: 'start' },
                            alignItems: { xs: 'flex-end', md: 'center' },
                            maxWidth: { xs: '100%', md: '50%' },
                            width: '100%',
                            padding: { xs: '0', md: '40px 40px 40px 0px' },
                        }}
                    >
                        <Box
                            sx={
                                displayImage
                                    ? {
                                          maxWidth: { xs: 600, md: 520 },
                                          width: '100%',
                                          margin: '0',
                                          paddingTop: { xs: '15px', md: 0 },
                                      }
                                    : {
                                          display: 'flex',
                                          justifyContent: 'center',
                                          width: '100%',
                                          margin: {
                                              xs: '5px',
                                              md: '20px 0px 20px 40px',
                                          },
                                      }
                            }
                        >
                            {displayImage && (
                                <img
                                    style={{
                                        width: '100%',
                                        borderRadius: '10px',
                                    }}
                                    src={mediaOverride || data.media}
                                />
                            )}
                            {!displayImage && (
                                <ReactPlayer
                                    url={mediaOverride || data.media}
                                    onError={(err) => setDisplayImage(true)}
                                    width={matches ? '85%' : '100%'}
                                    height={matches ? '400px' : '260px'}
                                    style={{ width: '100%' }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: { xs: 'center' },
                            alignItems: 'center',
                            p: { xs: '10px 0', md: '40px 0 40px 40px' },
                            m: { xs: '0', md: 0 },
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                textAlign: { xs: 'center', md: 'left' },
                            }}
                        >
                            <Typography
                                variant='h5'
                                sx={{ fontSize: { xs: '38px', md: '55px' } }}
                                ref={titleRef}
                            >
                                {title || data.title}
                            </Typography>
                            <Typography
                                variant='subtitle1'
                                sx={{ pb: '15px', fontSize: '20px' }}
                                ref={subtitleRef}
                            >
                                {subtitle || data.subtitle}
                            </Typography>
                            {(data.buttonText || buttonText) &&
                                (buttonLocation || data.buttonLocation) && (
                                    <Button
                                        sx={{
                                            color: btnColor,
                                            backgroundColor:
                                                buttonBackgroundColor,
                                            width: '100%',
                                            '&:hover': {
                                                backgroundColor:
                                                    buttonBackgroundColor,
                                            },
                                        }}
                                        onClick={() => {
                                            window.open(
                                                buttonLocation ||
                                                    data.buttonLocation,
                                                '_blank'
                                            );
                                        }}
                                        ref={buttonRef}
                                    >
                                        {buttonText || data.buttonText}
                                    </Button>
                                )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    margin: { xs: 0, md: '10px' },
                                    justifyContent: {
                                        xs: 'center',
                                        md: 'inherit',
                                    },
                                }}
                            >
                                <Horizontal
                                    integrated={integrated}
                                    displaySocialIcons={displaySocialIcons}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    return <div id='header'>{getLayout()}</div>;
};

const detailedEditorDefinition = getAdvanceSettings();

const { propTypes, defaultProps } = generatePropTypesAndDefaults(
    detailedEditorDefinition
);

// Assign propTypes and defaultProps
HeaderSection.propTypes = propTypes;
HeaderSection.defaultProps = defaultProps;

export default HeaderSection;
