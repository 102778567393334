import React, { useState } from 'react';
import isEmpty from 'lodash.isempty';
import SocialIcons from '../../../shared/SocialIcons';

function HomeSection({ integrations, profile, music }) {
    const [newRelease] = useState(music !== undefined ? music[0] : undefined);

    if (isEmpty(newRelease)) {
        return <></>;
    }

    return (
        <div id='home-section-new-album'>
            {!newRelease.thumbnail ? (
                <>
                    <img
                        src={profile.pictureurl}
                        alt
                        style={{
                            width: '100%',
                            height: '100vh',
                            objectFit: 'cover',
                        }}
                    />

                    <SocialIcons
                        integrations={integrations}
                        outerClass={'social-wrapper'}
                        iconClass={'apple-music-icon'}
                        iconWrapperClass={'profile-link'}
                        linkClass={'profile-link'}
                    />
                </>
            ) : (
                <div className='home-section1-newalbum'>
                    <div className='new-album-wrapper w-container'>
                        {newRelease.title && (
                            <div className='album-info-wrapper'>
                                <h1 className='new-album-title'>
                                    {newRelease.title && newRelease.title}
                                </h1>
                                <p className='new-album-description'>
                                    New Album by {profile.name} <br />
                                    {newRelease.releaseDate}
                                </p>
                                <a
                                    href={newRelease.url}
                                    target='_blank'
                                    className='new-album-button w-button'
                                >
                                    Listen Now
                                </a>
                                <SocialIcons
                                    integrations={integrations}
                                    outerClass={'social-wrapper'}
                                    iconClass={'apple-music-icon'}
                                    iconWrapperClass={'profile-link'}
                                    linkClass={'profile-link'}
                                />
                            </div>
                        )}
                        <div className='album-art-wrapper'>
                            <img
                                src={newRelease.thumbnail}
                                height
                                sizes='(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 991px) 433.203125px, 618.34375px'
                                alt
                                className='new-album-art-image'
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default HomeSection;
