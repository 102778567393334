import React, { useContext, useState, useEffect, useRef } from 'react';
import { List, Drawer, Divider, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import _sortBy from 'lodash.sortby';
import _get from 'lodash.get';
import { CompContext } from '../../context/CompContext';
import ToggleNavItem from '../../builder/sideBar/ToggleNavItem';
import { sectionList } from '../../sections';
import isEmpty from 'lodash.isempty';
import LoadingComponent from '../../components/LoadingComponent';
import { HEADERS_SECTION_KEY } from '../../sections/header';

const useStyles = makeStyles((theme) => ({
    list: {
        // paddingLeft: theme.spacing(4),
    },
    drawer: {
        width: 260,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 280,
        backgroundColor: theme.palette.background.paper,
    },
    drawerHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    content: {
        // flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: -260,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

const SectionSelection = ({
    bottomStyle,
    setDetailComponent,
    BottomIcons,
    ...props
}) => {
    const classes = useStyles();
    const compContext = useContext(CompContext);
    const [open, setOpen] = useState(false);
    const [detailedEditorComponentKey, setDetailedEditorComponentKey] =
        useState(undefined);
    const [prevHeaderLayout, setPrevHeaderLayout] = useState(null);

    const showDetailedView = async (componentKey) => {
        setDetailedEditorComponentKey(componentKey);
    };

    const getDetailView = async () => {
        if (!isEmpty(detailedEditorComponentKey)) {
            const DetailedView = compContext.getDetailedEditor(
                detailedEditorComponentKey
            );
            return setDetailComponent(DetailedView);
        }
        return setDetailComponent(undefined);
    };

    const onHeaderLayoutChange = (layoutKey) => {
        if (!layoutKey) return;
        return setDetailComponent(
            sectionList[HEADERS_SECTION_KEY].detailedEditor(layoutKey)
        );
    };

    const headerConfig = compContext.getComponentConfig(HEADERS_SECTION_KEY);
    const headerLayout = headerConfig && headerConfig.userDefinedProps.layout;

    const hideDetailedView = () => {
        setOpen(false);
        setDetailedEditorComponentKey(undefined);
    };

    useEffect(() => {
        getDetailView();
    }, [detailedEditorComponentKey]);

    useEffect(() => {
        if (
            typeof headerLayout !== 'undefined' &&
            headerLayout !== prevHeaderLayout
        ) {
            onHeaderLayoutChange(headerLayout);
        }
        if (typeof headerLayout !== 'undefined') {
            setPrevHeaderLayout(headerLayout);
        }
    }, [headerLayout, headerConfig]);

    if (!compContext || compContext.isLoading) {
        return <LoadingComponent hideTitle={true} />;
    }

    const orderedComponentKeys = _get(compContext, 'orderedComponentKeys', []);

    return (
        <div>
            {orderedComponentKeys && orderedComponentKeys.length > 0 ? (
                <List className={classes.list} style={bottomStyle}>
                    {orderedComponentKeys.map((key, idx) => {
                        const componentConfig =
                            compContext.getComponentConfig(key);
                        if (componentConfig && sectionList[key]) {
                            return (
                                <ToggleNavItem
                                    componentDetails={sectionList[key]}
                                    componentConfig={componentConfig}
                                    onNavItemClick={showDetailedView}
                                    {...props}
                                    key={idx}
                                />
                            );
                        }
                        return null; // Return null if componentConfig or sectionList[key] is not found
                    })}
                    {BottomIcons()}
                </List>
            ) : (
                <div>No components available</div>
            )}
            {open && (
                <div>
                    <Drawer
                        className={classes.drawer}
                        variant='persistent'
                        anchor='left'
                        open={open}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <div className={classes.drawerHeader}>
                            <Typography
                                variant='button'
                                sx={{ alignSelf: 'center' }}
                            >
                                {detailedEditorComponentKey}
                            </Typography>
                            <IconButton onClick={hideDetailedView} size='large'>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />
                        {getDetailView()}
                        <Divider />
                    </Drawer>
                    <main
                        className={clsx(classes.content, {
                            [classes.contentShift]: open,
                        })}
                    ></main>
                </div>
            )}
        </div>
    );
};

export default SectionSelection;
