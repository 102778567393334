import React from 'react';
import PropTypes from 'prop-types';
import V1 from './V1';
import V2 from './V2';
import V3 from './V3';

const Footer = (props) => {
    const { color, backgroundColor, variant, profile, integrations, displaySocialIcons, monoIcons,iconColor } = props;
    // ensure variant is number incase it switch
    // to a string
    let variantInt = variant;
    if (typeof variantInt !== 'number') {
        variantInt = parseInt(variantInt, 10);
    }

    switch (variantInt) {
        case 1:
            return (
                <V1
                    color={color}
                    backgroundColor={backgroundColor}
                    logoUrl={profile.logo}
                    artistName={profile.name}
                    integrations={integrations}
                    displaySocialIcons={displaySocialIcons}
                    monoIcons={monoIcons}
                    iconColor={iconColor}
                />
            );
        case 2:
            return (
                <V2
                    color={color}
                    backgroundColor={backgroundColor}
                    logoUrl={profile.logo}
                    artistName={profile.name}
                    integrations={integrations}
                    displaySocialIcons={displaySocialIcons}
                    monoIcons={monoIcons}
                    iconColor={iconColor}
                />
            );
        case 3:
            return (
                <V3
                    color={color}
                    backgroundColor={backgroundColor}
                    logoUrl={profile.logo}
                    artistName={profile.name}
                    integrations={integrations}
                    displaySocialIcons={displaySocialIcons}
                    monoIcons={monoIcons}
                    iconColor={iconColor}
                />
            );
        default:
            return (
                <V1
                    color={color}
                    backgroundColor={backgroundColor}
                    logoUrl={profile.logo}
                    artistName={profile.name}
                    integrations={integrations}
                    displaySocialIcons={displaySocialIcons}
                    monoIcons={monoIcons}
                    iconColor={iconColor}
                />
            );
    }
};

const title = 'Footer';
const isDisplayedOnNav = false;
const detailedEditorDefinition = {
    variant: {
		propType: PropTypes.number.isRequired,
		valueType: 'number',
		inputMethod: 'radio',
		inputOptions: ['1', '2', '3'],
		name: 'variant',
		label: 'Select Design',
        description: 'Select Design',
		isRequired: true,
		defaultValue: '1',
	},
	color: {
		propType: PropTypes.string,
		valueType: 'string',
		inputMethod: 'color',
		name: 'color',
		label: 'Text Color',
        description: 'Text Color',
		isRequired: false,
		defaultValue: '#000000',
	},
	backgroundColor: {
		propType: PropTypes.string,
		valueType: 'string',
		inputMethod: 'color',
		name: 'backgroundColor',
		label: 'Background Color',
        description: 'Background Color',
		isRequired: false,
		defaultValue: '#FFFFFF',
	},
	displaySocialIcons: {
		propType: PropTypes.bool.isRequired,
		valueType: 'boolean',
		inputMethod: 'switch',
		name: 'displaySocialIcons',
		label: 'Display Social Icons',
        description: 'Display Social Icons',
		isRequired: true,
	},
    monoIcons: {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'monoIcons',
        label: 'Display Mono Icons',
        description: 'Display Mono Icons',
        isRequired: false,
        defaultValue: false,
    },
    iconColor:{
        propType: PropTypes.string,
        valueType: 'string',
        inputMethod: 'color',
        name: 'iconColor',
        label: 'Icon Color',
        description: 'Icon Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    }, 
};

Footer.propTypes = {
    variant: detailedEditorDefinition.variant.propType,
    color: detailedEditorDefinition.color.propType,
    backgroundColor: detailedEditorDefinition.backgroundColor.propType,
    profile: PropTypes.shape({
        logo: PropTypes.string,
        name: PropTypes.string,
    }).isRequired,
    integrations: PropTypes.array.isRequired,
    displaySocialIcons: detailedEditorDefinition.displaySocialIcons.propType,
    monoIcons: detailedEditorDefinition.monoIcons.propType,
    iconColor: detailedEditorDefinition.iconColor.propType,
};

Footer.defaultProps = {
    variant: detailedEditorDefinition.variant.defaultValue,
    color: detailedEditorDefinition.color.defaultValue,
    backgroundColor: detailedEditorDefinition.backgroundColor.defaultValue,
    profile: {
        logo: '',
        name: '',
    },
    integrations: [],
    displaySocialIcons: detailedEditorDefinition.displaySocialIcons.defaultValue,
    monoIcons: detailedEditorDefinition.monoIcons.defaultValue,
    iconColor: detailedEditorDefinition.iconColor.defaultValue,
};

export { Footer as default, title, isDisplayedOnNav, detailedEditorDefinition };
