import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: ({ backgroundColor }) => backgroundColor,
        paddingTop: '40px',
        paddingBottom: '40px',
        width: '100%',
    },
    sectionHeader: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center !important',
    },
    header: {
        color: ({ color }) => color,
        marginTop: '20px',
        marginBottom: '20px',
        fontSize: '35px',
        margin: 'auto',
        padding: 'auto',
        fontWeight: 'bold',
        letterSpacing: '1px',
        // textTransform: 'uppercase',
    },
    container: {
        padding: '25px',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto',
        '@media (max-width:600px)': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginLeft: '0',
            marginRight: '0',
        },
        '@media (min-width:600px) and (max-width: 834px)': {
            alignItems: 'center',
            marginLeft: '50px',
            marginRight: '50px',
        },
    },
    gridList: {
        textAlign: 'center',
        alignItems: 'center',
        '@media (max-width:600px)': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    list: {
        justifySelf: 'center',
    },
    contactInfo: {
        color: ({ textColor }) => textColor,
        textAlign: 'center',
        marginTop: '25px',
        marginBottom: '25px',
    },
    titleHeader: {
        fontSize: '30px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    email: {
        margin: '10px',
        fontSize: '12px',
        color: ({ textColor }) => textColor,
        fontWeight: 'lighter',
        letterSpacing: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    contactBlock: {
        marginTop: '25px',
        lineHeight: '15px',
    },
}));

const ContactGrid = ({
    color,
    backgroundColor,
    textColor,
    headerText,
    cols,
    contacts,
}) => {
    const classes = useStyles({ color, backgroundColor, textColor });
    return (
        <div className={classes.root} id='contact'>
            <div className={classes.sectionHeader}>
                <Typography className={classes.header} variant='h1'>
                    {headerText}
                </Typography>
                <div className={classes.container}>
                    <Grid container className={classes.gridList}>
                        {contacts.map((contact) => (
                            <Grid
                                item
                                md={cols === 1 ? 12 : cols === 2 ? 6 : 4}
                                xs={12}
                                className={classes.list}
                            >
                                <div className={classes.contactInfo}>
                                    <Typography
                                        className={classes.titleHeader}
                                        variant='h2'
                                    >
                                        {contact.title}
                                    </Typography>
                                    <div className={classes.contactBlock}>
                                        <Typography
                                            component='h6'
                                            className={classes.email}
                                            title={contact.email}
                                        >
                                            <a
                                                href={`mailto: ${contact.email}`}
                                                style={{
                                                    color: textColor,
                                                    textDecoration: 'none',
                                                }}
                                            >
                                                {contact.email}
                                            </a>
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        </div>
    );
};

export default ContactGrid;
