import isEmpty from 'lodash.isempty';
import jamfeedClient from './jamfeedClient';

const creatorApi = {
    getProfile: async (id) =>
        jamfeedClient
            .get('/artist', {
                params: {
                    aid: id,
                },
            })
            .then((response) => {
                let { artist } = response.data;
                const publicContacts = isEmpty(artist.contacts) ? [] : artist.contacts.filter((c) => c.publiclyVisible);
                artist.publicContacts = publicContacts;
                delete artist.contacts;
                return artist;
            })
            .catch((error) => {
                console.log('error getting artist profile', error);
                return {};
            }),

    getVideos: async (id, type) =>
        jamfeedClient
            .get('/content/videos', {
                params: {
                    id,
                    type,
                },
            })
            .then((response) => response.data.results)
            .catch((error) => {
                console.log('error getting artist videos', error);
                return [];
            }),

    getMusic: async (id, type) =>
        jamfeedClient
            .get('/content/music', {
                params: {
                    id,
                    type,
                },
            })
            .then((response) => response.data.results)
            .catch((error) => {
                console.log('error getting artist music', error);
                return [];
            }),

    getNews: async (id, type) =>
        jamfeedClient
            .get('/news/creator', {
                params: {
                    id,
                    type,
                },
            })
            .then((response) => response.data.results)
            .catch((error) => {
                console.log('error getting artist news', error);
                return [];
            }),

    getIntegrations: async (id) =>
        jamfeedClient
            .get('/artist/integrations', {
                params: {
                    artistId: id,
                },
            })
            .then((response) => response.data)
            .catch((error) => {
                console.log('error getting artist integrations', error);
                return {
                    integrated: [],
                };
            }),
    getImages: async (id) =>
        jamfeedClient
            .get('/artist/images', {
                params: {
                    id,
                },
            })
            .then((response) => response.data.images)
            .catch((error) => {
                console.log('error getting artist photos', error);
                return [];
            }),
    getEvents: async (id, type) =>
        jamfeedClient
            .get('/events', {
                params: {
                    id,
                    type,
                },
            })
            .then((response) => response.data.results)
            .catch((error) => {
                console.log('error getting artist events', error);
                return [];
            }),
    getSubscriptionStatus: async (id, type) =>
        jamfeedClient
            .get('/subscription/status', {
                params: {
                    id,
                    type,
                },
            })
            .then(({ data }) => {
                console.log('payment plan data: ', data);
                return data;
            })
            .catch((e) => {
                console.log('error get subscription', e.response.data);
                return e.response.data;
            }),
};

export default creatorApi;
