import React from 'react';

function NewsGrid({ news, renderItem, outerClass, numItems }) {
    return (
        <div className={outerClass}>
            {news
                .slice(0, numItems ? numItems : 6)
                .map((item) => renderItem(item))}
        </div>
    );
}

export default NewsGrid;
