import React from 'react';
import SocialIcons from '../../../shared/SocialIcons';
import CommonContact from '../../../shared/contact/CommonContact';
function FooterSection({ appData, theme, profile, integrations }) {
    return (
        <div id='contact-section' className='footer-section'>
            <div className='footer-section-wrapper'>
                <h1
                    className='section-header'
                    style={{ color: theme.textColor }}
                >
                    Contact
                </h1>
                <CommonContact theme={theme} profile={profile} />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <SocialIcons
                        appData={appData}
                        integrations={integrations}
                    />
                </div>
                {profile && profile.name && (
                    <div
                        style={{
                            width: '100%',
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: theme.textColor,
                            margin: 10,
                        }}
                    >
                        Copyright © {(new Date().getFullYear())} {profile.name}
                    </div>
                )}
            </div>
        </div>
    );
}

export default FooterSection;
