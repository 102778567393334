import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Link, Typography } from '@mui/material/';
import DOMPurify from 'dompurify';
import { decode } from 'html-entities';

const useStyles = makeStyles(() => ({
    gridItem: {
        listStyle: 'none',
        height: 'auto',
        overflow: 'hidden',
    },
    pic: {
        height: 'auto',
        maxWidth: '100%',
    },
    roundPic: {
        height: 'auto',
        maxWidth: '100%',
        borderRadius: '1.5rem'
    },
    title: ({ textColor }) => ({
        paddingTop: 10,
        paddingBottom: 10,
        fontSize: 16,
        fontWeight: 400,
        width: 180,
        color: textColor,
    }),
}));

export const Card9 = ({ title, media, url, textColor, roundedCorners }) => {
    const styles = useStyles({ textColor });
    let cleanTitle = DOMPurify.sanitize(title, { SAFE_FOR_TEMPLATES: true });
    return (
        <Grid
            item
            direction='column'
            align='center'
            className={styles.gridItem}
            style={{ borderRadius: roundedCorners ? '1.5rem' : 0 }}
        >
            <Link href={url} style={{ textDecoration: 'none' }}>
                <img
                    href={url}
                    src={media}
                    alt={title}
                    className={roundedCorners ? styles.roundPic : styles.pic}
                />
                <Typography className={styles.title}>
                    {decode(cleanTitle)}
                </Typography>
            </Link>
        </Grid>
    );
};

const title = 'Card Option 9';
const detailedEditorDefinition = {
    'card.textColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.textColor',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#000000',
    },
    'card.roundedCorners': {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'card.roundedCorners',
        label: 'Rounded Corners',
        description: 'Creates rounded corners on each card',
        isRequired: false,
    },
};

Card9.propTypes = {
    title: PropTypes.string.isRequired,
    media: PropTypes.string.isRequired,
    url: PropTypes.string,
    textColor: detailedEditorDefinition['card.textColor'].propType,
    roundedCorners: detailedEditorDefinition['card.roundedCorners'].propType,
};

export { Card9 as default, title, detailedEditorDefinition };
