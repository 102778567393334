import React, { useContext, useEffect, lazy, Suspense, useState } from 'react';
import { makeStyles } from '@mui/styles/';
import { CompContext } from '../../context/CompContext';
import './FontPicker.scss';

const fontFamiliesList = [
    'Roboto',
    'Open Sans',
    'Noto Sans JP',
    'Lato',
    'Montserrat',
    'Poppins',
    'Roboto Condensed',
    'Source Sans Pro',
    'Oswald',
    'Roboto Mono',
    'Raleway',
    'Noto Sans',
    'Inter',
    'Ubuntu',
    'Nunito',
    'Roboto Slab',
    'Mukta',
    'PT Sans',
    'Playfair Display',
    'Merriweather',
    'Nunito Sans',
    'Rubik',
    'Noto Sans KR',
    'Work Sans',
    'Lora',
    'Noto Sans TC',
    'Nanum Gothic',
    'Fira Sans',
    'Barlow',
    'Quicksand',
    'Kanit',
    'Titillium Web',
    'PT Serif',
    'Mulish',
    'Hind Siliguri',
    'Inconsolata',
    'Heebo',
    'Noto Serif',
    'Cairo',
    'Josefin Sans',
    'IBM Plex Sans',
    'Karla',
    'Libre Franklin',
    'EB Garamond',
    'Dosis',
    'Oxygen',
    'DM Sans',
    'Arimo',
    'Libre Baskerville',
    'PT Sans Narrow',
    'Noto Sans SC',
    'Dancing Script',
    'Bitter',
    'Cabin',
    'Anton',
    'Bebas Neue',
    'Source Serif Pro',
    'Source Code Pro',
    'Barlow Condensed',
    'Signika Negative',
    'Prompt',
    'Hind',
    'Lobster',
    'Abel',
    'M PLUS Rounded 1c',
    'Comfortaa',
    'Fjalla One',
    'Varela Round',
    'Manrope',
    'Cinzel',
    'Noto Sans HK',
    'Pacifico',
    'Crimson Text',
    'Exo 2',
    'Noto Serif TC',
    'Maven Pro',
    'Overpass',
    'Yanone Kaffeesatz',
    'Noto Serif KR',
    'Taviraj',
    'Arvo',
    'Merriweather Sans',
    'Noto Serif JP',
    'Teko',
    'Fira Sans Condensed',
    'Abril Fatface',
    'Hind Madurai',
    'Asap',
    'Shadows Into Light',
    'Cormorant SC',
    'Ibarra Real Nova',
    'Archivo',
    'Rajdhani',
    'Indie Flower',
    'Assistant',
    'Caveat',
    'Secular One',
    'Questrial',
    'Slabo 27px',
    'Barlow Semi Condensed',
    'IBM Plex Serif',
    'Tajawal',
    'Catamaran',
    'Public Sans',
    'Jost',
    'Cormorant Garamond',
    'Zilla Slab',
    'Domine',
    'Red Hat Display',
    'Archivo Narrow',
    'Permanent Marker',
    'Alfa Slab One',
    'Play',
    'Signika',
    'Acme',
    'Nanum Myeongjo',
    'Space Grotesk',
    'Bree Serif',
    'Amatic SC',
    'ABeeZee',
    'Sarabun',
    'Righteous',
    'Exo',
    'Satisfy',
    'M PLUS 1p',
    'Chakra Petch',
    'Vollkorn',
    'IBM Plex Mono',
    'Encode Sans',
    'Yantramanav',
    'Alegreya Sans',
    'Alegreya',
    'Tinos',
    'Noto Sans Display',
    'Ubuntu Condensed',
    'Didact Gothic',
    'Frank Ruhl Libre',
    'Russo One',
    'Martel',
    'Fredoka One',
    'Crete Round',
    'Archivo Black',
    'Courgette',
    'Amiri',
    'Cardo',
    'Saira Condensed',
    'DM Serif Display',
    'Patua One',
    'Great Vibes',
    'Baloo 2',
    'Volkhov',
    'Antic Slab',
    'Lobster Two',
    'Kalam',
    'Almarai',
    'Prata',
    'PT Sans Caption',
    'Rokkitt',
    'Changa',
    'Space Mono',
    'Staatliches',
    'Kaushan Script',
    'Francois One',
    'Pathway Gothic One',
    'Cookie',
    'Gloria Hallelujah',
    'Noticia Text',
    'Cormorant',
    'Old Standard TT',
    'Padauk',
    'Montserrat Alternates',
    'Asap Condensed',
    'Spectral',
    'Patrick Hand',
    'Concert One',
    'Chivo',
    'Noto Kufi Arabic',
    'Noto Sans Arabic',
    'Sacramento',
    'Sawarabi Mincho',
    'Quattrocento Sans',
    'Playfair Display SC',
    'Orbitron',
    'Alata',
    'Passion One',
    'Gothic A1',
    'Cantarell',
    'Cuprum',
    'Monda',
    'Josefin Slab',
    'Gelasio',
    'Lexend Deca',
    'Unna',
    'PT Mono',
    'Quattrocento',
    'Philosopher',
    'Paytone One',
    'Press Start 2P',
    'Aclonica',
];

const FontPicker = lazy(() => import('font-picker-react'));

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        display: 'flex',
        position: 'absolute',
    },
    style: {
        padding: 15,
    },
    listItem: {
        display: 'flex',
        justifyContent: 'center',
        padding: 15,
        color: 'black',
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
        '& .MuiOutlinedInput-root': {
            '& label.Mui-focused': {
                color: '#02AA02',
            },
            '&:hover fieldset': {
                borderColor: '#02AA02',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#02AA02',
            },
        },
    },
    color: {
        width: '20px',
        height: '20px',
        borderRadius: '2px',
    },
}));

const FontPickerDropdown = () => {
    const classes = useStyles();
    const compContext = useContext(CompContext);
    const [fontFamilies, setFontFamilies] = useState(fontFamiliesList);

    useEffect(() => {
        setFontFamilies([...fontFamiliesList, compContext.font]);
    }, [compContext.font]);

    return (
        <div>
            <div className={classes.listItem}>
                <div>
                    <Suspense fallback={<div>Loading...</div>}>
                        <FontPicker
                            apiKey='AIzaSyCBT8LIOKTjPFun2U165tMesJpaJyvvh9E'
                            activeFontFamily={compContext.font}
                            onChange={(newValue) => {
                                if (newValue) {
                                    compContext.setWebFont(newValue.family);
                                }
                            }}
                            families={fontFamilies}
                            limit={250}
                        />
                    </Suspense>
                </div>
            </div>
        </div>
    );
};
export default FontPickerDropdown;
