import React from 'react';
import isEmpty from 'lodash.isempty';

function SocialIcons({ integrations }) {
    const setupIntegrations = integrations.integrated || [];
    return (
        <div className='home-page-social-wrapper'>
            {setupIntegrations.map(({ network, data: { url } }) => {
                if (isEmpty(url)) {
                    return;
                }
                switch (network) {
                    case 'SPOTIFY':
                        return (
                            <div className='profile-link'>
                                <a href={url} target='_blank' className='spotify-link w-inline-block'>
                                    <img
                                        src='https://assets.website-files.com/5f2c19129fc88015b9209854/5f2c19129fc88036f4209868_Spotify%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='spotify-icon'
                                    />
                                </a>
                            </div>
                        );
                    case 'APPLE_MUSIC':
                        return (
                            <div className='profile-link'>
                                <a href={url} target='_blank' className='apple-music-link w-inline-block'>
                                    <img
                                        src='https://assets.website-files.com/5f2c19129fc88015b9209854/5f2c19129fc880479120986a_Apple%20Music%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='apple-music-icon'
                                    />
                                </a>
                            </div>
                        );
                    case 'YOUTUBE':
                        return (
                            <div className='profile-link'>
                                <a href={url} target='_blank' className='youtube-link w-inline-block'>
                                    <img
                                        src='https://assets.website-files.com/5f2c19129fc88015b9209854/5f2c19129fc8806276209869_YouTube%20icon.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='youtube-icon-2'
                                    />
                                </a>
                            </div>
                        );
                    case 'SOUNDCLOUD':
                        return (
                            <div className='profile-link'>
                                <a href={url} target='_blank' className='soundcloud-link w-inline-block'>
                                    <img
                                        src='https://assets.website-files.com/5f2c19129fc88015b9209854/5f2c19129fc880b25120986e_SoundCloud%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='soundcloud-icon-2'
                                    />
                                </a>
                            </div>
                        );
                    case 'INSTAGRAM':
                        return (
                            <div className='profile-link'>
                                <a href={url} target='_blank' className='instagram-link w-inline-block'>
                                    <img
                                        src='https://assets.website-files.com/5f2c19129fc88015b9209854/5f2c19129fc88026bb20986c_Instagram%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='instagram-icon-2'
                                    />
                                </a>
                            </div>
                        );
                    case 'TWITTER':
                        return (
                            <div className='profile-link'>
                                <a href={url} target='_blank' className='twitter-link w-inline-block'>
                                    <img
                                        src='https://assets.website-files.com/5f2c19129fc88015b9209854/5f2c19129fc880b2ff20986b_Twitter%20Sidebar.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='twitter-icon-2'
                                    />
                                </a>
                            </div>
                        );
                    case 'BANDSINTOWN':
                        return (
                            <div className='profile-link'>
                                <a href={url} target='_blank' className='twitter-link w-inline-block'>
                                    <img
                                        src='https://musicrow.com/wp-content/uploads/2017/11/bandsintownlogo.png'
                                        width={30}
                                        height={30}
                                        alt
                                        className='twitter-icon-2'
                                        style={{
                                            borderRadius: 500,
                                        }}
                                    />
                                </a>
                            </div>
                        );
                    case 'FACEBOOK':
                        return (
                            <div className='profile-link'>
                                <a href={url} target='_blank' className='twitter-link w-inline-block'>
                                    <img
                                        src='https://jamfeed-storage.s3.amazonaws.com/icons/facebook.svg'
                                        width={30}
                                        height={30}
                                        alt
                                        className='twitter-icon-2'
                                        style={{
                                            borderRadius: 500,
                                        }}
                                    />
                                </a>
                            </div>
                        );
                    case 'SONGKICK':
                        return (
                            <div className='profile-link'>
                                <a href={url} target='_blank' className='twitter-link w-inline-block'>
                                    <img
                                        src='https://jamfeed-storage.s3.amazonaws.com/icons/songkick.svg'
                                        width={30}
                                        height={30}
                                        alt
                                        className='twitter-icon-2'
                                        style={{
                                            borderRadius: 500,
                                        }}
                                    />
                                </a>
                            </div>
                        );
                }
            })}
        </div>
    );
}

export default SocialIcons;
