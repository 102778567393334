import React, { useMemo, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';

const MenuItemLink = (props) => {
  const { icon, primary, to, className, onClick } = props;

  const renderLink = useMemo(
    () => forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li className={className}>
      <MenuItem button component={renderLink} onClick={onClick}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </MenuItem>
    </li>
  );
};

export default MenuItemLink;