import React, { useState } from 'react';
import { Box, Grid, Slide, styled } from '@mui/material';
import Navbar from '../../shared/headers/components/Navbar';
import IconGrid from '../../shared/social icons/IconGrid';
import VideoFrame from '../../shared/headers/components/VideoFrame';
import { NavbarConfigProvider } from '../../shared/headers/components/useNavbarConfig';
import { isBuilderURL } from '../../utils/isBuilderUrl';
import { generatePropTypesAndDefaults } from '../../utils/detailedEditorDefinition';
import { commonEditorDefinitions } from './commonEditorDefinitions';
import { useTheme } from '@emotion/react';
import { HEADERS_SECTION_KEY } from './constants';

const HeaderContainer = styled(Grid)(
    ({ theme, mediaOverride, backgroundColor }) => ({
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100vh',
        minHeight: '600px',
        maxHeight: isBuilderURL() ? 'calc(100vh - 4.5rem)' : '100vh',
        background: `url(${mediaOverride}) no-repeat center center`,
        backgroundSize: 'cover',
        padding: theme.spacing(1),
        backgroundColor: backgroundColor || '#000000',
        // ... other styles ...
    })
);

const MainContent = styled(Grid)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 0,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
}));

const ContentCallStyle = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    width: '66%',
    maxWidth: '66rem',
    margin: 'auto 0',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        maxWidth: '100%',
        margin: 'auto',
    },
}));

const MenuContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
}));

const Header4 = ({
    color: textColor,
    backgroundColor,
    btnColor: buttonTextColor,
    btnBg: buttonBackgroundColor,
    profile,
    integrations: { integrated },
    displaySocialIcons,
    fontSizeSelection = 6,
    sections,
    font,
}) => {
    // console.log('header4');
    const theme = useTheme();
    const [menuState, setMenuState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const styleDetails = {
        textColor,
        backgroundColor,
        buttonTextColor,
        buttonBackgroundColor,
        fontSizeSelection,
        font,
    };

    // Dynamic Navbar Styles
    const navStyles = {
        appBar: {
            background: 'transparent',
            boxShadow: 'none',
            height: 'auto',
            width: 'auto',
        },
        navContent: {
            display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
            flexFlow: 'row wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        navlinks: {
            marginLeft: theme.spacing(2),
            display: 'flex',
            overflowX: 'hidden',
            background: 'transparent',
            fontSize: '1.6rem',
        },
        logo: ({ logo, hasLogo }) => {
            return {
                borderRadius: '0',
                fontSize: '2rem',
                maxWidth: '10rem',
                [theme.breakpoints.down('md')]: {
                    maxWidth: '60%',
                },
                width: hasLogo ? '3rem' : 'auto',
                height: hasLogo ? '3rem' : 'auto',
                backgroundSize: hasLogo ? 'cover' : 'auto',
                backgroundPosition: hasLogo ? 'left' : 'auto',
                backgroundImage: hasLogo ? `url(${'{{logo}}'})` : 'none',
            };
        },
        link: {
            textDecoration: 'none',
            color: (props) => props.textColor || '#FFF',
            fontSize: '1rem', // Fixed to 1rem
            marginLeft: theme.spacing(2),
            '&:hover': {
                textDecoration: 'none',
                color: (props) => props.textColor || '#FFFF00',
                borderBottom: (props) =>
                    `1px solid ${props.textColor || '#FFFF00'}`,
            },
            '&:visited': {
                color: (props) => props.textColor || '#FFF',
            },
        },
        menuIcon: {
            display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' },
        },
    };

    const { pictureurl: coverPhoto, name, logo } = profile;

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setMenuState({ ...menuState, [anchor]: open });
    };

    const navbarConfig = {
        title: name,
        sections,
        classes: navStyles,
        toggleDrawer,
        menuState,
        styleDetails,
        logo,
        integrated,
        showMenuIcon: true,
        color: textColor,
    };

    return (
        <NavbarConfigProvider value={navbarConfig}>
            <HeaderContainer
                mediaOverride={profile.pictureurl}
                backgroundColor={backgroundColor}
            >
                <Grid item xs={12}>
                    <Navbar
                        {...navbarConfig}
                        sx={navStyles.appBar}
                        customStyle={{
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                            alignContent: 'flex-start',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    />
                </Grid>
                <MainContent>
                    <ContentCallStyle>
                        <VideoFrame url={profile.coverVideoUrl} />
                    </ContentCallStyle>
                    {integrated && (
                        <Slide
                            in={true}
                            direction='left'
                            style={{ transform: '0 0 0' }}
                        >
                            <MenuContainer>
                                {displaySocialIcons && (
                                    <IconGrid
                                        integrated={integrated}
                                        textColor={textColor}
                                    />
                                )}
                            </MenuContainer>
                        </Slide>
                    )}
                </MainContent>
            </HeaderContainer>
        </NavbarConfigProvider>
    );
};

const specificDefinitions = {};

const detailedEditorDefinition = {
    ...commonEditorDefinitions,
    ...specificDefinitions,
};

const { propTypes, defaultProps } = generatePropTypesAndDefaults(
    detailedEditorDefinition
);

Header4.propTypes = propTypes;
Header4.defaultProps = defaultProps;

export { Header4 as default, detailedEditorDefinition, specificDefinitions };
