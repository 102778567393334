import React, { useContext } from 'react';
import { Grid, Box } from '@mui/material';
import SectionsLinkMapping from './SectionsLinkMapping';
import UrlLinkMapping from './UrlLinkMapping';
import { CompContext } from '../../../context/CompContext';

/**
 * ListComponent renders a list of sections and URLs inside a drawer.
 * Each item in the list, when clicked, will close the drawer and scroll to the respective section on the main page.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.anchor - The anchor side of the drawer.
 * @param {Array} props.sections - Sections to be listed for navigation.
 * @param {Array} props.navLinks - Additional navigation links.
 * @param {Function} props.toggleDrawer - Function to toggle the drawer's open/close state.
 * @param {Object} props.styleDetails - Styles to be applied to the list.
 * @returns {React.Element} The rendered list component.
 */
const ListComponent = ({
    handleScrollToSection,
    anchor,
    sections,
    navLinks,
    toggleDrawer,
    styleDetails = {},
}) => {
    const compContext = useContext(CompContext);
    const font = compContext.font || 'initial';
    const backgroundColor = styleDetails.backgroundColor || '#000000';
    const textColor = styleDetails.textColor || 'white';

    const listStyle = {
        boxSizing: 'border-box',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: textColor,
        textTransform: 'uppercase',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: 0,
        margin: 0,
        top: 0,
        backgroundColor,
        ...styleDetails.listStyle,
    };

    const textStyle = {
        fontFamily: font,
        textDecoration: 'none',
        color: textColor,
        fontSize: '20px',
        margin: 2,
        ...styleDetails.textStyle,
    };

    return (
        <Grid
            sx={listStyle}
            role='presentation'
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '2rem',
                    right: '4rem',
                    backgroundColor: 'transparent',
                }}
                onClick={toggleDrawer(anchor, false)}
            >
                X
            </Box>
            <SectionsLinkMapping sections={sections} sx={textStyle}  onLinkClick={handleScrollToSection} />
            <UrlLinkMapping navLinks={navLinks} sx={textStyle} />
        </Grid>
    );
};

export default ListComponent;
