const template5 = {
	componentList: {
		news: {
			userDefinedProps: {
				layout: {
					columns: 3,
					spacing: 10,
				},
				sectionTitle: 'News',
				cardType: 'Card Option 4',
				layoutType: 'Slider',
				sectionBackgroundColor: '#000000',
				sectionTextColor: '#ffffff',
				card: {
					textColor: '#ffffff',
				},
			},
			componentName: "news",
			isVisible: true,
			version: 1,
			order: 3
		},
		navigation: {
			userDefinedProps: {
				backgroundColor: "#000000",
				position: "left",
				color: "#fffefe",
				displaySocialIcons: true
			},
			componentName: "navigation",
			isVisible: true,
			version: 1,
			order: 1
		},
		music: {
			userDefinedProps: {
				layout: {
					showMore: true,
					spacing: 2,
					rows: 3,
					columns: 3
				},
				backgroundColor: "black",
				color: "#FFF",
				sectionTitle: "Music",
				cardType: "Card Option 9",
				layoutType: "Grid",
				sectionBackgroundColor: "#000000",
				sectionTextColor: "#ffffff",
				cols: "3",
				card: {
					textColor: "#ffffff",
					roundedCorners: true
				},
				displayOnNavigation: true
			},
			componentName: "music",
			isVisible: true,
			version: 1,
			order: 4
		},
		footer: {
			userDefinedProps: {
				color: "#ffffff",
				backgroundColor: "#000000"
			},
			componentName: "footer",
			isVisible: true,
			version: 1,
			order: 11
		},
		contact: {
			userDefinedProps: {},
			componentName: "contact",
			isVisible: false,
			version: 1,
			order: 9
		},
		about: {
			userDefinedProps: {
				color: "#ffffff",
				backgroundColor: "#000000"
			},
			componentName: "about",
			isVisible: true,
			version: 1,
			order: 8
		},
		header: {
			userDefinedProps: {
				layout: "New Music Release Dark (Right)",
				button: "Listen Now",
				backgroundColor: "#000000",
				color: "#FFFFFF",
				subtitle: "New Release",
				btnColor: "#FFFFFF",
				btnBg: "#22AA02",
				sectionBackgroundColor: "#000000",
				source: "New Music",
				displaySocialIcons: true
			},
			componentName: "header",
			isVisible: true,
			version: 1,
			order: 2
		},
		video: {
			userDefinedProps: {},
			componentName: "video",
			isVisible: true,
			version: 1,
			order: 5
		},
		photos: {
			userDefinedProps: {},
			componentName: "photos",
			isVisible: false,
			version: 1,
			order: 7
		},
		tour: {
			userDefinedProps: {
				buttonBackgroundColor: "#6b6b6b",
				backgroundColor: "#000000",
				color: "#ffffff",
				titleColor: "#FFF",
				buttonColor: "#ffffff",
				sectionTitle: "TOUR"
			},
			componentName: "tour",
			isVisible: true,
			version: 1,
			order: 6
		},
		email: {
            userDefinedProps: {
                backgroundColor: "#cdced0",
                buttonBackgroundColor: "#565555",
                buttonColor: "#e8e8e8",
                color: "#4c4a4a",
                variant: '2',
            },
			componentName: "email",
			isVisible: false,
			version: 1,
			order: 10
		}
	}
}

export default template5;
