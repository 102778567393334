import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Button, Box } from '@mui/material';
import FontDrawer from './FontDrawer';

import SettingsIcon from '@mui/icons-material/Settings';
import BackWIcon from '@mui/icons-material/ArrowBack';
import { FormControlLabel, Switch } from '@mui/material';
import clsx from 'clsx';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    item: {
        color: 'white',
        backgroundColor: '#252627',
        zIndex: 10,
        display: 'flex',
        flexFlow: 'column',
    },
    buttonStyle: {
        color: 'white',
        borderColor: 'transparent',
        width: '166px',
        fontSize: '10px',
    },
    boxFullWidth: {
        width: '100%',
    },
}));

const usePresetStyle = makeStyles((theme) => ({
    optionWrapper: ({ openOptions }) => ({
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            paddingBottom: '6rem',
        },
    }),
    option: {
        textAlign: 'center',
        width: '95%',
        paddingBottom: theme.spacing(2),
    },
}));

/**
 * RenderBox Component
 *
 * @param {Object} props - Component props
 * @param {boolean} props.openOptions - To open or not the options
 * @param {boolean} props.openFontDrawer - To open or not the font drawer
 * @param {boolean} props.showDetails - To show or not the details
 * @param {Object} props.comp - Component configurations
 * @param {function} props.toggleComponent - Function to toggle component state
 * @param {boolean} props.checkedValue - Checked value for the switch
 * @param {string} props.selectedItem - Selected item label
 * @param {React.Component} props.detailComp - Detail component to render
 * @param {function} props.RenderDefaultOptions - Function to render default options
 * @param {function} props.ShowDetailPart - Function to toggle show details
 * @returns {React.Component} RenderBox component
 */
const RenderBox = (props) => {
    const {
        openOptions,
        openFontDrawer,
        showDetails,
        comp,
        toggleComponent,
        checkedValue,
        selectedItem,
        detailComp,
        RenderDefaultOptions,
        ShowDetailPart,
    } = props;

    const classes = useStyles();
    const presetClasses = usePresetStyle();

    /**
     * Handle switch state change.
     *
     * @param {Event} e - Event object
     */
    const handleSwitchChange = (e) => {
        e.stopPropagation();
        console.log('comp@RenderBox =>', comp);
        if (comp && comp.isEnabled !== undefined && e.target.checked && !comp.isEnabled) {
            toast.error(
                'Unable to turn on email capture until MailChimp integration is complete.'
            );
            return;
        }
        toggleComponent(e.target.checked);
    };

    if (!openOptions) return null;

    return (
        <Box
            sx={{
                width: { xs: '100vw', sm: '100vw', md: '450px', lg: '450px' },
                marginLeft: { xs: '0px', sm: '0px', md: '134px', lg: '134px' },
                height: '100%',
                overflowY: 'auto',
            }}
            className={classes.item}
        >
            <Box className={classes.boxFullWidth}>
                {openFontDrawer ? (
                    <FontDrawer />
                ) : (
                    <>
                        <Button
                            variant='outlined'
                            className={classes.buttonStyle}
                            onClick={() => ShowDetailPart(!showDetails)}
                        >
                            {showDetails ? (
                                <BackWIcon className={classes.icon} />
                            ) : (
                                <SettingsIcon className={classes.icon} />
                            )}
                            {showDetails ? 'Back' : 'Advance settings'}
                        </Button>
                        <FormControlLabel
                            control={
                                <Switch
                                    size='small'
                                    checked={checkedValue}
                                    onClick={handleSwitchChange}
                                />
                            }
                            label={selectedItem}
                        />
                    </>
                )}
            </Box>
            {showDetails && !openFontDrawer && detailComp}
            {!showDetails && !openFontDrawer && (
                <Box
                    className={clsx(
                        classes.boxFullWidth,
                        classes.sidebarWrapper
                    )}
                >
                    {RenderDefaultOptions(comp.key, presetClasses)}
                </Box>
            )}
        </Box>
    );
};

export default RenderBox;
