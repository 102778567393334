import React from 'react';

function AboutSection() {
    return (
        <div id='about-section' className='about-section'>
            <div className='section-header'>
                <h1 className='section-header'>About</h1>
            </div>
            <div className='about-section-wrapper'>
                <div className='about-image'>
                    <img
                        src='https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca3e9594dfb4_maxresdefault.jpg'
                        sizes='(max-width: 479px) 92vw, (max-width: 767px) 90vw, (max-width: 991px) 80vw, (max-width: 2612px) 49vw, 1280px'
                        srcSet='https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca3e9594dfb4_maxresdefault-p-800.jpeg 800w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca3e9594dfb4_maxresdefault-p-1080.jpeg 1080w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca3e9594dfb4_maxresdefault.jpg 1280w'
                        alt
                        className='image-2'
                    />
                </div>
                <div className='about-text'>
                    <p className='paragraph'>
                        <strong className='bold-text'>
                            instagram / twitter: @calscruby 
 cal scruby is 6’8” 113 lbs. the guy spends most of his
                            time completely overwhelmed by an existential / identity crisis that results in exponential
                            anxiety. good at rapping, not good at anything else. shit his pants on his last birthday
                            (age 41). expect big things from cal scruby in 2004.
                        </strong>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
