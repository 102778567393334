import React from "react";

function HomeSection() {
  return (
    <header id="home-section-new-album" className="home-section1-newalbum">
      <div className="new-video-home-wrapper w-container">
        <div className="video-info-wrapper">
          <h1 className="new-album-title">Chess Not Checkers</h1>
          <p className="new-album-description">
            New Video by Cal Scruby <br />
            October 20, 2020
          </p>
          <a
            href="https://www.youtube.com/watch?v=evrbFAz54_M"
            target="_blank"
            className="new-album-button w-button"
          >
            Watch Now
          </a>
        </div>
        <div className="video-wrapper">
          <div
            style={{ paddingTop: "56.17021276595745%" }}
            className="w-video w-embed"
          >
            <iframe
              className="embedly-embed"
              src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FevrbFAz54_M%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DevrbFAz54_M&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FevrbFAz54_M%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              scrolling="no"
              title="YouTube embed"
              frameBorder={0}
              allow="autoplay; fullscreen"
              allowFullScreen="true"
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default HomeSection;
