import React from "react";

function ContactSection() {
  return (
    <footer id="contact-footer-section" className="contact-section">
      <div className="contact-section-wrapper w-container">
        <div className="contact-container">
          <div className="team-member-wrapper">
            <h2 className="contact-header">Management</h2>
            <div className="contact-info-text">
              Cal Scruby
              <br />
              513-438-2869
              <br />
              <a
                href="mailto:ihatecalscruby@gmail.com"
                className="contact-email"
              >
                ihatecalscruby@gmail.com
                <br />‍
              </a>
            </div>
          </div>
          <div className="team-member-wrapper">
            <h2 className="contact-header">Booking</h2>
            <div>
              Cal Scruby
              <br />
              513-438-2869
              <br />
              <a href="mailto:ihatecalscruby@gmail.com">
                ihatecalscruby@gmail.com
                <br />‍
              </a>
            </div>
          </div>
          <div className="team-member-wrapper">
            <h2 className="contact-header">Press</h2>
            <div>
              Cal Scruby
              <br />
              513-438-2869
              <br />
              <a href="mailto:ihatecalscruby@gmail.com">
                ihatecalscruby@gmail.com
                <br />‍
              </a>
            </div>
          </div>
          <a href="#" className="contact-logo w-inline-block">
            <img
              src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38987c1efc2b_cal%20scruby%20logo.png"
              sizes="(max-width: 479px) 115.3125px, (max-width: 767px) 100vw, (max-width: 991px) 105.5px, 115.3125px"
              srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38987c1efc2b_cal%2520scruby%2520logo-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38987c1efc2b_cal%20scruby%20logo.png 640w"
              alt
              className="footer-image"
            />
          </a>
        </div>
        <div className="contact-copyright-text">
          Copyright © 2020 The Lunar Society
        </div>
      </div>
    </footer>
  );
}

export default ContactSection;
