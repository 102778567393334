const template1 = {
    componentList: {
        news: {
            userDefinedProps: {
                layout: {
                    columns: 2,
                },
                sectionTitle: 'News',
                cardType: 'Card Option 6',
                layoutType: 'Slider',
                sectionBackgroundColor: '#000000',
                sectionTextColor: '#ffffff',
                card: {
                    textColor: '#ffffff',
                },
            },
            componentName: 'news',
            isVisible: true,
            version: 1,
            order: 6,
        },
        navigation: {
            userDefinedProps: {
                layout: '1',
                backgroundColor: 'black',
                activity: 'fixed',
                color: 'white',
                monoIcons: true,
                iconColor: '#ffffff',
                position: 'left',
                transparentUntilScroll: false,
                displaySocialIcons: false,
            },
            componentName: 'navigation',
            isVisible: false,
            version: 1,
            order: 1,
        },
        music: {
            userDefinedProps: {
                backgroundColor: 'black',
                color: '#FFF',
                displayLayout: 'Slider',
                cardType: 'Card Option 4',
                itemView: 'Album Art + Song Name',
                sectionTextColor: '#ffffff',
                undefined: 2,
                layout: {
                    numItemsToRender: 10,
                    showMore: true,
                    spacing: 1,
                    rows: 1,
                    columns: 3,
                },
                sectionWidth: 'Narrow',
                sectionTitle: 'MUSIC',
                layoutType: 'Grid',
                sectionBackgroundColor: '#000000',
                cols: '2',
                card: {
                    textColor: '#ffffff',
                    roundedCorners: false,
                },
            },
            componentName: 'music',
            isVisible: true,
            version: 1,
            order: 4,
        },
        footer: {
            userDefinedProps: {
                color: '#ffffff',
                backgroundColor: '#000000',
            },
            componentName: 'footer',
            isVisible: true,
            version: 1,
            order: 11,
        },
        contact: {
            userDefinedProps: {
                layout: 'One Col',
                backgroundColor: '#000000',
                color: '#ffffff',
                textColor: '#ffffff',
            },
            componentName: 'contact',
            isVisible: true,
            version: 1,
            order: 9,
        },
        about: {
            userDefinedProps: {
                variant: '2',
                backgroundColor: '#000000',
                color: '#fbf6f6',
                sectionTitle: 'About',
            },
            componentName: 'about',
            isVisible: true,
            version: 1,
            order: 8,
        },
        header: {
            userDefinedProps: {
                layout: 'Cover Photo + Social Icons',
                button: 'Listen Now',
                backgroundColor: '#000000',
                color: '#FFFFFF',
                btnColor: '#FFFFFF',
                btnBg: '#FFFFFF',
                sectionBackgroundColor: '#000000',
                source: 'New Music',
                displaySocialIcons: true,
            },
            componentName: 'header',
            isVisible: true,
            version: 1,
            order: 2,
        },
        video: {
            userDefinedProps: {
                layout: {
                    showMore: true,
                    columns: 2,
                    spacing: 3,
                },
                color: '',
                sectionTitle: 'Videos',
                cardType: 'Card Option 10',
                layoutType: 'Grid',
                sectionBackgroundColor: '#020202',
                sectionTextColor: '#f7f4f4',
                card: {
                    textColor: '#f7f2f2',
                    backgroundColor: '#0a0a0a',
                    roundedCorners: false,
                },
            },
            componentName: 'video',
            isVisible: true,
            version: 1,
            order: 5,
        },
        photos: {
            userDefinedProps: {},
            componentName: 'photos',
            isVisible: true,
            version: 1,
            order: 7,
        },
        email: {
            userDefinedProps: {
                backgroundColor: "#cdced0",
                buttonBackgroundColor: "#565555",
                buttonColor: "#e8e8e8",
                color: "#4c4a4a"
            },
            componentName: 'email',
            isVisible: false,
            version: 1,
            order: 10,
        },
        tour: {
            userDefinedProps: {
                buttonBackgroundColor: '#6b6b6b',
                backgroundColor: '#000000',
                color: '#ffffff',
                titleColor: 'white',
                buttonColor: '#ffffff',
                sectionTitle: 'TOUR',
            },
            componentName: 'tour',
            isVisible: true,
            version: 1,
            order: 3,
        },
    },
    websiteFont: 'Georama',
};

export default template1;
