import React, { useContext, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import { Typography, Drawer, List, Divider, TextField } from '@mui/material';
import ColorPicker from './ColorPicker';
import { CompContext } from '../../context/CompContext';
import { ChromePicker, SketchPicker } from 'react-color';
import FontPicker from './FontPicker';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        display: 'flex',
        position: 'absolute',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        background: 'rgba(0,0,0,0)',
        color: 'black',
        boxShadow: 'none',
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    hide: {
        display: 'none',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
        height: '100%',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    style: {
        padding: 15,
    },
    listItem: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
        '& .MuiOutlinedInput-root': {
            '& label.Mui-focused': {
                color: '#02AA02',
            },
            '&:hover fieldset': {
                borderColor: '#02AA02',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#02AA02',
            },
        },
    },
    formControl: {
        minWidth: 200,
    },
    color: {
        width: '20px',
        height: '20px',
        borderRadius: '2px',
    },
    swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
}));

const data = [
    {
        id: 1,
        color: 'black',
        value: {
            name: 'text',
            label: 'Text Color',
        },
    },
    {
        id: 2,
        color: '#FFF',
        value: {
            name: 'background',
            label: 'Background Color',
        },
    },
];

const FontDrawer = ({ open, onClose, componentViewKey }) => {
    const classes = useStyles();
    const theme = useTheme();
    const compContext = useContext(CompContext);
    const [colorPicker, displayColorPicker] = useState('');
    const [pickedColor, setPickedColor] = useState('black');

    const handleDrawerClose = () => {
        onClose();
    };
    const handleClick = (fieldNameValue) => {
        if (colorPicker == !fieldNameValue) {
            displayColorPicker(fieldNameValue);
        } else {
            displayColorPicker('');
        }
    };

    const handleClose = () => {
        displayColorPicker('');
    };

    const handleChange = (color) => {
        setPickedColor(color.hex);
    };

    return (
        <div>
            {/*<Drawer
                className={classes.drawer}
                variant='persistent'
                anchor='top'
                open={open}
                onClose={handleDrawerClose}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >*/}
                {/*<div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose} size='large'>
                        {theme.direction === 'ltr' ? (
                            <CloseIcon />
                        ) : (
                            <CloseIcon />
                        )}
                    </IconButton>
                        </div>*/}
                <div className={classes.style}>
                    {/* <div className={classes.listItem}>
                        <Typography>Body</Typography>
                        <Divider />
                        <List>
                            {data.map((item, idx) => {
                                return (
                                    <div style={{ paddingTop: 5, paddingBottom: 5 }} key={idx}>
                                        <ColorPicker
                                            defaultValue={item.color}
                                            value={item.value}
                                            colorPicker={colorPicker}
                                            handleClick={handleClick}
                                            handleClose={handleClose}
                                            onFieldChange={handleChange}
                                        />
                                    </div>
                                );
                            })}
                        </List>
                    </div> */}
                    <div className={classes.listItem}>
                        <Typography>Font</Typography>
                        <Divider />
                        <FontPicker />
                    </div>
                </div>
            {/*}</Drawer>*/}
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            ></main>
        </div>
    );
};
export default FontDrawer;
