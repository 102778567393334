const template7 = {
    componentList: {
        news: {
            userDefinedProps: {
                layout: {
                    spacing: 2,
                },
                buttonBackground: '#6C2D2D',
                buttonColor: '#988181',
                sectionTitle: 'NEWS',
                cardType: 'Card Option 4',
                itemView: 'Design 3',
                layoutType: 'Grid',
                sectionBackgroundColor: '#000000',
                sectionTextColor: '#ffffff',
                card: {
                    textColor: '#ffffff',
                    roundedCorners: true,
                },
            },
            componentName: 'news',
            isVisible: true,
            version: 1,
            order: 4,
        },
        navigation: {
            userDefinedProps: {
                layout: '4',
                backgroundColor: '#000000',
                activity: 'fixed',
                color: '#ffffff',
                monoIcons: true,
                iconColor: '#ffffff',
                position: 'right',
                transparentUntilScroll: false,
                displaySocialIcons: false,
            },
            componentName: 'navigation',
            isVisible: true,
            version: 1,
            order: 1,
        },
        music: {
            userDefinedProps: {
                layout: {
                    numItemsToRender: 5,
                    spacing: 1,
                    rows: 4,
                    columns: 2,
                },
                sectionTitle: 'MUSIC',
                cardType: 'Card Option 6',
                layoutType: 'Grid',
                sectionBackgroundColor: '#000000',
                sectionTextColor: '#eadddd',
                card: {
                    textColor: '#ffffff',
                },
            },
            componentName: 'music',
            isVisible: true,
            version: 1,
            order: 5,
        },
        footer: {
            userDefinedProps: {
                variant: '1',
                backgroundColor: '#000000',
                color: 'white',
                displaySocialIcons: false,
            },
            componentName: 'footer',
            isVisible: true,
            version: 1,
            order: 11,
        },
        contact: {
            userDefinedProps: {
                layout: 'Three Cols',
                backgroundColor: '#000000',
                color: '#ffffff',
                textColor: '#ffffff',
            },
            componentName: 'contact',
            isVisible: true,
            version: 1,
            order: 9,
        },
        about: {
            userDefinedProps: {
                color: '#ffffff',
                variant: '4',
                backgroundColor: '#000000',
            },
            componentName: 'about',
            isVisible: true,
            version: 1,
            order: 8,
        },
        header: {
            userDefinedProps: {
                layout: 'Cover Photo + Artist Name',
                button: 'Click Here',
                backgroundColor: '#000000',
                color: '#FFFFFF',
                subtitle: '',
                btnColor: '#000000',
                btnBg: '#FFFFFF',
                sectionBackgroundColor: '#000000',
                source: 'Cover Photo',
                enableParallax: false,
                displaySocialIcons: true,
            },
            componentName: 'header',
            isVisible: true,
            version: 1,
            order: 2,
        },
        video: {
            userDefinedProps: {
                sectionTitle: 'VIDEO',
                titlePos: 'top',
                displayLayout: 'Slider',
                cardType: 'Card Option 4',
                itemView: 'Design 3',
                layoutType: 'Slider',
                sectionBackgroundColor: '#000000',
                sectionTextColor: '#ffffff',
                cols: '2',
                card: {
                    textColor: '#ffffff',
                    backgroundColor: '#000000',
                },
            },
            componentName: 'video',
            isVisible: true,
            version: 1,
            order: 6,
        },
        photos: {
            userDefinedProps: {},
            componentName: 'photos',
            isVisible: true,
            version: 1,
            order: 7,
        },
        email: {
            userDefinedProps: {
                backgroundColor: "#cdced0",
                buttonBackgroundColor: "#565555",
                buttonColor: "#e8e8e8",
                color: "#4c4a4a",
                variant: '2',
            },
            componentName: 'email',
            isVisible: false,
            version: 1,
            order: 10,
        },
        tour: {
            userDefinedProps: {
                color: '#ffffff',
                titleColor: '#ffffff',
                backgroundColor: '#000000',
            },
            componentName: 'tour',
            isVisible: true,
            version: 1,
            order: 3,
        },
    },
    websiteFont: 'Josefin Sans',
};

export default template7;
