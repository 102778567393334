import React, { useContext } from 'react';
import {
    ListItemText,
    Typography,
    FormControlLabel,
    Switch,
    ButtonGroup,
    IconButton,
    Box,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import _get from 'lodash.get';
import { CompContext } from '../../context/CompContext';
import isEmpty from 'lodash.isempty';
import { toast } from 'react-toastify';

import ListItemIcon from '@material-ui/core/ListItemIcon';

import DashboardIcon from '@mui/icons-material/Dashboard';
import CreateIcon from '@mui/icons-material/Create';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ShapesIcon from '@mui/icons-material/PanoramaFishEye';
import ImagesIcon from '@mui/icons-material/Image';
import TemplatesIcon from '@mui/icons-material/ViewQuilt';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import MuiListItem from '@mui/material/ListItem';
import { withStyles } from '@material-ui/core/styles';
import styles from './SideMenu.module.css';
import { ReactSVG } from 'react-svg';
import MusicWIcon from '../images/icons/music_white_24dp.svg';
import AboutWIcon from '../images/icons/about_white_24dp.svg';
import HWIcon from '../images/icons/home_white_24dp.svg';
import TourWIcon from '../images/icons/tour_white_24dp.svg';
import ContactWIcon from '../images/icons/contact_white_24dp.svg';
import DashWIcon from '../images/icons/dashboard_white_24dp.svg';
import FontsWIcon from '../images/icons/fonts_white_24dp.svg';
import NewsWIcon from '../images/icons/news_white_24dp.svg';
import PhotosWIcon from '../images/icons/photos_white_24dp.svg';
import VideosWIcon from '../images/icons/videos_white_24dp.svg';
import FooterWIcon from '../images/icons/footer_white_24dp.svg';
import EmailWIcon from '../images/icons/subscribe_white_24dp.svg';
import NavWIcon from '../images/icons/navigation_w_icon.svg';
import { width } from '@mui/system';

const ListItem = withStyles({
    root: {
        '&$selected': {
            //this works//filter: invert(48%) sepia(48%) saturate(3248%) hue-rotate(75deg) brightness(95%) contrast(102%);
            backgroundColor: '#252627',
            color: 'white',
            '& .MuiListItemIcon-root': {
                color: 'white',
            },
        },
        '&$selected img': {
            filter: 'invert(48%) sepia(48%) saturate(3248%) hue-rotate(75deg) brightness(95%) contrast(102%)',
        },
        '&$selected:hover': {
            //backgroundColor: "purple",
            color: 'white',
            '& .MuiListItemIcon-root': {
                color: 'white',
            },
        },
        '&:hover': {
            backgroundColor: '#252627 !important',
            color: 'white',
            '& .MuiListItemIcon-root': {
                color: 'white',
            },
        },
    },
    selected: {},
})(MuiListItem);

const ToggleNavItem = ({
    selectedItem,
    openOptions,
    setOptionsOpenValue,
    handleFont,
    selectOption,
    componentDetails,
    componentConfig,
    onNavItemClick,
    ...props
}) => {
    const compContext = useContext(CompContext);

    const IconReturn = (ic) => {
        switch (ic) {
            case 'navigation':
                return NavWIcon;
            case 'header':
                return HWIcon;
            case 'music':
                return MusicWIcon;
            case 'tour':
                return TourWIcon;
            case 'video':
                return VideosWIcon;
            case 'news':
                return NewsWIcon;
            case 'photos':
                return PhotosWIcon;
            case 'about':
                return AboutWIcon;
            case 'contact':
                return ContactWIcon;
            case 'email':
                return EmailWIcon;
            case 'footer':
                return FooterWIcon;
            default:
                return MusicWIcon;
        }
    };

    const toggleComponent = (componentKey, toggleOn) => {
        //compContext.toggleComponent(componentKey, toggleOn);
        // set 250ms timeout to wait for when a component is toggled on
        setTimeout(() => {
            if (toggleOn) {
                try {
                    const containerElement =
                        document.getElementById('mainContainer');
                    if (containerElement != null) {
                        //containerElement.scrollTop = 0;
                        const myElement = document.getElementById(componentKey);
                        const topPos = myElement.offsetTop;
                        containerElement.scroll({
                            behavior: 'smooth',
                            top: topPos - 168,
                        });
                        //containerElement.scrollTop = topPos -168;
                    } else {
                        window.document
                            .getElementById(componentKey)
                            .scrollIntoView({
                                behavior: 'smooth',
                                block: 'start',
                            });
                    }
                } catch (e) {
                    console.log(
                        `Error scrolling to element ${componentKey}`,
                        e.message
                    );
                }
            }
        }, 500);
    };

    const changePosition = (componentKey, currentOrder, changeMagnitude) => {
        compContext.changeOrder(componentKey, currentOrder, changeMagnitude);
    };

    if (isEmpty(componentConfig)) {
        return <></>;
    }

    const currentOrder = componentConfig.order;
    return (
        <ListItem
            onClick={(e) => {
                e.stopPropagation();
                if (
                    componentDetails.hasOwnProperty('isEnabled') &&
                    !componentDetails.isEnabled(props)
                ) {
                    toast.error(
                        'Unable to turn on email capture until MailChimp integration is complete.'
                    );
                    return;
                }
                toggleComponent(componentDetails.key, true);
                onNavItemClick(componentDetails.key);
                selectOption(
                    componentDetails.title,
                    componentDetails.key,
                    componentDetails,
                    componentConfig,
                    compContext
                );
                handleFont(false);
                setOptionsOpenValue(openOptions, componentDetails.key);
            }}
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                maxHeight: '55px',
            }}
            selected={selectedItem == componentDetails.title}
        >
            <ListItemIcon>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <img
                        src={IconReturn(componentDetails.key)}
                        width={'62px'}
                        height={'30px'}
                        style={{
                            backgroundColor: 'transparent',
                            color: 'black',
                            fill: 'whitesmoke',
                        }}
                    />
                    <span
                        style={{
                            textAlign: 'center',
                            color: 'whitesmoke',
                            fontSize: '12px',
                        }}
                    >
                        {componentDetails.title}
                    </span>
                </Box>
            </ListItemIcon>
            <ButtonGroup orientation='vertical'>
                {currentOrder > 1 && (
                    <IconButton
                        size='small'
                        style={{ padding: '0px' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            changePosition(
                                componentDetails.key,
                                currentOrder,
                                -1
                            );
                        }}
                    >
                        <KeyboardArrowUpIcon
                            size='small'
                            sx={{ color: 'whitesmoke' }}
                        />
                    </IconButton>
                )}
                {currentOrder <
                    _get(compContext, 'orderedComponentKeys', []).length && (
                    <IconButton
                        size='small'
                        style={{ padding: '0px' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            changePosition(
                                componentDetails.key,
                                currentOrder,
                                1
                            );
                        }}
                    >
                        <KeyboardArrowDownIcon sx={{ color: 'whitesmoke' }} />
                    </IconButton>
                )}
            </ButtonGroup>
        </ListItem>
    );
};

export default ToggleNavItem;
