import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export const buttonsVariants = {
    contained: 'contained',
    outlined: 'outlined',
    text: 'text'
}

const style = {
    position: 'absolute',
    top: { lg: '50%', xs: 0 },
    left: { lg: '50%', xs: 0 },
    transform: { lg: 'translate(-50%, -50%)', xs: '' },
    width: { md: 500, xs: '100%' },
    maxWidth: '100vw',
    height: { lg: 'auto', xs: '100vh' },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const buttonStyle = {
    m: '8px 5px',
    fontSize: '1rem'
};

const BasicModal = ({
    setModalProps,
    isOpen,
    title,
    paragraph,
    actionLabel,
    actionCallback,
    customButtons
}) => {
    const [open, setOpen] = useState(isOpen);

    const CustomButtons = () => {
        const handleClick = e => {
            e.preventDefault()
            const button = customButtons.find(
                button => button.actionLabel === e.currentTarget.id
            )
            setModalProps({
                isOpen: false
            })
            button.actionCallback()
        }

        return customButtons.map(button => {
            return (
                <Button
                    id={button.actionLabel}
                    sx={buttonStyle}
                    variant={button.variant || buttonsVariants.contained}
                    onClick={handleClick}
                >
                    {button.actionLabel}
                </Button>
            )
        })
    }

    const handleAction = () => {
        setModalProps({
            isOpen: false,
        });
        actionCallback();
    };

    const handleClose = () => {
        setOpen(false);
        setModalProps({
            isOpen: false,
        });
    };

    const ActionButton = () => {
        return (
            <Button sx={buttonStyle} variant='contained' onClick={handleAction}>
                {actionLabel}
            </Button>
        )
    }
    const DefaultButtons = () => [
        <ActionButton />,
        <Button
            sx={buttonStyle}
            onClick={handleClose}
            variant='outlined'
            color='error'
        >
            Cancel
        </Button>
    ]

    if (!open) {
        return <></>;
    }

    return (
        <Modal
            open={true}
            onClose={handleClose}
            aria-labelledby='basic-modal-title'
            aria-describedby='basic-modal-description'
        >
            <Box sx={style}>
                <Typography id='basic-modal-title' variant='h6' component='h2'>
                    {title}
                </Typography>
                <Typography id='basic-modal-description' sx={{ mt: 2 }}>
                    {paragraph}
                </Typography>
                <Box
                    display='flex'
                    justifyContent='space-evenly'
                    flexWrap={'wrap'}
                >
                    {customButtons ? <CustomButtons /> : <DefaultButtons />}
                </Box>
            </Box>
        </Modal>
    );
};

export default BasicModal;
