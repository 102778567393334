import React from "react";

function HomeSection() {
  return (
    <div id="home-section" className="home-section">
      <div
        data-animation="slide"
        data-duration={500}
        data-infinite={1}
        className="home-page-slider w-slider"
      >
        <div className="mask w-slider-mask">
          <div className="slider-image-wrapper w-slide">
            <img
              src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f7a163482b9721516d_Cal%20Scruby%20News%204%20Unsigned.png"
              sizes="(max-width: 1478px) 100vw, 1478px"
              srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f7a163482b9721516d_Cal%20Scruby%20News%204%20Unsigned-p-500.png 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f7a163482b9721516d_Cal%20Scruby%20News%204%20Unsigned-p-800.png 800w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f7a163482b9721516d_Cal%20Scruby%20News%204%20Unsigned-p-1080.png 1080w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f7a163482b9721516d_Cal%20Scruby%20News%204%20Unsigned.png 1478w"
              alt
            />
          </div>
          <div className="slider-image-wrapper w-slide">
            <img
              src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8e83d3d4d6343998b_Cal%20Scruby%20News%201%20WWWS.png"
              sizes="100vw"
              srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8e83d3d4d6343998b_Cal%20Scruby%20News%201%20WWWS-p-500.png 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8e83d3d4d6343998b_Cal%20Scruby%20News%201%20WWWS-p-800.png 800w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8e83d3d4d6343998b_Cal%20Scruby%20News%201%20WWWS-p-1080.png 1080w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8e83d3d4d6343998b_Cal%20Scruby%20News%201%20WWWS-p-1600.png 1600w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8e83d3d4d6343998b_Cal%20Scruby%20News%201%20WWWS.png 1790w"
              alt
              className="image-8"
            />
          </div>
          <div className="slider-image-wrapper w-slide">
            <img
              src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8f273cf0b1ca1d96e_Cal%20Scruby%20News%205%20White%20Privledge.png"
              sizes="100vw"
              srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8f273cf0b1ca1d96e_Cal%20Scruby%20News%205%20White%20Privledge-p-500.png 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8f273cf0b1ca1d96e_Cal%20Scruby%20News%205%20White%20Privledge-p-800.png 800w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8f273cf0b1ca1d96e_Cal%20Scruby%20News%205%20White%20Privledge-p-1080.png 1080w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8f273cf0b1ca1d96e_Cal%20Scruby%20News%205%20White%20Privledge.png 1268w"
              alt
              className="image-9"
            />
          </div>
        </div>
        <div className="w-slider-arrow-left">
          <div className="w-icon-slider-left" />
        </div>
        <div className="w-slider-arrow-right">
          <div className="w-icon-slider-right" />
        </div>
        <div className="w-slider-nav w-round" />
      </div>
    </div>
  );
}

export default HomeSection;
