import React from 'react';
import MusicLayout from '../../../shared/music/MusicLayout';
import isEmpty from 'lodash.isempty';

function MusicSection({ music }) {
    return (
        <div id='music-section' className='music-section'>
            {!isEmpty(music) && (
                <div className='music-section-wrapper'>
                    <div className='music-header-wrapper'>
                        <h1 className='section-header'>Music</h1>
                    </div>
                    <div className='music-collection'>
                        <MusicLayout
                            music={music}
                            numItems={3}
                            renderItem={({ url, thumbnail, title }) => (
                                <div className='music-item-wrapper'>
                                    <div className='music-item-title'>
                                        <h5 className='music-title-header'>
                                            {title}
                                        </h5>
                                    </div>
                                    <a
                                        href={url}
                                        target='blank'
                                        className='music-item-image'
                                    >
                                        <img
                                            src={thumbnail}
                                            sizes='(max-width: 479px) 100vw, (max-width: 767px) 69vw, (max-width: 991px) 37vw, 28vw'
                                            alt
                                            className='music-image'
                                        />
                                    </a>
                                    {/* <div className='music-item-buttons'>
                                    <div className='streaming-button-div'>
                                        <a
                                            href='#'
                                            className='streaming-button w-button'
                                        >
                                            Stream Music{' '}
                                        </a>
                                    </div>
                                    <div className='purchase-button-div'>
                                        <a
                                            href='#'
                                            className='purchase-button w-button'
                                        >
                                            Purchase Music
                                        </a>
                                    </div>
                                </div> */}
                                </div>
                            )}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default MusicSection;
