import React, { useRef, useState, useEffect, createContext } from 'react';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash.clonedeep';
import _isEmpty from 'lodash.isempty';
import _find from 'lodash.find';
import _sortBy from 'lodash.sortby';
import _get from 'lodash.get';
import _set from 'lodash.set';
import websiteApi from '../api/websiteApi';
import { sectionList } from '../sections';
import isEmpty from 'lodash.isempty';
import {
    getLayoutFromName,
    HEADERS_SECTION_KEY,
    HEADERS_WITH_NAV,
    HEADERS_WITHOUT_NAV,
} from '../sections/header';
import { NAVIGATION_SECTION_KEY } from '../sections/navigation';

export const CompContext = createContext({
    configType: '',
    getComponentList: () => { },
    getComponentConfig: () => { },
    getDetailedEditor: () => { },
    setSelectedTemplate: () => { },
    updateComponentConfig: () => { },
    updateComponentConfigUserDefinedProps: () => { },
    savePreviewConfig: () => { },
    savePublishedConfig: () => { },
    getDomain: () => { },
    templateConfiguration: {},
    font: '',
    setWebFont: () => { },
    toggleComponent: () => { },
    changeOrder: () => { },
    isAutoSaving: undefined,
    handleMenu: () => { },
    openMenu: false,
    isLoading: true,
    setIsLoading: () => { },
});

const CompProvider = ({
    children,
    entityId,
    defaultConfigType,
    isBuilder = true,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [templateConfiguration, setTemplateConfiguration] = useState({});
    const [font, setFont] = useState('Montserrat');
    const [configType] = useState(defaultConfigType || 'PREVIEW');
    const [orderedComponentKeys, setOrderedComponentKeys] = useState([]);
    const [isAutoSaving, setIsAutoSaving] = useState(false);
    const [openMenu, setOpenMenu] = useState(false);
    const [WebFontLoader, setWebFontLoader] = useState(undefined);

    const getComponentList = () => {
        const sortedComponents = _sortBy(
            Object.values(_get(templateConfiguration, 'componentList', {})),
            (sc) => {
                return sc.order;
            }
        );
        return sortedComponents;
    };

    const getComponentConfig = (key) => {
        if (
            _isEmpty(templateConfiguration) ||
            _isEmpty(templateConfiguration.componentList)
        ) {
            return undefined;
        }
        return templateConfiguration.componentList[key];
    };

    const getDetailedEditor = (key) => {
        if (key === HEADERS_SECTION_KEY) {
            const layoutKey = getComponentConfig(key).userDefinedProps.layout;
            return sectionList[key].detailedEditor(layoutKey);
        }
        return sectionList[key].detailedEditor;
    };

    const disableComponent = (templateConfigCopy, componentName) => {
        if (templateConfigCopy.componentList[componentName]) {
            templateConfigCopy.componentList[componentName].isVisible = false;

            let readableName = componentName;
            if (
                sectionList[componentName] &&
                sectionList[componentName].title
            ) {
                readableName = sectionList[componentName].title;
            }
            readableName =
                readableName.charAt(0).toUpperCase() + readableName.slice(1);
        }
        return templateConfigCopy;
    };

    const enableComponent = (templateConfigCopy, componentName) => {
        if (templateConfigCopy.componentList[componentName]) {
            templateConfigCopy.componentList[componentName].isVisible = true;

            let readableName = componentName;
            if (
                sectionList[componentName] &&
                sectionList[componentName].title
            ) {
                readableName = sectionList[componentName].title;
            }
            readableName =
                readableName.charAt(0).toUpperCase() + readableName.slice(1);
        }
        return templateConfigCopy;
    };

    const checkAndDisableNav = (templateConfigCopy, componentName, layout) => {
        if (
            componentName === HEADERS_SECTION_KEY &&
            HEADERS_WITH_NAV.includes(getLayoutFromName(layout))
        ) {
            return disableComponent(templateConfigCopy, NAVIGATION_SECTION_KEY);
        }

        if (
            componentName === HEADERS_SECTION_KEY &&
            HEADERS_WITHOUT_NAV.includes(getLayoutFromName(layout))
        ) {
            return enableComponent(templateConfigCopy, NAVIGATION_SECTION_KEY);
        }

        return templateConfigCopy;
    };

    const updateComponentConfig = (componentName, propertyName, value) => {
        let templateConfigCopy = _cloneDeep(templateConfiguration);

        if (
            componentName === HEADERS_SECTION_KEY &&
            propertyName === 'layout'
        ) {
            // if the layout is changed, disable the nav if necessary
            templateConfigCopy = checkAndDisableNav(
                templateConfigCopy,
                componentName,
                value
            );
        }

        _set(
            templateConfigCopy,
            `componentList.${componentName}.userDefinedProps.${propertyName}`,
            value
        );
        setTemplateConfiguration(templateConfigCopy);
    };

    const updateComponentConfigUserDefinedProps = (
        configUserData,
        componentName
    ) => {
        // console.log('updateComponentConfigUserDefinedProps', {
        //     componentName,
        //     configUserData,
        // });
        let templateConfigCopy = _cloneDeep(templateConfiguration);
        const newLayout = configUserData.layout;
        if (newLayout) {
            templateConfigCopy = checkAndDisableNav(
                templateConfigCopy,
                componentName,
                newLayout
            );
        }
        _set(
            templateConfigCopy,
            `componentList.${componentName}.userDefinedProps`,
            configUserData
        );
        setTemplateConfiguration(templateConfigCopy);
    };

    const getPreviewTemplateConfig = async () => {
        if (isEmpty(entityId)) {
            return;
        }
        const templateConfigs = await websiteApi.getTemplates(
            entityId,
            'ARTIST',
            configType
        );
        if (
            isEmpty(templateConfigs) ||
            isEmpty(templateConfigs[0]) ||
            isEmpty(templateConfigs[0].componentList)
        ) {
            const templateConfigs = await websiteApi.getTemplates(
                entityId,
                'ARTIST',
                'PREVIEW'
            );
            setTemplateConfiguration(templateConfigs[0]);
        } else {
            setTemplateConfiguration(templateConfigs[0]);
        }
    };

    const savePreviewConfig = async (refresh = true) => {
        const templateConfig = await websiteApi.savePreviewConfig(
            templateConfiguration
        );
        refresh && setTemplateConfiguration(templateConfig);
    };

    const setSelectedTemplate = async (templateComponentList, templateFont) => {
        let templateConfigCopy = _cloneDeep(templateConfiguration);
        _set(templateConfigCopy, `componentList`, templateComponentList);
        setTemplateConfiguration(templateConfigCopy);

        if (!isEmpty(templateFont)) {
            _set(templateConfigCopy, `websiteFont`, templateFont);
            setFont(templateFont);
        }
        await savePreviewConfig(false)
    };

    const savePublishedConfig = async () => {
        try {
            const response = await websiteApi.savePublishedConfig(
                templateConfiguration
            );
            const templateConfig = response.data.templateConfig;
            setTemplateConfiguration(templateConfig);

            return response;
        } catch (e) {
            console.log('Error saving published config', e.response.data.msg);
            throw e;
        }
    };

    const getDomain = async (artistId) => {
        try {
            const data = await websiteApi.getDomain(artistId);
            return data;
        } catch (e) {
            console.log('Error getting domain', e);
            throw e.message;
        }
    };

    const toggleComponent = (componentKey, toggleOn) => {
        let templateConfigCopy = _cloneDeep(templateConfiguration);
        if (toggleOn) {
            templateConfigCopy.componentList[componentKey].isVisible = true;
        } else {
            templateConfigCopy.componentList[componentKey].isVisible = false;
        }
        setTemplateConfiguration(templateConfigCopy);
    };

    const updateOrder = (
        component1Key,
        component1Order,
        component2Key,
        component2Order
    ) => {
        let templateConfigCopy = _cloneDeep(templateConfiguration);
        templateConfigCopy.componentList[component1Key].order = component1Order;
        templateConfigCopy.componentList[component2Key].order = component2Order;
        setTemplateConfiguration(templateConfigCopy);
    };

    const changeOrder = (componentKey, currentOrder, changeMagnitude) => {
        const newOrder =
            parseInt(currentOrder, 10) + parseInt(changeMagnitude, 10);
        const componentToMove = _find(
            Object.values(templateConfiguration.componentList),
            (c) => c.order === newOrder
        );
        updateOrder(
            componentKey,
            newOrder,
            componentToMove.componentName,
            currentOrder
        );
    };

    const autoSave = async () => {
        if (isBuilder) {
            setIsAutoSaving(true);
            await savePreviewConfig(false);
            setIsAutoSaving(false);
        }
    };

    const buildDefaultComponentList = (tc) => {
        let defaultTemplateConfiguration = _cloneDeep(tc);
        Object.values(sectionList).forEach((c) => {
            defaultTemplateConfiguration.componentList[c.key] = {
                componentName: c.key,
                version: 1,
                order: c.defaultOrder,
                userDefinedProps: {},
                isVisible: false,
            };
        });
        return defaultTemplateConfiguration;
    };

    const previousComponentListRef = useRef();

    useEffect(() => {
        const handleTemplateConfigChange = () => {
            if (
                !isEmpty(templateConfiguration) &&
                !isEmpty(templateConfiguration.componentList) &&
                !_isEqual(
                    previousComponentListRef.current,
                    templateConfiguration.componentList
                )
            ) {
                previousComponentListRef.current =
                    templateConfiguration.componentList;

                const sortedComponentKeyList = _sortBy(
                    Object.keys(templateConfiguration.componentList),
                    (key) => {
                        const componentConfig = getComponentConfig(key);

                        if (componentConfig && componentConfig.order) {
                            return componentConfig.order;
                        }
                        return sectionList[key].defaultOrder;
                    }
                );

                setOrderedComponentKeys(sortedComponentKeyList);

                if (sortedComponentKeyList.length > 0) {
                    setIsLoading(false);
                }
            }

            if (isAutoSaving === false) {
                autoSave();
            }
            setFont(templateConfiguration.websiteFont);
        };

        handleTemplateConfigChange();
    }, [templateConfiguration]);

    const fetchPreviewTemplateConfig = async () => {
        console.log('entity id => ', entityId);
        if (_isEmpty(entityId)) {
            console.log('No entity ID provided');
            return;
        }
            if (!_isEmpty(templateConfiguration) && !_isEmpty(templateConfiguration.componentList)) {
                console.log('Template configuration already loaded');
                return;
            }

        setIsLoading(true);
        try {
            console.log('Fetching Template configuration');
            let response = await websiteApi.getTemplates(entityId, 'ARTIST', configType);
            if (_isEmpty(response) || _isEmpty(response[0])) {
                console.log(`No ${configType} configuration found, fetching PREVIEW configuration`);
                response = await websiteApi.getTemplates(entityId, 'ARTIST', 'PREVIEW');
                if (_isEmpty(response) || _isEmpty(response[0]) || _isEmpty(response[0].componentList)) {
                    console.error('No PREVIEW configuration found, using default configuration');
                    setTemplateConfiguration(
                        buildDefaultComponentList(response[0])
                    );
                } else {
                    console.log('Template PREVIEW configuration fetched');
                    setTemplateConfiguration(
                        buildDefaultComponentList(response[0])
                    );
                    // setTemplateConfiguration(response[0]);
                }
            } else {
                console.log(`Template ${configType} configuration fetched`);
                setTemplateConfiguration(
                    buildDefaultComponentList(response[0])
                );
            }
        } catch (error) {
            console.error('Failed to fetch template configuration:', error);
            setTemplateConfiguration(buildDefaultComponentList({}));  // Default fallback
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        
            // fetchPreviewTemplateConfig();  // Now correctly using fetchPreviewTemplateConfig
            getPreviewTemplateConfig();  // Now correctly using fetchPreviewTemplateConfig
    }, [entityId]);

    useEffect(() => {
        if (!WebFontLoader) {
            import('webfontloader').then((WebFont) => {
                setWebFontLoader(WebFont);
            });
        }
        if (WebFontLoader && font) {
            WebFontLoader.load({
                google: {
                    families: [font],
                },
            });
        }
    }, [font, WebFontLoader]);

    const handleMenu = () => {
        setOpenMenu(!openMenu);
    };

    const setWebFont = (newFont) => {
        let templateConfigCopy = _cloneDeep(templateConfiguration);
        _set(templateConfigCopy, `websiteFont`, newFont);
        setTemplateConfiguration(templateConfigCopy);
        setFont(newFont);
    };

    return (
        <CompContext.Provider
            value={{
                getComponentList,
                getComponentConfig,
                getDetailedEditor,
                setSelectedTemplate,
                updateComponentConfig,
                updateComponentConfigUserDefinedProps,
                savePreviewConfig,
                savePublishedConfig,
                getDomain,
                templateConfiguration,
                font,
                setWebFont,
                toggleComponent,
                changeOrder,
                orderedComponentKeys,
                isAutoSaving,
                handleMenu,
                openMenu,
                isLoading,
                setIsLoading,
            }}
        >
            {children}
        </CompContext.Provider>
    );
};

export default CompProvider;
