import React from 'react';
import NoContent from '../../../shared/NoContent';
import isEmpty from 'lodash.isempty';

function MusicSection({ profile, theme, music }) {
    return (
        <div id='music-section'>
            {!isEmpty(music) && (
                <div className='music-section-wrapper'>
                    <div className='section-header'>
                        <h1
                            className='section-header'
                            style={{ color: theme.textColor }}
                        >
                            Music
                        </h1>
                    </div>
                    <NoContent
                        hasContent={profile && profile.id_spotify ? true : false}
                        style={{
                            color: theme.textColor,
                            opacity: 0.8,
                            textAlign: 'center',
                            margin: 20,
                        }}
                    />
                    {profile && profile.id_spotify && (
                        <div className='music-section-player-wrapper'>
                            <div className='music-player-embed w-embed w-iframe'>
                                <iframe
                                    src={`https://open.spotify.com/embed/artist/${profile.id_spotify}`}
                                    width='100%'
                                    height={450}
                                    frameBorder={0}
                                    allowTransparency='true'
                                    allow='encrypted-media'
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default MusicSection;
