import React from 'react';
import CardiBMenu from './CardiBMenu';
import MenuIconComponent from './MenuIconComponent';
import { AVAILABLE_MENU_LAYOUTS } from '../../../sections/header/constants';

/**
 * MenuFactory is a component that dynamically renders different menu layouts.
 * It decides which menu component to render based on the provided configuration.
 *
 * @param {Object} props - Props for MenuFactory.
 * @param {Object} props.custom - Custom configuration for the menu.
 * @param {Function} props.toggleDrawer - Function to toggle the menu drawer.
 * @param {Object} props.state - State of the menu component.
 * @param {Object} props.styleDetails - Custom styling details.
 * @param {React.Component} props.ListComponent - Component to be used for listing menu items.
 * @returns {React.Element} Rendered menu component based on configuration.
 */
const MenuFactory = ({
    custom = {},
    toggleDrawer,
    state,
    styleDetails = {},
    ListComponent,
}) => {
    const { component: customComponent, props: customProps = {}, listComponentStyle } = custom;

    if (customComponent === AVAILABLE_MENU_LAYOUTS.CARDI_B) {
        return (
            <CardiBMenu
                {...customProps}
                toggleDrawer={toggleDrawer}
                state={state}
                ListComponent={ListComponent}
                sx={listComponentStyle || styleDetails}
            />
        );
    }

    return (
        <MenuIconComponent
            {...customProps}
            toggleDrawer={toggleDrawer}
            state={state}
            ListComponent={ListComponent}
            sx={styleDetails}
        />
    );
};

export default MenuFactory;
