import React, { useContext, useRef, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Drawer, List, Button, Box, IconButton, Divider } from '@mui/material';
import { withStyles } from '@material-ui/core/styles';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import SectionSelection from '../../shared/editor/SectionSelection';
import JamfeedLogo from '../images/JamFeedLogo-light.png';

import { ReactSVG } from 'react-svg';
import MusicIcon from '../images/icons/music icon.svg';
import HWIcon from '../images/icons/home_white_24dp.svg';

import FontDrawer from './FontDrawer';
import { CreatorContext } from '../../context/creatorContext';
import { CompContext } from '../../context/CompContext';
import clsx from 'clsx';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TempImaghe from '../images/templates/template1.png';

import MusicImageTaylor from '../images/MusicScreenshot1.png';
import VideoImageTaylor from '../images/VideoScreenshot1.png';
import AboutImageTaylor from '../images/AboutScreenshot1.png';
import FooterImageTaylor from '../images/FooterScreenshot1.png';
import TourImageTaylor from '../images/TourScreenshot1.png';
import NewsImageTaylor from '../images/NewsScreenshot1.png';

import MusicImageYoung from '../images/MusicScreenshot2.png';
import VideoImageYoung from '../images/VideoScreenshot2.png';
import AboutImageYoung from '../images/AboutScreenshot2.png';
import FooterImageYoung from '../images/FooterScreenshot2.png';
import TourImageYoung from '../images/TourScreenshot2.png';
import NewsImageYoung from '../images/NewsScreenshot2.png';

import MusicImageMalone from '../images/MusicScreenshot3.png';
import VideoImageMalone from '../images/VideoScreenshot3.png';
import AboutImageMalone from '../images/AboutScreenshot3.png';
//import FooterImageMalone from '../images/FooterScreenshot3.png'
import TourImageMalone from '../images/TourScreenshot3.png';
import NewsImageMalone from '../images/NewsScreenshot3.png';
import PhotosImageMalone from '../images/PhotosScreenshot1.png';
import ContactImageMalone from '../images/ContactScreenshot1.png';

import MusicImageYoungw from '../images/MusicScreenshot4.png';
import VideoImageYoungw from '../images/VideoScreenshot4.png';
import AboutImageYoungw from '../images/AboutScreenshot4.png';
import FooterImageYoungw from '../images/FooterScreenshot3.png';
import NewsImageYoungw from '../images/NewsScreenshot4.png';
import PhotosImageYoung from '../images/PhotosScreenshot4.png';
import ContactImageYoung from '../images/ContactScreenshot4.png';

import MusicImageKid from '../images/MusicScreenshot5.png';
import VideoImageKid from '../images/VideoScreenshot5.png';
import AboutImageKid from '../images/AboutScreenshot5.png';
//import FooterImageKid from '../images/kid_footer.png'
import NewsImageKid from '../images/NewsScreenshot5.png';
import PhotosImageKid from '../images/PhotosScreenshot3.png';
import ContactImageKid from '../images/ContactScreenshot3.png';

import MusicImageRed from '../images/MusicScreenshot6.png';
import VideoImageRed from '../images/VideoScreenshot6.png';
import AboutImageRed from '../images/red_about.png';
import FooterImageRed from '../images/FooterScreenshot4.png';
import TourImageRed from '../images/TourScreenshot4.png';
import NewsImageRed from '../images/NewsScreenshot6.png';
import PhotosImageRed from '../images/PhotosScreenshot2.png';
import ContactImageRed from '../images/ContactScreenshot2.png';

import FontWIcon from '../images/icons/fonts_white_24dp.svg';
import DashWIcon from '../images/icons/dashboard_white_24dp.svg';

import DashboardIcon from '@mui/icons-material/Dashboard';
import CreateIcon from '@mui/icons-material/Create';
import SettingsIcon from '@mui/icons-material/Settings';
import BackWIcon from '@mui/icons-material/ArrowBack';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ShapesIcon from '@mui/icons-material/PanoramaFishEye';
import ImagesIcon from '@mui/icons-material/Image';
import TemplatesIcon from '@mui/icons-material/ViewQuilt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MuiListItem from '@material-ui/core/ListItem';
import {
    Typography,
    FormControlLabel,
    Switch,
    ButtonGroup,
} from '@mui/material';
import SectionDefaults from './SectionsDefaultData';

import styles from './SideMenu.module.css';
import RenderBox from './RenderBox';
import { HEADERS_SECTION_KEY } from '../../sections/header';
import headersPresets from '../images/headerPresets/index';

const transitionStyles = {
    transition: 'all 0.3s ease-in-out',
};

const useStyles = makeStyles((theme) => ({
    drawerPaper: ({ openOptions }) => ({
        display: 'flex',
    }),
    sidebarWrapper: {
        '& .MuiDrawer-paperAnchorDockedLeft': {
            ...transitionStyles,
            // [theme.breakpoints.down('md')]: {
            //     left: -280,
            // },
        },
    },
    on: {
        '& .MuiDrawer-paperAnchorDockedLeft': {
            left: '0 !important',
        },
    },
    item: {
        color: 'white',
        backgroundColor: '#252627',
        zIndex: 10,
        display: 'flex',
        flexFlow: 'column',
    },
    list: {
        width: 260,
        paddingBottom: 50,
    },
    logo: {
        maxWidth: '100%',
        padding: 15,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    title: {
        fontWeight: 'bold',
    },
    extraButtonText: {
        textTransform: 'capitalize',
        color: '#fff',
        fontSize: '12px'
    },
    extraButtons: {
        width: 'fit-content',
        left: 0,
        bottom: 0,
        ...transitionStyles,
    },
    buttons: {
        left: 0,
        bottom: 0,
        width: '100px',
        ...transitionStyles,
    },
    buttonsOn: {
        left: '0 !important',
    },
    bottomButton: {
        width: '70%',
        margin: '0 auto',
    },
    closeMenu: {
        justifyContent: 'right',
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
        },
    },
    paddingZero: {
        padding: 0,
    },
}));

const Sidebar = ({ openOptions, setOptionsOpenValue, ...props }) => {
    const classes = useStyles({ openOptions });
    const [open, setOpen] = React.useState(true);
    const [Secopen, setSecOpen] = React.useState(false);
    const [showDetails, setShowDetails] = React.useState(false);
    const [openFontDrawer, setOpenFontDrawer] = React.useState(false);
    const creatorContext = useContext(CreatorContext);
    const componentContext = useContext(CompContext);
    const { openMenu } = componentContext;

    const [selectedItem, setSelectedItem] = React.useState('dashboard');
    const [detailComp, setDetailComp] = React.useState(null);
    const [fontComp, setFontComp] = React.useState(null);
    const [comp, setComp] = React.useState(null);
    const [compKey, setCompKey] = React.useState(null);
    const [compConfig, setCompConfig] = React.useState(null);
    const [checkedValue, setCheckedValue] = React.useState(false);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const toggleComponent = (toggleOn) => {
        componentContext.toggleComponent(compKey, toggleOn);
        setCheckedValue(toggleOn);
        // set 250ms timeout to wait for when a component is toggled on
        setTimeout(() => {
            try {
                forceUpdate();
                const containerElement =
                    document.getElementById('mainContainer');
            } catch (e) {
                console.log(`Error scrolling to element ${compKey}`, e.message);
            }
        }, 500);
    };

    const menuRef = useRef();
    useEffect(() => {
        const handlerMenu = (event) => {
            if (!menuRef.current.contains(event.target) && openMenu != false) {
                handleClose();
            }
        };
        document.addEventListener('mousedown', handlerMenu);
        return () => {
            document.removeEventListener('mousedown', handlerMenu);
        };
    });
    useEffect(() => {
        setCheckedValue(compConfig && compConfig.isVisible);
    }, [compConfig]);

    const generateUrl = (basePath) => {
        const { creator } = creatorContext;
        if (creator && creator.id) {
            return `${basePath}?id=${creator.id}`;
        }
        return basePath;
    };

    const handleDrawerOpen = () => {
        setOpenFontDrawer(!openFontDrawer);
        setOptionsOpenValue(openFontDrawer, 'font');
    };

    const handleFont = (value) => {
        setOpenFontDrawer(value);
    };
    const handleClose = () => {
        componentContext.handleMenu();
    };
    const handleSecOpen = () => {
        setSecOpen(true);
    };

    const selectOption = (title, key, component, compConfig, compContext) => {
        setSelectedItem(title);
        setCompKey(key);
        setComp(component);
        setCompConfig(compConfig);
    };

    const setDetailComponent = (detail) => {
        setDetailComp(detail);
    };

    const ShowDetailPart = (value) => {
        setShowDetails(value);
    };

    const setConfigTemplate = (key, pos) => {
        // console.log('DATA selected', key, pos);
        if (isMobile == true) {
            setOptionsOpenValue(openOptions, key);
            setTimeout(() => {
                try {
                    forceUpdate();
                    const containerElement =
                        document.getElementById('mainContainer');
                    const myElement = document.getElementById(key);
                    const topPos = myElement.offsetTop;
                    containerElement.scroll({
                        behavior: 'smooth',
                        top: topPos - 168,
                    });
                } catch (e) {
                    console.log(`Error scrolling to element ${key}`, e.message);
                }
            }, 500);
        }

        componentContext.updateComponentConfigUserDefinedProps(
            SectionDefaults[key][pos],
            key
        );
        setTimeout(() => {
            try {
                forceUpdate();
                const containerElement =
                    document.getElementById('mainContainer');
                const myElement = document.getElementById(componentKey);
                const topPos = myElement.offsetTop;
                containerElement.scroll({
                    behavior: 'smooth',
                    top: topPos - 168,
                });
            } catch (e) {
                console.log(`Error scrolling to element ${key}`, e.message);
            }
        }, 500);
    };
    const breakpoint = 901;
    const checkForDevice = () => window.innerWidth < breakpoint;

    const [isMobile, setIsMobile] = useState(checkForDevice());

    useEffect(() => {
        const handlePageResized = () => {
            setIsMobile(checkForDevice());
        };

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handlePageResized);
            window.addEventListener('orientationchange', handlePageResized);
            window.addEventListener('load', handlePageResized);
            window.addEventListener('reload', handlePageResized);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', handlePageResized);
                window.removeEventListener(
                    'orientationchange',
                    handlePageResized
                );
                window.removeEventListener('load', handlePageResized);
                window.removeEventListener('reload', handlePageResized);
            }
        };
    }, []);

    // Image presets for different categories
    const imagePresets = {
        [HEADERS_SECTION_KEY]: Object.keys(headersPresets).map(
            (key) => headersPresets[key]
        ),
        music: [
            MusicImageTaylor,
            MusicImageYoung,
            MusicImageMalone,
            MusicImageYoungw,
            MusicImageKid,
            MusicImageRed,
        ],
        news: [
            NewsImageTaylor,
            NewsImageYoung,
            NewsImageMalone,
            NewsImageYoungw,
            NewsImageKid,
            NewsImageRed,
        ],
        about: [
            AboutImageTaylor,
            AboutImageYoung,
            AboutImageMalone,
            AboutImageYoungw,
            AboutImageKid,
        ],
        video: [
            VideoImageTaylor,
            VideoImageYoung,
            VideoImageMalone,
            VideoImageYoungw,
            VideoImageKid,
            VideoImageRed,
        ],
        tour: [TourImageTaylor, TourImageYoung, TourImageMalone, TourImageRed],
        footer: [
            FooterImageTaylor,
            FooterImageYoung,
            FooterImageYoungw,
            FooterImageRed,
        ],
        photos: [
            PhotosImageMalone,
            PhotosImageRed,
            PhotosImageKid,
            PhotosImageYoung,
        ],
        contact: [
            ContactImageMalone,
            ContactImageRed,
            ContactImageKid,
            ContactImageYoung,
        ],
    };

    const RenderDefaultOptions = (key, classes = {}) => {
        // Access the preset images directly using the key
        const presetImages = imagePresets[key];

        // Provide a default state for when the key does not exist in presets
        if (!presetImages) {
            return (
                <Box className={classes.optionWrapper}>
                    <span>No content</span>
                </Box>
            );
        }

        // Render the images using a map function
        return (
            <Box className={classes.optionWrapper}>
                {presetImages.map((imageSrc, index) => (
                    <img
                        className={classes.option}
                        key={`${key}-${index}`}
                        src={imageSrc}
                        alt={`${key}-${index}`}
                        onClick={() => setConfigTemplate(key, index)}
                    />
                ))}
            </Box>
        );
    };

    const drawWidth = openOptions
        ? { width: { xs: '100vw', sm: '100vw', md: '452px', lg: '452px' } }
        : {};
    const BottomIcons = () => {
        return (
            <Box
                className={clsx(
                    classes.extraButtons,
                    openMenu ? classes.buttonsOn : null
                )}
                sx={{
                    display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'flex' },
                    flexDirection: {
                        xs: 'row',
                        sm: 'row',
                        md: 'column',
                        lg: 'column',
                    },
                    width: 'auto',
                    paddingRight: { xs: '5rem', sm: '5rem', md: '0', lg: '0' },
                    color: '#fff',
                }}
            >
                <Button
                    sx={{
                        color: 'white',
                        //backgroundColor: '#22aa02',
                        marginBottom: '10px',
                        marginTop: { xs: '0', sm: '0', md: '10px', lg: '10px' },
                        fontSize: '13px',
                        //marginLeft:'10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                    }}
                    onClick={handleDrawerOpen}
                    className={classes.bottomButton}
                >
                    <img src={FontWIcon} width={'62px'} height={'30px'}></img>
                    <span className={classes.extraButtonText}>Fonts</span>
                </Button>
                <Button
                    style={{
                        backgroundColor: 'transparent',
                        marginBottom: {
                            xs: '0',
                            sm: '0',
                            md: '10px',
                            lg: '10px',
                        },
                        //marginTop: '10px',
                        fontSize: '12px',
                        //padding: '10px',
                        //marginLeft: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                    }}
                    className={classes.bottomButton}
                    color='secondary'
                    variant='contained'
                    href={generateUrl(
                        location.protocol + '//' + location.host + '/create'
                    )}
                >
                    <img src={DashWIcon} width={'62px'} height={'30px'}></img>
                    <span className={classes.extraButtonText}>Dashboard</span>
                </Button>
            </Box>
        );
    };
    return (
        <Box
            ref={menuRef}
            className={clsx(classes.drawerPaper, openMenu ? classes.on : null)}
            borderRight={1}
            sx={drawWidth}
        >
            <Drawer
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' },
                }}
                variant='permanent'
                anchor='left'
                classes={{ paper: styles.drawer }}
            >
                <SectionSelection
                    selectedItem={selectedItem}
                    openOptions={openOptions}
                    setOptionsOpenValue={setOptionsOpenValue}
                    setDetailComponent={setDetailComponent}
                    handleFont={handleFont}
                    selectOption={selectOption}
                    bottomStyle={{}}
                    BottomIcons={BottomIcons}
                    {...props}
                />
                <Divider></Divider>
            </Drawer>
            <RenderBox
                openOptions={openOptions}
                openFontDrawer={openFontDrawer}
                showDetails={showDetails}
                comp={comp}
                toggleComponent={toggleComponent}
                checkedValue={checkedValue}
                selectedItem={selectedItem}
                detailComp={detailComp}
                RenderDefaultOptions={RenderDefaultOptions}
                ShowDetailPart={ShowDetailPart}
            />

            <Drawer
                anchor='bottom'
                open={true}
                variant='permanent'
                sx={{
                    display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' },
                }}
                classes={{ paper: styles.drawerMobile }}
            >
                <SectionSelection
                    selectedItem={selectedItem}
                    openOptions={openOptions}
                    setOptionsOpenValue={setOptionsOpenValue}
                    setDetailComponent={setDetailComponent}
                    handleFont={handleFont}
                    selectOption={selectOption}
                    {...props}
                    bottomStyle={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 0,
                    }}
                    BottomIcons={BottomIcons}
                />
            </Drawer>
        </Box>
    );
};

export default Sidebar;
