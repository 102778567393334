import React from 'react';
import { Link } from '@mui/material';

/**
 * Maps sections to clickable links that smoothly scroll to the corresponding section of the page.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.sections - Array of section objects to map. Each section should have 'label' and 'tagId'.
 * @param {Object} props.sx - Style properties to be applied to each link.
 * @returns {React.Element} A React component rendering a list of links.
 */
const SectionsLinkMapping = ({ sections, sx, onLinkClick }) => {
    if (!sections || sections.length < 1) {
        return null;
    }

    return sections.map((section, index) => {
        const targetId =
            section.tagId || section.label.toLowerCase().replace(/\s+/g, '-');

        return (
            <Link
                href={`#${targetId}`}
                sx={{
                    ...sx,
                    cursor: 'pointer',
                    textDecoration: 'none',
                    display: 'block',
                }}
                key={index}
                onClick={(e) => {
                    e.preventDefault();
                    onLinkClick(targetId);
                }}
            >
                {section.label}
            </Link>
        );
    });
};

export default SectionsLinkMapping;
