import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import noProfileVideoURLimage from '../../../builder/images/placeholders/noProfileVideoURLimage.png';

const useVideoFrameStyles = makeStyles((theme) => ({
    videoContainer: {
        position: 'relative',
        width: '100%',
        height: '0',
        paddingBottom: '56.25%',
        maxHeight: 'calc(100vh - 100px)',
    },
    videoFrame: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    placeholderImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
}));

const VideoFrame = ({ url }) => {
    const classes = useVideoFrameStyles();

    const getYoutubeEmbedUrl = (url) => {
        let videoId = '';
        const urlObj = new URL(url);
        const pathname = urlObj.pathname;

        if (url.includes('youtu.be')) {
            videoId = pathname.split('/').pop();
        } else if (url.includes('youtube.com')) {
            if (pathname.startsWith('/embed/')) {
                videoId = pathname.split('/').pop();
            } else {
                videoId = urlObj.searchParams.get('v');
            }
        }

        return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
    };

    const isYouTubeLink = (url) => {
        const youtubeDomains = ['youtube.com', 'youtu.be'];
        return youtubeDomains.some((domain) => url && url.includes(domain));
    };

    return (
        <div className={classes.videoContainer}>
            {url && isYouTubeLink(url) ? (
                <iframe
                    className={classes.videoFrame}
                    src={getYoutubeEmbedUrl(url)}
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                />
            ) : (
                <img
                    className={classes.placeholderImage}
                    src={noProfileVideoURLimage}
                    alt='Profile placeholder'
                />
            )}
        </div>
    );
};

export default VideoFrame;
