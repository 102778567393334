/**
 * Import Header components and their specific definitions
 */
import Header2, {
    specificDefinitions as Header2specificDefinitions,
} from './Header2';
import Header3, {
    specificDefinitions as Header3SpecificDefinitions,
} from './Header3';
import Header4, {
    specificDefinitions as Header4SpecificDefinitions,
} from './Header4';
import Header5, {
    specificDefinitions as Header5SpecificDefinitions,
} from './Header5';
// import Header6, {
//     specificDefinitions as Header6SpecificDefinitions,
// } from './Header6';

/**
 * Headers configuration list
 */
const headersList = {
    header1: {
        component: Header3,
        specificDefinitions: Header3SpecificDefinitions,
    },
    header2: {
        component: Header2,
        specificDefinitions: Header2specificDefinitions,
    },
    header3: {
        component: Header5,
        specificDefinitions: Header5SpecificDefinitions,
    },
    header4: {
        component: Header4,
        specificDefinitions: Header4SpecificDefinitions,
    },
    // header5: {
    //     component: Header6,
    //     specificDefinitions: Header6SpecificDefinitions,
    // }
};

/**
 * Get the Header component based on headerType
 * @param {string} headerLayout - The type of header to retrieve
 * @returns {Object} The header component or null if not found
 */
const getHeaderComponent = (headerLayout) => {
    try {
        if (!headerLayout || !headersList[headerLayout]) {
            throw new Error(`Invalid header layout: ${headerLayout}`);
        }
        return headersList[headerLayout].component;
    } catch (error) {
        console.error(error);
        return null;
    }
};

/**
 * Get the Header specific definitions based on headerType
 * @param {string} headerType - The type of header to retrieve specific definitions for
 * @returns {Object} The header specific definitions or null if not found
 */
const getHeaderSpecificDefinitions = (headerType) => {
    try {
        if (!headerType || !headersList[headerType]) {
            return;
            throw new Error(`Invalid headerType: ${headerType}`);
        }
        return headersList[headerType].specificDefinitions;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export { headersList, getHeaderComponent, getHeaderSpecificDefinitions };
