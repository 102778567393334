import React from 'react';
import ReactPlayer from 'react-player';

import { useMediaQuery } from 'react-responsive';

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
};
const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
};
const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
};
const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: 768 });
    return isNotMobile ? children : null;
};

function HomeSection({ profile }) {
    return (
        <div id='home-section-bgvideo' className='home-section2-bgvideo'>
            <div className='bg-video-wrapper'>
                <Mobile>
                    <div
                        data-autoplay='false'
                        data-loop='true'
                        data-wf-ignore='true'
                        className='bg-video w-background-video w-background-video-atom'
                        style={{
                            justifyContent: 'center',
                            marginTop: 100,
                        }}
                    >
                        <ReactPlayer
                            url={profile.coverVideoUrl}
                            controls={true}
                            muted={false}
                            autoplay={false}
                            loop
                            playsinline
                            width={'100%'}
                            config={{
                                youtube: {
                                    playerVars: {
                                        autoplay: 0,
                                        controls: 1,
                                        loop: 1,
                                        modestbranding: 0,
                                    },
                                },
                            }}
                        />
                    </div>
                </Mobile>
                <Tablet>
                    <div
                        data-autoplay='true'
                        data-loop='true'
                        data-wf-ignore='true'
                        className='bg-video w-background-video w-background-video-atom'
                        style={{
                            justifyContent: 'center',
                            marginTop: 100,
                        }}
                    >
                        <ReactPlayer
                            url={profile.coverVideoUrl}
                            controls={true}
                            muted={false}
                            autoplay={false}
                            loop
                            playsinline
                            width={'100%'}
                            height={'100%'}
                            config={{
                                youtube: {
                                    playerVars: {
                                        autoplay: 0,
                                        controls: 1,
                                        loop: 1,
                                        modestbranding: 0,
                                    },
                                },
                            }}
                        />
                    </div>
                </Tablet>
                <Desktop>
                    <div
                        data-autoplay='true'
                        data-loop='true'
                        data-wf-ignore='true'
                        className='bg-video w-background-video w-background-video-atom'
                        style={{
                            height: 'calc(100vh - 75px)',
                            justifyContent: 'center',
                            marginTop: 75,
                        }}
                    >
                        <ReactPlayer
                            url={profile.coverVideoUrl}
                            controls={true}
                            muted={false}
                            autoplay={false}
                            loop
                            playsinline
                            width={'100%'}
                            height={'100%'}
                            config={{
                                youtube: {
                                    playerVars: {
                                        autoplay: 0,
                                        controls: 1,
                                        loop: 1,
                                        modestbranding: 0,
                                    },
                                },
                            }}
                        />
                    </div>
                </Desktop>
            </div>
        </div>
    );
}

export default HomeSection;
