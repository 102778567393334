import React from 'react';
import { Box } from '@mui/material';

const UrlLinkMapping = ({ navLinks, sx }) => {
    if (!navLinks || navLinks.length < 1) {
        return null;
    }

    return navLinks.map((navItem, index) => (
        <Box
            component="a"
            href={navItem.url}
            sx={sx}
            key={index}
            target="_blank"
            rel="noopener noreferrer"
        >
            {navItem.readableName}
        </Box>
    ));
};

export default UrlLinkMapping;
