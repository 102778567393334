import React from 'react';

const NavbarConfigContext = React.createContext();

export const useNavbarConfig = () => {
  const context = React.useContext(NavbarConfigContext);
  if (!context) {
    throw new Error('useNavbarConfig must be used within a NavbarConfigProvider');
  }
  return context;
};

export const NavbarConfigProvider = ({ children, value }) => (
  <NavbarConfigContext.Provider value={value}>
    {children}
  </NavbarConfigContext.Provider>
);
