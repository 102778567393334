import React from 'react'

function HomeSection() {
    return (
        <div className="home-section">
    <div className="hero-container w-container">
      <div className="hero-subtitle">Check out my NEW album</div>
      <h1 className="hero-heading">While you were sleeping</h1>
      <a
        href="https://music.apple.com/us/album/1522595521?app=itunes&cId=none&ign-itscg=30440&ign-itsct=catchall_p1&ign-mpt=uo%3D4&lId=22048362&sr=1&src=Linkfire"
        className="button big w-button"
      >
        Pre-Order
      </a>
      <a
        href="https://accounts.spotify.com/en/login?continue=https:%2F%2Faccounts.spotify.com%2Fauthorize%3Fscope%3Duser-follow-modify%2Buser-library-modify%2Bplaylist-modify-public%2Bplaylist-modify-private%2Buser-read-email%2Buser-read-private%26response_type%3Dcode%26redirect_uri%3Dhttps%253A%252F%252Flnk.to%252F%257E%252Fprerelease%252Fspotify%26state%3DdT1odHRwJTNBJTJGJTJGbG5rLnRvJTJGV1lXUyZ2dD0yMzYyOTY1NWEzMGZhMzIzZGRkODZjZTgxM2NkOGI0ZiZiSWQ9MjRkMTIwYzAtM2ZjNi00NWU2LTgxYTgtYmU4OTU2MDkwZGI4JmxJZD0wZmVmNmY2Yy1mZjBjLTQwYzQtOGI0NC05YjI5MmNiOTlkMmYmdElkPTBjMmFiMmY3LWMyMDgtNGYwZi1iMmExLWQ4M2QzNWQ0OTliMiZwaz1saW5rZmlyZQ%253D%253D%26client_id%3D5a14783d79444ee9babd9176b256979e"
        className="button big w-button"
      >
        Pre-Save
      </a>
    </div>
  </div>
    )
}

export default HomeSection
