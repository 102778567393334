const title = 'Header';
const HEADERS_SECTION_KEY = 'header';
const AVAILABLE_LAYOUTS = [
    'header1',
    'header2',
    'header3',
    'header4',
    'right',
    'left',
    'full',
];

const HEADERS_WITH_NAV = [
    AVAILABLE_LAYOUTS[0],
    AVAILABLE_LAYOUTS[1],
    AVAILABLE_LAYOUTS[2],
    AVAILABLE_LAYOUTS[3],
];

const HEADERS_WITHOUT_NAV = [
    AVAILABLE_LAYOUTS[4],
    AVAILABLE_LAYOUTS[5],
    AVAILABLE_LAYOUTS[6],
];

const AVAILABLE_LAYOUTS_MAPPER = {
    'Cover Photo + Social Icons': AVAILABLE_LAYOUTS[0],
    'Cover Photo + Side Menu Nav': AVAILABLE_LAYOUTS[1],
    'Cover Photo + New Release': AVAILABLE_LAYOUTS[2],
    'Cover Video': AVAILABLE_LAYOUTS[3],
    'New Music Release Dark (Right)': AVAILABLE_LAYOUTS[4], // right layout
    'New Music Release Dark (Left)': AVAILABLE_LAYOUTS[5],
    'Cover Photo + Artist Name': AVAILABLE_LAYOUTS[6],
    'New Music Release Light (Right)': AVAILABLE_LAYOUTS[4], // right layout
};
const AVAILABLE_LAYOUTS_NAMES = [
    'Cover Photo + Social Icons',
    'Cover Photo + Side Menu Nav',
    'Cover Photo + New Release',
    'Cover Video',
    'New Music Release Dark (Right)',
    'New Music Release Dark (Left)',
    'Cover Photo + Artist Name',
    'New Music Release Light (Right)',
];

const AVAILABLE_MENU_LAYOUTS = {
    CARDI_B: 'CardiBMenu',
};

const AVAILABLE_SOURCE = ['New Music', 'New Video', 'New Press', 'Cover Photo'];

const getLayoutFromName = (name) => {
    //check if name is included in available layouts first and call directly or return the actual return
    if (AVAILABLE_LAYOUTS.includes(name)) {
        //making used already headers names backwards compatible with AVAILABLE_LAYOUTS
        return name;
    }

    return AVAILABLE_LAYOUTS_MAPPER[name];
};

const musicDefaultSubtitle = 'New Release';

export {
    title,
    HEADERS_SECTION_KEY,
    HEADERS_WITH_NAV,
    HEADERS_WITHOUT_NAV,
    AVAILABLE_LAYOUTS,
    AVAILABLE_LAYOUTS_NAMES,
    AVAILABLE_MENU_LAYOUTS,
    AVAILABLE_SOURCE,
    getLayoutFromName,
    musicDefaultSubtitle,
};
