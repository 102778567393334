import NavigationSection from './NavigationSection';
import NavigationDetailedEditor from './NavigationDetailedEditor';
const title = 'Navigation';
const NAVIGATION_SECTION_KEY = 'navigation';

export {
    NavigationSection,
    NavigationDetailedEditor,
    title,
    NAVIGATION_SECTION_KEY,
};
