import React from 'react';
import MusicLayout from '../../../shared/music/MusicLayout';
import isEmpty from 'lodash.isempty';

function MusicSection({ music }) {
    return (
        <div
            id='music-section'
            className='music-section'
            style={{ maxWidth: '100vw', overflow: 'hidden' }}
        >
            {!isEmpty(music) && (
                <div>
                    <div className='section-header-wrapper'>
                        <h1 className='section-header'>Music</h1>
                    </div>
                    <div className='music-album-wrapper'>
                        <div className='w-layout-grid music-grid'>
                            <MusicLayout
                                music={music}
                                layout='grid'
                                numItems={8}
                                renderItem={({ url, thumbnail, title }) => {
                                    return (
                                        <a
                                            href={url}
                                            target='blank'
                                            className='artist-grid-link w-inline-block'
                                        >
                                            <div
                                                className='artist-album-wrapper'
                                                style={{
                                                    backgroundImage: `url(${thumbnail})`,
                                                }}
                                            >
                                                <div className='artist-name-wrapper'>
                                                    <h3 className='artist-name'>
                                                        {title}
                                                    </h3>
                                                </div>
                                            </div>
                                        </a>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MusicSection;
