import {
    InputMethods,
    createField,
} from '../../utils/detailedEditorDefinition';
import { commonEditorDefinitions } from './commonEditorDefinitions';
import { AVAILABLE_SOURCE, getLayoutFromName } from './constants';
import { getHeaderSpecificDefinitions } from './headerList';
import PropTypes from 'prop-types';

const defaultDefinitions = {
    source: createField(
        PropTypes.string.isRequired,
        InputMethods.STRING,
        InputMethods.SELECT,
        {
            inputOptions: AVAILABLE_SOURCE,
            name: 'source',
            label: 'Select Header',
            isRequired: true,
            defaultValue: 'music',
        }
    ),
    link: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.LINK,
        {
            name: 'link',
            label: 'Change Profile Photo',
            urlPage: 'profile',
            conditionParam: '&upload_profile_pic=true',
            isRequired: false,
        }
    ),
    color: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.COLOR,
        {
            name: 'color',
            label: 'Text Color',
            isRequired: false,
            defaultValue: '#FFFFFF',
        }
    ),
    backgroundColor: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.COLOR,
        {
            name: 'backgroundColor',
            label: 'Background Color',
            isRequired: false,
            defaultValue: '#000000',
        }
    ),
    btnBg: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.COLOR,
        {
            name: 'btnBg',
            label: 'Button Color',
            isRequired: false,
            defaultValue: '#FFFFFF',
        }
    ),
    btnColor: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.COLOR,
        {
            name: 'btnColor',
            label: 'Button Text Color',
            isRequired: false,
            defaultValue: '#000000',
        }
    ),
    title: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'title',
            label: 'Title Override',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    subtitle: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'subtitle',
            label: 'Subtitle Override',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    button: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'button',
            label: 'Button Text Override',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    buttonLocation: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'buttonLocation',
            label: 'Button Click Override',
            description:
                'URL where will the viewer go when clicking on the button?',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    mediaOverride: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'mediaOverride',
            label: 'Media Override',
            description: 'URL for image to replace automated content.',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    // enableParallax: createField(
    //     PropTypes.bool,
    //     InputMethods.BOOLEAN,
    //     InputMethods.SWITCH,
    //     {
    //         name: 'enableParallax',
    //         label: 'Parallax Effect',
    //         description:
    //             'Enable the parallax scrolling effect.  Only available on "full" layout.',
    //         isRequired: false,
        //     }
    // ),
    displaySocialIcons: createField(
        PropTypes.bool.isRequired,
        InputMethods.BOOLEAN,
        InputMethods.SWITCH,
        {
            name: 'displaySocialIcons',
            label: 'Display Social Icons',
            isRequired: false,
            defaultValue: false,
        }
    ),
};

/**
 * Get the detailed editor definition based on layout key.
 * If no layout key is provided, defaults will be used.
 *
 * @param {string} [layoutKey] - The layout key to fetch specific header definitions
 * @returns {Object} - Returns the complete set of editor definitions
 */
export const getAdvanceSettings = (layoutKey) => {
    try {
        // Initialize with common editor definitions
        const completeOptions = { ...commonEditorDefinitions };

        if (layoutKey) {
            // If layoutKey exists, attempt to merge the header-specific definitions
            const headerSpecificDefinitions =
                getHeaderSpecificDefinitions(getLayoutFromName(layoutKey));

            // Defensive check to ensure headerSpecificDefinitions is an object
            if (
                headerSpecificDefinitions &&
                typeof headerSpecificDefinitions === 'object'
            ) {
                Object.assign(completeOptions, headerSpecificDefinitions);
            } else {
                // Log a warning and use default options if headerSpecificDefinitions are not found
                console.warn(
                    'Returning default options as header-specific definitions not found.'
                );
                Object.assign(completeOptions, defaultDefinitions);
            }
        } else {
            // If layoutKey does not exist, merge the default definitions
            Object.assign(defaultDefinitions, completeOptions);
            return defaultDefinitions;
        }

        return completeOptions;
    } catch (error) {
        console.error(`Failed to get detailed editor definition: ${error}`);
        return null; // Or return an empty object {} depending on your use case
    }
};
