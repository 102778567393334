import React from 'react'

function FooterSection() {
    return (
        <div className="footer-section">
    <div className="footer-menu">
      <div className="footer-nav">
        <a
          href="#"
          data-w-id="23750328-0c19-748d-66f3-d2aeb003eadf"
          className="nav-link footer-link"
        >
          Music
          <span style={{ width: "0PX" }} className="nav-link-line">
            {" "}
          </span>
        </a>
        <a
          data-w-id="23750328-0c19-748d-66f3-d2aeb003eae3"
          href="https://nextup.webflow.io/#videos"
          className="nav-link footer-link"
        >
          Videos
          <span style={{ width: "0PX" }} className="nav-link-line">
            {" "}
          </span>
        </a>
        <a
          href="#"
          data-w-id="23750328-0c19-748d-66f3-d2aeb003eae7"
          className="nav-link footer-link"
        >
          Store
          <span style={{ width: "0PX" }} className="nav-link-line">
            {" "}
          </span>
        </a>
        <a
          href="#"
          data-w-id="23750328-0c19-748d-66f3-d2aeb003eaeb"
          className="nav-link footer-link"
        >
          Shows
          <span style={{ width: "0PX" }} className="nav-link-line">
            {" "}
          </span>
        </a>
        <a
          href="#"
          data-w-id="23750328-0c19-748d-66f3-d2aeb003eaef"
          className="nav-link footer-link"
        >
          Contact
          <span style={{ width: "0PX" }} className="nav-link-line">
            {" "}
          </span>
        </a>
      </div>
      <div className="nav-social footer-social">
        <div className="social-wrapper">
          <div className="profile-link">
            <a
              href="https://open.spotify.com/artist/2wcrc3fjebDRLVdtRUa3pu"
              className="profile-image w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0c15155ea6c2cc8997d9a_Spotify%20White.png"
                width={30}
                height={30}
                alt
                className="profile-icon"
              />
            </a>
          </div>
          <div className="profile-link">
            <a
              href="https://itunes.apple.com/us/artist/cal-scruby/670791732"
              className="profile-image w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0c15180440a04ba9e03b0_Apple%20White.png"
                width={30}
                height={30}
                alt
                className="profile-icon"
              />
            </a>
          </div>
          <div className="profile-link">
            <a
              href="http://youtube.com/calvinscruby"
              className="profile-image w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0c151b7492323f6f4baa9_FB%20White.png"
                width={30}
                height={30}
                alt
                className="profile-icon"
              />
            </a>
          </div>
          <div className="profile-link">
            <a
              href="http://soundcloud.com/calscruby"
              className="profile-image w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0c151a5b8fd30ca392c5e_Soundcloud%20White.png"
                width={30}
                height={30}
                alt
                className="profile-icon"
              />
            </a>
          </div>
          <div className="profile-link">
            <a
              href="http://instagram.com/calscruby"
              className="profile-image w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0c1515fda979420535407_Instagram%20White.png"
                width={30}
                height={30}
                alt
                className="profile-icon"
              />
            </a>
          </div>
          <div className="profile-link">
            <a
              href="https://twitter.com/calscruby"
              className="profile-image w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5fa0b9f78dbaea29c19acb46/5fa0c15155ea6c47df997d99_Twitter%20White.png"
                width={30}
                height={30}
                alt
                className="profile-icon"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-legal-notice">
      <p className="footer-legal">
        Copyright © Cal Scruby {(new Date().getFullYear())}. All Rights Reserved.
        <br />
      </p>
    </div>
  </div>
    )
}

export default FooterSection
