import React, { createContext, useEffect, useState } from 'react';
import isEmpty from 'lodash.isempty';
import jamfeedClient from '../api/jamfeedClient';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export const CreatorContext = createContext({
    setCreator: () => {},
    setCreatorById: () => {},
    creator: null,
    paymentPlan: null,
    hasSubscription: () => {},
    hasActiveSubscription: () => {},
    hasDelinquentSubscription: () => {},
    hasCanceledSubscription: () => {},
    hasConfiguredDNS: () => {},
    isLoading: true,
});

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const CreatorProvider = ({ children }) => {
    const query = useQuery();
    const routerHistory = useHistory();
    const routerLocation = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [creator, setCreator] = useState('');
    const [paymentPlan, setPaymentPlan] = useState({});
    const [domainConfig, setDomainConfig] = useState({});
    const {
        isLoading: isAuthLoading,
        isAuthenticated,
        getAccessTokenSilently,
    } = useAuth0();

    const getDefaultCreator = async (searchTerm = '') => {
        jamfeedClient
            .get('/creator/search', {
                params: {
                    searchTerm,
                },
            })
            .then((response) => {
                setCreator(response.data.results[0]); //TODO ISSUE FOR ADMINS ON SEARCH this is setting creator based on spotify only data, not checking if artist exists in dynamo
            })
            .catch((e) => {
                console.log('set creator default', e);
            });
    };

    const getCreator = async (creatorId) => {
        jamfeedClient
            .get('/artist', {
                params: {
                    aid: creatorId,
                },
            })
            .then((response) => {
                setCreator(response.data.artist);
            })
            .catch((e) => {
                console.log('Get creator', e);
            });
    };

    const getPaymentPlan = async () => {
        jamfeedClient
            .get('/subscription', {
                params: {
                    id: creator.id,
                    type: 'ARTIST',
                },
            })
            .then((response) => {
                // console.log('subscription', response.data);
                setPaymentPlan(response.data);
            })
            .catch((e) => {
                console.log('error get subscription', e);
            });
    };

    const getDomainConfig = async () => {
        jamfeedClient
            .get('/domain/config', {
                params: {
                    id: creator.id,
                    type: 'ARTIST',
                },
            })
            .then(({ data }) => {
                setDomainConfig(data);
            })
            .catch((err) => {
                setDomainConfig({});
            });
    };

    const hasSubscription = () => {
        return !isEmpty(paymentPlan);
    };

    const hasActiveSubscription = () => {
        return (
            !isEmpty(paymentPlan) &&
            ['trialing', 'active'].includes(paymentPlan.subscription.status)
        );
    };

    const hasDelinquentSubscription = () => {
        return (
            !isEmpty(paymentPlan) &&
            ['incomplete', 'past_due', 'unpaid'].includes(
                paymentPlan.subscription.status
            )
        );
    };

    const hasCanceledSubscription = () => {
        return (
            !isEmpty(paymentPlan) &&
            ['canceled'].includes(paymentPlan.subscription.status)
        );
    };

    const hasConfiguredDNS = () => {
        return (
            !isEmpty(domainConfig) &&
            !isEmpty(domainConfig.distribution) &&
            !isEmpty(domainConfig.certificate)
        );
    };

    const setCreatorById = async (creatorId) => {
        await getCreator(creatorId);
    };

    useEffect(() => {
        if (creator) {
            // get payment plan data
            getPaymentPlan();
            // get domain config
            getDomainConfig();
            setIsLoading(false);

            const previousCreatorId = query.get('id');
            if (creator && previousCreatorId === creator.id) {
                setIsLoading(false);
                return;
            }

            const currentPath = routerLocation.pathname;
            if (creator && creator.id) {
                const redirect = `${currentPath}?id=${creator.id}`;
                routerHistory.push(redirect);
            }
        }
    }, [creator]);

    useEffect(() => {
        if (!isAuthLoading && isAuthenticated) {
            jamfeedClient.setRefreshToken(getAccessTokenSilently);
            const creatorIdQueryParam = query.get('id');
            if (
                !isEmpty(creatorIdQueryParam) &&
                creator.id !== creatorIdQueryParam
            ) {
                setCreatorById(creatorIdQueryParam);
                return;
            }

            if (isEmpty(creator)) {
                getDefaultCreator();
            }
        }
    }, [isAuthLoading, isAuthenticated]);

    useEffect(() => {
        jamfeedClient.setRefreshToken(getAccessTokenSilently);
    }, []);

    return (
        <CreatorContext.Provider
            value={{
                creator,
                setCreator,
                setCreatorById,
                paymentPlan,
                hasSubscription,
                hasActiveSubscription,
                hasDelinquentSubscription,
                hasCanceledSubscription,
                hasConfiguredDNS,
                isLoading,
            }}
        >
            {children}
        </CreatorContext.Provider>
    );
};

export default CreatorProvider;
