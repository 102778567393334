import React, { useContext }  from 'react';
import DetailedEditor from '../../shared/editor/DetailedEditor';
import { detailedEditorDefinition } from './FooterSection';
import { CompContext } from '../../context/CompContext';

const FooterDetailedEditor = () => {
    const compContext = useContext(CompContext);
    const componentConfig = compContext.getComponentConfig('footer');
    const { displaySocialIcons, monoIcons } = componentConfig.userDefinedProps;

    const copy = {...detailedEditorDefinition}

    !displaySocialIcons && delete copy.monoIcons;
    (!displaySocialIcons || !monoIcons) && delete copy.iconColor;

    return <DetailedEditor detailedEditorDefinition={copy} componentViewKey={'footer'} />;
};

export default FooterDetailedEditor;
