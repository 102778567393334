import React from 'react';
import ResponsiveLink from '../../../shared/ResponsiveLink';

function FooterSection({ profile }) {
    return (
        <footer id='contact-section' className='footer'>
            <div className='w-container'>
                <div className='footer-flex-container'>
                    {/* <a href='#' className='footer-logo-link w-inline-block'>
                        <img
                            src={profile.logo || profile.pictureurl}
                            alt
                            className='footer-image'
                        />
                    </a> */}
                    {profile.publicContacts.map((c) => {
                        return (
                            <div className='footer-contact-wrapper'>
                                <h2 className='footer-heading'>{c.title}</h2>
                                <ul role='list' className='w-list-unstyled'>
                                    <li>
                                        <ResponsiveLink
                                            className='footer-link'
                                            linkText={c.email}
                                        />
                                    </li>
                                </ul>
                            </div>
                        );
                    })}
                </div>
                <div
                    className='text-block'
                    style={{
                        width: '100%',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    © {(new Date().getFullYear())} {profile.name}
                </div>
            </div>
        </footer>
    );
}

export default FooterSection;
