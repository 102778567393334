import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, styled, Typography } from '@mui/material';
import useDynamicFontSize from '../../../shared/headers/hooks/useDynamicFontSize';
import decodeHtml from '../../../utils/decodeHtml';
import { useTheme } from '@emotion/react';

const ContentContainer = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
    justifyContent: 'flex-end',
    height: '100%',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        marginBottom: '1rem',
        maxWidth: 'initial',
        alignItems: 'center',
        textAlign: 'center',
    },
}));

const Title = styled(Typography)(({ theme, font }) => ({
    fontFamily: font,
    fontSize: '3rem',
    fontWeight: 'normal',
    maxWidth: '100%',
    maxHeight: '20vh',
    overflow: 'hidden',
}));

const Subtitle = styled(Box)(({ theme, font }) => ({
    fontFamily: font,
    maxWidth: '60%',
    maxHeight: '20vh',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
        maxWidth: 'initial',
    },
}));

const StyledButton = styled(Button)(
    ({ theme, buttonBackgroundColor, buttonTextColor, font }) => ({
        fontFamily: font,
        border: `2px solid ${buttonBackgroundColor}`,
        backgroundColor: 'transparent',
        color: `${buttonTextColor}`,
        fontSize: 'small',
        [theme.breakpoints.up('md')]: {
            marginLeft: '2rem',
        },
        [theme.breakpoints.down('md')]: {
            marginTop: '2rem',
            marginLeft: '0',
        },
        '&:hover': {
            border: `2px solid #fff`,
        },
    })
);

const ButtonSubtitleContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'initial',
    },
}));

const LeftContent = ({
    title,
    subtitle,
    buttonText,
    buttonLocation,
    textColor,
    buttonBackgroundColor,
    buttonTextColor,
    font,
    logo,
    showButton,
}) => {
    const theme = useTheme();
    const titleRef = useDynamicFontSize();
    const subtitleRef = useDynamicFontSize();

    return (
        <ContentContainer>
            {logo ? (
                <img src={logo} alt='Logo' />
            ) : (
                <Title ref={titleRef} font={font} style={{ color: textColor }}>
                    {decodeHtml(title)}
                </Title>
            )}
            <ButtonSubtitleContainer>
                <Subtitle
                    ref={subtitleRef}
                    font={font}
                    style={{
                        color: textColor,
                        marginRight: theme.spacing(2),
                    }}
                >
                    {decodeHtml(subtitle)}
                </Subtitle>
                {showButton && (
                    <StyledButton
                        variant='outlined'
                        onClick={() => window.open(buttonLocation, '_blank')}
                        buttonBackgroundColor={buttonBackgroundColor}
                        buttonTextColor={buttonTextColor}
                        font={font}
                        showButton={showButton}
                    >
                        {decodeHtml(buttonText)}
                    </StyledButton>
                )}
            </ButtonSubtitleContainer>
        </ContentContainer>
    );
};

LeftContent.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonLocation: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    buttonBackgroundColor: PropTypes.string,
    buttonTextColor: PropTypes.string,
    font: PropTypes.string,
    logo: PropTypes.string,
    showButton: PropTypes.bool,
};

export default LeftContent;
