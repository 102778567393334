import React from 'react';
import { Grid, styled } from '@mui/material';
import OptionBox from './OptionBox';

const StyledIconsContent = styled(Grid)(({ theme, vertical, iconGridFlexEnd }) => ({
    boxSizing: 'border-box',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: vertical ? 'column' : 'row',
    flexWrap: vertical ? 'nowrap' : 'wrap',
    alignItems: 'center',
    justifyContent: iconGridFlexEnd ? 'flex-end' : 'flex-start',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const IconGrid = ({
    integrated,
    textColor,
    vertical = false,
    iconGridFlexEnd = false,
}) => {
    if (!integrated || !integrated.length) return null;

    return (
        <StyledIconsContent vertical={vertical} iconGridFlexEnd={iconGridFlexEnd}>
            {integrated.map(({ network, data }) => {
                if (!data || !data.url) return null;
                return (
                    <OptionBox
                        key={network}
                        network={network}
                        url={data.url}
                        icon
                        color={textColor}
                    />
                );
            })}
        </StyledIconsContent>
    );
};

export default IconGrid;
