import React, { useEffect, useLayoutEffect } from 'react';
import './template6.scss';
import HomeSection from './HomeSection/HomeSection';
import MusicSection from './MusicSection/MusicSection';
import VideoSection from './VideoSection/VideoSection';
import TourSection from './TourSection/TourSection';
import NewsSection from './NewsSection/NewsSection';
import SubscribeSection from './SubscribeSection/SubscribeSection';
import MerchSection from './MerchSection/MerchSection';
import AboutSection from './AboutSection/AboutSection';
import ContactSection from './ContactSection/ContactSection';
import FooterSection from './FooterSection/FooterSection';
import NavBarSection from './NavBarSection/NavBarSection';

function loadFonts() {
    import('webfontloader').then((WebFont) => {
        WebFont.load({
            google: {
                families: [
                    'Montserrat:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic',
                    'Lato:100,100italic,300,300italic,400,400italic,700,700italic,900,900italic',
                ],
            },
        });
    });
}

const Template6 = ({ profile, videos, news, music, integrations }) => {
    useLayoutEffect(() => {
        loadFonts();
    }, []);

    return (
        <div className='template6'>
            <div className='body'>
                <NavBarSection
                    news={news}
                    music={music}
                    videos={videos}
                    integrations={integrations}
                />
                <HomeSection />
                <MusicSection music={music} />
                <VideoSection videos={videos} />
                <TourSection profile={profile} />
                <NewsSection news={news} />
                <SubscribeSection />
                <MerchSection />
                <AboutSection />
                <ContactSection />
                <FooterSection />
                {/*[if lte IE 9]><![endif]*/}
            </div>
        </div>
    );
};

export default Template6;
