import React from 'react';
import NewsList from './NewsList';
import NewsGrid from './NewsGrid';
function NewsLayout(props) {
    switch (props.layout) {
        case 'grid':
            return <NewsGrid {...props} />;
        case 'list':
            return <NewsList {...props} />;
        default:
            return <NewsList {...props} />;
    }
}

export default NewsLayout;
