import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ReactPlayer from 'react-player';
import Close from '@mui/icons-material/Close';
import SwipableVideos from './SwipableVideos';
import StaticVideos from './StaticVideos';
import './swipablevideos.scss';

function VideosLayout(props) {
    const [videos] = useState(props.videos);
    const [videoToPreview, setVideoToPreview] = useState([]);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className='swipablevideos'>
            <div className={!props.outerClass && 'videos-section'}>
                <div
                    style={
                        !props.outerClass
                            ? {
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  textAlign: 'center',
                              }
                            : {}
                    }
                >
                    {props.swipable ? (
                        <SwipableVideos
                            videos={videos}
                            setVideoToPreview={setVideoToPreview}
                            handleOpen={handleOpen}
                            {...props}
                        />
                    ) : (
                        <StaticVideos
                            videos={videos}
                            setVideoToPreview={setVideoToPreview}
                            handleOpen={handleOpen}
                            {...props}
                        />
                    )}
                </div>
            </div>
            {open && (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='simple-modal-title'
                    aria-describedby='simple-modal-description'
                    style={{
                        backgroundColor: '#000000',
                        justifyContent: 'center',
                        flex: 1,
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#000000',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                        <Close
                            onClick={handleClose}
                            style={{
                                flex: 1,
                                fontSize: 30,
                                color: 'white',
                                margin: 10,
                                float: 'right',
                            }}
                        />
                        <ReactPlayer
                            url={videoToPreview}
                            controls={true}
                            muted={false}
                            loop
                            playsinline
                            width={'100%'}
                            height={'90%'}
                        />
                    </div>
                </Modal>
            )}
        </div>
    );
}

export default VideosLayout;
