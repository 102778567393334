import React from "react";

function MerchSection() {
  return (
    <div id="merch-section" className="merch-section">
      <div className="merch-section-wrapper">
        <div className="merch-section-header">
          <h1 className="section-header">Merch</h1>
        </div>
        <div className="merch-item-wrapper">
          <a
            href="https://shop.mynameisgriz.com/products/space-of-peace-griz-tee"
            className="merch-link-block w-inline-block"
          >
            <div className="merch-image">
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f6eefbca43180f4c14_Cal%20Scruby%20Merch%20Hoodie.png"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 74vw, (max-width: 991px) 39vw, 33vw"
                srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f6eefbca43180f4c14_Cal%20Scruby%20Merch%20Hoodie-p-500.png 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f6eefbca43180f4c14_Cal%20Scruby%20Merch%20Hoodie.png 758w"
                alt
                className="image-6"
              />
            </div>
            <div className="merch-header">
              <h5>Space of Peace T-Shirt</h5>
            </div>
            <div className="merch-buy-now-button">
              <div className="merch-cart-button">
                <img
                  src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca70ba94dfb1_263142.png"
                  alt
                  className="image-5"
                />
              </div>
              <div className="merch-buy-button">
                <div>Buy Now</div>
              </div>
            </div>
          </a>
          <a
            href="https://shop.mynameisgriz.com/products/space-of-peace-womens-tee-griz"
            className="merch-link-block w-inline-block"
          >
            <div className="merch-image">
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f62aa4a31a1489c38a_Cal%20Scruby%20Merch%20Album.png"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 74vw, (max-width: 991px) 39vw, 33vw"
                srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f62aa4a31a1489c38a_Cal%20Scruby%20Merch%20Album-p-500.png 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f62aa4a31a1489c38a_Cal%20Scruby%20Merch%20Album.png 762w"
                alt
                className="image-6"
              />
            </div>
            <div className="merch-header">
              <h5>Space of Peace Women's Fit T-Shirt</h5>
            </div>
            <div className="merch-buy-now-button">
              <div className="merch-cart-button">
                <img
                  src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca70ba94dfb1_263142.png"
                  alt
                  className="image-5"
                />
              </div>
              <div className="merch-buy-button">
                <div>Buy Now</div>
              </div>
            </div>
          </a>
          <a
            href="https://shop.mynameisgriz.com/products/griz-x-bl-visuals-hand-painted-snapback"
            className="merch-link-block w-inline-block"
          >
            <div className="merch-image">
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f59e2ca11ff234ac41_Cal%20Scruby%20Merch%203%20Hay.png"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 74vw, (max-width: 991px) 39vw, 33vw"
                srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f59e2ca11ff234ac41_Cal%20Scruby%20Merch%203%20Hay-p-500.png 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f59e2ca11ff234ac41_Cal%20Scruby%20Merch%203%20Hay.png 696w"
                alt
                className="image-6"
              />
            </div>
            <div className="merch-header">
              <h5>GRiZ x BL Visuals Painted Blacklight Snapback</h5>
            </div>
            <div className="merch-buy-now-button">
              <div className="merch-cart-button">
                <img
                  src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca70ba94dfb1_263142.png"
                  alt
                  className="image-5"
                />
              </div>
              <div className="merch-buy-button">
                <div>Buy Now</div>
              </div>
            </div>
          </a>
          <a
            href="https://shop.mynameisgriz.com/products/color-changing-long-sleeve"
            target="_blank"
            className="merch-link-block w-inline-block"
          >
            <div className="merch-image">
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f62aa4a31a1489c38a_Cal%20Scruby%20Merch%20Album.png"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 74vw, (max-width: 991px) 39vw, 33vw"
                srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f62aa4a31a1489c38a_Cal%20Scruby%20Merch%20Album-p-500.png 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f62aa4a31a1489c38a_Cal%20Scruby%20Merch%20Album.png 762w"
                alt
                className="image-6"
              />
            </div>
            <div className="merch-header">
              <h5>Color Changing Sunflower Long Sleeve</h5>
            </div>
            <div className="merch-buy-now-button">
              <div className="merch-cart-button">
                <img
                  src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca70ba94dfb1_263142.png"
                  alt
                  className="image-5"
                />
              </div>
              <div className="merch-buy-button">
                <div>Buy Now</div>
              </div>
            </div>
          </a>
          <a
            href="https://shop.mynameisgriz.com/products/holiday-bears-custom-tie-dye-t-shirt"
            className="merch-link-block w-inline-block"
          >
            <div className="merch-image">
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f59e2ca11ff234ac41_Cal%20Scruby%20Merch%203%20Hay.png"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 74vw, (max-width: 991px) 39vw, 33vw"
                srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f59e2ca11ff234ac41_Cal%20Scruby%20Merch%203%20Hay-p-500.png 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f59e2ca11ff234ac41_Cal%20Scruby%20Merch%203%20Hay.png 696w"
                alt
                className="image-6"
              />
            </div>
            <div className="merch-header">
              <h5>Holiday GRiZ Bears Custom Tie Dye T-Shirt</h5>
            </div>
            <div className="merch-buy-now-button">
              <div className="merch-cart-button">
                <img
                  src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca70ba94dfb1_263142.png"
                  alt
                  className="image-5"
                />
              </div>
              <div className="merch-buy-button">
                <div>Buy Now</div>
              </div>
            </div>
          </a>
          <a
            href="https://shop.mynameisgriz.com/products/the-griz-who-stole-grizmas-pullover-hoodie"
            className="merch-link-block w-inline-block"
          >
            <div className="merch-image">
              <img
                src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f6eefbca43180f4c14_Cal%20Scruby%20Merch%20Hoodie.png"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 74vw, (max-width: 991px) 39vw, 33vw"
                srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f6eefbca43180f4c14_Cal%20Scruby%20Merch%20Hoodie-p-500.png 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f6eefbca43180f4c14_Cal%20Scruby%20Merch%20Hoodie.png 758w"
                alt
                className="image-6"
              />
            </div>
            <div className="merch-header">
              <h5>How the GRiz Stole GRiZMAS Pullover Hoodie</h5>
            </div>
            <div className="merch-buy-now-button">
              <div className="merch-cart-button">
                <img
                  src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f1ee2e31a0aca70ba94dfb1_263142.png"
                  alt
                  className="image-5"
                />
              </div>
              <div className="merch-buy-button">
                <div>Buy Now</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default MerchSection;
