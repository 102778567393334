import React, { useEffect, useState } from 'react';
import { Box, Grid, styled } from '@mui/material';
import Navbar from '../../shared/headers/components/Navbar';
import LeftContent from './header3/LeftContent';
import RightContent from './header3/RightContent';
import {
    InputMethods,
    createField,
    generatePropTypesAndDefaults,
} from '../../utils/detailedEditorDefinition';
import { commonEditorDefinitions } from './commonEditorDefinitions';
import PropTypes from 'prop-types';
import { NavbarConfigProvider } from '../../shared/headers/components/useNavbarConfig';
import { AVAILABLE_SOURCE } from './constants';
import { CardiBMenuStyle } from '../../shared/headers/components/CardiBMenu';

// Styled components
const HeaderContainer = styled(Box)(
    ({ theme, mediaOverride, backgroundColor }) => ({
        fontFamily: (props) => props.font,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100vh',
        minHeight: '600px',
        maxHeight: '100vh',
        background: `url(${mediaOverride}) no-repeat center center`,
        backgroundSize: 'cover',
        padding: theme.spacing(1),
        backgroundColor,
        [theme.breakpoints.down('md')]: {
            padding: 0,
        },
    })
);

const MainContent = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flex: 1,
    minHeight: 0,
    order: 2,
    padding: `${theme.spacing(8)} ${theme.spacing(4)}`,
    paddingBottom: '9rem',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        margin: '0 auto',
    },
}));

const NavbarContainer = styled(Grid)({
    flexGrow: 0,
});

/**
 * Header3 Component - Renders a header with navbar, main content, left and right sections.
 *
 * @param {Object} props Component properties
 * @returns {React.ReactElement} Rendered component
 */
const Header3 = ({
    color,
    backgroundColor = '#000000',
    title,
    subtitle = 'subtitle',
    buttonText = 'Listen now',
    buttonLocation,
    profile,
    btnBg,
    btnColor,
    integrations: { integrated },
    displaySocialIcons,
    displayActionButton,
    font,
    fontSizeSelection = 6,
    sections,
    ...props
}) => {
    const styleDetails = {
        textColor: color,
        backgroundColor,
        buttonTextColor: btnColor,
        buttonBackgroundColor: btnBg,
        font,
        fontSizeSelection,
    };

    // console.log('Working on Header3.js');
    // useEffect(() => {
    //     console.log('...props => ', props);
    // }, [props]);

    const [menuState, setMenuState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setMenuState({ ...menuState, [anchor]: open });
    };

    const navbarConfig = {
        title: profile.name,
        sections,
        toggleDrawer,
        menuState,
        styleDetails,
        integrated,
    };

    const navStyleProps = {
        font: profile.font,
        color,
        fontSize: fontSizeSelection,
    };

    return (
        <NavbarConfigProvider value={navbarConfig}>
            <HeaderContainer
                mediaOverride={profile.pictureurl}
                backgroundColor={backgroundColor}
            >
                <NavbarContainer>
                    <Navbar
                        {...navbarConfig}
                        custom={CardiBMenuStyle}
                        sx={navStyleProps}
                        showMenuIcon={true}
                    />
                </NavbarContainer>
                <MainContent>
                    <LeftContent
                        title={title}
                        subtitle={subtitle}
                        buttonText={buttonText}
                        buttonLocation={buttonLocation}
                        textColor={color}
                        buttonBackgroundColor={btnBg}
                        buttonTextColor={btnColor}
                        font={font}
                        showButton={displayActionButton}
                    />
                    <RightContent
                        integrated={integrated}
                        textColor={color}
                        displaySocialIcons={displaySocialIcons}
                    />
                </MainContent>
            </HeaderContainer>
        </NavbarConfigProvider>
    );
};

// specificDefinitions and propsTypes and defaultProps section for this header layout type
const specificDefinitions = {
    source: createField(
        PropTypes.string.isRequired,
        InputMethods.STRING,
        InputMethods.SELECT,
        {
            inputOptions: AVAILABLE_SOURCE,
            name: 'source',
            label: 'Select Header',
            isRequired: true,
            defaultValue: 'music',
        }
    ),
    color: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.COLOR,
        {
            name: 'color',
            label: 'Text Color',
            isRequired: false,
            defaultValue: '#FFFFFF',
        }
    ),
    btnBg: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.COLOR,
        {
            name: 'btnBg',
            label: 'Button Color',
            isRequired: false,
            defaultValue: '#FFFFFF',
        }
    ),
    btnColor: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.COLOR,
        {
            name: 'btnColor',
            label: 'Button Text Color',
            isRequired: false,
            defaultValue: '#000000',
        }
    ),
    title: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'title',
            label: 'Title Override',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    subtitle: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'subtitle',
            label: 'Subtitle Override',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    displayActionButton: createField(
        PropTypes.bool.isRequired,
        InputMethods.BOOLEAN,
        InputMethods.SWITCH,
        {
            name: 'displayActionButton',
            label: 'Display Action Button',
            isRequired: false,
            defaultValue: true,
        }
    ),
    button: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'button',
            label: 'Button Text Override',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    buttonLocation: createField(
        PropTypes.string,
        InputMethods.STRING,
        InputMethods.TEXT,
        {
            name: 'buttonLocation',
            label: 'Button Click Override',
            description:
                'URL where will the viewer go when clicking on the button?',
            isRequired: false,
            defaultValue: undefined,
        }
    ),
    displaySocialIcons: createField(
        PropTypes.bool.isRequired,
        InputMethods.BOOLEAN,
        InputMethods.SWITCH,
        {
            name: 'displaySocialIcons',
            label: 'Display Social Icons',
            isRequired: false,
            defaultValue: false,
        }
    ),
};

const detailedEditorDefinition = {
    ...commonEditorDefinitions,
    ...specificDefinitions,
};

const { propTypes, defaultProps } = generatePropTypesAndDefaults(
    detailedEditorDefinition
);

Header3.propTypes = propTypes;
Header3.defaultProps = defaultProps;

export default Header3;
export { detailedEditorDefinition, specificDefinitions };
