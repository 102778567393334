import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Import Swiper styles
// swiper core styles
// import 'swiper/swiper.less';
// import 'swiper/swiper.min.css';

// modules styles
// import 'swiper/components/navigation/navigation.less';
// import 'swiper/components/pagination/pagination.less';
// import 'swiper/components/navigation/navigation.min.css';
// import 'swiper/components/pagination/pagination.min.css';

// import SliderLayoutGrid from './SliderLayoutGrid';
import SliderLayoutRow from './SliderLayoutRow';
import './styles.scss';

const SliderLayout = ({ items, renderItem, columns = 1, spacing = 0, rows = 1 }) => {
    // const [gridRows, setGridRows] = useState(1);

    // useEffect(() => {
    //     setGridRows(rows);
    // }, [rows]);

    // if (gridRows > 1) {
    //     return <SliderLayoutGrid {...{ items, renderItem, columns, spacing, rows }} />;
    // }
    return <SliderLayoutRow {...{ items, renderItem, columns, spacing, rows }} />;
};

const detailedEditorDefinition = {
    'layout.columns': {
        propType: PropTypes.number.isRequired,
        valueType: 'number',
        inputMethod: 'slider',
        step: 1,
        min: 1,
        max: 4,
        name: 'layout.columns',
        label: 'Columns',
        description: 'The number of columns to display in the grid.',
        isRequired: true,
        defaultValue: 2,
    },
    // 'layout.rows': {
    //     propType: PropTypes.number.isRequired,
    //     valueType: 'number',
    //     inputMethod: 'slider',
    //     step: 1,
    //     min: 1,
    //     max: 10,
    //     name: 'layout.rows',
    //     label: 'Rows',
    //     description: 'The number of rows to display in the grid.',
    //     isRequired: true,
    //     defaultValue: 2,
    // },
    'layout.spacing': {
        propType: PropTypes.number.isRequired,
        valueType: 'number',
        inputMethod: 'slider',
        step: 1,
        min: 0,
        max: 10,
        name: 'layout.spacing',
        label: 'Spacing',
        description: 'The amount of spacing between the grid items.',
        isRequired: true,
        defaultValue: 0,
    },
};

SliderLayout.propTypes = {
    items: PropTypes.arrayOf(Object).isRequired,
    renderItem: PropTypes.func.isRequired,
    columns: PropTypes.number,
    spacing: PropTypes.number,
    // rows: PropTypes.number,
};

SliderLayout.defaultProps = {
    items: [1, 2, 3, 4, 5],
    columns: 1,
    spacing: 0,
    // rows: 1,
};

export { SliderLayout as default, detailedEditorDefinition };
