import React, { useContext, useEffect, useState } from 'react';
import {
    InputLabel,
    List,
    ListItem,
    Typography,
    FormControlLabel,
    TextField,
    RadioGroup,
    Radio,
    Select,
    Switch,
    Link,
    MenuItem,
    FormControl,
    Slider,
    ListItemButton,
    ListItemText,
    Collapse,
    Grid,
    Card,
    CardMedia,
    CardContent,
} from '@mui/material';

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import makeStyles from '@mui/styles/makeStyles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import isEmpty from 'lodash.isempty';
import _get from 'lodash.get';
import { CompContext } from '../../context/CompContext';
import { CreatorContext } from '../../context/creatorContext';
import DetailedEditorDropdown from '../../builder/sideBar/DetailedEditorDropdown';
import ColorPicker from '../../builder/sideBar/ColorPicker';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: 260,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 260,
        backgroundColor: theme.palette.background.paper,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -260,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    sectionTitle: {
        fontWeight: 'bold',
        padding: 10,
        color: theme.palette.text.primary,
    },
    textField: {
        color: theme.palette.text.primary,
    },
    listSection: {
        width: '100%',
        color:'white',
    },
    layoutRadio: {
        padding: '0 10px',
        '& .MuiRadio-colorPrimary': {
            padding: '9px 3px 9px 9px',
        },
        '& .MuiFormControlLabel-label': {
            fontSize: 14,
        },
    },
    layoutImg: {
        padding: '4px 5px',
        background: '#eaebef',
        objectFit: 'contain',
        height: 62,
    },
    layoutWrapper: {
        '& .MuiCollapse-wrapper': {
            padding: '10px 0',
        },
    },
    layoutDropdown: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        margin: '-8px -16px',
    },
    select: {
        '&:before': {
            borderColor: 'white',
        },
        '&:after': {
            borderColor: 'white',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white',
        },
    },
    icon: {
        fill: 'white',
    },
}));

const DetailedEditor = ({
    detailedEditorDefinition,
    componentViewKey,
    colorPickerPosition,
}) => {
    const editorType = {
        layoutType: false,
        cardType: false,
    };
    const classes = useStyles();
    const compContext = useContext(CompContext);
    const creatorContext = useContext(CreatorContext);
    const [selComponentConfig, setSelComponentConfig] = useState({});
    const [colorPicker, displayColorPicker] = useState('');
    const [layoutOpen, setLayoutOpen] = useState(editorType);

    const handleClick = (fieldNameValue) => {
        if (colorPicker == !fieldNameValue) {
            displayColorPicker(fieldNameValue);
        } else {
            displayColorPicker('');
        }
    };

    const handleClose = () => {
        displayColorPicker('');
    };

    function onFieldChange(fieldName, value) {
        // console.log('onFieldChange', {
        //     componentViewKey,
        //     fieldName,
        //     value,
        // });
        
        // if(componentViewKey === 'header'){
        //     console.log('trying to update at header lvl')
        // }

        compContext.updateComponentConfig(componentViewKey, fieldName, value);
    }

    const handleCollapse = (e) => {
        setLayoutOpen({ ...layoutOpen, [e]: !layoutOpen[e] });
    };

    const { creator } = creatorContext;

    useEffect(() => {
        setSelComponentConfig(
            compContext.templateConfiguration.componentList[componentViewKey]
        );
    }, [compContext.templateConfiguration.componentList[componentViewKey]]);

    if (isEmpty(selComponentConfig)) {
        return <></>;
    }

    

    return (
        <List>
            {Object.entries(detailedEditorDefinition).map(
                ([key, value], idx) => {
                    const defaultValue = _get(
                        selComponentConfig,
                        `userDefinedProps.${key}`,
                        value.defaultValue
                    );
                    return (
                        <ListItem button key={idx}>
                            <div className={classes.listSection}>
                                <Typography>
                                    {value.inputMethod === 'select' ||
                                    value.inputMethod === 'link' ||
                                    value.inputMethod === 'color' ||
                                    value.inputMethod === 'switch' ||
                                    value.inputMethod === 'text' ||
                                    value.inputMethod === 'visualDropdown'
                                        ? ''
                                        : value.label}
                                </Typography>
                                {value.inputMethod === 'color' && (
                                    <ColorPicker
                                        defaultValue={defaultValue}
                                        value={value}
                                        colorPicker={colorPicker}
                                        handleClick={handleClick}
                                        handleClose={handleClose}
                                        onFieldChange={onFieldChange}
                                    />
                                )}
                                {value.inputMethod === 'text' && (
                                    <TextField
                                        id='font-body'
                                        sx={{ input: { color: 'white' }, label:{color:'whitesmoke'}, 
                                        "& .MuiOutlinedInput-root": {
                                            "& > fieldset": { borderColor: "white" },
                                          },
                                        }}
                                        label={value.label}
                                        variant='outlined'
                                        defaultValue={defaultValue}
                                        onChange={(e) =>
                                            onFieldChange(
                                                value.name,
                                                e.target.value
                                            )
                                        }
                                    />
                                )}
                                {value.inputMethod === 'radio' && (
                                    <RadioGroup
                                        name={value.label}
                                        // defaultValue={defaultValue}
                                        value={defaultValue}
                                        onChange={(e) =>
                                            onFieldChange(
                                                value.name,
                                                e.target.value
                                            )
                                        }
                                    >
                                        {value.inputOptions.map((io, idx) => {
                                            return (
                                                <FormControlLabel
                                                    value={io}
                                                    control={<Radio />}
                                                    label={io}
                                                    key={idx}
                                                ></FormControlLabel>
                                            );
                                        })}
                                    </RadioGroup>
                                )}
                                {value.inputMethod === 'dropdown' && (
                                    <DetailedEditorDropdown
                                        {...{
                                            defaultValue,
                                            value,
                                            onFieldChange,
                                            creator,
                                        }}
                                    />
                                )}
                                {value.inputMethod === 'switch' && (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={defaultValue}
                                                onChange={(e) =>
                                                    onFieldChange(
                                                        value.name,
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label={value.label}
                                    />
                                )}
                                {value.inputMethod === 'select' && (
                                    <FormControl sx={{ width: '100%', color:'white' }}>
                                        <InputLabel
                                            id={`${value.name}-select-label`}
                                            style={{color:'whitesmoke'}}
                                        >
                                            {value.label}
                                        </InputLabel>
                                        <Select
                                            labelId={`${value.name}-select-label`}
                                            id={`${value.name}-select`}
                                            value={defaultValue}
                                            label={value.label}
                                            onChange={(e) =>
                                                onFieldChange(
                                                    value.name,
                                                    e.target.value
                                                )
                                            }
                                            style={{color:'white'}}
                                            inputProps={{
                                                classes: {
                                                    icon: classes.icon,
                                                },
                                            }}
                                            sx={{
                                                color: 'white',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'white'
                                                },
                                                '& .MuiSvgIcon-root': {
                                                    color: 'white'
                                                }
                                            }}
                                        >
                                            {value.inputOptions.map((io) => {
                                                return (
                                                    <MenuItem value={io}>
                                                        {io}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                )}
                                {value.inputMethod === 'link' && creator.id && (
                                    <Link
                                        href={`${location.origin}/create/${value.urlPage}?id=${creator.id}${value.conditionParam}`}
                                        target='blank'
                                        style={{ display: 'flex' }}
                                    >
                                        <Typography
                                            variant='button'
                                            color='secondary'
                                            style={{ fontWeight: 400 }}
                                        >
                                            {value.label}
                                        </Typography>{' '}
                                        <OpenInNewIcon
                                            style={{
                                                color: 'grey',
                                                fontSize: 14,
                                            }}
                                        />
                                    </Link>
                                )}
                                {value.inputMethod === 'slider' && (
                                    <Slider
                                        aria-label={value.label}
                                        defaultValue={defaultValue}
                                        step={value.step}
                                        marks
                                        min={value.min}
                                        max={value.max}
                                        valueLabelDisplay='auto'
                                        onChangeCommitted={(e, newValue) =>
                                            onFieldChange(value.name, newValue)
                                        }
                                    />
                                )}
                                {value.inputMethod === 'visualDropdown' && (
                                    <div className={classes.layoutWrapper}>
                                        <div
                                            onClick={() =>
                                                handleCollapse(value.name)
                                            }
                                            className={classes.layoutDropdown}
                                        >
                                            <ListItemText
                                                primary={value.label}
                                            />
                                            {layoutOpen[value.name] ? (
                                                <KeyboardArrowUpIcon />
                                            ) : (
                                                <KeyboardArrowDownIcon />
                                            )}
                                        </div>
                                        <Collapse
                                            in={layoutOpen[value.name]}
                                            timeout='auto'
                                            unmountOnExit
                                        >
                                            <Grid container spacing={2}>
                                                {value.inputOptions.map(
                                                    (io, idx) => {
                                                        return (
                                                            <Grid item xs={6}>
                                                                <Card>
                                                                    <CardMedia
                                                                        component='img'
                                                                        height='62'
                                                                        image={
                                                                            value
                                                                                .inputImages[
                                                                                io.replace(
                                                                                    /\s/g,
                                                                                    ''
                                                                                )
                                                                            ]
                                                                        }
                                                                        className={
                                                                            classes.layoutImg
                                                                        }
                                                                        alt={
                                                                            value.label
                                                                        }
                                                                    />
                                                                    <div
                                                                        className={
                                                                            classes.layoutRadio
                                                                        }
                                                                    >
                                                                        <RadioGroup
                                                                            name={
                                                                                value.label
                                                                            }
                                                                            value={
                                                                                defaultValue
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                onFieldChange(
                                                                                    value.name,
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                        >
                                                                            <FormControlLabel
                                                                                value={
                                                                                    io
                                                                                }
                                                                                control={
                                                                                    <Radio
                                                                                        size='small'
                                                                                        sx={{
                                                                                            fontSize: 15,
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label={
                                                                                    value.name ===
                                                                                    'cardType'
                                                                                        ? io
                                                                                              .split(
                                                                                                  ' '
                                                                                              )
                                                                                              .splice(
                                                                                                  1
                                                                                              )
                                                                                        : io
                                                                                }
                                                                                key={
                                                                                    idx
                                                                                }
                                                                                sx={{
                                                                                    fontSize: 15,
                                                                                }}
                                                                            ></FormControlLabel>
                                                                        </RadioGroup>
                                                                    </div>
                                                                </Card>
                                                            </Grid>
                                                        );
                                                    }
                                                )}
                                            </Grid>
                                        </Collapse>
                                    </div>
                                )}
                            </div>
                        </ListItem>
                    );
                }
            )}
        </List>
    );
};

export default DetailedEditor;
