import React from 'react';
import VideosLayout from '../../../shared/videos/VideosLayout';
import NoContent from '../../../shared/NoContent';
import isEmpty from 'lodash.isempty';

function VideoSection({ videos, theme }) {
    return (
        <div id='video-section' className='videos-section'>
            {!isEmpty(videos) && (
                <div className='videos-section-wrapper'>
                    <div className='videos-section-header'>
                        <h1
                            className='section-header-2'
                            style={{ color: theme.textColor }}
                        >
                            Videos
                        </h1>
                    </div>
                    <NoContent
                        data={videos}
                        style={{
                            color: theme.textColor,
                            opacity: 0.8,
                            textAlign: 'center',
                            margin: 20,
                        }}
                    />
                    <VideosLayout
                        swipable={true}
                        classes={{
                            rootClass: 'videos-section, slider-div',
                            containerClass: 'slider w-slider slider-relative',
                            sliderClass: 'mask-2 w-slider-mask',
                            itemClass: 'w-slide',
                            videoClass: 'video w-video w-embed',
                        }}
                        videos={videos}
                    />
                </div>
            )}
        </div>
    );
}

export default VideoSection;
