import _get from 'lodash.get';
import {
    AboutSection,
    AboutDetailedEditor,
    title as aboutTitle,
} from './about';
import {
    ContactSection,
    ContactDetailedEditor,
    title as contactTitle,
} from './contact';
import {
    FooterSection,
    FooterDetailedEditor,
    title as footerTitle,
} from './footer';
import {
    HeaderSection,
    HeaderDetailedEditor,
    title as headerTitle,
} from './header';
import {
    MusicSection,
    MusicDetailedEditor,
    title as musicTitle,
} from './music';
import {
    NavigationSection,
    NavigationDetailedEditor,
    title as navigationTitle,
} from './navigation';
import { NewsSection, NewsDetailedEditor, title as newsTitle } from './news';
import {
    PhotosSection,
    PhotosDetailedEditor,
    title as photosTitle,
} from './photos';
import {
    SubscribeSection,
    SubscribeDetailedEditor,
    title as subscribeTitle,
} from './subscribe';
import { TourSection, TourDetailedEditor, title as tourTitle } from './tour';
import {
    VideosSection,
    VideosDetailedEditor,
    title as videosTitle,
} from './video';

const sectionList = {
    navigation: {
        key: 'navigation',
        component: NavigationSection,
        title: navigationTitle,
        detailedEditor: NavigationDetailedEditor,
        defaultOrder: 1,
    },
    header: {
        key: 'header',
        component: HeaderSection,
        title: headerTitle,
        isDisplayedOnNav: false,
        detailedEditor: HeaderDetailedEditor,
        defaultOrder: 2,
    },
    news: {
        key: 'news',
        component: NewsSection,
        title: newsTitle,
        isDisplayedOnNav: true,
        detailedEditor: NewsDetailedEditor,
        defaultOrder: 3,
    },
    music: {
        key: 'music',
        component: MusicSection,
        title: musicTitle,
        isDisplayedOnNav: true,
        detailedEditor: MusicDetailedEditor,
        defaultOrder: 4,
    },
    video: {
        key: 'video',
        component: VideosSection,
        title: videosTitle,
        isDisplayedOnNav: true,
        detailedEditor: VideosDetailedEditor,
        defaultOrder: 5,
    },
    tour: {
        key: 'tour',
        component: TourSection,
        title: tourTitle,
        isDisplayedOnNav: true,
        detailedEditor: TourDetailedEditor,
        defaultOrder: 6,
    },
    photos: {
        key: 'photos',
        component: PhotosSection,
        title: photosTitle,
        isDisplayedOnNav: true,
        detailedEditor: PhotosDetailedEditor,
        defaultOrder: 7,
    },
    about: {
        key: 'about',
        component: AboutSection,
        title: aboutTitle,
        isDisplayedOnNav: true,
        detailedEditor: AboutDetailedEditor,
        defaultOrder: 8,
    },
    contact: {
        key: 'contact',
        component: ContactSection,
        title: contactTitle,
        isDisplayedOnNav: true,
        detailedEditor: ContactDetailedEditor,
        defaultOrder: 9,
    },
    email: {
        key: 'email',
        component: SubscribeSection,
        title: subscribeTitle,
        isDisplayedOnNav: true,
        detailedEditor: SubscribeDetailedEditor,
        defaultOrder: 10,
        isEnabled: (props) => {
            const availableIntegrations = _get(
                props,
                'integrations.integrated',
                []
            );
            const mailchimpIntegration = availableIntegrations.find(
                (ai) => ai.network === 'MAILCHIMP'
            );
            return mailchimpIntegration !== undefined;
        },
    },
    footer: {
        key: 'footer',
        component: FooterSection,
        title: footerTitle,
        isDisplayedOnNav: false,
        detailedEditor: FooterDetailedEditor,
        defaultOrder: 11,
    },
};

export { sectionList };
