import React from 'react'

function ContactSection() {
    return (
        <div className="contact-section">
    <div className="column center">
      <h3 className="section-header">Stay Updated</h3>
      <h5 className="subheading center">
        the latest Updates Straight to your inbox
      </h5>
      <div className="signup-form w-form">
        <form
          id="email-form"
          name="email-form"
          data-name="Email Form"
          className="form"
        >
          <input
            type="email"
            className="form-field w-input"
            maxLength={256}
            name="email-2"
            data-name="Email 2"
            placeholder="Enter your email address"
            id="email-2"
            required
          />
          <input
            type="submit"
            defaultValue="SUBSCRIBE"
            data-wait="Please wait..."
            className="nav-link button inverted w-button"
          />
        </form>
        <div className="form-submission-message w-form-done">
          <div className="submission-message">
            Thank you! Your submission has been received!
          </div>
        </div>
        <div className="form-submission-message w-form-fail">
          <div className="submission-message">
            Oops! Something went wrong while submitting the form.
          </div>
        </div>
      </div>
    </div>
  </div>
    )
}

export default ContactSection
