import React from 'react';

const ResponsiveLink = (props) => {
    return props.linkText.includes('@') ? (
        <a
            href={`mailto: ${props.linkText}`}
            className={props.className}
            style={props.textStyle ? props.textStyle : {}}
        >
            {props.linkText}
        </a>
    ) : (
        <div
            className={props.className}
            style={props.textStyle ? props.textStyle : {}}
        >
            {props.linkText}
        </div>
    );
};
export default ResponsiveLink;
