import React from 'react';

import Carousel from 'react-multi-carousel';

import YouTube from '@mui/icons-material/YouTube';
import PlayArrow from '@mui/icons-material/PlayArrow';
import 'react-multi-carousel/lib/styles.css';
import CustomArrow from '../CustomArrow';
function SwipableVideos({ setVideoToPreview, handleOpen, videos }) {
    return (
        <>
            {videos.length === 1 ? (
                <div
                    style={{
                        paddingRight: 5,
                        position: 'relative',
                        width: '50%',
                    }}
                >
                    <img
                        src={videos[0].thumbnail}
                        alt={videos[0].title}
                        style={{
                            width: '100%',
                            height: '100%',
                            resizeMode: 'contain',
                        }}
                    />
                    <YouTube
                        style={{
                            fontSize: 80,
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: '#333333',
                            opacity: 0.9,
                        }}
                        onClick={() => {
                            setVideoToPreview(videos[0].url);
                            handleOpen();
                        }}
                    />
                    <PlayArrow
                        style={{
                            fontSize: 35,
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white',
                            opacity: 1,
                        }}
                        onClick={() => {
                            setVideoToPreview(videos[0].url);
                            handleOpen();
                        }}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            padding: 20,
                            width: 'calc(100%) - 5',
                            textAlign: 'left',
                            height: 50,
                            backgroundImage:
                                'linear-gradient(to bottom, black, transparent)',
                        }}
                    >
                        <p
                            style={{
                                color: 'white',
                                fontSize: 18,
                                fontFamily: 'Montserrat, sans-serif',
                            }}
                        >
                            {videos[0].title}
                        </p>
                    </div>
                </div>
            ) : (
                <Carousel
                    ssr={true}
                    infinite={false}
                    loop
                    responsive={{
                        desktop: {
                            breakpoint: { max: 4000, min: 1024 },
                            items: 2,
                            slidesToSlide: 1, // optional, default to 1.
                        },
                        tablet: {
                            breakpoint: { max: 1024, min: 464 },
                            items: 1,
                            slidesToSlide: 1, // optional, default to 1.
                        },
                        mobile: {
                            breakpoint: { max: 464, min: 0 },
                            items: 1,
                            slidesToSlide: 1, // optional, default to 1.
                        },
                    }}
                >
                    {videos.map((item) => {
                        return (
                            <div style={{ paddingRight: 5 }}>
                                <img
                                    src={item.thumbnail}
                                    alt={item.title}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        resizeMode: 'contain',
                                    }}
                                />
                                <YouTube
                                    style={{
                                        fontSize: 80,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: '#333333',
                                        opacity: 0.9,
                                    }}
                                    onClick={() => {
                                        setVideoToPreview(item.url);
                                        handleOpen();
                                    }}
                                />
                                <PlayArrow
                                    style={{
                                        fontSize: 35,
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: 'white',
                                        opacity: 1,
                                    }}
                                    onClick={() => {
                                        setVideoToPreview(item.url);
                                        handleOpen();
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        padding: 20,
                                        width: 'calc(100%) - 5',
                                        textAlign: 'left',
                                        height: 50,
                                        backgroundImage:
                                            'linear-gradient(to bottom, black, transparent)',
                                    }}
                                >
                                    <p
                                        style={{
                                            color: 'white',
                                            fontSize: 18,
                                            fontFamily:
                                                'Montserrat, sans-serif',
                                        }}
                                    >
                                        {item.title}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </Carousel>
            )}
        </>
    );
}

export default SwipableVideos;
