import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { openUrl } from '../../utils/action';
import DOMPurify from 'dompurify';
import { decode } from 'html-entities';

const useStyles = makeStyles(() => ({
    root: {
        margin: 'auto',
        borderRadius: 0,
        position: 'relative',
        '& :hover': {
            cursor: 'pointer',
        },
    },
    content: {
        padding: 24,
    },
    cta: {
        display: 'block',
        textAlign: 'center',
        color: '#fff',
        letterSpacing: '3px',
        fontWeight: 200,
        fontSize: 12,
    },
    title: {
        letterSpacing: '2px',
    },
    mediaStyles: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.08)', //bgColor,
        backgroundPosition: 'center', //bgPosition
    },
    useLightTopShadowStyles: {
        backgroundColor: 'transparent',
        boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
        transition: '0.3s',
        '&:hover': {
            boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.5)',
            zIndex: 100,
        },
    },
}));

export const Card6 = ({
    title,
    subtitle,
    note1,
    media,
    textColor,
    roundedCorners,
    url,
}) => {
    const styles = useStyles();
    // const mediaStyles = useCoverCardMediaStyles();
    // const shadowStyles = useLightTopShadowStyles();
    let cleanTitle = DOMPurify.sanitize(title, { SAFE_FOR_TEMPLATES: true });
    return (
        <Card
            className={cx(styles.root, styles.useLightTopShadowStyles)}
            onClick={() => openUrl(url)}
            style={{ borderRadius: roundedCorners ? '1.5rem' : 0 }}
        >
            {/* <CardMedia classes={mediaStyles} image={media} /> */}
            <CardMedia className={styles.mediaStyles} image={media} />
            <CardActionArea>
                <CardContent className={styles.content}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        minHeight={360}
                        color={textColor}
                        textAlign={'center'}
                    >
                        <h1 className={styles.title}>{decode(cleanTitle)}</h1>
                        <p>{subtitle}</p>
                    </Box>
                    <Typography
                        className={styles.cta}
                        variant={'overline'}
                        style={{ color: textColor }}
                    >
                        {note1}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

const title = 'Card Option 6';
const detailedEditorDefinition = {
    'card.textColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.textColor',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#FFFFFF',
    },
    'card.roundedCorners': {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'card.roundedCorners',
        label: 'Rounded Corners',
        description: 'Creates rounded corners on each card',
        isRequired: false,
    },
};

Card6.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    note1: PropTypes.string.isRequired,
    media: PropTypes.string.isRequired,
    textColor: detailedEditorDefinition['card.textColor'].propType,
    roundedCorners: detailedEditorDefinition['card.roundedCorners'].propType,
};

export { Card6 as default, title, detailedEditorDefinition };
