import React from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Typography } from '@mui/material';
import { openUrl } from '../../utils/action';
import DOMPurify from 'dompurify';
import { decode } from 'html-entities';

const useStyles = makeStyles(() => ({
    card4root: {
        '& :hover': {
            cursor: 'pointer',
        },
    },
    card: ({ roundedCorners }) => ({
        borderRadius: roundedCorners ? '1rem' : 0,
        boxShadow: 'none',
        position: 'relative',
        minWidth: 200,
        minHeight: 360,
        '&:after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            width: '100%',
            height: '64%',
            bottom: 0,
            zIndex: 1,
            background: 'linear-gradient(to top, #000, rgba(0,0,0,0))',
        },
    }),
    content: {
        position: 'absolute',
        zIndex: 2,
        bottom: 0,
        width: '100%',
    },
    mediaStyles: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        zIndex: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.08)', //bgColor,
        backgroundPosition: 'top', //bgPosition
    },
    title: {
        fontSize: '1.25rem',
        fontWeight: 'bold',
        lineHeight: '1.2',
    },
    subtitle: {
        fontSize: '0.875rem',
        lineHeight: '1.5',
    },
    note1: {
        fontSize: '1rem',
        fontWeight: '200',
        lineHeight: '1.4',
        letterSpacing: '1px',
    },
}));

export const Card4 = ({
    title,
    subtitle,
    note1,
    media,
    textColor,
    roundedCorners,
    url,
}) => {
    const styles = useStyles({ textColor, roundedCorners });
    let cleanTitle = DOMPurify.sanitize(title, { SAFE_FOR_TEMPLATES: true });
    return (
        <div className={styles.card4root} onClick={() => openUrl(url)}>
            {/* <NoSsr>
                <GoogleFontLoader
                    fonts={[
                        { font: 'Spartan', weights: [300] },
                        { font: 'Montserrat', weights: [200, 400, 700] },
                    ]}
                />
            </NoSsr> */}
            <Card className={styles.card}>
                <CardMedia className={styles.mediaStyles} image={media} />
                <Box py={3} px={2} className={styles.content}>
                    <Typography
                        variant='body1'
                        component='p'
                        className={styles.note1}
                        sx={{ color: Color(textColor).fade(0.3).toString() }}
                    >
                        {note1}
                    </Typography>
                    <Typography
                        variant='h6'
                        className={styles.title}
                        sx={{ fontSize: 18, color: textColor }}
                    >
                        {decode(cleanTitle)}
                    </Typography>
                    <Typography
                        variant='body1'
                        component='p'
                        className={styles.subtitle}
                        sx={{
                            mt: '1rem',
                            color: Color(textColor).fade(0.3).toString(),
                        }}
                    >
                        {subtitle && subtitle.length > 220
                            ? subtitle.substr(0, 220) + '...'
                            : subtitle}
                    </Typography>
                </Box>
            </Card>
        </div>
    );
};

const title = 'Card Option 4';
const detailedEditorDefinition = {
    'card.textColor': {
        propType: PropTypes.string.isRequired,
        valueType: 'string',
        inputMethod: 'color',
        name: 'card.textColor',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#000000',
    },
    'card.roundedCorners': {
        propType: PropTypes.bool.isRequired,
        valueType: 'boolean',
        inputMethod: 'switch',
        name: 'card.roundedCorners',
        label: 'Rounded Corners',
        description: 'Creates rounded corners on each card',
        isRequired: false,
    },
};

Card4.propTypes = {
    title: PropTypes.string.isRequired,
    media: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    note1: PropTypes.string.isRequired,
    textcolor: detailedEditorDefinition['card.textColor'].propType,
    roundedCorners: detailedEditorDefinition['card.roundedCorners'].propType,
};

export { Card4 as default, title, detailedEditorDefinition };
