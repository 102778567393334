import React from "react";

function MerchSection() {
  return (
    <div id="merch-section" className="merch-section">
      <div className="merch-section-wrapper">
        <div className="merch-section-header">
          <h1 className="section-header">Merch</h1>
        </div>
        <div className="merch-item-wrapper">
          <a
            href="#"
            target="_blank"
            className="merch-link-block w-inline-block"
          >
            <div className="merch-image">
              <img
                src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3820821efc36_Cal%20Scruby%20Merch%20Hoodie.png"
                sizes="100vw"
                srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3820821efc36_Cal%2520Scruby%2520Merch%2520Hoodie-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3820821efc36_Cal%20Scruby%20Merch%20Hoodie.png 758w"
                alt
                className="image-6"
              />
            </div>
            <div className="merch-header">
              <h5>Merch Item 1</h5>
            </div>
            <div className="merch-buy-now-button">
              <div className="merch-cart-button">
                <img
                  src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38c02c1efc1c_263142.png"
                  alt
                  className="image-5"
                />
              </div>
              <div className="merch-buy-button">
                <div>Buy Now</div>
              </div>
            </div>
          </a>
          <a
            href="#"
            target="_blank"
            className="merch-link-block w-inline-block"
          >
            <div className="merch-image">
              <img
                src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d385f401efc34_Cal%20Scruby%20Merch%20Album.png"
                sizes="100vw"
                srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d385f401efc34_Cal%2520Scruby%2520Merch%2520Album-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d385f401efc34_Cal%20Scruby%20Merch%20Album.png 762w"
                alt
                className="image-6"
              />
            </div>
            <div className="merch-header">
              <h5>Merch Item 1</h5>
            </div>
            <div className="merch-buy-now-button">
              <div className="merch-cart-button">
                <img
                  src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38c02c1efc1c_263142.png"
                  alt
                  className="image-5"
                />
              </div>
              <div className="merch-buy-button">
                <div>Buy Now</div>
              </div>
            </div>
          </a>
          <a
            href="#"
            target="_blank"
            className="merch-link-block w-inline-block"
          >
            <div className="merch-image">
              <img
                src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829a81efc37_Cal%20Scruby%20Merch%204%20bundle.png"
                sizes="100vw"
                srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829a81efc37_Cal%2520Scruby%2520Merch%25204%2520bundle-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829a81efc37_Cal%2520Scruby%2520Merch%25204%2520bundle-p-800.png 800w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829a81efc37_Cal%20Scruby%20Merch%204%20bundle.png 846w"
                alt
                className="image-6"
              />
            </div>
            <div className="merch-header">
              <h5>Merch Item 1</h5>
            </div>
            <div className="merch-buy-now-button">
              <div className="merch-cart-button">
                <img
                  src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38c02c1efc1c_263142.png"
                  alt
                  className="image-5"
                />
              </div>
              <div className="merch-buy-button">
                <div>Buy Now</div>
              </div>
            </div>
          </a>
          <a
            href="#"
            target="_blank"
            className="merch-link-block w-inline-block"
          >
            <div className="merch-image">
              <img
                src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829a81efc37_Cal%20Scruby%20Merch%204%20bundle.png"
                sizes="100vw"
                srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829a81efc37_Cal%2520Scruby%2520Merch%25204%2520bundle-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829a81efc37_Cal%2520Scruby%2520Merch%25204%2520bundle-p-800.png 800w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3829a81efc37_Cal%20Scruby%20Merch%204%20bundle.png 846w"
                alt
                className="image-6"
              />
            </div>
            <div className="merch-header">
              <h5>Merch Item 1</h5>
            </div>
            <div className="merch-buy-now-button">
              <div className="merch-cart-button">
                <img
                  src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38c02c1efc1c_263142.png"
                  alt
                  className="image-5"
                />
              </div>
              <div className="merch-buy-button">
                <div>Buy Now</div>
              </div>
            </div>
          </a>
          <a
            href="#"
            target="_blank"
            className="merch-link-block w-inline-block"
          >
            <div className="merch-image">
              <img
                src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3820821efc36_Cal%20Scruby%20Merch%20Hoodie.png"
                sizes="100vw"
                srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3820821efc36_Cal%2520Scruby%2520Merch%2520Hoodie-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d3820821efc36_Cal%20Scruby%20Merch%20Hoodie.png 758w"
                alt
                className="image-6"
              />
            </div>
            <div className="merch-header">
              <h5>Merch Item 1</h5>
            </div>
            <div className="merch-buy-now-button">
              <div className="merch-cart-button">
                <img
                  src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38c02c1efc1c_263142.png"
                  alt
                  className="image-5"
                />
              </div>
              <div className="merch-buy-button">
                <div>Buy Now</div>
              </div>
            </div>
          </a>
          <a
            href="#"
            target="_blank"
            className="merch-link-block w-inline-block"
          >
            <div className="merch-image">
              <img
                src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d385f401efc34_Cal%20Scruby%20Merch%20Album.png"
                sizes="100vw"
                srcSet="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d385f401efc34_Cal%2520Scruby%2520Merch%2520Album-p-500.png 500w, https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d385f401efc34_Cal%20Scruby%20Merch%20Album.png 762w"
                alt
                className="image-6"
              />
            </div>
            <div className="merch-header">
              <h5>Merch Item 1</h5>
            </div>
            <div className="merch-buy-now-button">
              <div className="merch-cart-button">
                <img
                  src="https://assets.website-files.com/5fa45567b29d384ec31efbed/5fa45567b29d38c02c1efc1c_263142.png"
                  alt
                  className="image-5"
                />
              </div>
              <div className="merch-buy-button">
                <div>Buy Now</div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default MerchSection;
