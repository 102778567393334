import MenuIcon from '@mui/icons-material/Menu';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import MobileNav1 from './MobileNav1';
import MobileNav2 from './MobileNav2';
import isEmpty from 'lodash.isempty';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        left: '0%',
        top: '0%',
        right: '0%',
        bottom: 'auto',
        zIndex: 2000,
    },
    wrapper: {
        position: 'fixed',
        left: '0%',
        top: '0%',
        right: '0%',
        bottom: 'auto',
    },
    desktopWrapper: (props) => ({
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'space-around',
        },
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: props.backgroundColor || 'white',
    }),
    desktopNav: (props) => ({
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'space-around',
        },
        width: '100vw',
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: props.backgroundColor || 'white',
    }),
    desktopLink: (props) => ({
        color: props.textColor || 'black',
        textDecoration: 'none',
        fontFamily: 'Montserrat, sans-serif',
        margin: 10,
        fontSize: 26,
        fontWeight: 'bold',
    }),
    mobileLink: (props) => ({
        fontSize: 30,
        marginRight: 10,
        color: props.textColor || 'black',
    }),
    mobileNav: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    menuButton: {
        marginLeft: 'auto',
        marginRight: 10,
    },
}));

function NavBar2(props) {
    const [isOpen, setOpen] = useState(false);
    const styles = {
        link: {
            color: props.textColor || 'black',
            textDecoration: 'none',
            fontFamily: 'Montserrat, sans-serif',
            margin: 10,
            fontSize: 26,
            fontWeight: 'bold',
        },
    };
    const isMobile = useMediaQuery({ query: '(max-width: 935px)' });
    const classes = useStyles({ ...props });

    const renderMobileNavBar = () => {
        switch (props.mobileNavType) {
            case 'minimal':
                return <MobileNav1 isOpen={isOpen} {...props} />;
            case 'drawer':
                return <MobileNav2 isOpen={isOpen} {...props} />;
            default:
                return <MobileNav1 isOpen={isOpen} {...props} />;
        }
    };
    return (
        <div className={classes.root}>
            <div className={classes.wrapper}>
                <div className={classes.desktopWrapper}>
                    {!isMobile ? (
                        <nav
                            role='navigation'
                            style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                width: '100vw',
                                paddingTop: 10,
                                paddingBottom: 10,
                                backgroundColor: props.backgroundColor || 'white',
                            }}
                        >
                            {props.routes.map((item) => {
                                if (!isEmpty(item) && item.href) {
                                    if (item.href.startsWith('#')) {
                                        return (
                                            <a
                                                style={styles.link}
                                                onClick={() => {
                                                    window.document
                                                        .getElementById(item.href.replace('#', ''))
                                                        .scrollIntoView();
                                                }}
                                            >
                                                {item.title}
                                            </a>
                                        );
                                    }
                                    return (
                                        <a href={item.href} style={styles.link} target={item.target || ''}>
                                            {item.title}
                                        </a>
                                    );
                                }
                            })}
                        </nav>
                    ) : (
                        <div className={classes.mobileNav}>
                            {props.profile.logo && (
                                <a href='#'>
                                    <img
                                        src={props.profile.logo}
                                        alt
                                        width={40}
                                        style={{ margin: '10px', marginLeft: '20px' }}
                                    />
                                </a>
                            )}
                            <div onClick={() => setOpen(!isOpen)} className={classes.menuButton}>
                                <MenuIcon className={classes.mobileLink} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {renderMobileNavBar()}
        </div>
    );
}

export default NavBar2;
