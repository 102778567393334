import React from 'react';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';

function CustomArrow({ onClick, isLeft, isMusic }) {

  const leftClasses = isMusic ? "arrow w-slider-arrow-left" : "arrow arrow-left-relative";
  const rightClasses = isMusic ? "arrow w-slider-arrow-right" : "arrow arrow-right-relative";
  const additionalStyle = isMusic ?
    {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      background: 'rgb(255,255,255)',
      background: `linear-gradient(${isLeft ? '270deg' : '90deg'}, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%)`,
      alignItems: isLeft ? 'flex-start' : 'flex-end'
    } : {};

  return (
    <div
      onClick={onClick}
      className={isLeft ? leftClasses : rightClasses}
      style={additionalStyle}
    >
      {isLeft ? <ChevronLeft style={{ fontSize: 60 }} /> : <ChevronRight style={{ fontSize: 60 }} />}
    </div>
  );

}

export default CustomArrow
