import React from "react";
import isEmpty from 'lodash.isempty';

function NewsSection({ news }) {
  return (
    <div id="news-section" className="news-section">
      {!isEmpty(news) && (
        <div className="news-wrapper">
          <div className="news-section-header">
            <h1 className="section-header">News</h1>
          </div>
          <div className="news-collection">
            <a
              href="https://edmidentity.com/2020/06/25/griz-wreckno-medusa/"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8e83d3d4d6343998b_Cal%20Scruby%20News%201%20WWWS.png"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 80vw, (max-width: 991px) 39vw, 27vw"
                    srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8e83d3d4d6343998b_Cal%20Scruby%20News%201%20WWWS-p-500.png 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8e83d3d4d6343998b_Cal%20Scruby%20News%201%20WWWS-p-800.png 800w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8e83d3d4d6343998b_Cal%20Scruby%20News%201%20WWWS-p-1080.png 1080w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8e83d3d4d6343998b_Cal%20Scruby%20News%201%20WWWS-p-1600.png 1600w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8e83d3d4d6343998b_Cal%20Scruby%20News%201%20WWWS.png 1790w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    Cal Scruby Shines On "While You Were Sleeping"
                  </h4>
                </div>
                <div className="news-date-wrapper">
                  <div className="news-article-date">June 27, 2020</div>
                </div>
                <div className="news-article-paragraph">
                  <p className="paragraph-2">
                    Last year, Los Angeles based emcee Cal Scruby turned heads
                    with his Unsigned project, impressing new fans with his slick
                    flow and ...
                  </p>
                </div>
              </div>
            </a>
            <a
              href="https://edm.com/music-releases/griz-solo-feat-probcause"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f7a163482b9721516d_Cal%20Scruby%20News%204%20Unsigned.png"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 80vw, (max-width: 991px) 39vw, 27vw"
                    srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f7a163482b9721516d_Cal%20Scruby%20News%204%20Unsigned-p-500.png 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f7a163482b9721516d_Cal%20Scruby%20News%204%20Unsigned-p-800.png 800w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f7a163482b9721516d_Cal%20Scruby%20News%204%20Unsigned-p-1080.png 1080w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f7a163482b9721516d_Cal%20Scruby%20News%204%20Unsigned.png 1478w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    Cal Scruby's "Unsigned" Is A Captivating Introduction
                  </h4>
                </div>
                <div className="news-date-wrapper">
                  <div className="news-article-date">May 31, 2020</div>
                </div>
                <div className="news-article-paragraph">
                  <p className="paragraph-2">
                    Cal Scruby name is beginning to buzz, a flashback to the days
                    in which “Unsigned Hype” carried special significance. Since
                    departing his...
                  </p>
                </div>
              </div>
            </a>
            <a
              href="https://edm.com/music-releases/griz-could-u"
              target="_blank"
              className="news-link-block w-inline-block"
            >
              <div className="news-article-wrapper">
                <div className="news-article-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8f273cf0b1ca1d96e_Cal%20Scruby%20News%205%20White%20Privledge.png"
                    sizes="(max-width: 479px) 100vw, (max-width: 767px) 80vw, (max-width: 991px) 39vw, 27vw"
                    srcSet="https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8f273cf0b1ca1d96e_Cal%20Scruby%20News%205%20White%20Privledge-p-500.png 500w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8f273cf0b1ca1d96e_Cal%20Scruby%20News%205%20White%20Privledge-p-800.png 800w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8f273cf0b1ca1d96e_Cal%20Scruby%20News%205%20White%20Privledge-p-1080.png 1080w, https://assets.website-files.com/5f1ee2e31a0aca054f94df7f/5f3165f8f273cf0b1ca1d96e_Cal%20Scruby%20News%205%20White%20Privledge.png 1268w"
                    alt
                    className="news-article-image"
                  />
                </div>
                <div className="news-article-title">
                  <h4 className="news-article-header">
                    Cal Scruby Is Honest About White Privilege In Hip Hop
                  </h4>
                </div>
                <div className="news-date-wrapper">
                  <div className="news-article-date">Feb 7, 2020</div>
                </div>
                <div className="news-article-paragraph">
                  <p className="paragraph-2">
                    It’s not so much that the 28-year-old is ashamed to be a
                    rapper who happens to be white. The assumption of how the
                    context of his music ...
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewsSection;
