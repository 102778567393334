import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import Horizontal from '../../shared/social icons/Horizontal';
import { CompContext } from '../../context/CompContext';
import _get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import { useScrollTrigger } from '@mui/material';
import { getNetwork } from '../../utils/network';
import {
    createField,
    generatePropTypesAndDefaults,
} from '../../utils/detailedEditorDefinition';
import { isBuilderURL } from '../../utils/isBuilderUrl';

const useStyles = makeStyles((theme) => ({
    menu: ({ isEditor }) => ({
        '& .MuiPaper-root': {
            backgroundColor: ({ backgroundColor }) => backgroundColor,
            textAlign: 'center',
            minWidth: '100vw',
            maxHeight: '100vh',
            maxWidth: '100vw',
            width: '100vw',
            position: 'relative',
            left: '0 !important',
            [theme.breakpoints.down('md')]: {
                top: isEditor ? '128px !important' : '64px !important',
                transformOrigin: 'center center !important',
                height: isEditor ? 'calc(100vh - 193px)' : 'calc(100vh - 64px)',
                borderRadius: 0,
            },
            [theme.breakpoints.down('sm')]: {
                top: isEditor ? '112px !important' : '56px !important',
                height: isEditor ? 'calc(100vh - 178px)' : 'calc(100vh - 56px)',
            },
        },
        '& .MuiMenuItem-root': {
            justifyContent: 'center',
        },
    }),
    menuBtn: {
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    appBar: {
        transition: 'background-color 1s linear',
        '&.MuiAppBar-colorPrimary': {
            backgroundColor: ({ backgroundColor = 'transparent' }) => {
                return backgroundColor;
            },
        },
    },
    appBarNotEditor: {
        top: 'inherit',
        width: { md: '100%' },
    },
    appBarFixedOpenOptions: {
        top: 'inherit',
        width: { md: 'calc(100vw - 451px)' },
        left: { md: '451px' },
    },
    appBarFixed: {
        top: 'inherit',
        width: { md: 'calc(100vw - 113px)' },
        left: { md: '134px' },
    },
    customContainer: {
        [theme.breakpoints.up('md')]: {
            width: 'auto',
        },
    },
    btnMy2: {
        marginBlock: theme.spacing(2),
    },
    txtUppercase: {
        textTransform: 'uppercase',
    },
    txtCenter: {
        textAlign: 'center',
    },
    flexNone: {
        flex: 'none',
    },
    flexGrow: {
        flexGrow: 1,
    },
    displayBlock: {
        display: 'block',
    },
    displayNoneMd: {
        display: { xs: 'none', md: 'flex' },
    },
    displayFlexMd: {
        display: { xs: 'none', md: 'flex' },
    },
    displayBlockMd: {
        display: { xs: 'block', md: 'none' },
    },
    justifyContentEnd: {
        justifyContent: 'end',
    },
    justifyContentCenter: {
        justifyContent: 'center',
    },
    boxDisplayBlockMd: {
        display: { xs: 'block', md: 'none' },
    },
    boxMaxWidth130: {
        maxWidth: '130px',
    },
    imgStyling: {
        width: '100%',
        maxHeight: '2rem',
        maxWidth: '5rem',
    },
    iconBtnColor: {
        color: ({ textColor }) => textColor,
    },
    menuDisplayBlockMd: {
        display: { xs: 'block', md: 'none' },
    },
    boxDisplayFlexMd: {
        display: { xs: 'flex', md: 'none' },
        justifyContent: 'center',
        paddingTop: theme.spacing(1),
    },
    appBarMockFixed: {
        position: 'sticky',
        top: 0, // or any other value that fits your layout
        zIndex: 10,
    },
    appBarMockStatic: {
        position: 'relative',
    },
    NavSecToolbar: ({ hasLogo }) => ({
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: hasLogo ? 'space-between' : 'flex-end',
    }),
    toolbarLogo: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'inherit',
        },
    },
}));

/*
 * Supported Variants
 * 1. Logo Placement (left, right, center) => Determines where the navigation icons are
 * 1.a Logo Left, Text Right, Social Icons?
 * 1.b Logo Right, Text Left, Social Icons?
 * 1.c Logo Center, Text Split on Each Side, Social Icons Split Far Left/Right
 * 2. Placement Options (Fixed, Sticky, Static)
 * 3. Color Options (Text Color, Background Color, Transparency)
 */
const NavigationSection = ({
    color,
    backgroundColor,
    position,
    activity,
    transparentUntilScroll,
    displaySocialIcons,
    profile,
    integrations: { integrated },
    isEditor,
    monoIcons,
    iconColor,
    openOptions,
}) => {
    const compContext = useContext(CompContext);
    const [textColor, setTextColor] = React.useState(color);

    const hasLogo = !isEmpty(profile.logo);

    const classes = useStyles({
        hasLogo,
        textColor,
        backgroundColor,
        isEditor,
        openOptions,
    });

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    let scrollTrigger;
    try {
        // Define a function to get the target element
        const getTargetElement = () => {
            if (isEditor) {
                const mainContainer = document.getElementById('mainContainer');
                if (mainContainer) {
                    return mainContainer;
                }
                console.warn(
                    "Element with ID 'mainContainer' not found. Fallback to document."
                );
                return document;
            }
            return window;
        };

        // Safely get the target element
        const targetElement = getTargetElement();

        // If targetElement is still null or undefined, log a warning
        if (!targetElement) {
            console.warn(
                'No valid target element found for scroll trigger. Defaulting to window.'
            );
        }

        // Use the scrollTrigger hook
        scrollTrigger = useScrollTrigger({
            target: targetElement || window,
        });
    } catch (error) {
        console.error(
            'An error occurred while setting up scrollTrigger:',
            error
        );
        // Fallback: if the scrollTrigger setup fails, you might want to provide a default behavior.
    }

    const sortedComponents = compContext.getComponentList().filter((c) => {
        const displayOnNav =
            c &&
            c.userDefinedProps &&
            c.userDefinedProps.displayOnNavigation !== undefined
                ? c.userDefinedProps.displayOnNavigation
                : true;
        return (
            c.isVisible &&
            displayOnNav &&
            !['navigation', 'header', 'footer'].includes(c.componentName)
        );
    });

    React.useEffect(() => {
        setTextColor(color);
    }, [color]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const getNavItems = (isDesktop) => {
        return sortedComponents.map((page) => {
            const sectionTitle = _get(
                page,
                'userDefinedProps.sectionTitle',
                undefined
            );

            const pageName = isEmpty(sectionTitle)
                ? page.componentName
                : sectionTitle;
            if (isDesktop) {
                return (
                    <Button
                        key={page.componentName}
                        onClick={() => {
                            const containerElement =
                                document.getElementById('mainContainer');
                            if (containerElement != null) {
                                const myElement = document.getElementById(
                                    page.componentName
                                );
                                const topPos = myElement.offsetTop;
                                containerElement.scroll({
                                    behavior: 'smooth',
                                    top: topPos - 168,
                                });
                                //containerElement.scrollTop = topPos -168;
                            } else {
                                window.document
                                    .getElementById(page.componentName)
                                    .scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'start',
                                    });
                            }
                            handleCloseNavMenu();
                        }}
                        sx={{
                            my: 2,
                            display: 'block',
                            whiteSpace: 'nowrap',
                            color: textColor,
                            textTransform: 'uppercase',
                        }}
                    >
                        {pageName}
                    </Button>
                );
            }

            return (
                <MenuItem
                    key={page.componentName}
                    onClick={() => {
                        handleCloseNavMenu();
                        setTimeout(() => {
                            const containerElement =
                                document.getElementById('mainContainer');
                            if (containerElement != null) {
                                const myElement = document.getElementById(
                                    page.componentName
                                );
                                const topPos = myElement.offsetTop;
                                containerElement.scroll({
                                    behavior: 'smooth',
                                    top: topPos - 120,
                                });
                                //containerElement.scrollTop = topPos -120;
                            } else {
                                window.document
                                    .getElementById(page.componentName)
                                    .scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'start',
                                    });
                            }
                        }, 100);
                    }}
                >
                    <Typography
                        textAlign='center'
                        sx={{ color: textColor, textTransform: 'uppercase' }}
                    >
                        {pageName}
                    </Typography>
                </MenuItem>
            );
        });
    };

    const getStaticNavItem = (name, title, isDekstop) => {
        const networkObj = getNetwork(integrated, name);

        if (isEmpty(networkObj) || isEmpty(networkObj.data.url)) {
            return <></>;
        }

        if (isDekstop) {
            return (
                <Button
                    key={name}
                    onClick={() => {
                        handleCloseNavMenu();

                        window.open(networkObj.data.url, '_blank');
                    }}
                    sx={{
                        my: 2,

                        display: 'block',

                        whiteSpace: 'nowrap',

                        color: textColor,

                        textTransform: 'uppercase',
                    }}
                >
                    {title}
                </Button>
            );
        }

        return (
            <MenuItem
                key={name}
                onClick={() => {
                    handleCloseNavMenu();

                    window.open(networkObj.data.url, '_blank');
                }}
            >
                <Typography
                    textAlign='center'
                    sx={{ color: textColor, textTransform: 'uppercase' }}
                >
                    {title}
                </Typography>
            </MenuItem>
        );
    };

    const getNavConfig = () => {
        if (position === 'right') {
            const elements = [
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <Horizontal
                        integrated={integrated}
                        displaySocialIcons={displaySocialIcons}
                        monoIcons={monoIcons}
                        iconColor={iconColor}
                    />
                </Box>,
                <Box
                    sx={{
                        flexGrow: 1,
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'end',
                    }}
                >
                    {getNavItems(true)}
                    {getStaticNavItem('SHOPIFY', 'Merch', true)}
                    {getStaticNavItem('PATREON', 'Subscribe', true)}
                    {getStaticNavItem('EPK', 'EPK', true)}
                    {getStaticNavItem('BOOKING', 'BOOKING', true)}
                </Box>,
            ];
            if (!isEmpty(profile.logo)) {
                elements.push(
                    <Box
                        sx={{
                            ml: 2,
                            display: {
                                xs: 'none',
                                md: 'flex',
                                maxWidth: '130px',
                            },
                        }}
                    >
                        <img
                            style={{
                                width: '100%',
                                maxHeight: '2rem',
                                maxWidth: '5rem',
                            }}
                            alt={profile.name}
                            src={profile.logo || profile.pictureurl}
                        />
                    </Box>
                );
            }
            return elements.map((e) => e);
        }
        if (position === 'left') {
            const elements = [];

            if (!isEmpty(profile.logo)) {
                elements.push(
                    <Box
                        sx={{
                            mr: 2,
                            display: {
                                xs: 'none',
                                md: 'flex',
                                maxWidth: '130px',
                            },
                        }}
                    >
                        <img
                            style={{
                                width: '100%',
                                maxHeight: '2rem',
                                maxWidth: '5rem',
                            }}
                            alt={profile.name}
                            src={profile.logo || profile.pictureurl}
                        />
                    </Box>
                );
            }
            elements.push(
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {getNavItems(true)}
                    {getStaticNavItem('SHOPIFY', 'Merch', true)}
                    {getStaticNavItem('PATREON', 'Subscribe', true)}
                    {getStaticNavItem('EPK', 'EPK', true)}
                    {getStaticNavItem('BOOKING', 'BOOKING', true)}
                </Box>,
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'end',
                    }}
                >
                    <Horizontal
                        integrated={integrated}
                        displaySocialIcons={displaySocialIcons}
                        monoIcons={monoIcons}
                        iconColor={iconColor}
                    />
                </Box>
            );
            return elements.map((e) => e);
        }
        if (position === 'center') {
            const elements = [
                <Box sx={{ display: { xs: 'none', md: 'flex' }, flex: 'none' }}>
                    {getNavItems(true)}
                    {getStaticNavItem('SHOPIFY', 'Merch', true)}
                    {getStaticNavItem('PATREON', 'Subscribe', true)}
                    {getStaticNavItem('EPK', 'EPK', true)}
                    {getStaticNavItem('BOOKING', 'BOOKING', true)}
                </Box>,
            ];
            if (!isEmpty(profile.logo)) {
                elements.push(
                    <Box
                        sx={{
                            flexGrow: 1,
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'center',
                            flex: 'none',
                        }}
                    >
                        <img
                            style={{
                                width: '100%',
                                maxHeight: '2rem',
                                maxWidth: '5rem',
                            }}
                            alt={profile.name}
                            src={profile.logo || profile.pictureurl}
                        />
                    </Box>
                );
            }
            elements.push(
                <Box
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'end',
                    }}
                >
                    <Horizontal
                        integrated={integrated}
                        displaySocialIcons={displaySocialIcons}
                        monoIcons={monoIcons}
                        iconColor={iconColor}
                    />
                </Box>
            );
            return elements.map((e) => e);
        }
    };

    const isNotTransparent =
        activity === 'static'
            ? true
            : transparentUntilScroll === false ||
              (transparentUntilScroll === true && scrollTrigger);

    const appBarStyle = () => {
        const isBuilder = isBuilderURL();
        if (!isEditor) return classes.appBarNotEditor;

        if (activity === 'fixed') {
            if (isBuilder) {
                return classes.appBarMockFixed; // Mocking "fixed" behavior when in builder
            }
            return openOptions
                ? classes.appBarFixedOpenOptions
                : classes.appBarFixed;
        }

        if (activity === 'static') {
            if (isBuilder) {
                return classes.appBarMockStatic; // Mocking "static" behavior when in builder
            }
            return '';
        }

        return '';
    };

    return (
        <AppBar
            className={`${classes.appBar} ${appBarStyle()}`}
            id='navigation'
        >
            <Container maxWidth='xl' className={classes.customContainer}>
                <Toolbar className={classes.NavSecToolbar}>
                    {hasLogo && (
                        <Box
                            className={classes.toolbarLogo}
                            sx={{
                                mr: 2,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src={profile.logo}
                                alt='Logo'
                                style={{ maxHeight: '50px' }}
                            />
                        </Box>
                    )}
                    {getNavConfig()}
                    {position === 'center' && (
                        <Box
                            className={classes.boxDisplayBlockMd}
                            style={{ width: '48px', height: '48px' }}
                        ></Box>
                    )}

                    <Box className={classes.boxDisplayBlockMd}>
                        <IconButton
                            size='large'
                            aria-label='account of current user'
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={handleOpenNavMenu}
                            className={`${classes.iconBtnColor} ${classes.menuBtn}`}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            className={`${classes.menu} ${classes.menuDisplayBlockMd}`}
                            style={{
                                height: '100vh',
                                width: '100vw',
                                overflowX: 'hidden',
                                overflowY: 'auto',
                                left: 0,
                            }}
                        >
                            {getNavItems(false)}
                            {getStaticNavItem('SHOPIFY', 'Merch', false)}
                            {getStaticNavItem('PATREON', 'Subscribe', false)}
                            {getStaticNavItem('EPK', 'EPK', false)}
                            {getStaticNavItem('BOOKING', 'BOOKING', false)}
                            <Box className={classes.boxDisplayFlexMd}>
                                <Horizontal
                                    integrated={integrated}
                                    displaySocialIcons={displaySocialIcons}
                                    monoIcons={monoIcons}
                                    iconColor={iconColor}
                                />
                            </Box>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

const InputMethods = {
    SELECT: 'select',
    TEXT: 'text',
    LINK: 'link',
    COLOR: 'color',
    SWITCH: 'switch',
    STRING: 'string',
    BOOLEAN: 'boolean',
};

const detailedEditorDefinition = {
    position: createField(
        PropTypes.string.isRequired,
        'string',
        InputMethods.SELECT,
        {
            inputOptions: ['left', 'right', 'center'],
            name: 'position',
            label: 'View Option',
            isRequired: true,
            defaultValue: 'left',
        }
    ),
    activity: createField(
        PropTypes.string.isRequired,
        'string',
        InputMethods.SELECT,
        {
            inputOptions: ['fixed', 'static'],
            name: 'activity',
            label: 'Nav Bar Position',
            isRequired: true,
            defaultValue: 'fixed',
        }
    ),
    transparentUntilScroll: createField(
        PropTypes.bool.isRequired,
        'boolean',
        InputMethods.SWITCH,
        {
            name: 'transparentUntilScroll',
            label: 'Transparent Until Scroll',
            isRequired: false,
            defaultValue: false,
        }
    ),
    color: createField(PropTypes.string, 'string', InputMethods.COLOR, {
        name: 'color',
        label: 'Text Color',
        isRequired: false,
        defaultValue: '#000000',
    }),
    backgroundColor: createField(
        PropTypes.string,
        'string',
        InputMethods.COLOR,
        {
            name: 'backgroundColor',
            label: 'Background Color',
            isRequired: false,
            defaultValue: '#FFFFFF',
        }
    ),
    displaySocialIcons: createField(
        PropTypes.bool.isRequired,
        'boolean',
        InputMethods.SWITCH,
        {
            name: 'displaySocialIcons',
            label: 'Display Social Icons',
            isRequired: false,
            defaultValue: false,
        }
    ),
    monoIcons: createField(
        PropTypes.bool.isRequired,
        'boolean',
        InputMethods.SWITCH,
        {
            name: 'monoIcons',
            label: 'Display Mono Icons',
            isRequired: false,
            defaultValue: false,
        }
    ),
    iconColor: createField(PropTypes.string, 'string', InputMethods.COLOR, {
        name: 'iconColor',
        label: 'Icon Color',
        isRequired: false,
        defaultValue: '#606060',
    }),
};

const { propTypes, defaultProps } = generatePropTypesAndDefaults(
    detailedEditorDefinition
);

// Assign propTypes and defaultProps
NavigationSection.propTypes = propTypes;
NavigationSection.defaultProps = defaultProps;

export { NavigationSection as default, detailedEditorDefinition };
