import React, { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';
import makeStyles from '@mui/styles/makeStyles';
import isEmpty from 'lodash.isempty';

const useStyles = makeStyles({
    linkWrapper: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    mobileLink: (props) => ({
        color: props.textColor || 'black',
        textDecoration: 'none',
        margin: 10,
        fontSize: 18,
    }),
});

function MobileNav2(props) {
    const classes = useStyles(props);
    const [isOpen, setOpen] = useState(false);
    useEffect(() => {
        setOpen(props.isOpen);
    }, [props.isOpen]);

    return (
        // <div>
        <Menu
            right
            disableAutoFocus
            customBurgerIcon={false}
            customCrossIcon={false}
            styles={{
                bmMenuWrap: {
                    position: 'fixed',
                    height: '100%',
                },
                bmMenu: {
                    background: props.backgroundColor || '#141414',
                    fontSize: '16',
                },
                bmItemList: {
                    color: props.backgroundColor || '#141414',
                    padding: '0.8em',
                },
                bmOverlay: {
                    background: 'rgba(0, 0, 0, 0.3)',
                },
                bmItem: {
                    padding: 0,
                    margin: 0,
                },
            }}
            width={200}
            isOpen={isOpen}
        >
            <div className={classes.linkWrapper} style={{ display: 'flex' }}>
                {props.routes.map((item) => {
                    if (!isEmpty(item)) {
                        if (item.href.startsWith('#')) {
                            return (
                                <a
                                    className={classes.mobileLink}
                                    onClick={() => {
                                        window.document.getElementById(item.href.replace('#', '')).scrollIntoView();
                                        setOpen(false);
                                    }}
                                >
                                    {item.title}
                                </a>
                            );
                        }
                        return (
                            <a href={item.href} className={classes.mobileLink} onClick={() => setOpen(false)}>
                                {item.title}
                            </a>
                        );
                    }
                })}
            </div>
        </Menu>
        // </div>
    );
}

export default MobileNav2;
