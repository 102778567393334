import isEmpty from 'lodash.isempty';

const openUrl = (url) => {
    if (isEmpty(url)) {
        return;
    }

    window.open(url, '_blank');
};

export { openUrl };
