import FacebookIcon from '../icons/facebook.svg';
import TwitterIcon from '../icons/twitter.svg';
import InstagramIcon from '../icons/instagram.svg';
import SpotifyIcon from '../icons/spotify.svg';
import AppleMusicIcon from '../icons/appleMusic.svg';
import YouTubeIcon from '../icons/youtube.svg';
import SoundCloudIcon from '../icons/soundcloud.svg';
// import ShopifyIcon from '../icons/shopify.svg';
// import PatreonIcon from '../icons/patreon.svg';
import BandsinTown from '../icons/bandsintown.svg';
import SongKick from '../icons/songkick.svg';
import BandCampIcon from '../icons/bandcamp.svg';
import AudioMackIcon from '../icons/audiomack.svg';
import AmazonMusicIcon from '../icons/amazon_music.svg';
import TidalIcon from '../icons/tidal.svg';
import TikTokIcon from '../icons/tiktok.svg';
import { isEmpty } from 'lodash';

const networkDataMap = {
    FACEBOOK: {
        icon: FacebookIcon,
        humanReadableName: 'Facebook',
        primaryColor: '#3A5997',
    },
    TWITTER: {
        icon: TwitterIcon,
        humanReadableName: 'Twitter',
    },
    INSTAGRAM: {
        icon: InstagramIcon,
        humanReadableName: 'Instagram',
    },
    SPOTIFY: {
        icon: SpotifyIcon,
        humanReadableName: 'Spotify',
        primaryColor: '#1CB954',
    },
    APPLE_MUSIC: {
        icon: AppleMusicIcon,
        humanReadableName: 'Apple Music',
    },
    YOUTUBE: {
        icon: YouTubeIcon,
        humanReadableName: 'Youtube',
        primaryColor: '#E74C3C',
    },
    SOUNDCLOUD: {
        icon: SoundCloudIcon,
        humanReadableName: 'Soundcloud',
    },
    BANDSINTOWN: {
        icon: BandsinTown,
        humanReadableName: 'Bandsintown',
    },
    SONGKICK: {
        icon: SongKick,
        humanReadableName: 'Songkick',
    },
    // SHOPIFY: {
    //     icon: ShopifyIcon,
    //     humanReadableName: 'Shopify',
    // },
    // PATREON: {
    //     icon: PatreonIcon,
    //     humanReadableName: 'Patreon',
    // },
    BANDCAMP: {
        icon: BandCampIcon,
        humanReadableName: 'Bandcamp',
    },
    AUDIOMACK: {
        icon: AudioMackIcon,
        humanReadableName: 'Audiomack',
    },
    GOOGLE_ANALYTICS: {
        analyticsUrl: 'https://www.googletagmanager.com/gtag/js?id=G-',
        humanReadableName: 'Google Analytics',
    },
    FACEBOOK_PIXEL: {
        analyticsUrl: 'https://connect.facebook.net/en_US/fbevents.js',
        humanReadableName: 'Facebook Pixel',
    },
    AMAZON_MUSIC: {
        icon: AmazonMusicIcon,
        humanReadableName: 'Amazon Music',
    },
    TIDAL: {
        icon: TidalIcon,
        humanReadableName: 'Tidal',
    },
    TIKTOK: {
        icon: TikTokIcon,
        humanReadableName: 'TikTok',
    },
};

export const getNetworkIcon = (network) => {
    const n = networkDataMap[network];
    return n ? n.icon : '';
};

export const getHumanReadableName = (network) => {
    const n = networkDataMap[network];
    return n ? n.humanReadableName : network;
};

export const getAnalyticsUrl = (network) => {
    const n = networkDataMap[network];
    return n ? n.analyticsUrl : '';
};
export const getPrimaryColor = (network) => {
    const n = networkDataMap[network];
    return n ? n.primaryColor : '#FFFFFF';
};

export const getNetwork = (integrations, network) => { 

    if (isEmpty(integrations)) {
        return undefined;
    }

    const networkIntegration = integrations.filter((i) => i.network == network);
    return !isEmpty(networkIntegration) ? networkIntegration[0] : undefined;
};
