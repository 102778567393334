import React from 'react';
import ResponsiveLink from '../ResponsiveLink';

function CommonContact({ theme, profile }) {
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
            }}
        >
            {profile.publicContacts.map((c) => {
                return (
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <h2
                            style={{
                                marginTop: 20,
                                color: theme.textColor,
                                fontSize: '1.25em',
                            }}
                        >
                            {c.title}
                        </h2>
                        <ResponsiveLink
                            textStyle={{
                                display: 'block',
                                marginBottom: 10,
                                fontFamily: 'font-family: Varela, sans-serif',
                                color: theme.textColor,
                                textDecoration: 'none',
                                opacity: 0.9,
                                fontSize: '1em',
                            }}
                            linkText={c.email}
                        />
                    </div>
                );
            })}
        </div>
    );
}

export default CommonContact;
