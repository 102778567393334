const template6 = {
    componentList: {
        news: {
            userDefinedProps: {
                layout: {
                    columns: 3,
                    spacing: 8,
                },
                sectionTitle: 'NEWS',
                cardType: 'Card Option 3',
                layoutType: 'Slider',
                sectionBackgroundColor: '#ffffff',
                sectionTextColor: '#000000',
                card: {
                    textColor: '#000000',
                },
            },
            componentName: 'news',
            isVisible: true,
            version: 1,
            order: 6,
        },
        navigation: {
            userDefinedProps: {
                layout: '1',
                backgroundColor: '#ffffff',
                activity: 'fixed',
                color: '#000000',
                monoIcons: true,
                iconColor: '#000000',
                position: 'left',
                transparentUntilScroll: false,
                displaySocialIcons: true,
            },
            componentName: 'navigation',
            isVisible: true,
            version: 1,
            order: 1,
        },
        music: {
            userDefinedProps: {
                layout: {
                    columns: 4,
                    spacing: 6,
                },
                backgroundColor: 'black',
                color: '',
                sectionTitle: 'MUSIC',
                displayLayout: 'Slider',
                cardType: 'Card Option 8',
                layoutType: 'Slider',
                sectionBackgroundColor: '#ffffff',
                sectionTextColor: '#000000',
                cols: '3',
                card: {
                    textColor: '#ffffff',
                },
            },
            componentName: 'music',
            isVisible: true,
            version: 1,
            order: 4,
        },
        footer: {
            userDefinedProps: {
                variant: '1',
                backgroundColor: '#ffffff',
                color: '#000000',
                displaySocialIcons: true,
            },
            componentName: 'footer',
            isVisible: true,
            version: 1,
            order: 11,
        },
        contact: {
            userDefinedProps: {
                color: '#000000',
                layout: 'Three Cols',
                textColor: '#000000',
            },
            componentName: 'contact',
            isVisible: true,
            version: 1,
            order: 9,
        },
        about: {
            userDefinedProps: {
                variant: '2',
                backgroundColor: '#ffffff',
                color: '#000000',
                sectionTitle: 'ABOUT',
            },
            componentName: 'about',
            isVisible: true,
            version: 1,
            order: 8,
        },
        header: {
            userDefinedProps: {
                layout: 'New Music Release Dark (Left)',
                button: 'Listen Now',
                backgroundColor: '#fffdfd',
                color: '#000000',
                subtitle: 'New Release',
                btnColor: '#FFFFFF',
                btnBg: '#22AA02',
                sectionBackgroundColor: '#000000',
                source: 'New Music',
                displaySocialIcons: true,
            },
            componentName: 'header',
            isVisible: true,
            version: 1,
            order: 2,
        },
        video: {
            userDefinedProps: {
                layout: {
                    spacing: 1,
                },
                sectionTitle: 'VIDEOS',
                cardType: 'Card Option 9',
                layoutType: 'Grid',
                sectionBackgroundColor: '#ffffff',
                sectionTextColor: '#000000',
                card: {
                    textColor: '#000000',
                },
            },
            componentName: 'video',
            isVisible: true,
            version: 1,
            order: 5,
        },
        photos: {
            userDefinedProps: {},
            componentName: 'photos',
            isVisible: false,
            version: 1,
            order: 7,
        },
        email: {
            userDefinedProps: {
                backgroundColor: "#cdced0",
                buttonBackgroundColor: "#565555",
                buttonColor: "#e8e8e8",
                color: "#4c4a4a",
            },
            componentName: 'email',
            isVisible: false,
            version: 1,
            order: 10,
        },
        tour: {
            userDefinedProps: {
                buttonBackgroundColor: '#000000',
                backgroundColor: '#ffffff',
                color: '#000000',
                titleColor: '#000000',
                buttonColor: '#fdfdfd',
            },
            componentName: 'tour',
            isVisible: true,
            version: 1,
            order: 3,
        },
    },
    websiteFont: 'Allerta',
};

export default template6;
