import React, { useState } from 'react';

function AboutSection(props) {
    const [isFullView, setIsFullView] = useState(false);
    const charLimit = 500;
    const { bio } = props.profile;
    return (
        <div id='about-section' className='about-section'>
            <div className='section-header'>
                <h1
                    className='section-header'
                    style={{ color: props.theme.textColor }}
                >
                    About
                </h1>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                }}
            >
                {props.profile && props.profile.pictureurl && (
                    <img
                        src={props.profile.pictureurl}
                        style={{ width: 500, height: 500, objectFit: 'cover' }}
                        alt
                    />
                )}
                <div style={{ width: '70vw', margin: 20 }}>
                    <p
                        className='paragraph'
                        style={{ color: props.theme.textColor }}
                    >
                        {bio && (
                            <strong
                                className='bold-text'
                                style={props.textStyle}
                            >
                                {isFullView
                                    ? bio
                                    : bio.substring(0, charLimit) +
                                      (bio.length > charLimit ? '...' : '')}
                            </strong>
                        )}
                        {bio && bio.length > charLimit && (
                            <a
                                onClick={() => setIsFullView(!isFullView)}
                                style={{
                                    color: 'white',
                                    fontWeight: 'bold',
                                }}
                            >
                                {isFullView ? ' Show Less' : ' Show More'}
                            </a>
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default AboutSection;
