const template8 = {
    componentList: {
        news: {
            userDefinedProps: {
                layout: {
                    numItemsToRender: 3,
                    spacing: 3,
                    rows: 4,
                    columns: 3,
                },
                sectionWidth: 'Narrow',
                mediaOverride:
                    'https://i.scdn.co/image/ab67616d0000b273b5768c0dc620d8e5c8f3193b',
                sectionTitle: 'NEWS',
                cardType: 'Card Option 1',
                layoutType: 'Grid',
                sectionBackgroundColor: '#ffffff',
                sectionTextColor: '#000000',
                card: {
                    textColor: '#000000',
                    roundedCorners: true,
                    backgroundColor: '#ffffff',
                },
            },
            componentName: 'news',
            isVisible: true,
            version: 1,
            order: 4,
        },
        navigation: {
            userDefinedProps: {
                backgroundColor: '#ffffff',
                color: '#161515',
                activity: 'fixed',
                monoIcons: false,
                iconColor: '#ffffff',
                position: 'left',
                transparentUntilScroll: false,
                displaySocialIcons: true,
            },
            componentName: 'navigation',
            isVisible: true,
            version: 1,
            order: 1,
        },
        music: {
            userDefinedProps: {
                layout: {
                    showMore: false,
                    spacing: 4,
                    rows: 3,
                    columns: 3,
                },
                sectionWidth: 'Narrow',
                mediaOverride:
                    'https://i.scdn.co/image/ab67616d0000b2731990945b14d9979364ef0259',
                sectionTitle: 'Music',
                sectionBackgroundImage: null,
                cardType: 'Card Option 3',
                layoutType: 'Grid',
                sectionBackgroundColor: '#ffffff',
                sectionTextColor: '#000000',
                card: {
                    textColor: '#000000',
                    backgroundColor: '#fafafa',
                },
                displayOnNavigation: true,
            },
            componentName: 'music',
            isVisible: true,
            version: 1,
            order: 3,
        },
        footer: {
            userDefinedProps: {
                variant: '1',
                backgroundColor: '#ffffff',
                color: '#000000',
                displaySocialIcons: true,
            },
            componentName: 'footer',
            isVisible: true,
            version: 1,
            order: 11,
        },
        contact: {
            userDefinedProps: {
                layout: 'One Col',
                backgroundColor: '#ffffff',
                color: '#000000',
                mediaOverride: '',
                textColor: '#000000',
            },
            componentName: 'contact',
            isVisible: true,
            version: 1,
            order: 9,
        },
        about: {
            userDefinedProps: {
                variant: '1',
                backgroundColor: '#ffffff',
                color: '#000000',
                mediaOverride:
                    'https://i.scdn.co/image/ab67616d0000b273b5768c0dc620d8e5c8f3193b',
                sectionTitle: 'ABOUT ME',
            },
            componentName: 'about',
            isVisible: true,
            version: 1,
            order: 8,
        },
        header: {
            userDefinedProps: {
                layout: 'right',
                button: 'Listen Now',
                backgroundColor: '#ffffff',
                color: '#000000',
                subtitle: 'New Release',
                btnColor: '#ffffff',
                btnBg: '#22aa02',
                sectionBackgroundColor: '#000000',
                source: 'New Music',
                displaySocialIcons: true,
            },
            componentName: 'header',
            isVisible: true,
            version: 1,
            order: 2,
        },
        video: {
            userDefinedProps: {
                layout: {
                    showMore: true,
                    spacing: 2,
                    rows: 2,
                    columns: 3,
                },
                sectionWidth: 'Narrow',
                mediaOverride:
                    'https://i.scdn.co/image/ab67616d0000b273b5768c0dc620d8e5c8f3193b',
                sectionTitle: 'VIDEOS',
                cardType: 'Card Option 4',
                layoutType: 'Grid',
                sectionBackgroundColor: '#ffffff',
                sectionTextColor: '#000000',
                card: {
                    textColor: '#ffffff',
                    roundedCorners: false,
                    backgroundColor: '#ffffff',
                },
                displayOnNavigation: false,
            },
            componentName: 'video',
            isVisible: true,
            version: 1,
            order: 5,
        },
        photos: {
            userDefinedProps: {
                sectionWidth: 'Full',
                mediaOverride:
                    'https://i.scdn.co/image/ab67616d0000b273b5768c0dc620d8e5c8f3193b',
                sectionTitle: 'Photo ',
                cardType: 'Card Option 2',
                sectionBackgroundColor: '#272121',
                sectionTextColor: '#cebebe',
                card: {
                    textColor: '#fceded',
                    backgroundColor: '#edd6d6',
                },
            },
            componentName: 'photos',
            isVisible: true,
            version: 1,
            order: 7,
        },
        tour: {
            userDefinedProps: {
                buttonBackgroundColor: '#000000',
                sectionWidth: 'Narrow',
                backgroundColor: '#ffffff',
                color: '#000000',
                titleColor: '#000000',
                mediaOverride:
                    'https://i.scdn.co/image/ab67616d0000b273b5768c0dc620d8e5c8f3193b',
                buttonColor: '#ffffff',
            },
            componentName: 'tour',
            isVisible: true,
            version: 1,
            order: 6,
        },
        email: {
            userDefinedProps: {
                backgroundColor: "#cdced0",
                buttonBackgroundColor: "#565555",
                buttonColor: "#e8e8e8",
                color: "#4c4a4a",
            },
            componentName: 'email',
            isVisible: false,
            version: 1,
            order: 10,
        },
    },
    websiteFont: 'Anton',
};

export default template8;
