import React from 'react';
import { styled } from '@mui/material/styles';
import { getHumanReadableName, getNetworkMonoIcon } from '../../utils/monoIcon';
import isEmpty from 'lodash.isempty';

const StyledOptionBox = styled('div')(({ theme, icon }) => ({
    paddingRight: icon ? '0.8rem' : undefined,
}));

const isValidHexColor = (str) => /^#([0-9A-F]{3}){1,2}$/i.test(str);

const OptionBox = ({
    network,
    url,
    color,
    icon,
    excludedNetworks = [
        'MUSICBRAINZ', 'BOOKING', 'SHOPIFY', 'MAILCHIMP', 'EPK',
        'GOOGLE_ANALYTICS', 'FACEBOOK_PIXEL', 'PATREON',
    ],
}) => {
    if (isEmpty(url) || excludedNetworks.includes(network)) return null;

    let iconElement;
    try {
        if (isValidHexColor(color)) {
            iconElement = getNetworkMonoIcon(network, color);
        } else {
            iconElement = getNetworkMonoIcon(network);
        }
    } catch (error) {
        console.error(`Error in getNetworkMonoIcon: ${error}`);
        return null;
    }

    return (
        <StyledOptionBox icon={icon}>
            <a
                href={url}
                target='_blank'
                rel='noopener noreferrer'
                style={{ textDecoration: 'none' }} // Example style, adjust as needed
            >
                {icon ? iconElement : getHumanReadableName(network)}
            </a>
        </StyledOptionBox>
    );
};

export default OptionBox;
