import React from "react";
import ShowMore from "@tedconf/react-show-more";
import isEmpty from 'lodash.isempty';

function News({ news }) {
  return (
    <div id="news-section" className="news-section">
      {!isEmpty(news) && (
        <div className="news-section-wrapper">
          <div className="music-section-header-wrapper">
            <h1 className="section-header">News</h1>
          </div>
          <ShowMore items={news} by={4}>
            {({ current, onMore }) => (
              <>
                <div className="news-cell-grid">
                  {current.map(item => {
                    return (
                      <a href={item.url} target='_blank' style={{ textDecoration: 'inherit', color: 'inherit' }}>
                        <div className="news-cell-wrapper">
                          <div className="news-image-div">
                            <img
                              src={item.media}
                              width={450}
                              sizes="(max-width: 479px) 100vw, (max-width: 767px) 69vw, (max-width: 991px) 56vw, 48vw"
                              alt
                              className="news-image"
                            />
                          </div>
                          <div className="news-info-wrapper">
                            <h3 className="news-article-header">{item.title}</h3>
                            <h6 className="news-article-source">{item.source}</h6>
                          </div>
                        </div>
                      </a>
                    );
                  })}
                </div>
                {news.length !== current.length && (
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      alignSelf: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <button
                      outline
                      className="button w-button"
                      disabled={!onMore}
                      onClick={() => {
                        if (!!onMore) onMore();
                      }}
                    >
                      Load more
                    </button>
                  </div>
                )}
              </>
            )}
          </ShowMore>
        </div>
      )}
    </div>
  );
}

export default News;
