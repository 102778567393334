import axios from 'axios';
import { config } from '../config';
import { PUBLIC_ROUTES } from '../routes';

/**
 * Service class for handling API requests with authentication via a refresh token.
 */
class JamFeedApiService {
    /**
     * Constructs the JamFeedApiService instance and initializes its properties.
     */
    constructor() {
        this.accessToken = null;
        this.isAuthenticated = false;
        this.refreshToken = null;
        this.logoutCallback = null;
        this.client = this.createAxiosClient();
    }

    /**
     * Sets the authentication status.
     * @param {boolean} isAuth - The authentication status.
     */
    setAuthenticationStatus(isAuth) {
        this.isAuthenticated = isAuth;
    }

    /**
     * Gets the current authentication status.
     * @returns {boolean} The current authentication status.
     */
    getAuthenticationStatus() {
        return this.isAuthenticated;
    }

    /**
     * Creates and configures an Axios client with predefined settings.
     * @returns {axios.AxiosInstance} The configured Axios instance.
     */
    createAxiosClient() {
        const jamfeedClient = axios.create({
            baseURL: `${config.JAMFEED_API}`,
            timeout: 10000,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        // Request Interceptor
        // Request Interceptor
        jamfeedClient.interceptors.request.use(async (config) => {
            if (!config) return;
            if (this.getAuthenticationStatus()) {
                // update token
                await this.updateToken();
                config.headers.Authorization = `Bearer ${this.accessToken}`;
            } else {
                if (config.headers && 'Authorization' in config.headers) {
                    // If Authorization property exists, delete it
                    delete config.headers.Authorization;
                }
            }
            return config;
        });
        
        // Response Interceptor for handling authentication errors
        jamfeedClient.interceptors.response.use(
            (response) => response,
            async (error) => {
                const originalRequest = error.config;
                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    await this.updateToken();
                    originalRequest.headers.Authorization = `Bearer ${this.accessToken}`;
                    return jamfeedClient(originalRequest);
                }
                return Promise.reject(error);
            }
        );

        return jamfeedClient;
    }

    /**
     * Sets the method used for refreshing the access token.
     * @param {function} refreshToken - The method to be called for refreshing the token.
     */
    setRefreshToken(refreshToken) {
        this.refreshToken = refreshToken;
    }

    /**
     * Sets the access token used for authenticated requests.
     * @param {string} accessToken - The access token.
     */
    setAccessToken(accessToken) {
        this.accessToken = accessToken;
    }

    /**
     * Refreshes and updates the access token using the set refresh token method.
     */
    updateToken = async () => {
        if (typeof this.refreshToken !== 'function') {
            return this.setAccessToken(this.accessToken || null);
        }
        const newToken = await this.refreshToken();
        this.setAccessToken(newToken);
    };

    /**
     * Sets a callback function to be called upon logout.
     * @param {function} logoutCallback - The callback function for logout.
     */
    setupLogoutCallback(logoutCallback) {
        this.logoutCallback = logoutCallback;
    }
    /**
     * Generic method for making API requests, automatically refreshing the token beforehand.
     * @param {string} method - The HTTP method to use for the request.
     * @param  {...any} params - Parameters to pass to the Axios request method.
     * @returns {Promise} A promise resolving to the response of the API request.
     */
    async makeRequest(method, ...params) {
        try {
            // console.debug(
            //     `Making ${method.toUpperCase()} request with params:`,
            //     params
            // );
            const response = await this.client[method](...params);
            // console.debug(
            //     `Received response status for ${method.toUpperCase()} request:`,
            //     response.status
            // );
            return response;
        } catch (error) {
            console.error(
                `Error making ${method.toUpperCase()} request:`,
                error
            );
            throw error;
        }
    }

    get(...params) {
        return this.makeRequest('get', ...params);
    }
    post(...params) {
        return this.makeRequest('post', ...params);
    }
    put(...params) {
        return this.makeRequest('put', ...params);
    }
    patch(...params) {
        return this.makeRequest('patch', ...params);
    }
    remove(...params) {
        return this.makeRequest('delete', ...params);
    }
    request(...params) {
        return this.makeRequest('request', ...params);
    }
}

// Singleton Implementation
/**
 * Singleton instance of JamFeedApiService.
 * @returns {JamFeedApiService} The singleton instance.
 */
let instance = null;
const getInstance = () => {
    if (!instance) {
        instance = new JamFeedApiService();
    }
    return instance;
};

export default getInstance();
